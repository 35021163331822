import React from 'react';
import { useProgressBar } from 'react-aria';
import cx from 'classnames';
import type { ReactNode } from 'react';
import css from './Spinner.module.css';

export interface SpinnerProps {
  label?: string;
  className?: string;
  /**
   * If passed, will be used to render the spinner.
   * The children will be inside a div with the `progressbar` role.
   */
  children?: ReactNode;
}

export const Spinner = ({ label = 'Loading...', className, children }: SpinnerProps) => {
  const { progressBarProps } = useProgressBar({
    isIndeterminate: true,
    'aria-label': label,
  });

  return (
    <div {...progressBarProps}>
      {children ? (
        children
      ) : (
        <svg
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={cx(css.spinner, className)}
        >
          <path
            d="M5.6 28c0 12.371 10.029 22.4 22.4 22.4 12.371 0 22.4-10.029 22.4-22.4C50.4 15.629 40.371 5.6 28 5.6"
            stroke="url(#a)"
          />
          <defs>
            <linearGradient
              id="a"
              x1="5.6"
              y1="43.867"
              x2="38.049"
              y2="56.311"
              gradientUnits="userSpaceOnUse"
            >
              <stop className={css.firstStop} />
              <stop className={css.secondStop} offset="1" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};

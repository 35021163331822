import { ScreenerQuestion } from 'types/grdn';
import { PrimaryKey } from 'types/tables/base';

export interface ScreenerSection {
  key: string;
  questionList: ScreenerQuestion[];
}

export interface ScreenerWithResponses {
  description: string;
  displayNumberings: boolean;
  encounterId: PrimaryKey;
  id: PrimaryKey;
  name: ScreenerType;
  questionnaireId: string;
  scoringStatus: string;
  screenerGroup: string;
  sections: ScreenerSection[];
  source: string;
}

export interface ScreenerResponse {
  chatPostable: boolean;
  completed: boolean;
  createdAt: string;
  id: PrimaryKey;
  patientId: PrimaryKey;
  requiresAssignment: boolean;
  screenerId: PrimaryKey;
  screenerWithResponses: ScreenerWithResponses;
  showOnLoad: boolean;
  updatedAt: string;
}

export enum ScreenerType {
  dailyCovid = 'COVID-19 Daily Screener',
  srha = 'SRHA',
  welcome = 'Eden Welcome Survey',
}

import React, { useEffect } from 'react';
import { Tab, Menu } from 'semantic-ui-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Alert from '../../ui/Alert';
import Files from '../Files';
import css from './InfoPane.module.css';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import TicklersPresenceDot from 'components/features/InfoPane/TicklersPresenceDot';
import NextStepPresenceDot from 'components/features/InfoPane/NextStepPresenceDot';
import {
  fetchNextSteps,
  fetchPatientTicklers,
  getPatientNextStepsLoadingState,
  getPatientTicklerLoadingState,
} from 'reducers/patients';
import MedicalSummary from 'components/features/MedicalSummary/MedicalSummary';
import TicklerOverview from 'components/features/TicklerOverview/TicklerOverview';
import VisitOverview from 'components/features/VisitOverview/VisitOverview';
import DigitalVisitButton from 'components/features/DigitalVisitButton/DigitalVisitButton';
import CarePlan from 'components/features/CarePlan/CarePlan';
import { getIsCloseKnitPatient } from 'reducers/providerGroups';
import CreateButton from 'components/features/CreateButton/CreateButton';
import { getInitialFiles, stopPollingFiles } from 'reducers/files';

interface InfoPaneProps {}

const InfoPane: React.FC<InfoPaneProps> = () => {
  //TODO(@bear-eden): [APP-7431] providerShowCarePlanCloseKnit: remove when obsolete
  const { athenaOutage, pdfUploadEnabled, providerShowCarePlanCloseKnit } = useFlags();
  const dispatch = useAppDispatch();

  const { id, sponsorId } = useActivePatient();
  const ticklersLoadingState = useParameterizedSelector(getPatientTicklerLoadingState, id);
  const nextStepsLoadingState = useParameterizedSelector(getPatientNextStepsLoadingState, id);
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, sponsorId);

  useEffect(() => {
    if (ticklersLoadingState === undefined) {
      dispatch(fetchPatientTicklers({ patientId: id }));
    }
  }, [dispatch, id, ticklersLoadingState]);

  useEffect(() => {
    if (nextStepsLoadingState === undefined) {
      dispatch(fetchNextSteps({ patientId: id }));
    }
  }, [dispatch, id, nextStepsLoadingState]);

  useEffect(() => {
    dispatch(getInitialFiles(id));
    return () => {
      dispatch(stopPollingFiles());
    };
  }, [dispatch, id]);

  const renderAthenaOutageMessage = () => {
    return (
      <div className={css.alertContainer}>
        <Alert severity="error">
          This functionality is not available due to the Athena outage.
        </Alert>
      </div>
    );
  };

  const renderMedicalSummary = () => {
    return (
      <Tab.Pane attached={false} vertical>
        <MedicalSummary />
      </Tab.Pane>
    );
  };

  const renderVisitOverview = () => {
    return (
      <Tab.Pane attached={false} vertical>
        <VisitOverview />
      </Tab.Pane>
    );
  };

  const renderTicklersOverview = () => {
    return (
      <Tab.Pane attached={false} vertical>
        <TicklerOverview />
      </Tab.Pane>
    );
  };

  const renderCarePlan = () => {
    return (
      <Tab.Pane attached={false} vertical>
        <CarePlan />
      </Tab.Pane>
    );
  };

  const renderFiles = () => {
    return (
      <Tab.Pane attached={false} vertical>
        <Files />
      </Tab.Pane>
    );
  };

  const panes = [
    {
      menuItem: <Menu.Item key="medical-summary-tab">Medical Summary</Menu.Item>,
      render: athenaOutage ? renderAthenaOutageMessage : renderMedicalSummary,
    },
    {
      menuItem: <Menu.Item key="visits-cases-tab">Visits &amp; Cases</Menu.Item>,
      render: athenaOutage ? renderAthenaOutageMessage : renderVisitOverview,
    },
    {
      menuItem: (
        <Menu.Item key="ticklers-tab">
          Ticklers
          <TicklersPresenceDot />
        </Menu.Item>
      ),
      render: athenaOutage ? renderAthenaOutageMessage : renderTicklersOverview,
    },
    ...(providerShowCarePlanCloseKnit || !isCloseKnitPatient
      ? [
          {
            menuItem: (
              <Menu.Item key="care-plan-tab">
                Care Plan <NextStepPresenceDot />
              </Menu.Item>
            ),
            render: athenaOutage ? renderAthenaOutageMessage : renderCarePlan,
          },
        ]
      : []),
    ...(pdfUploadEnabled
      ? [
          {
            menuItem: <Menu.Item key="files-tab">Files</Menu.Item>,
            render: athenaOutage ? renderAthenaOutageMessage : renderFiles,
          },
        ]
      : []),
  ];

  return (
    <div className={css.menuContainer}>
      <div className={css.tab}>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} defaultActiveIndex={0} />
      </div>
      <div className={css.sideButtonSection}>
        <DigitalVisitButton />
        <CreateButton />
      </div>
    </div>
  );
};

export default InfoPane;

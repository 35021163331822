import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const CrossMark = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="m9.078 8 6.699-6.7A.763.763 0 1 0 14.7.223L8 6.923 1.3.222A.762.762 0 1 0 .224 1.3l6.7 6.7-6.7 6.7a.762.762 0 1 0 1.078 1.077l6.7-6.7 6.699 6.7a.762.762 0 0 0 1.077-1.078L9.077 8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CrossMark;

import React, { MouseEvent, useState } from 'react';
import Papa from 'papaparse';
import { intersection, isNull, uniq, xor } from 'lodash';
import { toast } from 'react-toastify';
import css from './styles.module.css';
import { FormProps, handleInternalError } from './index';
import { dropTrailingEmptyRows } from 'lib/util';
import { FormHeader, SubmitButton } from 'components/ui/Forms';
import { Folder } from 'components/ui/svg';
import * as GrdnApi from 'lib/grdn';
import SelectFile from 'components/ui/SelectFile';
import { isSuccessResponse } from 'types/api';

const UploadRoster: React.FC<FormProps> = (props: FormProps) => {
  const { sponsor } = props;
  const [csvText, setCsvText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [csvValid, setCsvValid] = useState(false);
  const requiredColumns = ['first_name', 'last_name', 'dob', 'employee_id', 'email'];

  const missingColumns = (parseResults) => {
    const fields = parseResults.meta.fields;
    const foundColumns = intersection(fields, requiredColumns);
    return xor(requiredColumns, foundColumns);
  };

  const badColumns = (parseResults) => {
    // Finds columns lacking names and returns the spreadsheet-friendly column indicators
    // e.g., 'A', 'D'.
    return parseResults.meta.fields.reduce((acc, field, index) => {
      if (!field) acc.push(String.fromCharCode(65 + index));
      return acc;
    }, []);
  };

  const rowsWithMissingData = (csvData) => {
    return csvData.reduce((acc, row, index) => {
      const missingData = requiredColumns.filter((columnName) => {
        return row[columnName] === '' || isNull(row[columnName]);
      }, []);
      if (missingData.length > 0) acc.push({ index, missingData });
      return acc;
    }, []);
  };

  const findDuplicates = (csvData, fieldName) => {
    const values = csvData.map((row) => row[fieldName]);
    const duplicates = values.filter((el, idx) => idx !== values.lastIndexOf(el));
    return uniq(duplicates);
  };

  const badDobRows = (csvData) => {
    const re = /^\d\d\d\d-\d\d-\d\d$/;
    return csvData.reduce((acc, row, index) => {
      if (!row['dob'].match(re)) {
        acc.push(index);
      }
      return acc;
    }, []);
  };

  const validateCSV = (text, fileName) => {
    const delimiter = ', ';
    const listDelimiter = '\n- ';
    setCsvValid(false);
    setCsvText('');
    // Filename
    if (!sponsor.schemaName) throw 'Sponsor has no schema';
    const sponsorShortName = sponsor.schemaName.substring(8);
    const re = new RegExp(sponsorShortName, 'i');
    if (isNull(fileName.match(re))) {
      setErrorText(`File name must include sponsor short name (${sponsorShortName})`);
      return;
    }
    // General formatting errors
    const parseResults = Papa.parse<Record<string, string>>(text, {
      header: true,
      skipEmptyLines: true,
    });
    if (parseResults.errors.length > 0) {
      const messageList = parseResults.errors.map((e) => e.message);
      setErrorText(messageList.join('\n'));
      return;
    }
    // Missing columns
    const missingCols = missingColumns(parseResults);
    if (missingCols.length) {
      setErrorText(
        `CSV is missing the following required column(s): ${missingCols.join(delimiter)}`,
      );
      return;
    }
    // Bad column names
    const badCols = badColumns(parseResults);
    if (badCols.length) {
      setErrorText(`CSV columns ${badCols.join(delimiter)} lack names`);
      return;
    }
    // Remove trailing empty rows
    const trimmedCsvData = dropTrailingEmptyRows(parseResults.data);
    // Missing data
    const missingDataRows = rowsWithMissingData(trimmedCsvData);
    if (missingDataRows.length) {
      const missingDataText = missingDataRows.map(
        // Add 2 to each row index to correspond to display in spreadsheet programs: 1-indexed plus header
        (e) => `- Row ${e.index + 2} is missing data in column(s) ${e.missingData.join(delimiter)}`,
      );
      setErrorText(`CSV has missing data:\n${missingDataText.join('\n')}`);
      return;
    }
    // Duplicate employee IDs
    const duplicateEids = findDuplicates(trimmedCsvData, 'employee_id');
    if (duplicateEids.length) {
      setErrorText(
        `CSV contains duplicate Employee ID(s):${listDelimiter}${duplicateEids.join(
          listDelimiter,
        )}`,
      );
      return;
    }
    // Duplicate emails
    const duplicateEmails = findDuplicates(trimmedCsvData, 'email');
    if (duplicateEmails.length) {
      setErrorText(
        `CSV contains duplicate Email(s):${listDelimiter}${duplicateEmails.join(listDelimiter)}`,
      );
      return;
    }
    // Bad date of birth format
    const badDobs = badDobRows(trimmedCsvData);
    if (badDobs.length) {
      const displayRows = badDobs.map((r) => r + 2);
      setErrorText(`DOB format is not YYYY-MM-DD in row(s): ${displayRows.join(delimiter)}`);
      return;
    }
    // Valid roster
    setCsvValid(true);
    setErrorText('');
    const strippedCsvText = Papa.unparse(trimmedCsvData, {
      newline: '\n',
      skipEmptyLines: true,
    });
    setCsvText(strippedCsvText);
  };

  const submitForm = async (e: MouseEvent) => {
    const clearAndClose = () => {
      setCsvText('');
      setErrorText('');
      props.closeModal();
    };
    e.preventDefault();
    if (csvValid) {
      try {
        const resp = await GrdnApi.uploadRoster({ sponsorId: props.sponsor.id, csvText });
        if (isSuccessResponse(resp)) {
          toast.success('Roster successfully uploaded.');
        }
      } catch (e) {
        const errorMsg = handleInternalError(e);
        toast.error(`Unable to upload roster.\nError details: ${errorMsg}`);
      }
      clearAndClose();
    }
  };

  return (
    <div data-testid={`upload-roster-form-${props.sponsor.id}`}>
      <FormHeader formName={'Upload roster'} icon={Folder} />
      <SelectFile
        setFileText={validateCSV}
        fileFormat="text/csv"
        prompt="Select CSV file"
        className={css.selectFile}
        maxFileSize={4000000}
      />
      <SubmitButton disabled={!csvValid} onClick={submitForm} data-testid="upload-button">
        Upload
      </SubmitButton>
      {errorText && (
        <div className={css.error} data-testid={`upload-roster-error-${props.sponsor.id}`}>
          {errorText}
        </div>
      )}
    </div>
  );
};

export default UploadRoster;

import { intersection, isEmpty } from 'lodash';
import { hasProviderRole, hasNavigatorRole } from 'lib/util';
import { Patient } from 'types/tables/patients';
import { SidebarChannel } from 'types/tables/channels';
import { Carepod } from 'types/tables/carepods';
import { Provider } from 'types/tables/providers';

export const assignmentFilterOptions = {
  all: 'All',
  you: 'You',
  providers: 'Providers',
  associates: 'Navigators',
};

const createAssignmentFilter = (
  assignmentFilter = assignmentFilterOptions.all,
  providers,
  activeUserProviderId,
) => {
  const isYou = (patient: Patient) => patient.assigned.includes(activeUserProviderId);
  const isAssociate = (patient: Patient) => {
    return !isEmpty(
      intersection(
        providers.filter((c) => hasNavigatorRole(c)).map((c) => c.id),
        patient.assigned,
      ),
    );
  };
  const isProvider = (patient: Patient) => {
    return !isEmpty(
      intersection(
        providers.filter((c) => hasProviderRole(c)).map((c) => c.id),
        patient.assigned,
      ),
    );
  };

  return (channel) => {
    const { patient } = channel;
    switch (assignmentFilter) {
      case assignmentFilterOptions.you:
        return isYou(patient);
      case assignmentFilterOptions.providers:
        return isProvider(patient);
      case assignmentFilterOptions.associates:
        return isAssociate(patient);
      case assignmentFilterOptions.all:
      default:
        return true;
    }
  };
};

export const generateAssignmentFilters = (channels, providers, activeUserProviderId) => {
  return Object.values(assignmentFilterOptions).map((option) => ({
    filter: option as string,
    patients: channels.filter(createAssignmentFilter(option, providers, activeUserProviderId)),
  }));
};

export const generateUnassignedFilters = (channels: SidebarChannel[], carepods: Carepod[]) => {
  return carepods.map((carepod) => ({
    filter: carepod.name,
    patients: channels.filter(({ patient }) => {
      return patient.carePodId === carepod.id;
    }),
  }));
};

export const generateRoutingFilters = (channels: SidebarChannel[], systemProviders: Provider[]) => {
  return systemProviders.map((systemProvider) => ({
    filter: systemProvider.displayName,
    patients: channels.filter(({ patient }) => {
      return patient.assigned.includes(systemProvider.id);
    }),
  }));
};

import classNames from 'classnames';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { Comment, Header, SemanticCOLORS } from 'semantic-ui-react';
import { ChannelType } from 'types/tables/channels';
import css from 'components/features/Chat/MessageContainer.module.css';
import MessageControls from 'components/features/Chat/MessageControls';
import { shouldDivideChat } from 'components/features/Chat/util';
import Tooltip from 'components/design-system/Tooltip';
import { User } from 'types/tables/user';
import { getProviderBySendbirdUserId } from 'reducers/providers';
import { AdminMessageType, Message, PrimaryMessageType } from 'types/tables/messages';
import { Profile } from 'types/tables/profiles';
import { getPatientDisplayName, getPatientTimeZone } from 'selectors/profiles';
import { useActiveChat, useParameterizedSelector } from 'lib/hooks';

export interface MessageContainerProps {
  message: Message;
  profile?: Profile;
  previous?: Message;
  user: User;
  content?: ReactElement;
  lastReadTimestamp?: number;
}

const isOfAdminMessageType = (message: Message) =>
  Object.values<string>(AdminMessageType).includes(message.type);

export const useProviderName = (message: Message, profile?: Profile): string => {
  const providerSender = useParameterizedSelector(
    getProviderBySendbirdUserId,
    'senderId' in message ? message.senderId : '',
  );

  const senderId = 'senderId' in message ? message.senderId : undefined;

  const patientId = profile ? profile.patientId : '';
  const patientDisplayName = useParameterizedSelector(getPatientDisplayName, patientId);

  return isOfAdminMessageType(message)
    ? 'Care Team'
    : senderId && providerSender
    ? providerSender.displayName
    : patientDisplayName ?? '';
};

const MessageContainer = (props: MessageContainerProps) => {
  const { message, user, profile, previous, content, lastReadTimestamp } = props;
  const patientId = profile ? profile.patientId : '';
  const patientTimeZone = useParameterizedSelector(getPatientTimeZone, patientId);

  const { channel } = useActiveChat();

  const providerSender = useParameterizedSelector(
    getProviderBySendbirdUserId,
    'senderId' in message ? message.senderId : '',
  );

  const name = useProviderName(message, profile);
  const providerSenderId = providerSender && providerSender.id;

  const senderId = 'senderId' in message ? message.senderId : undefined;

  const messageSentByUser = providerSender?.googleId === user.googleId;

  const color: SemanticCOLORS = isOfAdminMessageType(message)
    ? 'blue'
    : senderId && providerSender
    ? messageSentByUser
      ? 'pink'
      : 'blue'
    : 'teal';

  const timestamp = message.timestamp;
  const prevTimestamp = previous?.timestamp;
  const time = DateTime.fromMillis(timestamp);
  const providerTime = time.toFormat('h:mma');
  const patientTime = patientTimeZone ? time.setZone(patientTimeZone).toFormat('h:mma ZZZZ') : '';
  const prevTime = prevTimestamp ? DateTime.fromMillis(prevTimestamp).toFormat('h:mma') : null;
  const isApptMessage = message.type === AdminMessageType.Appointment;
  const prevIsApptMsg = previous?.type === AdminMessageType.Appointment;
  const isAfterhoursMessage = message.type === AdminMessageType.AfterHours;
  const prevIsAfterhoursMsg = previous?.type === AdminMessageType.AfterHours;

  const isDiffSender =
    (isAfterhoursMessage && !prevIsAfterhoursMsg) ||
    get(message, 'senderId') !== get(previous, 'senderId') ||
    (isApptMessage && !prevIsApptMsg); // TODO: something is broken here. message.senderId doesn't exist in the message type and lodash is hiding it!
  const isDiffTime = providerTime !== prevTime;
  const isDiffDay = shouldDivideChat(message, previous);
  const primaryTime = isDiffTime || isDiffSender || isDiffDay ? providerTime : null;
  const messageTypeCanDelete =
    (message.type === PrimaryMessageType.Text && !('pendStatus' in message)) ||
    message.type === PrimaryMessageType.Image ||
    message.type === PrimaryMessageType.Document;

  // All users can delete this message if it's a document and sent by a provider.
  const isMessageAnyoneCanDelete =
    message.type === PrimaryMessageType.Document && senderId && providerSender;

  const messageChannelCanDelete =
    channel?.channelType === ChannelType.Primary || channel?.channelType === ChannelType.Internal;
  const tooltipArrowWidth = 16;
  const popperOptions = {
    modifiers: [
      {
        name: 'arrow',
        options: { padding: ({ popper }) => (popper.width - tooltipArrowWidth) / 2 },
      },
    ],
  };

  const timeTooltipVerticalOffset = -8;
  const timeSection = patientTimeZone ? (
    <div className={classNames(css.spacer, css.commentText)}>
      <Tooltip
        content={`(${patientTime})`}
        className={css.patientTime}
        offset={[0, timeTooltipVerticalOffset]}
        placement={'top'}
        popperOptions={popperOptions}
      >
        <Comment.Text className={classNames(css.time, css.commentText)}>{primaryTime}</Comment.Text>
      </Tooltip>
    </div>
  ) : (
    <Comment.Text className={classNames(css.time, css.commentText)}>{primaryTime}</Comment.Text>
  );

  const header =
    isDiffSender || isDiffDay ? (
      <Comment.Text className={css.commentText}>
        <Header as="h5" color={color} className={css.header}>
          {name}
        </Header>
      </Comment.Text>
    ) : null;

  const messageControls =
    messageChannelCanDelete &&
    messageTypeCanDelete &&
    (messageSentByUser || isMessageAnyoneCanDelete) ? (
      <MessageControls
        message={message}
        patientId={profile?.patientId}
        name={name}
        content={content}
        lastReadTimestamp={lastReadTimestamp}
        providerSenderId={providerSenderId}
      />
    ) : null;

  return (
    <Comment
      className={classNames(css.comment, {
        [css.extraSpace]: isDiffSender,
      })}
    >
      {timeSection}
      <Comment.Content className={css.content}>
        {header}
        {content}
      </Comment.Content>
      {messageControls}
    </Comment>
  );
};

export default MessageContainer;

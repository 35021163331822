import React, { ReactNode } from 'react';
import css from 'components/features/MedicalSummary/MedicalSummaryItem.module.css';

const MedicalSummaryItem = ({ label, children }: { label: string; children: ReactNode }) => {
  return (
    <div>
      <div className={css.label}>{label}</div>
      {children}
    </div>
  );
};

export default MedicalSummaryItem;

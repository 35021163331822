import React from 'react';

import DateDisplay from '../../date';
import type { Marked } from '../../../CarePlanTypes';

import { useParameterizedSelector } from 'lib/hooks';
import { getProviderById } from 'reducers/providers';
import { formatPreferredFullNameFor } from 'lib/util';
import { getPatientByMrn } from 'reducers/patients';
import type { Patient } from 'types/tables/patients';

interface Props {
  disposition: Marked;
}

const MarkedCardDetail = ({ disposition }: Props) => {
  const { nextStep } = disposition;

  const { mrn } = nextStep;

  const patient = useParameterizedSelector(getPatientByMrn, mrn) as Patient;

  const provider = useParameterizedSelector(getProviderById, disposition.providerId);

  return disposition ? (
    <>
      <DateDisplay date={disposition.when} />
      {' • Completed • '}
      {provider ? provider.displayName : formatPreferredFullNameFor(patient)}
    </>
  ) : (
    <></>
  );
};

export default MarkedCardDetail;

import { DateTime } from 'luxon';
import {
  NextStep,
  NextStepDispositionType,
  TaggedStatus,
  Active,
  AlreadyCompleted,
  Booked,
  Declined,
  Dropped,
  Marked,
  Disposition,
  DispositionBase,
  Deleted,
} from '../CarePlanTypes';

export function dispositionTypeOf(step: NextStep): NextStepDispositionType {
  const { dropped, appointment, response } = step;
  if (dropped) return 'dropped';
  if (appointment) return 'booked';
  if (!response) return 'active';

  // FIXME: workaround, see https://edenhealth.atlassian.net/browse/APP-8713
  if (response && response.bookedAppointmentAthenaId) {
    return 'booked';
  }

  switch (response.type) {
    case 'already-completed':
      return 'already-completed';

    case 'booked':
      return 'booked';

    case 'deleted':
      return 'deleted';

    case 'marked':
      return 'marked';

    case 'allergy':
    case 'already-taken':
    case 'elsewhere':
    case 'irrelevant':
    case 'no-answer':
    case 'no-insurance':
    case 'nonsensical':
    case 'not-interested':
    case 'other':
    case 'provider-talk':
      return 'declined';
  }
}

function statusOf(dispositionType: NextStepDispositionType, dueDate: string): TaggedStatus {
  const isCompleted = dispositionType !== 'active';
  const isOverdue = !!dueDate && DateTime.fromISO(dueDate) < DateTime.now().startOf('day');
  const isDueToday = !!dueDate && DateTime.fromISO(dueDate).hasSame(DateTime.local(), 'day');
  const isDeclined = isCompleted && dispositionType === 'declined';
  const isDeleted = isCompleted && dispositionType === 'deleted';

  // Note: order is important for precedence
  if (isDeleted) return 'deleted';
  if (isDeclined) return 'declined';
  if (isCompleted) return 'completed';
  if (isDueToday) return 'due today';
  if (isOverdue) return 'overdue';
  return 'active';
}

//
// Determines the disposition of a Next Step given the values filled in by the fetching endpoint. The
// disposition is determined by a combination of the values of the `response`, `appointment`, and
// `dropped` properties (or absence thereof).
//
// @param step: NextStep, the Next Step whose disposition is to be determined
// @returns a Disposition instance detailing information needed to display the various Care Plan screens
//
export function dispositionOf(step: NextStep): Disposition {
  const dispositionType = dispositionTypeOf(step);

  const { response, dueDate } = step;

  const base: DispositionBase = {
    dispositionType: dispositionType,
    nextStep: step,
    isComplete: step.dropped || !!step.appointment || !!step.response,
    when: response?.responsedAt,
    status: statusOf(dispositionType, dueDate),
  };

  switch (dispositionType) {
    case 'active':
      return { ...base } as Active;

    case 'already-completed':
      return {
        ...base,
        visitMonth: response?.pastVisitDate,
      } as AlreadyCompleted;

    case 'booked':
      return {
        ...base,
        appointment: step.appointment,
      } as Booked;

    case 'declined':
      return {
        ...base,
        declineReasonType: response?.type,
        comment: response?.comment,
      } as Declined;

    case 'deleted':
      return {
        ...base,
        deleteReason: response?.comment,
        providerId: response?.providerId,
      } as Deleted;

    case 'dropped':
      return { ...base } as Dropped;

    case 'marked':
      return {
        ...base,
        providerId: response?.providerId,
        comment: response?.comment,
      } as Marked;
  }
}

export enum StateKey {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  XX = 'XX',
}

export type StateLabel =
  | 'Alaska'
  | 'Alabama'
  | 'Arkansas'
  | 'Arizona'
  | 'California'
  | 'Colorado'
  | 'Connecticut'
  | 'District of Columbia'
  | 'Delaware'
  | 'Florida'
  | 'Georgia'
  | 'Hawaii'
  | 'Iowa'
  | 'Idaho'
  | 'Illinois'
  | 'Indiana'
  | 'Kansas'
  | 'Kentucky'
  | 'Louisiana'
  | 'Massachusetts'
  | 'Maryland'
  | 'Maine'
  | 'Michigan'
  | 'Minnesota'
  | 'Missouri'
  | 'Mississippi'
  | 'Montana'
  | 'North Carolina'
  | 'North Dakota'
  | 'Nebraska'
  | 'New Hampshire'
  | 'New Jersey'
  | 'New Mexico'
  | 'Nevada'
  | 'New York'
  | 'Ohio'
  | 'Oklahoma'
  | 'Oregon'
  | 'Pennsylvania'
  | 'Rhode Island'
  | 'South Carolina'
  | 'South Dakota'
  | 'Tennessee'
  | 'Texas'
  | 'Utah'
  | 'Virginia'
  | 'Virgin Islands'
  | 'Vermont'
  | 'Washington'
  | 'Wisconsin'
  | 'West Virginia'
  | 'Wyoming'
  | 'Abroad';

export interface State {
  key: StateKey;
  label: StateLabel;
}

export const states: Record<StateKey, State> = {
  AK: { key: StateKey.AK, label: 'Alaska' },
  AL: { key: StateKey.AL, label: 'Alabama' },
  AR: { key: StateKey.AR, label: 'Arkansas' },
  AZ: { key: StateKey.AZ, label: 'Arizona' },
  CA: { key: StateKey.CA, label: 'California' },
  CO: { key: StateKey.CO, label: 'Colorado' },
  CT: { key: StateKey.CT, label: 'Connecticut' },
  DC: { key: StateKey.DC, label: 'District of Columbia' },
  DE: { key: StateKey.DE, label: 'Delaware' },
  FL: { key: StateKey.FL, label: 'Florida' },
  GA: { key: StateKey.GA, label: 'Georgia' },
  HI: { key: StateKey.HI, label: 'Hawaii' },
  IA: { key: StateKey.IA, label: 'Iowa' },
  ID: { key: StateKey.ID, label: 'Idaho' },
  IL: { key: StateKey.IL, label: 'Illinois' },
  IN: { key: StateKey.IN, label: 'Indiana' },
  KS: { key: StateKey.KS, label: 'Kansas' },
  KY: { key: StateKey.KY, label: 'Kentucky' },
  LA: { key: StateKey.LA, label: 'Louisiana' },
  MA: { key: StateKey.MA, label: 'Massachusetts' },
  MD: { key: StateKey.MD, label: 'Maryland' },
  ME: { key: StateKey.ME, label: 'Maine' },
  MI: { key: StateKey.MI, label: 'Michigan' },
  MN: { key: StateKey.MN, label: 'Minnesota' },
  MO: { key: StateKey.MO, label: 'Missouri' },
  MS: { key: StateKey.MS, label: 'Mississippi' },
  MT: { key: StateKey.MT, label: 'Montana' },
  NC: { key: StateKey.NC, label: 'North Carolina' },
  ND: { key: StateKey.ND, label: 'North Dakota' },
  NE: { key: StateKey.NE, label: 'Nebraska' },
  NH: { key: StateKey.NH, label: 'New Hampshire' },
  NJ: { key: StateKey.NJ, label: 'New Jersey' },
  NM: { key: StateKey.NM, label: 'New Mexico' },
  NV: { key: StateKey.NV, label: 'Nevada' },
  NY: { key: StateKey.NY, label: 'New York' },
  OH: { key: StateKey.OH, label: 'Ohio' },
  OK: { key: StateKey.OK, label: 'Oklahoma' },
  OR: { key: StateKey.OR, label: 'Oregon' },
  PA: { key: StateKey.PA, label: 'Pennsylvania' },
  RI: { key: StateKey.RI, label: 'Rhode Island' },
  SC: { key: StateKey.SC, label: 'South Carolina' },
  SD: { key: StateKey.SD, label: 'South Dakota' },
  TN: { key: StateKey.TN, label: 'Tennessee' },
  TX: { key: StateKey.TX, label: 'Texas' },
  UT: { key: StateKey.UT, label: 'Utah' },
  VA: { key: StateKey.VA, label: 'Virginia' },
  VI: { key: StateKey.VI, label: 'Virgin Islands' },
  VT: { key: StateKey.VT, label: 'Vermont' },
  WA: { key: StateKey.WA, label: 'Washington' },
  WI: { key: StateKey.WI, label: 'Wisconsin' },
  WV: { key: StateKey.WV, label: 'West Virginia' },
  WY: { key: StateKey.WY, label: 'Wyoming' },
  XX: { key: StateKey.XX, label: 'Abroad' },
};

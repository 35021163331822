import { useEffect, useState } from 'react';
import { isNil, cloneDeep } from 'lodash';

type Validator = (...args: any) => string | undefined;
interface FormItem {
  key: string;
  value: any;
  initialValue: any;
  dirty: boolean;
  validator?: Validator;
  error: undefined | string;
}

const maxLengthValidator: Validator = (max, value) => {
  if (value && value.length > max) {
    return `Maximum of ${max} characters`;
  }
  return undefined;
};

const requiredValidator: Validator = (value) => {
  if (isNil(value) || value === '') {
    return 'Required';
  }
  return undefined;
};

const isFormDirty = (data: Record<string, FormItem>) => {
  for (const item in data) {
    if (data[item].dirty) {
      return true;
    }
  }
  return false;
};

const isValidForm = (data: Record<string, FormItem>) => {
  for (const item in data) {
    if (data[item].error) {
      return false;
    }
  }
  return true;
};

interface InitialData {
  internalNotes: string | null;
  isDependent: boolean;
  patientDocumentTypeId: string | null;
  title: string | null;
}

export const useAnnotateFileForm = (initialData: InitialData) => {
  const initialFormData: Record<string, FormItem> = {
    internalNotes: {
      key: 'internalNotes',
      value: initialData.internalNotes,
      initialValue: initialData.internalNotes,
      dirty: false,
      validator: (value) => maxLengthValidator(2000, value),
      error: undefined,
    },
    isDependent: {
      key: 'isDependent',
      value: initialData.isDependent,
      initialValue: initialData.isDependent,
      dirty: false,
      error: undefined,
    },
    patientDocumentTypeId: {
      key: 'patientDocumentTypeId',
      value: initialData.patientDocumentTypeId,
      initialValue: initialData.patientDocumentTypeId,
      dirty: false,
      validator: (value) => requiredValidator(value),
      error: undefined,
    },
    title: {
      key: 'title',
      value: initialData.title,
      initialValue: initialData.title,
      dirty: false,
      validator: (value) => maxLengthValidator(200, value),
      error: undefined,
    },
  };

  const [data, setData] = useState(initialFormData);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsDirty(isFormDirty(data));
  }, [data]);

  useEffect(() => {
    setIsValid(isValidForm(data));
  }, [data]);

  const validate = (validator: Validator, value: any) => {
    if (validator) {
      return validator(value);
    }
    return undefined;
  };

  /** Validates the entire for and returns true if the form is valid */
  const validateForm = (): boolean => {
    const newData = cloneDeep(data);
    let isValid = true;
    for (const item in newData) {
      if (newData[item].validator !== undefined) {
        newData[item].error = validate(newData[item].validator as Validator, newData[item].value);
        if (newData[item].error) {
          isValid = false;
        }
      }
    }
    setData(newData);
    setIsValid(isValid);
    return isValid;
  };

  const setFormValue = (key: string, value: any) => {
    const newData = cloneDeep(data);
    /** If a user changes a value to empty string that was originally 
    null (entered text and then imediately deleted it) then set the value back to null.  
    This prevents the form form being considered dirty even though nothing really changed. */
    const nulledValue = value === '' && newData[key].initialValue === null ? null : value;

    newData[key].value = nulledValue;
    if (newData[key].validator !== undefined) {
      newData[key].error = validate(newData[key].validator as Validator, nulledValue);
    }
    newData[key].dirty = newData[key].initialValue !== nulledValue;

    setData(newData);
  };

  const validateFormValue = (key: string, value: any) => {
    const newData = cloneDeep(data);
    if (newData[key].validator !== undefined) {
      newData[key].error = validate(newData[key].validator as Validator, value);
    }
    setData(newData);
  };

  return {
    form: data,
    isDirty,
    isValid,
    setFormValue,
    validateFormValue,
    validateForm,
  };
};

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const PlusCircle = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.665 8a7.333 7.333 0 1 1 14.667 0A7.333 7.333 0 0 1 .666 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.667c.369 0 .667.298.667.666v5.334a.667.667 0 1 1-1.333 0V5.333c0-.368.298-.666.667-.666Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.666 8c0-.368.298-.667.667-.667h5.333a.667.667 0 1 1 0 1.334H5.333A.667.667 0 0 1 4.666 8Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusCircle;

import React from 'react';

import { Placeholder } from 'semantic-ui-react';

import css from './NextStepLoadingCard.module.css';

const NextStepLoadingCard = () => {
  return (
    <div className={css.card}>
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>

        <Placeholder.Paragraph>
          <Placeholder.Line medium />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
  );
};

export default NextStepLoadingCard;

import React, { useState } from 'react';
import cx from 'classnames';
import css from 'components/features/Sidebar/Sidebar.module.css';
import FilterView from 'components/features/Sidebar/FilterView';
import MultiFilterView from 'components/features/Sidebar/MultiFilterView';
import { DownArrow } from 'components/ui/svg';
import { Field } from 'components/features/Sidebar/Field';
import { Bucket, BucketFilterOption } from 'types/sendbird/chat';
import { Provider } from 'types/tables/providers';
import { SidebarChannel } from 'types/tables/channels';

interface SectionProps {
  onPickFilter?: (...args: any[]) => any;
  onClick: (...args: any[]) => any;
  showSectionOnLoad: boolean;
  bucket: Bucket;
  selectedFilters?: string[];
  filters?: BucketFilterOption[];
  providers: Provider[];
  channels: SidebarChannel[];
}

const Section = (props: SectionProps) => {
  const { bucket, channels, providers, selectedFilters, filters, showSectionOnLoad } = props;

  const [showSection, setShowSection] = useState(showSectionOnLoad);

  const toggleShowSection = (e) => {
    e.stopPropagation();
    setShowSection((showSection) => !showSection);
  };

  const showChannelCount = (): boolean => {
    switch (bucket) {
      case Bucket.assigned:
      case Bucket.routed:
      case Bucket.unassigned:
        return true;
      default:
        return false;
    }
  };

  return (
    <div className={css.item} data-testid={'SidebarSection-' + bucket}>
      <div className={css.header} style={{ justifyContent: 'space-between' }}>
        <div
          onClick={toggleShowSection}
          className={css.sectionTitle}
          data-testid={bucket + '-show-section'}
        >
          <span className={cx(bucket === Bucket.unassigned && showSection && css.unassignedHeader)}>
            {bucket}
          </span>
          {showChannelCount() && <span className={css.count}>{`(${channels.length})`}</span>}
          <div className={cx(css.titleArrow, !showSection && css.titleArrowClosed)}>
            <DownArrow />
          </div>
        </div>
        {bucket === Bucket.assigned && showSection && props.onPickFilter && (
          <div className={css.assignedFilter}>
            <FilterView
              onPickFilter={props.onPickFilter}
              filters={filters || []}
              selectedFilter={selectedFilters?.at(0) || ''}
              bucket={bucket}
            />
          </div>
        )}
        {bucket === Bucket.routed && showSection && (
          <div className={css.routedFilter}>
            {props.onPickFilter && (
              <MultiFilterView
                filters={filters || []}
                selectedFilters={selectedFilters || []}
                onSelectFilter={props.onPickFilter}
                placeholder={'Filter by Tag'}
                bucket={bucket}
                showItemCount
              />
            )}
          </div>
        )}
      </div>
      {showSection && (
        <div>
          {channels.map((channel: SidebarChannel) => (
            /*
          TODO
           patient should always exist here, so the types are incorrect & the separate
           patient / channel load is still causing an issue, which surfaces when loading CFE
           with a specific MRN in the url
          */
            <Field
              key={channel.channel.id}
              channel={channel}
              onClick={props.onClick}
              bucket={bucket}
              providers={providers}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Section;

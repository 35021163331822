import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { PrimaryKey } from 'types/tables/base';
import { Popup, PopupState } from 'types/tables/popups';
import { isSuccessResponse } from 'types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';

const prefix = 'popup';

interface UpdatePopupArgs {
  popupId: PrimaryKey;
  payload: Partial<Popup>;
}

enum FetchPopupsRejectionType {
  Generic = 'Unable to fetch popups.',
}

enum CreatePopupRejectionType {
  Generic = 'Unable to create popup.',
}

enum UpdatePopupRejectionType {
  Generic = 'Unable to update popup.',
}

enum DeletePopupRejectionType {
  Generic = 'Unable to delete popup.',
}

export const fetchActivePopups = createAsyncThunk(
  `${prefix}/fetchActivePopups`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getActivePopups();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
    }
  },
);

export const fetchDraftPopups = createAsyncThunk(
  `${prefix}/fetchDraftPopups`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getDraftPopups();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
    }
  },
);

export const fetchPastPopups = createAsyncThunk(
  `${prefix}/fetchPastPopups`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getPastPopups();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: FetchPopupsRejectionType.Generic });
    }
  },
);

export const createPopup = createAsyncThunk<Popup, Partial<Popup>>(
  `${prefix}/createPopup`,
  async (args: Partial<Popup>, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.createPopup(args);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: CreatePopupRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: CreatePopupRejectionType.Generic });
    }
  },
);

export const updatePopup = createAsyncThunk<Popup, UpdatePopupArgs>(
  `${prefix}/updatePopup`,
  async (args: UpdatePopupArgs, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.updatePopup(args);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: UpdatePopupRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: UpdatePopupRejectionType.Generic });
    }
  },
);

export const deletePopup = createAsyncThunk<number, Popup>(
  `${prefix}/deletePopup`,
  async (popup: Popup, { rejectWithValue }) => {
    const res = await GrdnApi.deletePopup(popup);
    try {
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: DeletePopupRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: DeletePopupRejectionType.Generic });
    }
  },
);

const popupsAdapter = createEntityAdapter<Popup>({});

const activeAdapter = createEntityAdapter<Popup>({});

const draftsAdapter = createEntityAdapter<Popup>({});

const pastAdapter = createEntityAdapter<Popup>({});

export const popups = createSlice({
  name: prefix,
  initialState: {
    allPopups: popupsAdapter.getInitialState(),
    activePopups: activeAdapter.getInitialState(),
    draftPopups: draftsAdapter.getInitialState(),
    pastPopups: pastAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActivePopups.fulfilled, (state, action) => {
      draftsAdapter.upsertMany(state.activePopups, action.payload);
    });
    builder.addCase(fetchDraftPopups.fulfilled, (state, action) => {
      draftsAdapter.upsertMany(state.draftPopups, action.payload);
    });
    builder.addCase(fetchPastPopups.fulfilled, (state, action) => {
      draftsAdapter.upsertMany(state.pastPopups, action.payload);
    });
    builder.addCase(createPopup.fulfilled, (state, action) => {
      draftsAdapter.upsertOne(state.draftPopups, action.payload);
    });
    builder.addCase(updatePopup.fulfilled, (state, action) => {
      if (action.payload.popupState === PopupState.active) {
        draftsAdapter.removeOne(state.draftPopups, action.meta.arg.popupId);
        activeAdapter.upsertOne(state.activePopups, action.payload); //TODO: replace with `popupsAdapter.setOne(state, action.payload.data);` after we update to @reduxjs/toolkit v1.6.0
      } else {
        activeAdapter.removeOne(state.activePopups, action.meta.arg.popupId);
        draftsAdapter.upsertOne(state.draftPopups, action.payload); //TODO: replace with `popupsAdapter.setOne(state, action.payload.data);` after we update to @reduxjs/toolkit v1.6.0

        //manually set the popupDates to be empty so that deactivation removes popupDates
        if (action.payload.popupDates == null) {
          draftsAdapter.updateOne(state.draftPopups, {
            id: action.meta.arg.popupId,
            changes: { popupDates: [] },
          });
        }
      }
    });
    builder.addCase(deletePopup.fulfilled, (state, action) => {
      draftsAdapter.removeOne(state.draftPopups, action.meta.arg.id);
    });
  },
});

export const allPopupSelectors = popupsAdapter.getSelectors<Store>(
  (state) => state.popups.allPopups,
);

export const activePopupSelectors = popupsAdapter.getSelectors<Store>(
  (state) => state.popups.activePopups,
);

export const draftPopupSelectors = popupsAdapter.getSelectors<Store>(
  (state) => state.popups.draftPopups,
);

export const pastPopupSelectors = popupsAdapter.getSelectors<Store>(
  (state) => state.popups.pastPopups,
);

export const selectAllPopups = createSelector(allPopupSelectors.selectAll, (popups) => popups);

export const selectPastPopups = createSelector(pastPopupSelectors.selectAll, (popups) => popups);

export const selectActivePopups = createSelector(
  activePopupSelectors.selectAll,
  (popups) => popups,
);

export const selectDraftPopups = createSelector(draftPopupSelectors.selectAll, (popups) => popups);

export default popups.reducer;

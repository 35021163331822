import { DateTime } from 'luxon';
import React, { useState } from 'react';
import cx from 'classnames';
import css from './VaxStatusLabel.module.css';
import { Ellipse } from 'components/ui/svg';
import { Vaccination, VaccinationStatus } from 'types/tables/profiles';
import { ordinalify } from 'lib/util';

const getVaccinationName = (mfg: string) => {
  switch (mfg) {
    case 'PFR':
      return 'Pfizer';
    case 'MOD':
      return 'Moderna';
    case 'JSN':
      return 'Johnson & Johnson';
    default:
      return `Unknown (${mfg})`;
  }
};

const getClassName = (status) => {
  switch (status) {
    case VaccinationStatus.NotVaccinated:
      return 'not-vaccinated';
    case VaccinationStatus.FirstDoseComplete:
      return 'first-dose-complete';
    case VaccinationStatus.SecondDoseComplete:
      return 'second-dose-complete';
    case VaccinationStatus.PrimarySeriesComplete:
      return 'fully-vaccinated';
    case VaccinationStatus.UnknownVaccine:
      return 'unknown-vaccine';
    default:
      if (status.match(VaccinationStatus.PrimarySeriesPlus)) {
        return 'fully-vaccinated';
      } else {
        return 'not-vaccinated';
      }
  }
};

const DoseInfo = ({
  index,
  manufacturer,
  date,
}: {
  index: number;
  manufacturer: string;
  date: string;
}) => {
  const number = index + 1;

  return (
    <li className={css.dose}>
      <div className={css.doseNum}>{ordinalify(number)} dose</div>

      <div className={css.doseDetails}>
        <div className={css.label}>Manufacturer</div>
        <div className={css.detail}>{getVaccinationName(manufacturer)}</div>

        <div className={css.label}>Administered</div>
        <div className={css.detail}>
          {DateTime.fromISO(date, { setZone: true }).toFormat('MM/dd/yyyy')}
        </div>
      </div>
    </li>
  );
};

export const VaxStatusPill = ({
  status,
  vaccinations,
}: {
  status: string;
  vaccinations: Vaccination[];
}) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <div
      className={cx(css.container)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-testid="VaxStatus"
    >
      <div className={cx(css.innerContainer)}>
        <Ellipse className={css[getClassName(status)]} />
        <span className={css.status}>{status}</span>
      </div>

      {isHovered && vaccinations.length > 0 && (
        <div
          className={css.dosesContainer}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ul className={css.doses}>
            {vaccinations.map((v, i) => (
              <DoseInfo key={i} index={i} manufacturer={v.manufacturer} date={v.vaccinatedAt} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

import React, { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getMyProvider } from 'reducers/user';
import { updateTickler } from 'reducers/patients';
import { PrimaryButton, Label, Field } from 'components/design-system/Form';
import { Bell } from 'components/ui/svg';
import { Modal } from 'components/ui/Modal';
import { HeaderMedium } from 'components/design-system/Text';
import { NoteInput, ProviderSelector } from 'components/features/TicklerOverview/TicklerFormFields';
import css from 'components/features/TicklerOverview/EditTicklerModal.module.css';
import { useActivePatient } from 'lib/hooks';
import { createTickler } from 'reducers/ticklers';
import { useAppDispatch } from 'lib/hooks';
import { Tickler } from 'types/grdn';
import { PrimaryKey } from 'types/tables/base';
import { formatPreferredFullNameFor } from 'lib/util';
import DatePickerWithDay from 'components/design-system/date-picker-with-day';

interface EditTicklerModalState {
  dueDate: string;
  athenaProviderId: number;
  note: string;
}

interface EditTicklerModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  ticklerToUpdate?: Tickler;
}

const initialState = {
  dueDate: '',
  athenaProviderId: 0,
  note: '',
};

export const formValid = (state) => state.athenaProviderId && state.dueDate && state.note;

// Create or edit tickler
const EditTicklerModal = (props: EditTicklerModalProps) => {
  const { isOpen, closeModal, ticklerToUpdate } = props;

  const myProvider = useSelector(getMyProvider);
  const myAthenaProviderId = myProvider?.athenaProviderId;
  const initialAthenaProviderId = myAthenaProviderId ? myAthenaProviderId : 0;

  const [formState, setState] = useState<EditTicklerModalState>({
    athenaProviderId: ticklerToUpdate?.assignedTo || initialAthenaProviderId,
    dueDate: ticklerToUpdate?.dueDate || '',
    note: ticklerToUpdate?.note || '',
  });

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useAppDispatch();

  const handleCreateTickler = async (athenaProviderId: number, dueDate: string, note: string) => {
    setSubmitting(true);
    const resp = await dispatch(
      createTickler({
        patientId,
        athenaProviderId,
        dueDate,
        note,
      }),
    );
    if (createTickler.fulfilled.match(resp)) {
      toast.success('Tickler successfully created.');
    } else {
      toast.error('Unable to create tickler. Try again.');
    }
    setSubmitting(false);
    clearAndClose();
  };

  const handleUpdateTickler = async (
    patientId: PrimaryKey,
    ticklerAthenaId: number,
    athenaProviderId: number,
    dueDate: string,
    note: string,
  ) => {
    setSubmitting(true);
    const response = await dispatch(
      updateTickler({
        patientId,
        ticklerAthenaId,
        athenaProviderId: athenaProviderId,
        dueDate: dueDate,
        note: note,
      }),
    );

    if (updateTickler.fulfilled.match(response)) {
      toast.success('Tickler successfully updated.');
    } else {
      toast.error(response.payload?.rejectionType);
    }
    setSubmitting(false);
    clearAndClose();
  };

  const patient = useActivePatient();

  const { id: patientId } = patient;

  const handleChange = (value, field) => {
    setState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const clearAndClose = () => {
    setState(initialState);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={clearAndClose}>
      <HeaderMedium style={{ marginTop: -12, marginBottom: 8 }}>
        <Bell className={css.bellIcon} />
        {ticklerToUpdate ? 'Update' : 'Create'} tickler
      </HeaderMedium>

      <div className={css.container}>
        <Label text="Patient" htmlFor="tickler-patient">
          <Field
            id="tickler-patient"
            data-testid="tickler-patient"
            value={formatPreferredFullNameFor(patient)}
            type="text"
            name="patient"
            disabled
            className={css.wide}
          />
        </Label>

        <ProviderSelector
          label="Assign To"
          required
          value={formState.athenaProviderId ? formState.athenaProviderId.toString() : undefined}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            handleChange(e.target.value, 'athenaProviderId');
          }}
        />

        <Label text="Due Date" required>
          <DatePickerWithDay
            value={formState.dueDate}
            onChange={(value) => {
              handleChange(value, 'dueDate');
            }}
            testId="tickler-day-picker"
            futureOnly
          />
        </Label>

        <NoteInput
          label="Notes"
          required
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            handleChange(e.target.value, 'note');
          }}
          value={formState.note}
        />
        <div className={css.bottomNav}>
          <PrimaryButton
            type="button"
            className={cx(css.btn, css.cancelBtn)}
            value="Cancel"
            onClick={closeModal}
          />
          <PrimaryButton
            onClick={() => {
              if (ticklerToUpdate) {
                handleUpdateTickler(
                  ticklerToUpdate.patientId,
                  ticklerToUpdate.ticklerAthenaId,
                  formState.athenaProviderId,
                  formState.dueDate,
                  formState.note,
                );
              } else {
                handleCreateTickler(formState.athenaProviderId, formState.dueDate, formState.note);
              }
            }}
            value={ticklerToUpdate !== undefined ? 'Save' : 'Create'}
            disabled={!formValid(formState)}
            className={css.btn}
            loading={submitting}
            data-testid="submit-tickler-button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditTicklerModal;

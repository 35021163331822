import React from 'react';

import type { Active } from '../../../CarePlanTypes';
import DateDisplay from '../../date';

import css from '../NextStepCard.module.css';

interface Props {
  disposition: Active;
}

const ActiveCardDetail = ({ disposition }: Props) => {
  const { dueDate } = disposition.nextStep;

  return (
    <>
      <div className={css.dueDateDetail}>
        {dueDate ? (
          <>
            Due <DateDisplay date={dueDate} />
          </>
        ) : (
          <>No due date: see Athena for more details</>
        )}
      </div>
    </>
  );
};

export default ActiveCardDetail;

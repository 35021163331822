import React, { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import css from './styles.module.css';
import { FormProps, handleInternalError, RDashToolErrors } from './index';
import { Field, Label, FormHeader, SubmitButton, validateString } from 'components/ui/Forms';
import { Trash } from 'components/ui/svg';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';

interface RemoveEmployeeState {
  employeeId: string;
  employeeIdError?: string;
}

const initialState = Object.freeze({
  employeeId: '',
});

const RemoveEmployee: React.FC<FormProps> = (props: FormProps) => {
  const [{ employeeId, employeeIdError }, setState] = useState<RemoveEmployeeState>(initialState);

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    const clearAndClose = () => {
      setState(initialState);
      props.closeModal();
    };
    e.preventDefault();
    try {
      const resp = await GrdnApi.removeEmployee(props.sponsor.id, employeeId);
      if (isSuccessResponse(resp)) {
        toast.success('Employee successfully removed.');
        clearAndClose();
      }
    } catch (e) {
      switch (e.data?.error?.type) {
        case RDashToolErrors.memberNotFound:
          setState((state) => ({
            ...state,
            employeeIdError: 'Employee with given ID not found.',
          }));
          break;
        case RDashToolErrors.sponsorNotFound:
          toast.error('Sponsor not found.');
          clearAndClose();
          break;
        default:
          const errorMessage = handleInternalError(e);
          toast.error(`Unable to remove employee.\nError details: ${errorMessage}`);
          clearAndClose();
      }
    }
  };

  return (
    <div data-testid={`remove-employee-form-${props.sponsor.id}`}>
      <FormHeader formName={'Remove employee'} icon={Trash} />
      <form className={css.form} onSubmit={submitForm}>
        <Label text="Employee ID">
          <Field
            data-testid="employeeid-input"
            placeholder="eg. 123456"
            value={employeeId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value;
              setState((state) => ({ ...state, employeeId: val }));
            }}
            onBlur={() =>
              setState((state) => ({
                ...state,
                employeeIdError: validateString(employeeId, 'employee ID'),
              }))
            }
            error={employeeIdError}
          />
        </Label>
        <SubmitButton data-testid="remove-button" disabled={!employeeId}>
          Remove
        </SubmitButton>
      </form>
    </div>
  );
};

export default RemoveEmployee;

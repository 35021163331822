import React, { ReactComponentElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Comment, Segment } from 'semantic-ui-react';
import MessageElement from './MessageElement';
import css from './Chat.module.css';
import OpenLinkModal from './OpenLinkModal';
import { Message, PendingMessage } from 'types/tables/messages';
import { belongsInPrimaryChannel } from 'lib/util';
import { useActiveChat } from 'lib/hooks';

interface MessagesProps {
  setOldestMessage: (arg: any) => void;
  setNewestMessage: (arg: any) => void;
  loadHistory: () => void;
  scrollComponentId: string;
  showLastRead: boolean;
  lastRead?: number;
}

export const Messages = (props: MessagesProps) => {
  const {
    setOldestMessage,
    setNewestMessage,
    loadHistory,
    scrollComponentId,
    showLastRead,
    lastRead,
  } = props;

  const { canLoadEarlier, getPendingMessages, getMessages } = useActiveChat();
  const messages = useSelector(getMessages);
  const pendingMessages = useSelector(getPendingMessages);

  const [linkState, setLinkState] = useState({ isModalOpen: false, linkTarget: '' });

  const interceptClickEvent = (e) => {
    const href = e?.target?.getAttribute('href');
    if (href) {
      e.preventDefault();
      setLinkState({ isModalOpen: true, linkTarget: href });
    }
  };

  let previousMsg: Message;
  let previousPrimaryMsg: Message;

  const messageComponents: ReactComponentElement<any>[] = messages
    .concat(pendingMessages)
    .map((message: Message | PendingMessage, messageIndex) => {
      const isFirstMessage = messageIndex === 0;
      const isLastMessage = messageIndex === messages.length - 1;

      const componentElement = (
        <MessageElement
          message={message}
          previousMessage={previousMsg}
          previousPrimaryMessage={previousPrimaryMsg}
          isLastMessage={isLastMessage}
          showLastRead={showLastRead}
          lastRead={lastRead}
          refSetMessage={
            isFirstMessage ? setOldestMessage : isLastMessage ? setNewestMessage : undefined
          }
          key={message.id}
        />
      );

      previousMsg = message;
      if (belongsInPrimaryChannel(message)) {
        previousPrimaryMsg = message;
      }

      return componentElement;
    });

  return (
    <Segment id={scrollComponentId} className={css.msgContainer} basic>
      {canLoadEarlier && (
        <div className={css.msgHeader}>
          <Button fluid onClick={loadHistory}>
            Load Previous
          </Button>
        </div>
      )}
      <Comment.Group className={css.msgBody}>
        <div onClick={interceptClickEvent}>{messageComponents}</div>
      </Comment.Group>
      <OpenLinkModal
        isOpen={linkState.isModalOpen}
        onClose={() => {
          setLinkState({ linkTarget: '', isModalOpen: false });
        }}
        linkTarget={linkState.linkTarget}
      />
    </Segment>
  );
};

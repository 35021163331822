import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import * as userReducer from 'reducers/user';
import GoogleAuthClient from 'lib/google';
import { Profile } from 'types/tables/user';
import { Dispatch } from 'store';

interface AuthProvider {
  token: Promise<string | undefined>;
  signIn: (user?: Record<string, any>) => Promise<Profile>;
  signOut: () => void;
}

export class Auth {
  public provider: AuthProvider;

  constructor(provider: AuthProvider) {
    this.provider = provider;
  }

  public get token() {
    return this.provider.token;
  }

  public async signIn(user?: Record<string, any>) {
    return this.provider.signIn(user);
  }

  public signOut() {
    this.provider.signOut();
  }
}

// TODO move this to a react Context
export const auth = new Auth(GoogleAuthClient);

export const useAuth = (authInstance: Auth = auth) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const login = useCallback(
    async (user?: Record<string, any>) => {
      const res = await dispatch(userReducer.login({ authInstance, user }));
      if (userReducer.login.fulfilled.match(res)) {
        return unwrapResult(res);
      } else {
        return;
      }
    },
    [dispatch, authInstance],
  );

  const logout = useCallback(() => {
    authInstance.signOut();
    dispatch(userReducer.logout());
  }, [dispatch, authInstance]);

  const authorize = useCallback(async () => {
    const res = await dispatch(userReducer.authorize());
    return userReducer.authorize.fulfilled.match(res);
  }, [dispatch]);

  const signOut = useCallback(() => {
    logout();
    navigate('/');
  }, [logout, navigate]);

  const signIn = useCallback(
    async (redirectTo, user?) => {
      const profile = await login(user);
      if (profile && (await authorize())) {
        navigate(redirectTo);
      } else {
        if (profile) {
          // logged in, but not authorized
          navigate('/not-authorized');
        } else {
          // not logged in
          signOut();
        }
      }
    },
    [signOut, authorize, login, navigate],
  );

  return { signIn, signOut };
};

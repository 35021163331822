import React from 'react';
import cx from 'classnames';
import css from './InsuranceEligibility.module.css';
import insuranceCSS from './Insurance.module.css';
import Tooltip from 'components/design-system/Tooltip';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { getPatientProfile } from 'selectors/profiles';
import { checkInsuranceEligibility } from 'reducers/profiles';
import { profileIsError } from 'types/tables/profiles';
import {
  InsuranceRecord,
  InsuranceLoadState,
  InsuranceEligibilityStatus,
} from 'types/athena/insurance';
import { Info, RefreshIcon, Loader, SuccessIcon, ErrorIcon, WarningIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

type InsuranceEligibilityProps = {
  athenaRecord?: InsuranceRecord;
};

const InsuranceEligibility: React.FC<InsuranceEligibilityProps> = ({
  athenaRecord,
}: InsuranceEligibilityProps) => {
  const { id: activePatientId } = useActivePatient();
  const dispatch = useAppDispatch();
  const patientProfile = useParameterizedSelector(getPatientProfile, activePatientId);

  const getEligibilityLoadState = (eligibilityLoadState?: InsuranceLoadState) => {
    switch (eligibilityLoadState) {
      case InsuranceLoadState.Loading:
        return (
          <div className={insuranceCSS.smallMessageContainer}>
            <div className={insuranceCSS.smallLoadingIcon}>
              <Loader color={EdenColors.SlateDarken20} width={14} height={14} />
            </div>
            <div className={insuranceCSS.smallMessage}>
              <div>Loading...</div>
            </div>
          </div>
        );
      case InsuranceLoadState.Success:
        return (
          <div className={insuranceCSS.smallMessageContainer}>
            <div className={insuranceCSS.insuranceSmallSuccessIcon}>
              <SuccessIcon />
            </div>
            <div className={insuranceCSS.smallMessage}>
              <div>Check successful!</div>
              <div>Leave this patient&apos;s tab and come back for result.</div>
            </div>
          </div>
        );
      case InsuranceLoadState.Failure:
        return (
          <div className={insuranceCSS.smallMessageContainer}>
            <div className={insuranceCSS.insuranceSmallErrorIcon}>
              <ErrorIcon />
            </div>
            <div className={insuranceCSS.smallMessage}>
              <div>Check unsuccessful.</div>
              <div>Couldn&apos;t reach system.</div>
              <div>Please try again.</div>
            </div>
          </div>
        );
      default:
        return '';
    }
  };

  const renderElgibilityStatus = (eligibilityStatus) => {
    return (
      <div className={css.eligibilityStatusOuterContainer}>
        <div className={css.eligibilityStatusInnerContainer}>
          <div className={css[eligibilityStatus.concat('Color')]} />
          <div>{eligibilityStatus}</div>
        </div>
      </div>
    );
  };

  const renderEligibilityCheck = () => {
    if (!profileIsError(patientProfile)) {
      return (
        <div>
          <div className={insuranceCSS.buttonContainer}>
            <button
              className={cx(
                (patientProfile.primaryInsuranceEligibilityLoadingState ===
                  InsuranceLoadState.Loading ||
                  patientProfile.primaryInsuranceEligibilityLoadingState ===
                    InsuranceLoadState.Success) &&
                  insuranceCSS.buttonInactive,
                insuranceCSS.button,
              )}
              onClick={() => {
                dispatch(
                  checkInsuranceEligibility({
                    patientId: activePatientId,
                    insuranceId: patientProfile.primaryInsurance?.athenaRecord?.id || '',
                  }),
                );
              }}
              disabled={
                patientProfile.primaryInsuranceEligibilityLoadingState ===
                  InsuranceLoadState.Loading ||
                patientProfile.primaryInsuranceEligibilityLoadingState ===
                  InsuranceLoadState.Success
              }
            >
              <RefreshIcon color={EdenColors.Slate} /> Check Eligibility
            </button>
          </div>
          <div className={css.eligibilityCheckStatus}>
            {getEligibilityLoadState(patientProfile.primaryInsuranceEligibilityLoadingState)}
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  const renderEligibilityTooltip = () => {
    return (
      <div className={css.eligibilityTooltip}>
        {renderElgibilityStatus(InsuranceEligibilityStatus.Eligible)}
        <div className={css.eligibilityTooltipLine}> = Patient is active member of plan</div>
        {renderElgibilityStatus(InsuranceEligibilityStatus.Unverified)}
        <div className={css.eligibilityTooltipLine}>
          = Patient eligibility for plan has not been verified
        </div>
        {renderElgibilityStatus(InsuranceEligibilityStatus.Ineligible)}
        <div className={css.eligibilityTooltipLine}> = Patient is not active plan member</div>
        <div className={css.eligibilityTooltipNote}>
          <b>Note:</b> this pertains to whether plans are accurate and active, not whether they are
          in-network with Eden
        </div>
      </div>
    );
  };

  return (
    <div className={css.eligibilityContainer}>
      <div className={insuranceCSS.panel}>{renderEligibilityCheck()}</div>
      <div className={insuranceCSS.panel}>
        <div className={css.eligibilityDetailsContainer}>
          <div className={css.eligibilityDetails}>
            <div className={css.eligibilityStatusLine}>
              <div className={css.eligibilityStatusTitle}>Status</div>
              <div className={css.eligibilityStatusIcon}>
                <Tooltip content={renderEligibilityTooltip()}>
                  <Info color={EdenColors.Slate55} height="14" width="14" />
                </Tooltip>
              </div>
            </div>
            {renderElgibilityStatus(athenaRecord?.eligibilityStatus)}
          </div>
          {athenaRecord?.eligibilityStatus !== InsuranceEligibilityStatus.Eligible &&
            athenaRecord?.eligibilityMessage && (
              <div className={insuranceCSS.smallMessageContainer}>
                <div className={insuranceCSS.insuranceSmallWarningIcon}>
                  <WarningIcon />
                </div>
                <div className={insuranceCSS.smallMessage}>
                  <div>
                    <b>Warning: </b>
                    {athenaRecord?.eligibilityMessage}
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className={insuranceCSS.detailsContainer}>
          <div>Last checked</div>
          <div>{athenaRecord?.eligibilityLastCheckedDate}</div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceEligibility;

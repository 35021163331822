import * as React from 'react';
import cx from 'classnames';
import css from './Dropdown.module.css';

interface DropdownProps {
  isOpen: boolean;
  className?: string;
  dropdownStyle?: string;
  closeStyle?: string;
  children: any;
  onClose: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Dropdown = (props: DropdownProps) => {
  return (
    <div className={css.outerWrapper} onClick={(ev) => ev.stopPropagation()} data-testid="Dropdown">
      <div
        className={cx(
          css.dropdown,
          props.isOpen && css.isOpen,
          props.className,
          props.dropdownStyle,
        )}
      >
        {props.children}
      </div>
      {props.isOpen && (
        <div
          className={props.closeStyle || css.closeArea}
          onClick={props.onClose}
          data-testid="DropdownClose"
        />
      )}
    </div>
  );
};

export default Dropdown;

import React from 'react';

import DateDisplay from '../../date';
import type { AlreadyCompleted } from '../../../CarePlanTypes';

import { formatDateTime } from 'lib/formatDateTime';
import { formatPreferredFullNameFor } from 'lib/util';
import { useParameterizedSelector } from 'lib/hooks';
import { getPatientByMrn } from 'reducers/patients';
import type { Patient } from 'types/tables/patients';

interface Props {
  disposition: AlreadyCompleted;
}

const AlreadyCompletedCardDetail = ({ disposition }: Props) => {
  const { nextStep, visitMonth } = disposition;
  const { mrn } = nextStep;

  const patient = useParameterizedSelector(getPatientByMrn, mrn) as Patient;

  return (
    <>
      <DateDisplay date={disposition.when} />
      {' • Already done: '}
      {visitMonth ? formatDateTime(visitMonth).as('LLL yyyy').inLocalTimeZone() : 'No date given'}
      {' • '}
      {formatPreferredFullNameFor(patient)}
    </>
  );
};

export default AlreadyCompletedCardDetail;

// === All other reducers are to be imported to and exposed from here. ===
import { combineReducers } from 'redux';
import { sidebar } from './sidebar';
import addons from 'reducers/addons';
import patient from 'legacy/reducers/patient';
import db from 'legacy/reducers/db';
import app from 'reducers/app';
import brad from 'reducers/brad';
import channels from 'reducers/channels';
import charts from 'reducers/chart';
import macros from 'reducers/macros';
import popups from 'reducers/popups';
import popupReasons from 'reducers/popupReasons';
import athenaProviders from 'reducers/athenaProviders';
import careteams from 'reducers/careteams';
import carepods from 'reducers/carepods';
import patients from 'reducers/patients';
import messages from 'reducers/messages';
import providerGroups from 'reducers/providerGroups';
import providers from 'reducers/providers';
import profiles from 'reducers/profiles';
import sponsors from 'reducers/sponsors';
import pharmacies from 'reducers/pharmacies';
import edenScreenerResponses from 'reducers/screeners';
import appointmentScreeners from 'reducers/appointmentScreeners';
import user from 'reducers/user';
import files from 'reducers/files';

const legacyReducers = {
  patient,
};

const combined = combineReducers({
  // old reducers
  ...legacyReducers,
  store: db,
  // new reducer
  app: app.reducer,
  sidebar: sidebar.reducer,
  appointmentScreeners,
  athenaProviders,
  brad,
  careteams,
  carepods,
  channels,
  charts,
  macros,
  messages,
  patients,
  pharmacies,
  popupReasons,
  popups,
  profiles,
  providerGroups,
  providers,
  sponsors,
  edenScreenerResponses,
  user,
  addons,
  files,
});

export default combined;

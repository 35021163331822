import React from 'react';

import css from './VisitDetailsModal.module.css';

import VisitDateAndTime from './VisitDateAndTime';
import VisitLocation from './VisitLocation';
import VisitProvider from './VisitProvider';
import VisitSummary from './VisitSummary';
import { HeaderMedium } from 'components/design-system/Text';
import { Modal } from 'components/ui/Modal';
import { Visit } from 'types/tables/messages';
import { ButtonSizes, SecondaryButton } from 'components/ui/Buttons';

interface Props {
  visit: Visit;
  isOpen: boolean;
  closeModal: VoidFunction;
}

const browserTimeZoneDepartments = [2, 8];

const VisitDetailsModal = ({ visit, isOpen, closeModal }: Props) => {
  const { startTime, location, provider, afterVisitSummary, departmentId, timeZone } = visit;

  const useDeviceTimeZone = browserTimeZoneDepartments.includes(visit.departmentId);

  const displayTimeZone = useDeviceTimeZone ? undefined : timeZone;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} override={css.modal}>
      <header>
        <HeaderMedium className={css.header}>
          {visit.patientAppointmentTypeName || 'Visit'}
        </HeaderMedium>
      </header>

      <main className={css.main}>
        {!!startTime && <VisitDateAndTime date={startTime} timeZone={displayTimeZone} />}

        {!!location && <VisitLocation location={location} departmentId={departmentId} />}

        <VisitProvider provider={provider} />

        <VisitSummary summary={afterVisitSummary ? afterVisitSummary.content : ''} />
      </main>

      <footer className={css.footer}>
        <SecondaryButton size={ButtonSizes.wide} onClick={closeModal}>
          Close
        </SecondaryButton>
      </footer>
    </Modal>
  );
};

export default VisitDetailsModal;

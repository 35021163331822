import React from 'react';
import { useSelector } from 'react-redux';
import { Item } from 'react-stately';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { HoverableText } from 'components/design-system/HoverableText';
import { Store } from 'types/redux';
import css from 'components/features/TicklerOverview/TicklerOverview.module.css';
import { Tickler } from 'types/grdn';
import { ListHeaderMedium, ListBody } from 'components/ui/Typography';
import { athenaProvidersSelectors } from 'reducers/athenaProviders';
import { isDueToday, isOverdue } from 'lib/util';
import EllipsisMenu from 'components/design-system/EllipsisMenu';
import { EdenColors } from 'types/colors';

interface TicklerCardProps {
  tickler: Tickler;
  onComplete: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

export const generateDueLine = ({ dueDate, assignedProvider }) => {
  const formattedDueDate = dueDate.toFormat('MMM. d, yyyy');
  const assignedProviderDisplayName = assignedProvider?.displayName;
  const assignedToString = assignedProviderDisplayName ? `• ${assignedProviderDisplayName}` : '';

  return `Due ${formattedDueDate} ${assignedToString}`;
};

type MenuItemOption = 'MarkAsDone' | 'Edit' | 'Delete';

export const TicklerCard = (props: TicklerCardProps) => {
  const {
    tickler: { dueDate, assignedTo, note, ticklerAthenaId },
    onComplete,
    onDelete,
    onEdit,
  } = props;

  const dueDateParsed = DateTime.fromFormat(dueDate, 'yyyy-MM-dd').startOf('day');
  const isTicklerDueToday = isDueToday(dueDateParsed);
  const isTicklerOverdue = isOverdue(dueDateParsed);
  const assignedProvider = useSelector((state: Store) =>
    athenaProvidersSelectors.selectById(state, assignedTo),
  );
  const dueLine = generateDueLine({
    dueDate: dueDateParsed,
    assignedProvider: assignedProvider,
  });
  const dueLabel = isTicklerDueToday ? 'DUE TODAY' : isTicklerOverdue ? 'OVERDUE' : '';
  const dueLabelClass = isTicklerDueToday
    ? css.dueTodayLabel
    : isTicklerOverdue
    ? css.overdueLabel
    : null;

  const onMenuSelect = (key: MenuItemOption) => {
    switch (key) {
      case 'MarkAsDone':
        onComplete();
        break;
      case 'Edit':
        onEdit();
        break;
      case 'Delete':
        onDelete();
        break;
    }
  };

  return (
    <div className={css.ticklerCard} data-testid={`TicklerCard-${ticklerAthenaId}`}>
      <div className={css.ticklerCardHeader}>
        <ListHeaderMedium className={css.ticklerCardDueLine}>{dueLine}</ListHeaderMedium>
        <div className={css.ticklerCardHeaderRight}>
          {dueLabel && <span className={cx(css.ticklerDueLabel, dueLabelClass)}>{dueLabel}</span>}
          <div className={css.ticklerDropdown}>
            <EllipsisMenu
              ellipsisColor={EdenColors.SlateDarken20}
              onMenuSelect={onMenuSelect}
              testId={`TicklerMenuButton-${ticklerAthenaId}`}
            >
              <Item key="MarkAsDone" textValue="Mark as done">
                <span data-testid={`CompleteTickler-${ticklerAthenaId}`}>Mark as done</span>
              </Item>
              <Item key="Edit">Edit</Item>
              <Item key="Delete">Delete</Item>
            </EllipsisMenu>
          </div>
        </div>
      </div>
      <ListBody>
        <HoverableText
          text={note}
          hoverableContainerClass={css.hoverableContainer}
          hoverableClass={css.hoverable}
          copyButtonClass={css.copyButton}
        />
      </ListBody>
    </div>
  );
};
export default TicklerCard;

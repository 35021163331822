import React from 'react';

import css from './SourceDetail.module.css';

import Tooltip from 'components/design-system/Tooltip';
import { formatDateTime } from 'lib/formatDateTime';
import { Disposition } from 'components/features/CarePlan/CarePlanTypes';
import { HelpCircle } from 'components/ui/svg';

interface Props {
  disposition: Disposition;
}

const SourceDetail = ({ disposition }: Props) => {
  const { nextStep } = disposition;
  const { createdAt, stepSource } = nextStep;

  return (
    <div className={css.source}>
      {(nextStep.type === 'custom' || nextStep.type === 'followup') && stepSource === 'provider' ? (
        <>
          Added to Care Plan on {formatDateTime(createdAt).as('LLL d, yyyy').inLocalTimeZone()} by{' '}
          {nextStep.creatingProviderName}
        </>
      ) : (
        <>
          Automatically added to Care Plan on{' '}
          {formatDateTime(createdAt).as('LLL d, yyyy').inLocalTimeZone()}{' '}
          <Tooltip content="This next step was automatically added to the care plan to close a care gap identified by either Eden or Athena.">
            <HelpCircle />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default SourceDetail;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { isSuccessResponse } from '../types/api';
import * as GrdnApi from 'lib/grdn';
import { Tickler } from 'types/grdn';

const prefix = 'ticklers';
const createTicklerRejectionType = 'Failed to create tickler';

interface CreateTicklerArgs {
  patientId: string;
  athenaProviderId: number;
  dueDate: string;
  note: string;
}

export const createTickler = createAsyncThunk<
  Tickler,
  CreateTicklerArgs,
  { rejectValue: { rejectionType: string } }
>(
  `${prefix}/createTickler`,
  async ({ patientId, athenaProviderId, dueDate, note }, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.createTickler(patientId, athenaProviderId, dueDate, note);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: createTicklerRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: createTicklerRejectionType });
    }
  },
);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import cx from 'classnames';
import TurndownService from 'turndown';
import { DeleteAVSModal, SaveAVSModal } from 'components/features/VisitOverview/SubmitAVSModal';
import GenerateAVS from 'components/features/VisitOverview/GenerateAVS';
import css from 'components/features/VisitOverview/Visit.module.css';
import Dropdown from 'components/ui/Dropdown';
import { ListItem1 } from 'components/ui/Lists';
import { ListBody, ListHeaderMedium } from 'components/ui/Typography';
import {
  Clipboard,
  HealthCare,
  InfoFeather,
  EllipsisFeather,
  RotateCWArrowlessFeather,
  RotateCWFeather,
} from 'components/ui/svg';
import { Visit } from 'types/athena/visits';
import { EdenColors } from 'types/colors';
import { isSuccessResponse } from 'types/api';
import { sendAfterVisitSummary, retractAfterVisitSummary } from 'lib/grdn';
import { loadEnv } from 'lib/env';
import { useActivePatient, useAppDispatch, useIsMounted } from 'lib/hooks';
import { markdownFormatter, sanitizeAvsBlobToMarkdown } from 'lib/markdown';
import { fetchEncounter, saveAVS, updateVisitWithAfterVisitSummary } from 'reducers/visits';
import VisitInfoBar from 'components/features/VisitOverview/VisitInfoBar';
import type { DropdownState, Tag } from 'components/features/VisitOverview/VisitInfoBar';
import { getAppointmentURL } from 'lib/athenaUrls';

interface EncounterProps {
  encounter: Visit;
  encounterId: string;
}

enum LoadingState {
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
}

enum AvsStatus {
  Editing = 'editing',
  Pending = 'pending',
  Ready = 'ready',
  Sent = 'sent',
}

type MenuItemOption = 'Document visit' | 'View in Athena';

const avsInfoHoverOptions = {
  ready: `How to send an After Visit Summary
1. Go to the visit A/P in Athena
2. Fill out Patient Instructions
3. Return to Visits and Cases in the CFE
4. Expand the corresponding visit
5. Tap “Confirm and Send”`,
  sent: 'This After Visit Summary has been sent successfully.',
  pending:
    'After Visit Summary entries must be entered via the Patient Instructions section of the encounter A/P.',
  expired: 'After Visit Summaries cannot be shown for encounters that occured over two weeks ago.',
  excluded: 'After Visit Summaries are not available for visits of this type.',
};

const statusInBodyPill = (status) => {
  if (!status) {
    return;
  }
  const pillTextOptions = {
    sent: 'SENT',
    ready: 'READY TO SEND',
    editing: 'EDIT MODE',
  };
  const pillText = pillTextOptions[status] || 'EMPTY';
  return <div className={`${css.statusInBodyPill} ${css[status]}`}>{pillText}</div>;
};

const renderDropdownState = (isOpen: boolean): DropdownState => {
  if (isOpen) {
    return 'open';
  } else {
    return 'closed';
  }
};

const getStatusTags = (encounter: Visit, numDxOrders: number) => {
  const statusTags: Tag[] = [];

  if (numDxOrders > 0) {
    statusTags.push({ type: 'DxOrders', content: numDxOrders });
  }

  const avs = encounter.afterVisitSummary;
  if (avs?.status === AvsStatus.Sent) {
    statusTags.push('AvsSent');
  } else if (avs?.status === AvsStatus.Ready) {
    statusTags.push('AvsReady');
  } else {
    statusTags.push('NoAvs');
  }
  return statusTags;
};

const getMenuItems = (encounter: Visit) => {
  if (encounter.status === 'CLOSED') {
    return [{ content: 'View in Athena', testId: 'ViewInAthena' }];
  } else {
    return [{ content: 'Document visit', testId: 'DocumentVisit' }];
  }
};

const onMenuSelect = (appointmentId: string | number) => {
  return (key: MenuItemOption) => {
    switch (key) {
      case 'Document visit':
      case 'View in Athena':
        window.open(getAppointmentURL(`${appointmentId}`), '_blank', 'noopener, noreferrer');
        break;
    }
  };
};

const Encounter = (props: EncounterProps) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { generativeAvs } = useFlags();
  const encounter = props.encounter;
  const { encounterId } = encounter;
  const diagnoses = encounter.diagnoses || [];

  const { id: patientId } = useActivePatient();
  const isMounted = useIsMounted();

  const [loadingState, setLoadingState] = useState(LoadingState.Success);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditingAvs, setIsEditingAvs] = useState(false);
  const [isSendingAvs, setIsSendingAvs] = useState(false);
  const [isRetractingAvs, setIsRetractingAvs] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [modalState, setModalState] = useState({
    isSaveOpen: false,
    isDeleteOpen: false,
  });

  const closeModal = (shouldFinishEditing: boolean) => {
    setModalState({ isSaveOpen: false, isDeleteOpen: false });
    if (shouldFinishEditing) {
      setIsEditingAvs(false);
    }
  };

  const toggleDropdown = (e) => {
    setIsConfirmOpen(false);
    setIsDropdownOpen(!isDropdownOpen);
    e.stopPropagation();
  };

  const refetch = useCallback(async () => {
    if (!encounterId) {
      return;
    }
    setLoadingState(LoadingState.Loading);
    try {
      await dispatch(fetchEncounter(patientId, encounterId));
      if (isMounted.current) {
        setLoadingState(LoadingState.Success);
      }
    } catch (e) {
      if (isMounted.current) {
        setLoadingState(LoadingState.Failure);
      }
    }
  }, [dispatch, encounterId, isMounted, patientId]);

  const toggle = useCallback(async () => {
    if (!isOpen) {
      refetch();
    }
    setIsOpen(!isOpen);
  }, [isOpen, refetch]);

  const { encounterDate, departmentName, departmentId, encounterVisitName, providerName } =
    props.encounter;

  const date = encounterDate
    ? DateTime.fromFormat(encounterDate, 'MM/dd/yyyy')
    : DateTime.fromMillis(0);
  const isThisYear = date.hasSame(DateTime.local(), 'years');
  const formattedDate = isThisYear ? date.toFormat('MMM d') : date.toFormat('MMM d, yyyy');

  const avs = encounter.afterVisitSummary;
  const avsMarkdown = avs?.content
    ? markdownFormatter.render(sanitizeAvsBlobToMarkdown(avs.content))
    : '';

  const avsBodyContentOptions = useMemo(() => {
    return {
      ready: avsMarkdown,
      sent: avsMarkdown,
      pending: 'Edit patient instructions here or in Athena to create an AVS.',
      expired: 'After Visit Summary not available.',
      excluded: 'This After Visit Summary has been excluded.',
    };
  }, [avsMarkdown]);

  const avsCssClass = `${css.avsBody} ${isEditingAvs && css.avsBodyEdit}`;

  const [avsContent, setAvsContent] = useState<string>(
    avs?.status && avsBodyContentOptions[avs.status],
  );

  useEffect(() => {
    setAvsContent(avs?.status && avsBodyContentOptions[avs.status]);
  }, [avs, avsBodyContentOptions]);

  const editAvs = () => {
    if (avs?.status === AvsStatus.Pending) {
      setAvsContent('');
    }
    setIsEditingAvs(true);
  };

  const cancelEditAvs = () => {
    setIsEditingAvs(false);
    setAvsContent(avs?.status && avsBodyContentOptions[avs.status]);
  };

  const saveEditedAvs = async () => {
    if (!encounterId) return;

    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(avsContent.replaceAll('&nbsp;', ' ').trim());
    const response = await appDispatch(saveAVS({ encounterId, content: markdown }));
    if (saveAVS.fulfilled.match(response)) {
      appDispatch(
        updateVisitWithAfterVisitSummary(patientId, encounterId, {
          afterVisitSummary: response.payload,
        }),
      );
      toast.success('AVS was saved successfully.');
    } else {
      toast.error(
        'Unable to save AVS. Please try again, and if the problem persists please report the issue.',
      );
    }
  };

  const deleteAvs = async () => {
    if (!encounterId) return;

    const response = await appDispatch(saveAVS({ encounterId, content: '' }));
    if (saveAVS.fulfilled.match(response)) {
      appDispatch(
        updateVisitWithAfterVisitSummary(patientId, encounterId, {
          afterVisitSummary: response.payload,
        }),
      );
      toast.success('AVS was deleted.');
    } else {
      toast.error(
        'Unable to delete AVS. Please try again, and if the problem persists please report the issue.',
      );
    }
  };

  const sendAvs = useCallback(
    async (appointmentId, content) => {
      if (!appointmentId || !content || !encounterId || !patientId) {
        return;
      }
      setIsSendingAvs(true);
      try {
        const res = await sendAfterVisitSummary(appointmentId, content);
        if (isSuccessResponse(res)) {
          dispatch(
            updateVisitWithAfterVisitSummary(patientId, encounterId, {
              afterVisitSummary: res.data,
            }),
          );
        } else {
          toast.error('Error creating After Visit Summary.');
        }
      } catch (e) {
        const cause = e.data?.error?.cause;
        if (cause?.toLowerCase.includes('could not create avs message in sendbird')) {
          toast.error('Unable to send message. Please try again.');
        } else {
          toast.error('Error creating After Visit Summary. Contact support for help.');
        }
      }
      setIsSendingAvs(false);
    },
    [dispatch, encounterId, patientId],
  );

  const retractAvs = useCallback(
    async (appointmentId) => {
      if (!appointmentId || !encounterId || !patientId) {
        return;
      }
      try {
        setIsRetractingAvs(true);
        const res = await retractAfterVisitSummary(appointmentId);
        if (isSuccessResponse(res)) {
          dispatch(
            updateVisitWithAfterVisitSummary(patientId, encounterId, {
              afterVisitSummary: res.data,
            }),
          );
        } else {
          toast.error('Error retracting After Visit Summary.');
        }
      } catch (e) {
        toast.error('Error retracting After Visit Summary.');
      }
      setIsDropdownOpen(false);
      setIsConfirmOpen(false);
      setIsRetractingAvs(false);
    },
    [dispatch, encounterId, patientId],
  );

  const confirmAVSRetractCard = (
    <div className={css.avsRetractWrapper}>
      <div className={css.retractHeader}>Retract AVS?</div>
      <div className={css.retractBody}>
        Are you sure you want to retract this AVS and <b>delete</b> it from the patient chat?
      </div>
      {isRetractingAvs ? (
        <button className={css.retractButtonLoading}>Retracting...</button>
      ) : (
        <button className={css.retractButton} onClick={() => retractAvs(encounter.appointmentId)}>
          <span>Confirm and Retract</span>
        </button>
      )}
    </div>
  );

  const wrappedRefresh = (e) => {
    refetch();
    e.stopPropagation();
  };

  const refreshButton = () => (
    <div className={`${css.refreshWrapper} ${css[loadingState]}`} onClick={wrappedRefresh}>
      {loadingState === LoadingState.Loading ? (
        <RotateCWArrowlessFeather className={css.refreshIcon} />
      ) : (
        <RotateCWFeather className={css.refreshIcon} />
      )}
    </div>
  );

  const orders = encounter.orders || [];
  const isAvsEditable = avs?.status === AvsStatus.Pending || avs?.status === AvsStatus.Ready;

  return (
    <div
      data-testid="EncounterCard"
      onClick={() => {
        setIsDropdownOpen(false);
        setIsConfirmOpen(false);
      }}
    >
      <div
        className={cx(css.basicDetails, css.pastDetails)}
        onClick={toggle}
        data-testid="EncounterCardHeader"
      >
        <div className={css.headerContainer}>
          <ListHeaderMedium className={css.header}>
            {departmentName && departmentId !== loadEnv('REACT_APP_TELEHEALTH_PROVIDER')
              ? `${encounterVisitName} at ${departmentName}`
              : encounterVisitName}
          </ListHeaderMedium>
          <div className={css.subDetails}>
            <ListBody>{formattedDate}</ListBody>
            <ListBody className={css.separator}>•</ListBody>
            <ListBody>{providerName}</ListBody>
          </div>
        </div>
        <div className={css.pastControls}>
          <VisitInfoBar
            tags={getStatusTags(encounter, diagnoses.length + orders.length)}
            dropdownState={renderDropdownState(isOpen)}
            menuItems={getMenuItems(encounter)}
            onMenuSelect={
              encounter.appointmentId ? onMenuSelect(encounter.appointmentId) : undefined
            }
          />
          {isOpen && refreshButton()}
        </div>
      </div>
      {isOpen && (
        <div className={css.ap}>
          <div className={css.apHeader}>
            <HealthCare width="14px" height="14px" color={EdenColors.Slate55} />
            Assessment and Plan
          </div>
          <div className={css.apBody}>
            <div className={css.apFallback}>
              {loadingState === LoadingState.Loading &&
                !Array.isArray(props.encounter.diagnoses) &&
                'Loading...'}
              {loadingState === LoadingState.Failure && 'Failed to load A/P.'}
              {loadingState === LoadingState.Loading &&
                props.encounter.diagnoses?.length === 0 &&
                'No A/P available.'}
              {loadingState === LoadingState.Success &&
                diagnoses.length === 0 &&
                'No A/P available.'}
            </div>
            {diagnoses.length > 0 && (
              <>
                <div className={css.apSubheader}>Diagnoses</div>
                <ul className={css.apList}>
                  {diagnoses.map((diagnosis, idx) => (
                    <li className={css.apLi} key={idx}>
                      {diagnosis.description}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {orders.length > 0 && (
              <>
                <div className={css.apSubheader}>Orders</div>
                <ul className={css.apList}>
                  {orders.map((order, idx) => {
                    const status = order.status?.toLowerCase();
                    let statusEl = <>{status && `${status} -`}</>;
                    if (status === 'closed') {
                      statusEl = (
                        <>
                          <strong>{status}</strong> -
                        </>
                      );
                    }
                    return (
                      <li className={css.apLi} key={idx}>
                        {statusEl} {order.description}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
          <div className={css.avsHeader}>
            <Clipboard className={css.clipboardIcon} />
            After Visit Summary
            {statusInBodyPill(isEditingAvs ? AvsStatus.Editing : avs?.status)}
            <div className={css.avsCornerActions}>
              {avs?.status === AvsStatus.Sent ? (
                <>
                  <span
                    onClick={toggleDropdown}
                    className={`${css.avsDropdown} ${isDropdownOpen && css.dropdownOpen}`}
                  >
                    <EllipsisFeather className={css.avsEllipsisIcon} />
                  </span>
                  <Dropdown
                    dropdownStyle={css.dropdownStyle}
                    closeStyle={css.hide}
                    isOpen={isDropdownOpen}
                    onClose={toggleDropdown}
                  >
                    {isConfirmOpen ? (
                      confirmAVSRetractCard
                    ) : (
                      <ListItem1 onClick={() => setIsConfirmOpen(true)}>Retract AVS</ListItem1>
                    )}
                  </Dropdown>
                </>
              ) : (
                <div className={css.avsHelpWrapper}>
                  <InfoFeather className={css.avsHelpIcon} />
                  <div className={`${css.avsHelpHover} ${avs?.status && css[avs.status]}`}>
                    {avs?.status && avsInfoHoverOptions[avs.status]}
                  </div>
                </div>
              )}
            </div>
          </div>
          {generativeAvs && encounterId && isAvsEditable && (
            <GenerateAVS encounterId={encounterId} />
          )}
          <div className={avsCssClass}>
            {isAvsEditable &&
              (isEditingAvs ? (
                <div className={css.topButtonsWrapper}>
                  <button onClick={cancelEditAvs} className={css.cancelEditButton}>
                    Cancel
                  </button>
                  <button
                    onClick={() => setModalState({ ...modalState, isSaveOpen: true })}
                    className={css.saveAVSButton}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className={css.topButtonsWrapper}>
                  <button
                    onClick={editAvs}
                    className={`${css.editAVSButton} ${isSendingAvs && css.sendingAVS}`}
                  >
                    Edit
                  </button>
                </div>
              ))}
            <div
              dangerouslySetInnerHTML={{
                __html: avsContent,
              }}
              contentEditable={isEditingAvs}
              onBlur={(e) => setAvsContent(e.currentTarget.innerHTML)}
              className={css.avsContent}
              // eslint-disable-next-line react/no-unknown-property
              custom-placeholder="Type your AVS here..."
            />
            {!avs && loadingState === LoadingState.Loading && 'Loading...'}
            {!avs && loadingState === LoadingState.Success && 'No After Visit Summary found.'}
            {!avs && loadingState === LoadingState.Failure && 'Error fetching After Visit Summary.'}
            {avs?.status === AvsStatus.Ready && !isEditingAvs && (
              <div className={css.bottomButtonsWrapper}>
                <button
                  onClick={() => setModalState({ ...modalState, isDeleteOpen: true })}
                  className={`${css.deleteAVSButton} ${isSendingAvs && css.sendingAVS}`}
                >
                  Delete Draft
                </button>
                <button
                  onClick={() => sendAvs(encounter.appointmentId, avs.content)}
                  className={`${css.confirmAVSButton} ${isSendingAvs && css.sendingAVS}`}
                >
                  {isSendingAvs ? 'Sending...' : 'Confirm and Send'}
                </button>
              </div>
            )}
            {isEditingAvs && (
              <div className={css.footnote}>
                CMD+B to <strong>bold</strong>, CMD+I to <em>italicize</em>
              </div>
            )}
          </div>
        </div>
      )}
      <SaveAVSModal isOpen={modalState.isSaveOpen} onClose={closeModal} onSave={saveEditedAvs} />
      <DeleteAVSModal isOpen={modalState.isDeleteOpen} onClose={closeModal} onDelete={deleteAvs} />
    </div>
  );
};

export default Encounter;

import React from 'react';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Placeholder } from 'semantic-ui-react';
import Tooltip from 'components/design-system/Tooltip';
import { LoadingState } from 'types/tables/patients';
import TicklerCard from 'components/features/TicklerOverview/TicklerCard';
import css from 'components/features/TicklerOverview/TicklerOverview.module.css';
import {
  getPatientName,
  fetchPatientTicklers,
  getPatientTicklers,
  getPatientTicklerLoadingState,
  getPatientTicklersFetchedAt,
} from 'reducers/patients';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { completeTickler } from 'reducers/patients';
import { PrimaryKey } from 'types/tables/base';
import { Tickler } from 'types/grdn';
import { Info, RotateCWArrowlessFeather, RotateCWFeather } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import EditTicklerModal from 'components/features/TicklerOverview/EditTicklerModal';
import DeleteTicklerModal from 'components/features/TicklerOverview/DeleteTicklerModal';

interface TicklerListProps {
  ticklers: Tickler[];
  openEditTicklerModal: (Tickler) => void;
  openDeleteTicklerModal: (Tickler) => void;
}

interface TicklerOverviewContentProps {
  ticklersLoadingState?: LoadingState;
  ticklers: Tickler[] | null | undefined;
  openEditTicklerModal: (Tickler) => void;
  openDeleteTicklerModal: (Tickler) => void;
}

interface TicklersState {
  editTicklerModalOpen: boolean;
  deleteTicklerModalOpen: boolean;
  currentTickler?: Tickler;
}

const initialTicklersState = {
  editTicklerModalOpen: false,
  deleteTicklerModalOpen: false,
  currentTickler: undefined,
};

const TicklerListLoading = () => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <div key={i} className={css.ticklerCard}>
          <Placeholder data-testid="PlaceholderGraphic" className={css.placeholder}>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      ))}
    </>
  );
};

const TicklerListEmpty = () => {
  return <>This patient has no ticklers at this time.</>;
};

const TicklerListError = () => {
  return <div>We had an issue trying to load this patient&apos;s ticklers.</div>;
};

const TicklersPartialFetchFailureNotice = () => {
  return <div className={css.partialFailureNotice}>Error loading some ticklers from Athena</div>;
};

const TicklersFetchedAtNotice = ({ ticklersFetchedAt }: { ticklersFetchedAt: string }) => {
  return (
    <div className={css.ticklersFetchedAtNotice}>{`Last updated at ${ticklersFetchedAt}`}</div>
  );
};

const RefetchButton = ({
  loadingState,
  patientId,
}: {
  loadingState: LoadingState | undefined;
  patientId: PrimaryKey;
}) => {
  const dispatch = useAppDispatch();
  const secondaryWrapperClass = loadingState === LoadingState.Loading ? css.loading : null;
  const refetchTicklers = (e) => {
    if (loadingState === LoadingState.Success || loadingState === LoadingState.PartialFailure) {
      dispatch(fetchPatientTicklers({ patientId }));
    }
    e.stopPropagation();
  };

  return (
    <div
      className={`${css.refetchWrapper} ${secondaryWrapperClass}`}
      onClick={refetchTicklers}
      data-testid="RefetchButton"
    >
      {loadingState === LoadingState.Success || loadingState === LoadingState.PartialFailure ? (
        <RotateCWFeather className={css.refetchIcon} />
      ) : (
        <RotateCWArrowlessFeather className={css.refetchIcon} />
      )}
    </div>
  );
};

const TicklerList = ({
  ticklers,
  openEditTicklerModal,
  openDeleteTicklerModal,
}: TicklerListProps) => {
  const dispatch = useAppDispatch();

  const completeTicklerOnClick = async (tickler: Tickler) => {
    const { ticklerAthenaId, note, patientId } = tickler;
    const response = await dispatch(completeTickler({ patientId, ticklerAthenaId, note }));

    if (completeTickler.fulfilled.match(response)) {
      toast.success('Tickler successfully completed.');
    } else {
      toast.error('Unable to complete tickler. Try again.');
    }
  };

  return (
    <>
      {ticklers?.map((tickler, index) => {
        const { ticklerAthenaId } = tickler;

        return (
          <div key={ticklerAthenaId || index}>
            <TicklerCard
              tickler={tickler}
              onComplete={() => {
                completeTicklerOnClick(tickler);
              }}
              onDelete={() => {
                openDeleteTicklerModal(tickler);
              }}
              onEdit={() => {
                openEditTicklerModal(tickler);
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const TicklerOverviewContent = ({
  ticklersLoadingState,
  ticklers,
  openEditTicklerModal,
  openDeleteTicklerModal,
}: TicklerOverviewContentProps) => {
  if (ticklersLoadingState === LoadingState.Loading || ticklersLoadingState === undefined) {
    return <TicklerListLoading />;
  } else if (ticklersLoadingState === LoadingState.Success && ticklers && ticklers.length === 0) {
    return <TicklerListEmpty />;
  } else if (
    ticklersLoadingState === LoadingState.Failure ||
    (ticklersLoadingState === LoadingState.PartialFailure && ticklers && ticklers.length === 0)
  ) {
    return <TicklerListError />;
  } else if (
    (ticklersLoadingState === LoadingState.Success ||
      ticklersLoadingState === LoadingState.PartialFailure) &&
    ticklers &&
    ticklers.length > 0
  ) {
    return (
      <TicklerList
        ticklers={ticklers}
        openEditTicklerModal={openEditTicklerModal}
        openDeleteTicklerModal={openDeleteTicklerModal}
      />
    );
  } else {
    return <TicklerListLoading />;
  }
};

export const TicklerOverview = () => {
  const activePatient = useActivePatient();
  const activePatientName = useParameterizedSelector(getPatientName, activePatient.id);
  const dispatch = useAppDispatch();
  const ticklersLoadingState = useParameterizedSelector(
    getPatientTicklerLoadingState,
    activePatient.id,
  );
  const ticklers = useParameterizedSelector(getPatientTicklers, activePatient.id);
  const tooltipText = 'Showing ticklers for the past 3 months and the next 12 months.';
  const ticklersFetchedAt = useParameterizedSelector(getPatientTicklersFetchedAt, activePatient.id);

  const [{ editTicklerModalOpen, deleteTicklerModalOpen, currentTickler }, setState] =
    useState<TicklersState>(initialTicklersState);

  const closeEditTicklerModal = () => {
    setState((state) => ({
      ...state,
      editTicklerModalOpen: false,
      currentTickler: undefined,
    }));
  };

  const openEditTicklerModal = (tickler) => {
    setState((state) => ({
      ...state,
      currentTickler: tickler,
      editTicklerModalOpen: true,
    }));
  };

  const closeDeleteTicklerModal = () => {
    setState((state) => ({
      ...state,
      deleteTicklerModalOpen: false,
      currentTickler: undefined,
    }));
  };

  const openDeleteTicklerModal = (tickler) => {
    setState((state) => ({
      ...state,
      currentTickler: tickler,
      deleteTicklerModalOpen: true,
    }));
  };

  useEffect(() => {
    if (ticklersLoadingState === undefined) {
      dispatch(fetchPatientTicklers({ patientId: activePatient.id }));
    }
  }, [dispatch, activePatient.id, ticklersLoadingState]);

  return (
    <div className={css.container}>
      <div className={css.label}>
        <span className={css.ticklerListInfoGroup}>
          <span className={css.ticklerListTitle}>
            {ticklersLoadingState === LoadingState.Loading || ticklersLoadingState === undefined
              ? 'Loading Ticklers'
              : `Ticklers for ${activePatientName}`}
          </span>
          <Tooltip content={tooltipText}>
            <Info color={EdenColors.SlateDarken20} height="14" width="14" />
          </Tooltip>
        </span>
      </div>
      {ticklersFetchedAt && (
        <div className={css.ticklersListRefetchCenter}>
          <TicklersFetchedAtNotice ticklersFetchedAt={ticklersFetchedAt} />
          <RefetchButton loadingState={ticklersLoadingState} patientId={activePatient.id} />
        </div>
      )}
      {ticklersLoadingState === LoadingState.PartialFailure && (
        <TicklersPartialFetchFailureNotice />
      )}
      <TicklerOverviewContent
        ticklersLoadingState={ticklersLoadingState}
        ticklers={ticklers}
        openEditTicklerModal={openEditTicklerModal}
        openDeleteTicklerModal={openDeleteTicklerModal}
      />
      {editTicklerModalOpen && (
        // Only render the Edit and Delete modals when they are open
        // This ensures that the modals can dismount and remount, and reset form state
        <EditTicklerModal
          isOpen={editTicklerModalOpen}
          closeModal={closeEditTicklerModal}
          ticklerToUpdate={currentTickler}
        />
      )}
      {deleteTicklerModalOpen && currentTickler && (
        <DeleteTicklerModal
          isOpen={deleteTicklerModalOpen}
          closeModal={closeDeleteTicklerModal}
          ticklerToDelete={currentTickler}
        />
      )}
    </div>
  );
};

export default TicklerOverview;

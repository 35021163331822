import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import css from './ChatDocument.module.css';
import { ChatErrorMessage } from './ChatErrorMessage';
import { DocumentIcon } from 'components/ui/svg';
import { getDocumentMetadata } from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { DocumentMetadata } from 'types/tables/documents';
import { downloadDocument } from 'lib/document';

const errorMessages = {
  noMetadata:
    'Unable to retrieve information for an uploaded document. Please contact engineering to resolve this issue.',
};

interface ChatDocumentError {
  message: string;
  css?: string;
}

interface ChatDocumentProps {
  documentId: string;
}

const ChatDocument = (props: ChatDocumentProps) => {
  const [metadata, setMetadata] = useState<DocumentMetadata | undefined>();

  const [error, setError] = useState<ChatDocumentError | null>(null);
  const { documentId } = props;

  const getMetadata = useCallback(async (documentId: string) => {
    try {
      const response = await getDocumentMetadata(documentId);
      if (isSuccessResponse(response)) {
        setMetadata(response.data);
      }
    } catch (e) {
      setError({ message: errorMessages.noMetadata });
    }
  }, []);

  useEffect(() => {
    getMetadata(documentId);
  }, [documentId, getMetadata]);

  const handleClick = async () => {
    setError(null);
    if (metadata?.filename) {
      try {
        await downloadDocument(documentId, metadata.filename, 'chat');
      } catch (e) {
        setError({
          message: `Error opening “${metadata.filename}”`,
          css: css.withFilename,
        });
      }
    }
  };

  const documentFor = metadata && metadata.isDependent ? 'dependent' : 'patient';

  if (error?.message === errorMessages.noMetadata) {
    return <ChatErrorMessage message={error.message} messageCSS={error.css} />;
  }

  /** Empty div when there isn't metadata is intentional.
   * Prevents message times from stacking on each other while loading data. */
  return metadata ? (
    <>
      <div className={css.cardWrapper}>
        <button
          className={cx(css.card, css.buttonCard)}
          onClick={handleClick}
          aria-label={`View ${metadata?.filename}`}
        >
          <div className={css.file}>
            <span className={css.documentIcon}>
              <DocumentIcon height={18} width={15} />
            </span>
            <div className={css.filename}>{metadata?.filename}</div>
          </div>
          <div className={css.for}>For {documentFor}</div>
          {metadata?.notes && <div className={css.notes}>{metadata.notes}</div>}
        </button>
      </div>
      {error && (
        <div>
          <ChatErrorMessage message={error.message} messageCSS={error.css} />
        </div>
      )}
    </>
  ) : (
    <div> </div>
  );
};

export default React.memo(ChatDocument);

import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { EdenColors } from '../../../types/colors';
import { BodySmall, HeaderMedium } from '../../design-system/Text';
import { PrimaryButton } from '../../design-system/Form';
import css from './DeleteModal.module.css';
import { Trash, XIcon } from 'components/ui/svg';
import { getSponsorById } from 'reducers/sponsors';
import { useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { deletePopup } from 'reducers/popups';
import { Popup } from 'types/tables/popups';

export interface DeleteModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  popup?: Popup;
}

export const DeleteModal = ({ isOpen, closeModal, popup }: DeleteModalProps) => {
  const sponsor = useParameterizedSelector(getSponsorById, popup?.sponsorId ?? '');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const deletePopupClicked = async () => {
    if (!popup) return;
    setSubmitting(true);
    const res = await dispatch(deletePopup(popup));
    setSubmitting(false);
    if (deletePopup.fulfilled.match(res)) closeModal();
    else toast.error('Unable to delete pop-up, please try again.');
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeModal={closeModal}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <div className={css.topNav}>
        <div className={css.icon}>
          <Trash />
        </div>
        <HeaderMedium>Delete this pop-up?</HeaderMedium>
        <div className={css.spacer} />
        <div onClick={closeModal} style={{ cursor: 'pointer' }}>
          <XIcon color={EdenColors.Slate55} height={24} width={24} />
        </div>
      </div>
      <p />
      <BodySmall className={css.instructions}>
        <b>This will permanently delete the pop-up for the {` ${sponsor?.name} `} sponsor.</b>
        <p />
        <p />
        Patient appointments that have already been booked must be manually reconciled in Athena.
      </BodySmall>
      <div className={css.bottomNav}>
        <PrimaryButton
          className={cx(css.button, css.cancel)}
          type="button"
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          className={css.button}
          type="button"
          value="Delete Now"
          onClick={deletePopupClicked}
          loading={submitting}
          data-testid="delete-button"
        />
      </div>
    </ReactModal>
  );
};

import React from 'react';
import cx from 'classnames';
import css from 'components/features/PatientPills/PatientPills.module.css';
import { calendarDate } from 'lib/time';
import { PatientTriageStatus, TriageStatus } from 'types/tables/profiles';
import { Ellipse } from 'components/ui/svg';

export const getTriageStatusLabel = (triageStatus: PatientTriageStatus): string => {
  switch (triageStatus.status) {
    case TriageStatus.NeverTaken:
      return `No Back to Work status set`;
    case TriageStatus.Cleared:
      return `Cleared for work`;
    case TriageStatus.Incomplete:
      return `Incomplete`;
    case TriageStatus.Isolate:
      return triageStatus.returnToWorkDate
        ? `Isolate RTW on ${calendarDate(triageStatus.returnToWorkDate)}`
        : `Isolate set RTW date`;
  }
};

export const StatusPill = ({ triageStatus }: { triageStatus: PatientTriageStatus }) => {
  return (
    <span className={cx(css.labelContainer, css.inlineContainer)}>
      <div className={css[triageStatus.status]}>
        <Ellipse className={css.ellipse} />
        {getTriageStatusLabel(triageStatus)}
      </div>
    </span>
  );
};

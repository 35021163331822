import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import camelize from 'camelize';
import { convertToFormData, apiRequestHeaders } from './util';
import { APIResponse } from 'types/api';
import { captureException, captureGrdnErrorBreadcrumb } from 'lib/sentry';
import { ServerErrorCode } from 'types/http';

/** Class for creating an Axios instance specifically for sending and receiving documents */
export default class DocumentApi {
  baseURI: string;
  instance: AxiosInstance;

  constructor(uri: string) {
    this.baseURI = uri;
    const instanceConfig: AxiosRequestConfig = {
      baseURL: this.baseURI,
    };

    this.instance = axios.create(instanceConfig);

    this.instance.interceptors.response.use(
      (resp: AxiosResponse) => {
        // if this is a file don't decamelize its properties
        if (resp.request.responseType == 'arraybuffer') {
          return resp;
        }

        return {
          ...resp,
          data: camelize(resp.data.data),
        };
      },
      (error: any) => {
        // any type comes from Axios docs
        // captures exception for sentry if server error OR if error has no parsed response
        const method = error?.config?.method;
        const status = error?.response?.status;
        const url = error?.config?.url;
        if (status in ServerErrorCode || (!error.response && error.message)) {
          captureException(new Error(error), error.response);
        }
        captureGrdnErrorBreadcrumb(
          {
            url,
            method,
            status,
            error,
          },
          `Call to ${method} ${url} failed with ${status}.`,
        );
        return Promise.reject(error.response);
      },
    );
  }

  async get<T>(url: string, cancelToken?: CancelToken): Promise<APIResponse<T>> {
    return await this.instance.get(url, {
      responseType: 'arraybuffer',
      cancelToken,
      headers: await this.requestHeaders(),
    });
  }

  async post<T>(url: string, data: Record<string, any>): Promise<APIResponse<T>> {
    const formData = convertToFormData(data);
    return await this.instance.post(url, formData, { headers: await this.requestHeaders() });
  }

  async requestHeaders() {
    const headers = await apiRequestHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    return headers;
  }
}

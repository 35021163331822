import { StateKey } from 'types/geo';
import { BaseRecord } from 'types/tables/base';

export interface AthenaProviderId {
  providerGroup: string | null;
  providerGroupId: number;
  providerId: number;
}

export type ProviderId = string;

export enum Permission {
  MACRO_CREATE = 'macro-create',
  MACRO_READ = 'macro-read',
  MACRO_UPDATE = 'macro-update',
  MACRO_DELETE = 'macro-delete',
  BRAD_CREATE = 'brad-create',
  BRAD_READ = 'brad-read',
  BRAD_UPDATE = 'brad-update',
  BRAD_DELETE = 'brad-delete',
  POP_UP_CREATE = 'pop-up-create',
  POP_UP_READ = 'pop-up-read',
  POP_UP_UPDATE = 'pop-up-update',
  POP_UP_DELETE = 'pop-up-delete',
  TEST_RESULTS_CREATE = 'test-results-create',
  TEST_RESULTS_READ = 'test-results-read',
  TEST_RESULTS_UPDATE = 'test-results-update',
  TEST_RESULTS_DELETE = 'test-results-delete',
  RDASH_CREATE = 'rdash-create',
  RDASH_READ = 'rdash-read',
  RDASH_UPDATE = 'rdash-update',
  RDASH_DELETE = 'rdash-delete',
  SPONSOR_CREATE = 'sponsor-create',
  SPONSOR_READ = 'sponsor-read',
  SPONSOR_UPDATE = 'sponsor-update',
  SPONSOR_DELETE = 'sponsor-delete',
  PATIENT_INFO_READ = 'patient-info-read',
  PATIENT_INFO_UPDATE = 'patient-info-update',
}

export const ALL_PERMISSIONS = Object.values(Permission);

export enum ProviderRole {
  TEMP_ROLE = 'temp_role',
  PROVIDER = 'provider',
  ASSOCIATE = 'associate',
  CLIENT_SERVICES = 'client_services',
  TECH_ADMIN = 'tech_admin',
  CLOPS_ADMIN_PROVIDER = 'clops_admin_provider',
  CLOPS_ADMIN_ASSOCIATE = 'clops_admin_associate',
  EXTERNAL_USER_PROVIDER = 'external_user_provider',
  EXTERNAL_USER_ASSOCIATE = 'external_user_associate',
  INACTIVE = 'inactive',
}

export interface Provider extends BaseRecord {
  id: ProviderId;
  createdAt: string;
  googleId: string;
  athenaUsername: string | null;
  athenaProviderId: number | null; // XXX: We may eventually be able to get rid of this, but not right now
  athenaProviderIds?: AthenaProviderId[];
  displayName: string;
  licenses: StateKey[] | null;
  headshotUrl: string | null;
  zoomId: string | null;
  sendbirdUserId: string;
  careteamId: string | null;
  isCloseknitCredentialed: boolean;
  role: ProviderRole | null;
  permissions?: Permission[];
}

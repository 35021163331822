import React, { ChangeEvent, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import css from 'components/features/VisitOverview/CustomTimeFrame.module.css';
import { ButtonSizes, PrimaryButton } from 'components/ui/Buttons';
import { LoaderAnimated } from 'components/ui/svg';
import { BodySmall } from 'components/design-system/Text';
import {
  DayPicker,
  Label,
  TimePicker,
  formatDateForDayPicker,
} from 'components/design-system/Form';
import { isInFuture } from 'components/features/VisitOverview/utils';
import { CustomTimeFrameFormData } from 'components/features/VisitOverview/types';

interface Props {
  isGenerating: boolean;
  onSubmit: (formData: CustomTimeFrameFormData) => Promise<void>;
}

export const CustomTimeFrame = ({ isGenerating, onSubmit }: Props) => {
  const emptyForm: CustomTimeFrameFormData = {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  };

  const [formData, setFormData] = useState<CustomTimeFrameFormData>(emptyForm);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      !!formData.startDate &&
        !!formData.startTime &&
        !!formData.endDate &&
        !!formData.endTime &&
        !isInFuture(formData.startDate) &&
        !isInFuture(formData.endDate),
    );
  }, [formData]);

  const submit = async () => {
    if (!isFormValid) return;
    await onSubmit(formData);
    setFormData(emptyForm);
  };

  return (
    <div className={css.customTimeFrameContainer}>
      <div className={css.convoContainer}>
        <BodySmall className={css.convoLabel}>Convo Start:</BodySmall>
        <Label text="Date" required>
          <DayPicker
            value={formData.startDate}
            disabled={isGenerating}
            max={formatDateForDayPicker(DateTime.now().toISO())}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, startDate: e.target.value });
            }}
          />
        </Label>
        <Label text="Time" required>
          <TimePicker
            value={formData.startTime}
            disabled={isGenerating}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, startTime: e.target.value });
            }}
          />
        </Label>
      </div>
      <div className={css.convoContainer}>
        <BodySmall className={css.convoLabel}>Convo End:</BodySmall>
        <Label text="Date" required>
          <DayPicker
            value={formData.endDate}
            disabled={isGenerating}
            max={formatDateForDayPicker(DateTime.now().toISO())}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, endDate: e.target.value });
            }}
          />
        </Label>
        <Label text="Time" required>
          <TimePicker
            value={formData.endTime}
            disabled={isGenerating}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, endTime: e.target.value });
            }}
          />
        </Label>
      </div>
      <div className={css.bottomNav}>
        <PrimaryButton
          size={ButtonSizes.large}
          disabled={!isFormValid}
          onClick={submit}
          content={isGenerating ? 'Generating' : 'Generate'}
          icon={
            isGenerating &&
            LoaderAnimated({ className: css.svgCircleSpinner, width: 20, height: 20 })
          }
          className={`${isGenerating && css.buttonDisabled}`}
        />
      </div>
    </div>
  );
};

import React from 'react';

import type { Deleted, DeleteReasonType } from '../../../CarePlanTypes';
import DateDisplay from '../../date';

import { useParameterizedSelector } from 'lib/hooks';
import { getProviderById } from 'reducers/providers';

const reasonText: Record<DeleteReasonType, string> = {
  irrelevant: 'No longer relevant',
  mistake: 'Created on mistake',
  'wrong-patient': 'Wrong care plan',
};

interface Props {
  disposition: Deleted;
}

const DeletedCardDetail = ({ disposition }: Props) => {
  const { deleteReason } = disposition;

  const provider = useParameterizedSelector(getProviderById, disposition.providerId);

  return (
    <>
      <DateDisplay date={disposition.when} />
      {' • Deleted'}
      {deleteReason ? (
        <>
          {': '}
          {reasonText[deleteReason]}
        </>
      ) : (
        ''
      )}

      {!!provider && (
        <>
          {' • '}
          {provider.displayName}
        </>
      )}
    </>
  );
};

export default DeletedCardDetail;

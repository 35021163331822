import React, { useState } from 'react';
import Tag from 'components/design-system/tag';
import StatusTag from 'components/features/CarePlan/next-steps/StatusTag';
import { dispositionOf } from 'components/features/CarePlan/utils/dispositionOf';
import { descriptorFor } from 'components/features/CarePlan/utils/descriptorFor';
import { Disposition } from 'components/features/CarePlan/CarePlanTypes';
import PatientNote from 'components/features/CarePlan/next-steps/note';
import SourceDetail from 'components/features/CarePlan/next-steps/card/detail/SourceDetail';
import css from 'components/features/CarePlan/next-steps/card/NextStepCard.module.css';
import Toggler from 'components/features/CarePlan/next-steps/card/Toggler';
import ActiveCardDetail from 'components/features/CarePlan/next-steps/card/detail/ActiveCardDetail';
import AlreadyCompletedCardDetail from 'components/features/CarePlan/next-steps/card/detail/AlreadyCompletedCardDetail';
import BookedCardDetail from 'components/features/CarePlan/next-steps/card/detail/BookedCardDetail';
import DeclinedCardDetail from 'components/features/CarePlan/next-steps/card/detail/DeclinedCardDetail';
import DeletedCardDetail from 'components/features/CarePlan/next-steps/card/detail/DeletedCardDetail';
import DroppedCardDetail from 'components/features/CarePlan/next-steps/card/detail/DroppedCardDetail';
import MarkedCardDetail from 'components/features/CarePlan/next-steps/card/detail/MarkedCardDetail';
import NextStepIcon from 'components/features/CarePlan/next-steps/icon/NextStepIcon';

interface DetailProps {
  disposition: Disposition;
}

const Detail = ({ disposition }: DetailProps) => {
  switch (disposition.dispositionType) {
    case 'active':
      return <ActiveCardDetail disposition={disposition} />;
    case 'already-completed':
      return <AlreadyCompletedCardDetail disposition={disposition} />;
    case 'booked':
      return <BookedCardDetail disposition={disposition} />;
    case 'declined':
      return <DeclinedCardDetail disposition={disposition} />;
    case 'deleted':
      return <DeletedCardDetail disposition={disposition} />;
    case 'dropped':
      return <DroppedCardDetail disposition={disposition} />;
    case 'marked':
      return <MarkedCardDetail disposition={disposition} />;
  }
};

const NextStepContent = ({ step }) => {
  const [showPatientNote, setShowPatientNote] = useState(false);

  const disposition = dispositionOf(step);
  const descriptor = descriptorFor(step);

  const { directive } = descriptor;

  const onToggleNote = () => {
    setShowPatientNote(!showPatientNote);
  };

  const showToggler =
    (disposition.dispositionType === 'declined' || disposition.dispositionType === 'marked') &&
    !!disposition.comment;

  const showTagBar =
    disposition.dispositionType === 'active' &&
    step.type === 'followup' &&
    (step.inPersonOnly || !!step.withProviderId);

  return (
    <>
      <div className={css.icon}>
        <NextStepIcon step={step} />
      </div>

      <div className={css.info}>
        <div className={css.displayName}>{directive}</div>

        <div className={css.stepDetails}>
          <Detail disposition={disposition} />
        </div>

        {!disposition.isComplete && (step.type === 'custom' || step.type === 'followup') && (
          <div className={css.providerNote}>{step.note}</div>
        )}

        <PatientNote show={showPatientNote} step={step} />

        {step.type === 'followup' && showTagBar && (
          <div className={css.tagBar}>
            {step.inPersonOnly && <Tag>IN-PERSON VISIT</Tag>}
            {step.withProviderId && <Tag>BOOK WITH ME</Tag>}
          </div>
        )}

        {!disposition.isComplete && <SourceDetail disposition={disposition} />}
      </div>

      <div>
        <StatusTag status={disposition.status} />
      </div>

      <Toggler isShown={showToggler} isOpen={showPatientNote} onToggle={onToggleNote} />
    </>
  );
};

export default NextStepContent;

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { Careteam } from 'types/tables/careteams';
import { isSuccessResponse } from 'types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { PrimaryKey } from 'types/tables/base';

const prefix = 'careteam';
const initializationRejectionType = 'Unable to initialize careteams.';

export const initializeCareteams = createAsyncThunk(
  `${prefix}/getAllCareteams`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getAllCareteams();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: initializationRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: initializationRejectionType });
    }
  },
);

export const careteamsAdapter = createEntityAdapter<Careteam>({});

export const careteams = createSlice({
  name: prefix,
  initialState: careteamsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeCareteams.fulfilled, (state, action) => {
      careteamsAdapter.upsertMany(state, action.payload);
    });
  },
});

export const careteamSelectors = careteamsAdapter.getSelectors<Store>((state) => state.careteams);

export const getCareteamEntities = careteamSelectors.selectEntities;

const extractCareteamId = (_, careteamId: PrimaryKey) => careteamId;

export const getCareteamRecord = createSelector(
  extractCareteamId,
  getCareteamEntities,
  (careteamId, careteams) => careteams[careteamId] || ({} as Careteam),
);

export const getCareteamName = createSelector(
  getCareteamRecord,
  (careteam) => careteam.name || null,
);

export default careteams.reducer;

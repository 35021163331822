import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer } from 'semantic-ui-react';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import css from './Chat.module.css';
import { Messages } from './Messages';
import { useActiveChat, useActivePatient, usePrevious, useParameterizedSelector } from 'lib/hooks';
import Composer from 'components/features/Composer/Composer';
import { captureException } from 'lib/sentry';
import { getPatientCopilotSuggestion } from 'reducers/patients';

interface ChatProps {
  loadHistory: () => Promise<void>;
  showLastRead: boolean;
  lastRead?: number;
}

enum InitialHistoryState {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  Loaded = 'Loaded',
}

enum ScrollTarget {
  top,
  bottom,
  none,
}

export default function Chat({ loadHistory, showLastRead, lastRead }: ChatProps) {
  const patient = useActivePatient();
  const { channel, ready: channelsReady } = useActiveChat();
  const [oldestMessage, setOldestMessage] = useState<HTMLDivElement>();
  const [newestMessage, setNewestMessage] = useState<HTMLDivElement>();
  const previousNewestMessage = usePrevious(newestMessage);
  const [initialHistoryState, setInitialHistoryState] = useState(InitialHistoryState.NotLoaded);
  const [scrollTarget, setScrollTarget] = useState(ScrollTarget.none);
  const scrollComponentId = `${patient.id}_${channel.channelType}`;
  const copilotSuggestion = useParameterizedSelector(getPatientCopilotSuggestion, patient.id);

  // when we switch patients we need to mark history as not loaded
  useEffect(() => {
    setInitialHistoryState(InitialHistoryState.NotLoaded);
    setScrollTarget(ScrollTarget.none);
  }, [patient.id]);

  const loadMoreHistory = useCallback(async () => {
    await loadHistory();
    setScrollTarget(ScrollTarget.top);
  }, [loadHistory]);

  const scrollTo = useCallback(
    (el: HTMLDivElement) => {
      const scrollingContainer = document.getElementById(scrollComponentId);
      if (
        scrollingContainer &&
        scrollingContainer.scrollTop + scrollingContainer.clientHeight >
          scrollingContainer.scrollHeight - 120
      ) {
        scrollingContainer.scrollTop = scrollingContainer.scrollHeight;
      }
      el.scrollIntoView({ inline: 'start' });
      setScrollTarget(ScrollTarget.none);
    },
    [scrollComponentId],
  );

  useEffect(() => {
    if (channelsReady && initialHistoryState === InitialHistoryState.NotLoaded) {
      setInitialHistoryState(InitialHistoryState.Loading);
      loadHistory()
        .then(() => {
          setInitialHistoryState(InitialHistoryState.Loaded);
          setScrollTarget(ScrollTarget.bottom);
          return;
        })
        .catch(captureException);
    }
  }, [channelsReady, patient.id, initialHistoryState, loadHistory]);

  useEffect(() => {
    if (previousNewestMessage && previousNewestMessage !== newestMessage) {
      setScrollTarget(ScrollTarget.bottom);
    }
  }, [previousNewestMessage, newestMessage]);

  // Force scroll to bottom when
  // - initial history is loaded
  // - a new message is received or sent
  // - there are pending messages
  // Otherwise keep previous first message in view when more are loaded
  useEffect(() => {
    if (scrollTarget === ScrollTarget.top && oldestMessage) {
      scrollTo(oldestMessage);
    } else if (scrollTarget === ScrollTarget.bottom && newestMessage) {
      scrollTo(newestMessage);
    }
  }, [scrollTarget, patient.id, newestMessage, oldestMessage, scrollTo]);

  // Force scroll chat to bottom when copilot suggestion appears
  useEffect(() => {
    setScrollTarget(ScrollTarget.bottom);
  }, [copilotSuggestion]);

  const showDimmer = initialHistoryState !== InitialHistoryState.Loaded;
  return (
    <Dimmer.Dimmable className={css.chat} blurring dimmed={showDimmer}>
      <Dimmer active={showDimmer}>
        <Loader>Loading...</Loader>
      </Dimmer>
      <Messages
        loadHistory={loadMoreHistory}
        setOldestMessage={setOldestMessage}
        setNewestMessage={setNewestMessage}
        scrollComponentId={scrollComponentId}
        showLastRead={showLastRead}
        lastRead={lastRead}
      />
      <Composer />
    </Dimmer.Dimmable>
  );
}

import React from 'react';

import css from './VisitProvider.module.css';

import { VisitProvider as Provider } from 'types/tables/messages';
import { BodySmall } from 'components/design-system/Text';
import { EdenCircleLogo } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

interface Props {
  provider: Provider;
}

const VisitProvider = ({ provider }: Props) => {
  return (
    <div className={css.visitProvider}>
      <div className={css.headshot}>
        {provider.headshotUrl ? (
          <img src={provider.headshotUrl} className={css.headshotImg} />
        ) : (
          <EdenCircleLogo color={EdenColors.Slate} className={css.headshotImg} />
        )}
      </div>

      <BodySmall className={css.body}>{provider.name}</BodySmall>
    </div>
  );
};

export default VisitProvider;

import { MessageDraft, PendingMessage } from 'types/tables/messages';
import { BaseRecord, PrimaryKey } from 'types/tables/base';

export interface BaseTable<T extends BaseRecord> {
  allIds: PrimaryKey[];
  byId: Record<PrimaryKey, T>;
}

export type MessageDraftsTable = BaseTable<MessageDraft>;
export type PendingMessagesTable = BaseTable<PendingMessage>;
export enum TableRecordType {
  MessageDrafts = 'messageDrafts',
  PendingMessages = 'pendingMessages',
}

export interface TableStore {
  [TableRecordType.MessageDrafts]: MessageDraftsTable;
  [TableRecordType.PendingMessages]: PendingMessagesTable;
}

export type TableRecord = MessageDraft | PendingMessage;

import React from 'react';
import { EdenColors } from 'types/colors';

const PhysicalTherapyIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Apple10} />
      <path
        d="M19.8624 20.9561L21.3234 21.4846L15.5737 37.35L9.01953 34.9734L14.7692 19.1113L18.048 20.2963"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0479 20.2961L20.6803 13.0249"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.14 20.9628L26.6992 21.4846L32.4488 37.35L38.9995 34.9734L33.2499 19.1113L29.9746 20.2963"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.975 20.2964L27.4268 13.1699"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6465 13.1697C21.1077 11.739 22.4508 10.7021 24.0364 10.7021C25.6219 10.7021 26.965 11.739 27.4262 13.1697"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5632 16.9797C24.3844 17.6037 22.9108 17.1761 22.2728 16.023C21.6348 14.8699 22.072 13.4286 23.2509 12.8045C24.4297 12.1804 27.0401 12.8583 27.3153 13.3494C27.5841 13.8467 26.7421 16.3556 25.5632 16.9797Z"
        stroke={EdenColors.Eden}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhysicalTherapyIcon;

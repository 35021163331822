export enum EdenColors {
  ActionPrimary = '#5C60D4',
  Apple = '#6ed69a',
  Apple10 = '#f0fbf5',
  Apple20 = '#e2f7eb',
  Apple40 = '#c5efd7',
  Apple60 = '#a8e6c2',
  Apple80 = '#8bdeae',
  Eden = '#14b87c',
  EdenDarken10 = '#12a670',
  EdenDarken20 = '#008051',
  EdenLogoGreen = '#48a590',
  EdenLogoGrey = '#2b3b3d',
  Grape = '#5061FA',
  Grape10 = '#EDEFFF',
  GrapeDarken20 = '#3C30C2',
  Lemon = '#ffcc25',
  Lemon10 = '#fffae9',
  Lemon20 = '#fff5d3',
  Lemon40 = '#ffeba8',
  Lemon60 = '#ffe07c',
  Lemon80 = '#ffd651',
  Razz = '#f8506f',
  Razz10 = '#feedf1',
  RazzDarken20 = '#b42641',
  Slate = '#3a545b',
  Slate05 = '#f5f6f7',
  Slate15 = '#e1e5e6',
  Slate25 = '#ced4d6',
  Slate35 = '#bac3c6',
  Slate45 = '#a6b2b5',
  Slate55 = '#93a1a5',
  Slate85 = '#586e74',
  SlateDarken20 = '#2e4349',
  Transparent = 'rgba(0,0,0,0)',
  White = '#ffffff',

  // Depricated
  OffLightGrey = '#e9eef0',
  Grey55 = '#808080',
}

export interface ColorProps {
  color?: EdenColors;
}

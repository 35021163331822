import React, { ChangeEvent, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import cx from 'classnames';
import css from './MacroModal.module.css';
import PersonalizationDropdown from './PersonalizationDropdown';
import { Button, ButtonSizes, ButtonType, PrimaryButton } from 'components/ui/Buttons';
import { Macro } from 'types/tables/macros';
import { createMacro, editMacro, getMacros } from 'reducers/macros';
import { DocumentIcon, DownArrow, EditIcon, TrashIcon, UpArrow, XIcon } from 'components/ui/svg';
import { Input, TextArea } from 'components/ui/Fields';
import { Disclaimer, Header2, Label } from 'components/ui/Typography';
import { useAppDispatch } from 'lib/hooks';
import formCss from 'components/design-system/Form.module.css';

interface MacroModalProps {
  isOpen: boolean;
  macro: Macro | null;
  inputTitle: string;
  inputText: string;
  onRequestDelete: () => void;
  onRequestPreview: (text: string, title: string) => void;
  onClose: () => void;
}

interface MacroModalState {
  showPersonalization: boolean;
  textareaPosition: number;
  error: { title?: string; text?: string };
  macroText: string;
  macroTitle: string;
}

const initialMacroModalState = {
  showPersonalization: false,
  textareaPosition: 0,
  error: {},
};

const MacroModal = ({
  isOpen,
  macro,
  inputTitle,
  inputText,
  onRequestDelete,
  onRequestPreview,
  onClose,
}: MacroModalProps) => {
  const [{ showPersonalization, textareaPosition, error, macroText, macroTitle }, setState] =
    useState<MacroModalState>({
      ...initialMacroModalState,
      macroText: inputText,
      macroTitle: inputTitle,
    });
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setState((state) => ({
      ...state,
      showPersonalization: false,
      macroText: '',
    }));
    onClose();
  };

  const setText = (newText: string) => {
    setState((state) => ({
      ...state,
      macroText: newText,
    }));
  };

  const setTitle = (newTitle: string) => {
    setState((state) => ({
      ...state,
      macroTitle: newTitle,
    }));
  };

  const validate = (callback: () => void) => {
    const error: { title?: string; text?: string } = {};
    if (macro) {
      if (macroTitle === '') {
        error.title = 'This field cannot be left empty';
      }
      if (macroText === '') {
        error.text = 'This field cannot be left empty';
      }
    }
    setState((state) => ({
      ...state,
      error: error,
    }));
    if (_.isEmpty(error)) {
      callback();
    }
  };

  const handleRequestPreview = async () => {
    validate(async () => {
      onRequestPreview(macroText, macroTitle);
    });
  };

  const handleSubmit = async () => {
    validate(async () => {
      try {
        if (macro) {
          const res = await dispatch(
            editMacro({ id: macro.id, title: macroTitle, text: macroText }),
          );
          if (editMacro.fulfilled.match(res)) {
            await dispatch(getMacros());
          } else {
            toast.error(res.payload?.rejectionType);
          }
        } else {
          const res = await dispatch(createMacro({ title: macroTitle, text: macroText }));
          if (createMacro.rejected.match(res)) {
            toast.error(res.payload?.rejectionType);
          }
        }
      } finally {
        setSubmitting(false);
        onClose();
      }
    });
  };

  const togglePersonalizationDropdown = () => {
    setState((state) => ({
      ...state,
      showPersonalization: !showPersonalization,
    }));
  };

  const trackTextareaPosition = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaPosition = e.target.selectionStart;
    setState((state) => ({
      ...state,
      textareaPosition,
    }));
  };

  const handleAddPersonalization = (name: string) => {
    const insertIndex = textareaPosition + 1;
    const text = macroText;
    const newText = text.substr(0, insertIndex) + name + text.substr(insertIndex);
    setText(newText);
    setState((state) => ({
      ...state,
      showPersonalization: false,
    }));
  };

  const renderPersonalization = () => {
    return (
      <div className={css.assign}>
        <Button
          buttonType={ButtonType.transparent}
          size={ButtonSizes.small}
          content="Personalization"
          onClick={togglePersonalizationDropdown}
          icon={showPersonalization ? <UpArrow /> : <DownArrow />}
          className={css.personalizationButton}
          active={showPersonalization}
        />
        <PersonalizationDropdown
          isOpen={showPersonalization}
          onClickItem={handleAddPersonalization}
          onClose={togglePersonalizationDropdown}
        />
      </div>
    );
  };

  return (
    <Modal open={isOpen} size="tiny" onClose={handleClose}>
      <Modal.Content className={css.modal}>
        <div className={css.closeContainer} onClick={handleClose}>
          <XIcon />
        </div>
        <Header2 className={css.header}>
          {macro ? (
            <>
              <EditIcon height="20" width="20" />
              <span className={css.headerText}>Edit Macro</span>
            </>
          ) : (
            <>
              <DocumentIcon />
              <span className={css.headerText} data-testid="new-macro">
                Create Macro
              </span>
            </>
          )}
        </Header2>
        <div className={css.inputContainer}>
          <Label htmlFor="macro-title-input" className={css.label}>
            Macro title
          </Label>
          <Input
            id="macro-title-input"
            data-testid="macro-title-input"
            onChange={(_: Event, data) => setTitle(data.value)}
            value={macroTitle}
            placeholder={`e.g. "Introduction, New Patient, Sick Complaint"`}
            type="text"
            name="title"
            className={css.title}
            error={error.title}
          />
        </div>
        <div className={css.inputContainer}>
          <Label htmlFor="macro-text-input" className={css.label}>
            Message
          </Label>
          {renderPersonalization()}
          <TextArea
            id="macro-text-input"
            data-testid="macro-text-input"
            value={macroText}
            onChange={(_: Event, data) => setText(data.value)}
            name="text"
            className={cx(formCss.field, css.text)}
            onFocus={trackTextareaPosition}
            onKeyDown={trackTextareaPosition}
            onClick={trackTextareaPosition}
            error={error.text}
          />
          <Disclaimer>
            <strong>**bold**</strong> <em>_italic_</em>
          </Disclaimer>
        </div>
        <div className={css.submitContainer}>
          <div className={css.deleteContainer}>
            {macro && (
              <Button
                buttonType={ButtonType.transparent}
                size={ButtonSizes.small}
                onClick={onRequestDelete}
                className={css.delete}
                icon={<TrashIcon />}
                content="Delete"
              />
            )}
          </div>
          <div className={css.buttonsContainer}>
            {macro && (
              <Button
                buttonType={ButtonType.grey}
                onClick={handleRequestPreview}
                className={css.previewButton}
                content="Preview"
              />
            )}
            <PrimaryButton
              data-testid="save-button"
              onClick={handleSubmit}
              className={css.submit}
              disabled={submitting}
              content={macro ? 'Save' : 'Create'}
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default MacroModal;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './Events.module.css';
import MessageContainer, { MessageContainerProps } from './MessageContainer';
import { useParameterizedSelector } from 'lib/hooks';
import { TriageStatus } from 'types/tables/profiles';
import { calendarDate } from 'lib/time';
import * as GrdnApi from 'lib/grdn';
import { isThisHour } from 'lib/util';
import {
  CalendarIcon,
  CardIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentIcon,
  VideoIconCircle,
} from 'components/ui/svg';
import { ButtonSizes, PrimaryButton } from 'components/ui/Buttons';
import { Placeholder } from 'components/ui/Placeholder';
import { Body, Caption, Size } from 'components/ui/Typography';
import {
  ScreenerMultipleChoiceQuestion,
  ScreenerMultipleSelectQuestion,
  ScreenerPayload,
  ScreenerQuestion,
} from 'types/grdn';
import {
  getProviderById,
  getProviderBySendbirdUserId,
  providersSelectors,
} from 'reducers/providers';
import {
  AssignmentEventType,
  EventMessage,
  ScreenerCompletedPrimaryMessage,
  ScreenerEventType,
  StatusEventType,
  TriageType,
  VideoPrimaryMessage,
  VideoVisitRequestMessage,
} from 'types/tables/messages';
import { ScreenerType } from 'types/tables/screeners';
import { isSuccessResponse } from 'types/api';
import { EdenColors } from 'types/colors';
import { getPatientName } from 'reducers/patients';

interface MetadataProps {
  message: EventMessage;
}

const SRHAScores: string[] = ['excellent', 'very good', 'good', 'fair', 'poor'];

const formatTriageStatus = (
  triageStatus: TriageStatus,
  returnToWorkDate: string | null,
): string => {
  if (triageStatus === TriageStatus.Isolate) {
    return returnToWorkDate ? `Isolate until ${calendarDate(returnToWorkDate)}` : `Isolate`;
  } else {
    return _.startCase(triageStatus);
  }
};

// eslint-disable-next-line react/display-name
export const Metadata = React.forwardRef((props: MetadataProps, ref: any) => {
  const { message } = props;
  const providers = useSelector(providersSelectors.selectEntities);
  const date = new Date(message.timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const formattedDate = new Date(message.timestamp).toLocaleString([], DateTime.DATE_SHORT);
  const eventAuthorName =
    useParameterizedSelector(getProviderById, message.data.by)?.displayName || message.data.by;
  const patientName = useParameterizedSelector(getPatientName, message.data.by);
  let msg;
  switch (message.type) {
    case TriageType:
      msg = (
        <div>
          {eventAuthorName} updated <span className={css.emphasis}>Back to Work status</span> from{' '}
          <span className={css.emphasis}>
            {formatTriageStatus(message.data.previousStatus, message.data.previousReturnToWorkDate)}
          </span>{' '}
          to{' '}
          <span className={css.emphasis}>
            {formatTriageStatus(message.data.currentStatus, message.data.currentReturnToWorkDate)}
          </span>
        </div>
      );
      break;
    case AssignmentEventType.Unassigned:
    case AssignmentEventType.Assigned:
      const { assigneeIds, automatic } = message.data;
      const assigneeNames = assigneeIds
        .map((assigneeId) => {
          const provider = providers[assigneeId];
          return provider?.displayName || assigneeId;
        })
        .join('; ');
      const verb = message.type === AssignmentEventType.Unassigned ? 'unassigned' : 'assigned';
      msg = automatic
        ? `${assigneeNames} auto-${verb} at ${date}`
        : `${assigneeNames} ${verb} by ${eventAuthorName} at ${date}`;
      break;
    case StatusEventType.Archived:
      msg = `Archived by ${eventAuthorName} at ${date}`;
      break;
    case ScreenerEventType.srha:
      msg = `${patientName} completed the Self-Reported Health Assessment on ${formattedDate}`;
      break;
    case ScreenerEventType.welcomeSurvey:
      msg = `${patientName} completed the ${message.data.screenerName}(Onboarding Intake Survey) on ${formattedDate}`;
      break;
  }
  return (
    <div ref={ref} className={css.event}>
      {msg}
    </div>
  );
});

interface VideoCallCardProps {
  message: VideoPrimaryMessage;
}

interface VideoVisitRequestCardProps {
  message: VideoVisitRequestMessage;
}

const ActiveCallCard = (props: VideoCallCardProps & MessageContainerProps) => {
  const { zoomLinks } = useFlags();
  const { message } = props;
  const { data } = message;
  const { startUrl, startTime } = data;
  const providerSender = useParameterizedSelector(getProviderBySendbirdUserId, message.senderId);
  const senderName = providerSender?.displayName ?? 'Provider';

  if (!startTime) throw new Error('Cannot render ActiveCallCard without startTime');

  const formattedStartTime = new Date(startTime).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div className={css.videoCallCard}>
      <div className={css.videoRow}>
        <div className={css.videoIcon}>
          <VideoIconCircle fill={EdenColors.Eden} />
        </div>
        <div className={css.videoCallInfo}>
          <div className={css.videoCallHeader}>Video visit started</div>
          <div className={css.videoCallMetadata}>
            Started at {formattedStartTime} by {senderName}
          </div>
        </div>
      </div>
      <div className={css.videoRow}>
        <div className={css.callButtonSpacer} />
        <div className={css.videoCallButton}>
          <PrimaryButton
            size={ButtonSizes.wide}
            reactive
            onClick={() => {
              window.open(startUrl);
            }}
          >
            {zoomLinks ? 'Join Video Call' : 'Join Video Visit'}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const InactiveCallCard = () => (
  <div className={css.videoCallCardDone}>
    <div className={css.videoIcon}>
      <VideoIconCircle fill={EdenColors.Slate55} />
    </div>
    <div className={css.videoCallInfo}>
      <div className={css.videoCallHeaderDone}>Video visit ended</div>
    </div>
  </div>
);

export const MockVideoCallCard = () => (
  <div className={css.videoCallCardMock}>
    <div className={css.videoRow}>
      <div className={css.videoIcon}>
        <VideoIconCircle fill={EdenColors.Eden} />
      </div>
      <div className={css.videoCallInfo}>
        <div className={css.videoCallHeaderMock}>Video Visit</div>
        <div className={css.videoCallMetadataMock}>
          <Placeholder full />
        </div>
      </div>
    </div>
  </div>
);

export const VideoCallCard = React.forwardRef<
  HTMLDivElement,
  VideoCallCardProps & MessageContainerProps
>((props: VideoCallCardProps & MessageContainerProps, ref) => {
  const { message, profile, previous, user } = props;
  let callCard;
  const startTime = message.data.startTime;
  if (!startTime) {
    callCard = <MockVideoCallCard />;
  } else if (isThisHour(DateTime.fromISO(startTime))) {
    callCard = <ActiveCallCard {...props} />;
  } else {
    callCard = <InactiveCallCard />;
  }
  const content = (
    <div ref={ref} className={css.callCardOuterContainer} data-testid="VideoCallCard">
      {callCard}
    </div>
  );
  return (
    <MessageContainer
      message={message}
      profile={profile}
      previous={previous}
      user={user}
      content={content}
    />
  );
});
VideoCallCard.displayName = 'VideoCallCard';

export const VideoVisitRequestCard = React.forwardRef<
  HTMLDivElement,
  VideoVisitRequestCardProps & MessageContainerProps
>((props: VideoVisitRequestCardProps & MessageContainerProps, ref) => {
  const { message, profile, previous, user } = props;
  const [isScheduled, setIsScheduled] = useState(false);
  const expDate = DateTime.fromMillis(message.timestamp).plus({ days: 2 });
  const isStale = expDate < DateTime.local();
  useEffect(() => {
    const loadVideoVisit = async () => {
      const res = await GrdnApi.getVideoVisit(message.data.videoVisitId);
      if (isSuccessResponse(res) && !!res.data.appointmentId) {
        setIsScheduled(true);
      }
    };
    if (!isStale) {
      loadVideoVisit().catch(() => {
        toast.error('Encountered an error while loading a video visit.');
      });
    }
  }, [setIsScheduled, message.data.videoVisitId, isStale]);
  const isExpired = isStale || isScheduled;
  const expDateDisplay = `${expDate.toFormat('MMMM dd')} at ${expDate.toFormat('hh:mm a')}`;
  const headerText = `Video visit scheduling link ${isExpired ? 'expired' : 'sent'}`;
  const containerCSS = isExpired
    ? css.requestVideoVisitContainerExpired
    : css.requestVideoVisitContainerActive;
  const content = (
    <div
      ref={ref}
      className={`${containerCSS} ${css.requestVideoVisitContainer}`}
      data-testid="VideoVisitRequestCard"
    >
      <div className={css.requestVideoVisitHeader}>
        <CalendarIcon className={css.videoVisitIcon} /> {headerText}
      </div>
      {!isExpired && (
        <div className={css.requestVideoVisitExpirationNotice}>
          This link expires automatically on {expDateDisplay} or once a visit is booked.
        </div>
      )}
    </div>
  );
  return (
    <MessageContainer
      message={message}
      profile={profile}
      previous={previous}
      user={user}
      content={content}
    />
  );
});

VideoVisitRequestCard.displayName = 'VideoVisitRequestCard';

type ScreenerQuestionProps = {
  question: ScreenerQuestion;
  index: number;
  screenerTimestamp?: string;
  screenerName: ScreenerType;
};

const ScreenerQuestionComponent = (props: ScreenerQuestionProps) => {
  const { question, index, screenerTimestamp, screenerName } = props;

  return (
    <>
      {question.questionType === 'MultipleChoice' && (
        <MultipleChoiceQuestionReply
          question={question}
          index={index}
          screenerName={screenerName}
        />
      )}
      {question.questionType === 'MultipleSelect' && (
        <>
          <MultipleSelectQuestionReply
            question={question}
            index={index}
            screenerName={screenerName}
          />
          <MultipleSelectQuestionDetail question={question} screenerTimestamp={screenerTimestamp} />
        </>
      )}
    </>
  );
};

type MultipleChoiceQuestionReplyProps = {
  question: ScreenerMultipleChoiceQuestion;
  index: number;
  screenerName: ScreenerType;
};

const MultipleChoiceQuestionReply = ({
  question,
  index,
  screenerName,
}: MultipleChoiceQuestionReplyProps) => {
  const { answer, possibleAnswers } = question;

  const answerString = possibleAnswers[answer];

  return (
    <div className={css.screenerRow}>
      <Caption className={css.screenerRowQuestion}>
        <span className={css.screenerRowNumber}>{index + 1}.</span> {question.question}
      </Caption>
      <Caption
        data-testid="Answer"
        className={
          answerString === 'Yes' && screenerName === ScreenerType.dailyCovid
            ? css.screenerRowAnswerIsolate
            : css.screenerRowAnswer
        }
      >
        {answerString}
      </Caption>
    </div>
  );
};

type MultipleSelectQuestionReplyProps = {
  question: ScreenerMultipleSelectQuestion;
  index: number;
  screenerName: ScreenerType;
};

const MultipleSelectQuestionReply = ({
  question,
  index,
  screenerName,
}: MultipleSelectQuestionReplyProps) => {
  const { answer, possibleAnswers, preamble } = question;

  const answerString = !answer || answer.length === 0 ? 'No' : 'Yes';

  const isAnswerIsolateWorthy = (answer || []).reduce(
    (isolate, index) => isolate || possibleAnswers[index].value === 1,
    false,
  );

  return (
    <div className={css.screenerRow}>
      <Caption className={css.screenerRowQuestion}>
        <span className={css.screenerRowNumber}>{index + 1}.</span> {question.question}
        {preamble && <span> {preamble}</span>}
      </Caption>
      <Caption
        className={
          isAnswerIsolateWorthy && screenerName === ScreenerType.dailyCovid
            ? css.screenerRowAnswerIsolate
            : css.screenerRowAnswer
        }
      >
        {answerString}
      </Caption>
    </div>
  );
};

type MultipleSelectQuestionDetailProps = {
  question: ScreenerMultipleSelectQuestion;
  screenerTimestamp?: string;
};

const MultipleSelectQuestionDetail = ({
  question,
  screenerTimestamp,
}: MultipleSelectQuestionDetailProps) => {
  const screenerVersion = screenerTimestamp
    ? DateTime.fromISO(screenerTimestamp).toFormat('MMM dd, yyyy')
    : '';

  const { answer, possibleAnswers } = question;

  return (
    <>
      {answer && answer.length > 0 && (
        <>
          <div className={css.screenerRow}>
            <ul className={css.screenerRowStateStatusList}>
              {answer.map((index) => (
                <li key={index}>
                  <Caption className={css.screenerRowStateStatus}>
                    {possibleAnswers[index].name}
                    {': '}
                    <span
                      className={
                        possibleAnswers[index].value === 1
                          ? css.screenerRowAnswerIsolate
                          : css.screenerRowAnswer
                      }
                    >
                      {possibleAnswers[index].value === 1 ? 'Isolate' : 'Clear'}
                    </span>
                  </Caption>
                </li>
              ))}
            </ul>
          </div>

          <div className={css.screenerRow}>
            <Caption className={css.screenerRowStatePolicyVersion}>
              State status based on Travel Policy version: {screenerVersion}
            </Caption>
          </div>
        </>
      )}
    </>
  );
};

interface ScreenCompletedProps {
  message: ScreenerCompletedPrimaryMessage;
}

export const ScreenerCompleted = React.forwardRef<
  HTMLDivElement,
  ScreenCompletedProps & MessageContainerProps
>((props: ScreenCompletedProps & MessageContainerProps, ref) => {
  const [screenerData, setScreenerData] = useState<ScreenerPayload>();
  const [expanded, setExpanded] = useState(false);

  const getScreenerIconClass = (screenerName: ScreenerType, score: number): string => {
    switch (screenerName) {
      case ScreenerType.dailyCovid:
        return score === 0 ? css.screenerCompletedIconPassed : css.screenerCompletedIconNotPassed;
      case ScreenerType.srha:
        return score <= 2 ? css.screenerCompletedIconPassed : css.screenerCompletedIconNotPassed;
      default:
        return css.screenerCompletedIconPassed;
    }
  };

  const handleClickExpand = async () => {
    if (expanded) {
      setExpanded(false);
    } else {
      const screener = await GrdnApi.getScreener(
        message.data.screenerResponseId,
        message.data.covidScreenerId,
      );
      if (isSuccessResponse(screener)) {
        setScreenerData(screener.data);
        setExpanded(true);
      }
    }
  };
  const filteredQuestionList = screenerData?.questionList.filter(
    (question) => !!question.possibleAnswers,
  );
  const { message, profile, previous, user } = props;
  const {
    data: { screenerName, score },
  } = message;
  const content = (
    <div className={css.screenerCompleted} ref={ref} data-testid="ScreenerCompletedCard">
      <div
        className={css.screenerCompletedHeader}
        onClick={handleClickExpand}
        data-testid="ScreenerCompletedHeader"
      >
        <div
          className={
            screenerName === ScreenerType.dailyCovid
              ? css.screenerCompletedHeaderLeft
              : css.screenerCompletedHeaderLeftAlignTop
          }
        >
          <span className={getScreenerIconClass(screenerName, score)} data-testid="ScreenerIcon">
            <DocumentIcon height={16} width={16} />
          </span>
          {screenerName === ScreenerType.dailyCovid && (
            <Body className={css.screenerHeaderTitle}>COVID-19 Screener completed</Body>
          )}
          {screenerName === ScreenerType.srha && (
            <div className={css.screenerHeader}>
              <Body className={css.screenerHeaderTitle}>
                SRHA (overall health check-in) completed
              </Body>
              <Caption className={css.screenerHeaderSubtitle} data-testid="Subtitle">
                Result: patient health is <span className={css.emphasis}>{SRHAScores[score]}</span>
              </Caption>
            </div>
          )}
        </div>
        {expanded ? (
          <ChevronUpIcon className={css.screenerHeaderChevron} />
        ) : (
          <ChevronDownIcon className={css.screenerHeaderChevron} />
        )}
      </div>
      {expanded && (
        <div className={css.screenerExpandedArea} data-testid="ExpandedArea">
          {screenerName === ScreenerType.dailyCovid && (
            <Body size={Size.Small} className={css.screenerTitle}>
              In the past 14 days...
            </Body>
          )}
          <div className={css.screenerQuestions}>
            {filteredQuestionList?.map((question, index) => (
              <div key={question.key}>
                <ScreenerQuestionComponent
                  question={question}
                  index={index}
                  screenerTimestamp={screenerData?.screenerUpdatedAt}
                  screenerName={screenerName}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  return (
    <MessageContainer
      message={message}
      profile={profile}
      previous={previous}
      user={user}
      content={content}
    />
  );
});

ScreenerCompleted.displayName = 'ScreenerCompleted';

export const InsuranceSubmitted = React.forwardRef<HTMLDivElement, MessageContainerProps>(
  (props: MessageContainerProps, ref) => {
    const { message, profile, previous, user } = props;
    const content = (
      <div className={css.insuranceSubmitted} ref={ref} data-testid="InsuranceSubmittedCard">
        <div className={css.insuranceSubmittedHeader} data-testid="InsuranceSubmittedHeader">
          <div className={css.insuranceSubmittedHeaderLeft}>
            <span className={css.insuranceSubmittedIcon} data-testid="ScreenerIcon">
              <CardIcon height={16} width={16} />
            </span>
            <Body className={css.insuranceSubmittedTitle}>Insurance card submitted</Body>
          </div>
        </div>
      </div>
    );

    return (
      <MessageContainer
        message={message}
        profile={profile}
        previous={previous}
        user={user}
        content={content}
      />
    );
  },
);

InsuranceSubmitted.displayName = 'InsuranceSubmitted';

import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, EntityId } from '@reduxjs/toolkit';
import css from './TypingIndicator.module.css';
import { useParameterizedSelector } from 'lib/hooks';
import {
  InternalChannel,
  PrimaryChannel,
  SerializedMember,
  SidebarChannel,
} from 'types/tables/channels';
import { Patient } from 'types/tables/patients';
import { Provider } from 'types/tables/providers';
import { providersSelectors } from 'reducers/providers';
import { getPatientName } from 'reducers/patients';

interface FullTypingIndicatorProps {
  patientRecord: Patient;
  channel: PrimaryChannel | InternalChannel;
}

interface BasicTypingIndicatorProps {
  providers: Dictionary<Provider>;
  providerIds: EntityId[];
  sidebarChannel: SidebarChannel;
}

enum Typers {
  Patient = 'Patient',
  CareTeam = 'Care Team',
  Someone = 'Someone',
}

const getTyper = (
  patientRecord: Patient,
  patientName: string,
  providers: Dictionary<Provider>,
  providerIds: EntityId[],
  typingEvent: SerializedMember,
  basic: boolean,
): string => {
  const providerId = providerIds.find((id) => providers[id]?.sendbirdUserId === typingEvent.userId);
  const clinicianThatIsTyping = providerId && providers[providerId];
  if (clinicianThatIsTyping) {
    return basic ? Typers.CareTeam : clinicianThatIsTyping.displayName || Typers.CareTeam;
  } else if (patientRecord && patientRecord.sendbirdUserId === typingEvent.userId) {
    return basic ? Typers.Patient : patientName || Typers.Patient;
  } else {
    return Typers.Someone;
  }
};

const peopleThatAreTyping = (
  patientRecord: Patient,
  patientName: string,
  channel: PrimaryChannel | InternalChannel,
  providers: Dictionary<Provider>,
  providerIds: EntityId[],
  basic: boolean,
) => {
  const init: Map<string, number> = new Map();
  return channel.typingMembers
    ? channel.typingMembers?.reduce((result: Map<Typers | string, number>, recentTypingEvent) => {
        const typer = getTyper(
          patientRecord,
          patientName,
          providers,
          providerIds,
          recentTypingEvent,
          basic,
        );
        const count = result.has(typer) ? result.get(typer) : 0;
        result.set(typer, (count || 0) + 1);
        return result;
      }, init)
    : init;
};

export const TypingIndicator: React.FC<FullTypingIndicatorProps> = (props) => {
  const patientName = useParameterizedSelector(getPatientName, props.patientRecord.id);
  const providers = useSelector(providersSelectors.selectEntities);
  const providerIds = useSelector(providersSelectors.selectIds);

  const namesObject = peopleThatAreTyping(
    props.patientRecord,
    patientName,
    props.channel,
    providers,
    providerIds,
    false,
  );
  const names = Array.from(namesObject);
  if (names.length === 0) {
    return <></>;
  } else if (names.length === 1) {
    return <div className={css.typingMember}>{names[0][0]} is typing...</div>;
  } else if (names.length === 2) {
    return (
      <div className={css.typingMember}>
        {names[0][0]} and {names[1][0]} are typing...
      </div>
    );
  } else {
    return (
      <div className={css.typingMember}>
        {names[0][0]} and {names.length - 1} others are typing...
      </div>
    );
  }
};

export const BasicTypingIndicator = (props: BasicTypingIndicatorProps) => {
  const patientName = useParameterizedSelector(getPatientName, props.sidebarChannel.patient.id);

  const names = peopleThatAreTyping(
    props.sidebarChannel.patient,
    patientName,
    props.sidebarChannel.channel,
    props.providers,
    props.providerIds,
    true,
  );
  if (names.has(Typers.Patient) && names.has(Typers.CareTeam)) {
    return <div className={css.basicTypingIndicator}>Care team &amp; patient typing...</div>;
  } else if (names.has(Typers.CareTeam)) {
    return <div className={css.basicTypingIndicator}>Care team typing...</div>;
  } else if (names.has(Typers.Patient)) {
    return <div className={css.basicTypingIndicator}>Patient typing...</div>;
  } else {
    return null;
  }
};

import React from 'react';

import type { FollowupNextStep, NextStep, NextStepType } from '../../CarePlanTypes';
import { dispositionOf } from '../../utils/dispositionOf';
import { Reason } from '../../utils/reasons';

import AnnualPreventativeVisitIcon from './AnnualPreventativeVisitIcon';
import CompletedIcon from './CompletedIcon';
import CustomNextStepIcon from './CustomNextStepIcon';
import DeclinedIcon from './DeclinedIcon';
import DeletedIcon from './DeletedIcon';
import InjectionIcon from './InjectionIcon';
import LabWorkIcon from './LabWorkIcon';
import PhysicalTherapyIcon from './PhysicalTherapyIcon';
import TestIcon from './TestIcon';
import VisitIcon from './VisitIcon';
import WomensHealthIcon from './WomensHealthIcon';
import HealthAssessmentIcon from './HealthAssessmentIcon';

interface Props {
  step: NextStep;
}

const NextStepIcon = ({ step }: Props) => {
  const { type } = step;
  const disposition = dispositionOf(step);
  const { status } = disposition;

  const iconByType = (type: NextStepType) => {
    switch (type) {
      case 'annual-preventative-visit':
        return <AnnualPreventativeVisitIcon />;

      case 'annual-assessment':
        return <HealthAssessmentIcon />;

      case 'onboarding-assessment':
        return <HealthAssessmentIcon />;

      case 'custom':
        return <CustomNextStepIcon />;

      case 'flu-shot':
        return <InjectionIcon />;

      case 'followup':
        const { visitReasonId } = step as FollowupNextStep;
        switch (visitReasonId) {
          case Reason.ANNUAL_PREVENTATIVE_VISIT:
            return <AnnualPreventativeVisitIcon />;

          case Reason.FLU_SHOT:
          case Reason.COVID_19_VACCINATION_JANDJ:
          case Reason.COVID_19_VACCINATION_MODERNA:
          case Reason.COVID_19_VACCINATION_MODERNA_BOOSTER:
            return <InjectionIcon />;

          case Reason.FOCUSED_VISIT:
            return <VisitIcon />;

          case Reason.COVID_19_PCR_TEST:
          case Reason.COVID_19_RAPID_TEST:
            return <TestIcon />;

          case Reason.PHYSICAL_THERAPY:
            return <PhysicalTherapyIcon />;

          case Reason.BLOOD_OR_LAB_WORK:
            return <LabWorkIcon />;

          case Reason.WOMENS_HEALTH_VISIT:
            return <WomensHealthIcon />;
        }

        return <CustomNextStepIcon />; // fall-through should never happen

      case 'srha':
        return <HealthAssessmentIcon />;
    }
  };

  const Icon = () => {
    switch (status) {
      case 'active':
      case 'due today':
      case 'overdue':
        return iconByType(type);
      case 'completed':
        return <CompletedIcon />;
      case 'declined':
        return <DeclinedIcon />;
      case 'deleted':
        return <DeletedIcon />;
    }
  };

  return <Icon />;
};

export default NextStepIcon;

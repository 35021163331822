import React, { useEffect } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './Insurance.module.css';
import eligibilityCSS from './InsuranceEligibility.module.css';
import InsuranceCardButton from './InsuranceCardButton';
import InsuranceEligibility from './InsuranceEligibility';
import MedicalSummaryItem from './MedicalSummaryItem';
import { ChartElement, EmptyChartElement } from './ChartElements';
import { useActivePatient, usePrevious, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { getPatientProfile } from 'selectors/profiles';
import { fetchPrimaryInsurance, fetchPrimaryInsuranceOld } from 'reducers/profiles';
import { profileIsError } from 'types/tables/profiles';
import { InsuranceLoadState } from 'types/athena/insurance';

interface InsuranceProps {}

const Insurance: React.FC<InsuranceProps> = () => {
  const { id: activePatientId } = useActivePatient();
  const prevPatientId = usePrevious(activePatientId);
  const dispatch = useAppDispatch();
  const patientProfile = useParameterizedSelector(getPatientProfile, activePatientId);
  const profileId = !profileIsError(patientProfile) ? patientProfile.id : undefined;
  const { automatedInsuranceCardUpload } = useFlags();

  useEffect(() => {
    if (
      !profileIsError(patientProfile) &&
      profileId &&
      (patientProfile.primaryInsuranceLoadingState === InsuranceLoadState.NotStarted ||
        activePatientId !== prevPatientId)
    ) {
      if (automatedInsuranceCardUpload) {
        dispatch(fetchPrimaryInsurance(activePatientId));
      } else {
        dispatch(fetchPrimaryInsuranceOld(activePatientId));
      }
    }
  }, [
    dispatch,
    patientProfile,
    automatedInsuranceCardUpload,
    activePatientId,
    prevPatientId,
    profileId,
  ]);

  // TODO: remove when removing staging feature flag
  const renderDetailsOld = () => {
    if (profileIsError(patientProfile))
      throw new Error('Cannot render insurance details without profile');
    const primaryInsuranceOld = patientProfile.primaryInsuranceOld;
    if (!primaryInsuranceOld) throw new Error('Cannot render insurance details without insurance');
    const {
      ircName,
      insuranceIdNumber,
      eligibilityStatus,
      eligibilityLastChecked,
      insurancePhone,
      copays,
    } = primaryInsuranceOld;
    return (
      <ChartElement>
        <div className={css.columnContainer}>
          <div className={css.header}>
            <div className={eligibilityCSS.eligibilityStatusOuterContainer}>
              <div className={eligibilityCSS.eligibilityStatusInnerContainer}>
                <div className={eligibilityCSS[eligibilityStatus.concat('Color')]} />
                <div>{eligibilityStatus}</div>
              </div>
            </div>
            <div className={eligibilityCSS.eligibilityLastChecked}>
              Last checked: {eligibilityLastChecked}
            </div>
          </div>
          <div className={css.details}>
            <div className={css.panel}>
              <div className={css.ircName}>{ircName}</div>
              <InsuranceCardButton
                patientProfile={patientProfile}
                automatedInsuranceCardUpload={automatedInsuranceCardUpload}
              ></InsuranceCardButton>
            </div>
            <div className={css.panel}>
              <div className={css.detailsContainer}>
                <div>ID</div>
                <div>{insuranceIdNumber}</div>
              </div>
              <div className={css.detailsContainer}>
                <div>Provider Services</div>
                <div>{insurancePhone}</div>
              </div>
              <div className={css.copayDetailsContainer}>
                <div>Copay</div>
                {copays && copays[0] ? (
                  <div className={css.copayDetails}>
                    <div>{copays[0].copaytype}:</div>
                    <div>${copays[0].copayamount}</div>
                  </div>
                ) : (
                  <div className={css.unknownCopay}>Unknown</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ChartElement>
    );
  };

  const renderDetails = () => {
    if (!profileIsError(patientProfile) && patientProfile.primaryInsurance) {
      const { athenaRecord } = patientProfile.primaryInsurance;

      return (
        <ChartElement>
          <div className={css.columnContainer}>
            <div className={css.header}>
              <div className={css.ircName}>{athenaRecord?.displayName}</div>
            </div>
            <InsuranceEligibility athenaRecord={athenaRecord}></InsuranceEligibility>
            <div className={css.details}>
              <div className={css.panel}>
                <InsuranceCardButton
                  patientProfile={patientProfile}
                  automatedInsuranceCardUpload={automatedInsuranceCardUpload}
                ></InsuranceCardButton>
              </div>
              <div className={css.panel}>
                <div className={css.detailsContainer}>
                  <div>ID</div>
                  <div>{athenaRecord?.memberId}</div>
                </div>
                <div className={css.detailsContainer}>
                  <div>Provider Services</div>
                  <div>{athenaRecord?.providerServicesPhone}</div>
                </div>
                <div className={css.detailsContainer}>
                  <div>Policyholder</div>
                  <div>{athenaRecord?.policyholderName?.toLowerCase()}</div>
                </div>
                <div className={css.detailsContainer}>
                  <div>Policyholder DOB</div>
                  <div>{athenaRecord?.policyholderDob}</div>
                </div>
              </div>
            </div>
          </div>
        </ChartElement>
      );
    } else {
      throw new Error('Cannot render insurance details without insurance');
    }
  };

  const renderPhotoNoDetails = () => {
    return (
      <ChartElement>
        <div className={css.photoNoDetailsContainer}>
          <div className={css.photoNoDetailsText}>
            <div className={css.photoNoDetailsHeader}>
              Patient could not add insurance to chart.
            </div>
            <div>Please add information and photo to chart manually.</div>
          </div>
          <div>
            <InsuranceCardButton
              patientProfile={patientProfile}
              automatedInsuranceCardUpload={automatedInsuranceCardUpload}
            ></InsuranceCardButton>
          </div>
        </div>
      </ChartElement>
    );
  };

  const renderNoInsurance = () => {
    return (
      <EmptyChartElement>
        This patient is self pay or has no active insurance information on file.
      </EmptyChartElement>
    );
  };

  if (
    !profileIsError(patientProfile) &&
    patientProfile.primaryInsuranceLoadingState === InsuranceLoadState.Loading
  ) {
    return (
      <ChartElement>
        <Placeholder style={{ width: '100%' }}>
          <Placeholder.Line />
        </Placeholder>
      </ChartElement>
    );
  }

  const showDetailsOld =
    !profileIsError(patientProfile) &&
    patientProfile &&
    patientProfile.primaryInsuranceOld &&
    patientProfile.primaryInsuranceOld.ircName &&
    patientProfile.primaryInsuranceOld.ircName !== 'Self-Pay (cash)';

  const showDetails =
    !profileIsError(patientProfile) &&
    patientProfile &&
    patientProfile.primaryInsurance &&
    patientProfile.primaryInsurance.athenaRecordExists &&
    patientProfile.primaryInsurance.athenaRecord &&
    patientProfile.primaryInsurance.athenaRecord.displayName !== 'Self-Pay (cash)';

  const showPhotoNoDetails =
    !profileIsError(patientProfile) &&
    patientProfile &&
    patientProfile.primaryInsurance &&
    patientProfile.primaryInsurance.documentExists;

  return (
    <MedicalSummaryItem label="Insurance">
      {automatedInsuranceCardUpload
        ? showDetails || showPhotoNoDetails
          ? showDetails
            ? renderDetails()
            : renderPhotoNoDetails()
          : renderNoInsurance()
        : showDetailsOld
        ? renderDetailsOld()
        : renderNoInsurance()}
    </MedicalSummaryItem>
  );
};

export default Insurance;

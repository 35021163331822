import React, { useState } from 'react';

import cx from 'classnames';
import { toast } from 'react-toastify';

import { MarkedNextStepResolution, NextStep } from '../../CarePlanTypes';

import css from './MarkAsCompleteModal.module.css';

import { HeaderMedium, BodySmall } from 'components/design-system/Text';
import { Modal } from 'components/ui/Modal';
import { CheckCircleIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { PrimaryButton } from 'components/design-system/Form';
import { resolveNextStep } from 'reducers/patients';
import { useActivePatient, useAppDispatch } from 'lib/hooks';
import NextStepContent from 'components/features/CarePlan/next-steps/card/NextStepContent';

interface Props {
  step: NextStep;
  onClose: () => void;
}

const MarkAsCompleteModal = ({ step, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const activePatient = useActivePatient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const resolution: MarkedNextStepResolution = {
      nextStepId: step.id,
      replyType: 'marked',
      patientId: activePatient.id,
    };

    const response = await dispatch(resolveNextStep({ patientId: activePatient.id, resolution }));
    if (resolveNextStep.fulfilled.match(response)) {
      toast.success(`Next step successfully completed.`);
    } else {
      toast.error(
        'Unable to mark next step as completed. Please try again, and if the problem persists please report the issue.',
      );
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal isOpen closeModal={onClose}>
      <HeaderMedium className={css.header}>
        <CheckCircleIcon
          className={css.headerIcon}
          width={24}
          height={24}
          color={EdenColors.SlateDarken20}
        />{' '}
        Mark as complete
      </HeaderMedium>

      <BodySmall>This will mark the next step as complete on behalf of the patient</BodySmall>

      <div className={css.card}>
        <NextStepContent step={step} />
      </div>

      <div className={css.buttonBar}>
        <PrimaryButton
          type="button"
          className={cx(css.button, css.cancelButton)}
          value="Cancel"
          onClick={onClose}
        />

        <PrimaryButton
          onClick={onSubmit}
          value="Mark As Complete"
          className={cx(css.button)}
          loading={isSubmitting}
          data-testid="step-edit-submit-button"
        />
      </div>
    </Modal>
  );
};

export default MarkAsCompleteModal;

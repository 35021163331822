import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isSuccessResponse } from 'types/api';
import { Store } from 'types/redux';
import { BradState } from 'types/grdn';
import { useAppDispatch } from 'lib/hooks';
import { Brad } from 'types/tables/brad';
import * as GrdnApi from 'lib/grdn';
import poll from 'lib/poll';
import { getIsOnline } from 'legacy/selectors/AppSelector';
import { loadEnv } from 'lib/env';

const prefix = 'brad';

enum BradRejectionType {
  fetchBradStatus = 'Failed to fetch BRAD status',
  putBradActive = 'Failed to set BRAD status to active',
  putBradInactive = 'Failed to set BRAD status to inactive',
}

export const fetchBradStatus = createAsyncThunk(
  `${prefix}/fetchBradStatus`,
  async (_, { rejectWithValue }) => {
    const response = await GrdnApi.getBradStatus();

    if (isSuccessResponse(response)) {
      return response.data;
    } else {
      return rejectWithValue({ rejectionType: BradRejectionType.fetchBradStatus });
    }
  },
);

export const useBradPoll = () => {
  const dispatch = useAppDispatch();
  const isOnline = useSelector(getIsOnline);

  useEffect(() => {
    if (isOnline) {
      dispatch(startBradPolling());
    }
    return () => {
      dispatch(stopBradPolling());
    };
  }, [dispatch, isOnline]);
};

export const startBradPolling = createAsyncThunk(
  `${prefix}/startBradPolling`,
  async (_, { dispatch }) => {
    return poll.start(
      async () => dispatch(fetchBradStatus()),
      Number(loadEnv('REACT_APP_BRAD_POLL_INTERVAL') || 5 * 60 * 1_000),
    );
  },
);

export const stopBradPolling = createAsyncThunk(`${prefix}/stopBradPolling`, (_, { getState }) => {
  const intervalId = (getState() as Store).brad.intervalId;

  if (intervalId) {
    poll.stop(intervalId);
    return true;
  }

  return false;
});

export const setBradActive = createAsyncThunk(
  `${prefix}/setBradActive`,
  async (_, { rejectWithValue }) => {
    const response = await GrdnApi.putBradActive();

    if (isSuccessResponse(response)) {
      return null;
    } else {
      return rejectWithValue({ rejectionType: BradRejectionType.putBradActive });
    }
  },
);

export const setBradInactive = createAsyncThunk(
  `${prefix}/setBradInactive`,
  async (_, { rejectWithValue }) => {
    const response = await GrdnApi.putBradInactive();

    if (isSuccessResponse(response)) {
      return null;
    } else {
      return rejectWithValue({ rejectionType: BradRejectionType.putBradInactive });
    }
  },
);

export const initialState: Brad = { status: BradState.Inactive };

export const brad = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchBradStatus.fulfilled, (state, action) => {
      state.status = action.payload.status;
    });
    builder.addCase(setBradActive.fulfilled, (state) => {
      state.status = BradState.Active;
    });
    builder.addCase(setBradInactive.fulfilled, (state) => {
      state.status = BradState.Inactive;
    });
    builder.addCase(startBradPolling.fulfilled, (state, { payload }) => {
      state.intervalId = payload;
    });
    builder.addCase(stopBradPolling.fulfilled, (state) => {
      state.intervalId = undefined;
    });
  },
});

export const getBradStatus = (state: Store) => state.brad?.status;

export default brad.reducer;

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ProviderGroup } from 'types/tables/providerGroups';
import { isSuccessResponse } from 'types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { ForeignKey } from 'types/tables/base';
import { loadEnv } from 'lib/env';

export const CLOSE_KNIT_PG_NAME = 'close-knit';
export const EDEN_PG_NAME = 'eden';

const TELEHEALTH_PROVIDER_ID = loadEnv('REACT_APP_TELEHEALTH_PROVIDER');
const CLOSEKNIT_PRIMARY_PROVIDER_ID = loadEnv('REACT_APP_CLOSEKNIT_PRIMARY_PROVIDER');
const EDEN_OUTSIDE_PCP_ID = loadEnv('REACT_APP_EDEN_OUTSIDE_PCP');
const CLOSEKNIT_OUTSIDE_PCP_ID = loadEnv('REACT_APP_CLOSEKNIT_OUTSIDE_PCP');

const prefix = 'providerGroup';

enum FetchProviderGroupsRejectionType {
  Generic = 'Unable to fetch popups.',
}

export const initializeProviderGroups = createAsyncThunk(
  `${prefix}/getAllProviderGroups`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getAllProviderGroups();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: FetchProviderGroupsRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: FetchProviderGroupsRejectionType.Generic });
    }
  },
);

export const providerGroupsAdapter = createEntityAdapter<ProviderGroup>({
  selectId: (providerGroup) => providerGroup.athenaId,
});

export const providerGroups = createSlice({
  name: prefix,
  initialState: providerGroupsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeProviderGroups.fulfilled, (state, action) => {
      providerGroupsAdapter.upsertMany(state, action.payload);
    });
  },
});

export const providerGroupSelectors = providerGroupsAdapter.getSelectors<Store>(
  (state) => state.providerGroups,
);

const getProviderGroups = providerGroupSelectors.selectAll;

export const getProviderGroupBySponsorId = createSelector(
  (_, sponsorId: ForeignKey) => sponsorId,
  getProviderGroups,
  (sponsorId, providerGroups) => {
    const sponsorProviderGroup = providerGroups.find(
      (providerGroup) => providerGroup.sponsorId === sponsorId,
    );
    return sponsorProviderGroup
      ? sponsorProviderGroup
      : providerGroups.find((providerGroup) => providerGroup.name === EDEN_PG_NAME);
  },
);

export const getProviderGroupIdBySponsorId = createSelector(
  getProviderGroupBySponsorId,
  (providerGroup) => providerGroup?.athenaId,
);

export const getIsCloseKnitPatient = createSelector(
  getProviderGroupBySponsorId,
  (pG) => Boolean(pG && pG.name === CLOSE_KNIT_PG_NAME) || false,
);

export const getDefaultPrimaryProviderIdBySponsorId = createSelector(
  getIsCloseKnitPatient,
  (isCloseknitPatient) =>
    isCloseknitPatient ? CLOSEKNIT_PRIMARY_PROVIDER_ID : TELEHEALTH_PROVIDER_ID,
);

export const getOutsidePCPIdBySponsorId = createSelector(
  getIsCloseKnitPatient,
  (isCloseknitPatient) => (isCloseknitPatient ? CLOSEKNIT_OUTSIDE_PCP_ID : EDEN_OUTSIDE_PCP_ID),
);

export const getVideoVisitDepartmentBySponsorId = createSelector(
  getProviderGroupBySponsorId,
  (providerGroup) => providerGroup?.videoVisitDepartmentId,
);

export default providerGroups.reducer;

import * as React from 'react';
import css from './PersonalizationDropdown.module.css';
import { List2, ListItem2 } from 'components/ui/Lists';
import Dropdown from 'components/ui/Dropdown';

const Row = ({ title, details, onClick }: { title: string; details: string; onClick: any }) => (
  <List2 className={css.dropdownRow}>
    <ListItem2 onClick={onClick} details={details}>
      {title}
    </ListItem2>
  </List2>
);

const rows = [
  {
    name: '$firstName',
    details: 'Patient First Name',
  },
  {
    name: '$lastName',
    details: 'Patient Last Name',
  },
  {
    name: '$clinicianFullName',
    details: 'Clinician Full Name',
  },
  {
    name: '$clinicianFirstName',
    details: 'Clinician First Name',
  },
];

interface PersonalizationDropdownProps {
  isOpen: boolean;
  onClose: () => void;
  onClickItem: (name: string) => void;
}

const PersonalizationDropdown = ({
  isOpen,
  onClose,
  onClickItem,
}: PersonalizationDropdownProps) => {
  const handleClickItem = (name: string) => {
    onClickItem(name);
  };

  return (
    <Dropdown className={css.dropdown} isOpen={isOpen} onClose={onClose}>
      <div className={css.providerList}>
        {rows.map((listItem) => {
          return (
            <Row
              onClick={() => handleClickItem(listItem.name)}
              key={listItem.name}
              title={listItem.name}
              details={listItem.details}
            />
          );
        })}
      </div>
    </Dropdown>
  );
};

export default PersonalizationDropdown;

/** Manages files slice for the Files tab and InfoPane. */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Store, AsyncAction } from 'types/redux';
import * as GrdnApi from 'lib/grdn';
import { loadEnv } from 'lib/env';
import { PrimaryKey } from 'types/tables/base';
import { isSuccessResponse } from 'types/api';
import { FilesState, LoadingStatus, UpdateFile } from 'types/tables/files';
import {
  DocumentMetadata,
  DocumentCategoryType,
  DocumentCategoryOption,
} from 'types/tables/documents';
import { PatientDocumentMetadataPayload } from 'types/grdn';

export const rejectionMessages = {
  fetchTypes: 'Unable to fetch document types.',
  fetchDocuments: 'Unable to fetch document data.',
  incorrectPatient: 'Results recieved for incorrect patient',
  unableToUpdateDocument: 'Unable to update document.',
  unableToDeleteDocument: 'Unable to delete document.',
};

const prefix = 'files';

let pollTimeoutId: ReturnType<typeof setTimeout>;
const FILES_POLL_INTERVAL = Number(loadEnv('REACT_APP_FILES_POLL_INTERVAL')) || 60_000;

export const updateDocumentAnnotations = createAsyncThunk(
  `${prefix}/updateDocumentAnnotations`,
  async ({ id, changes }: UpdateFile, { rejectWithValue }) => {
    try {
      const response = await GrdnApi.updateDocument(id, changes);
      if (isSuccessResponse(response)) {
        return { id, changes };
      } else {
        return rejectWithValue({
          rejectionType: rejectionMessages.unableToUpdateDocument,
        });
      }
    } catch (e) {
      return rejectWithValue({
        rejectionType: rejectionMessages.unableToUpdateDocument,
      });
    }
  },
);

/** Gets Document Types used when annotating documents/files */
export const getDocumentCategoryTypes = createAsyncThunk(
  `${prefix}/getDocumentTypes`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await GrdnApi.getDocumentTypes();
      if (isSuccessResponse(response)) {
        return response.data;
      } else {
        return rejectWithValue({
          rejectionType: rejectionMessages.fetchTypes,
        });
      }
    } catch (e) {
      return rejectWithValue({
        rejectionType: rejectionMessages.fetchTypes,
      });
    }
  },
);

/** Halts polling for files. Resets files slice state. This also prevents any
 *  ongoing getPatientDocumentMetadata calls from updating files. */
export const stopPollingFiles = (): AsyncAction => async (dispatch) => {
  dispatch(resetFiles());
  clearTimeout(pollTimeoutId);
};

/** Removes any existing document data in files slice.
 * Sets status to "loading". Calls to start getting document data.
 */
export const getInitialFiles =
  (patientId: PrimaryKey): AsyncAction =>
  async (dispatch) => {
    clearTimeout(pollTimeoutId);
    dispatch(resetFiles());
    if (patientId) {
      dispatch(setStatus('loading'));
      dispatch(getFiles(patientId));
    }
  };

/** Calls to get document data for the files slice.
 * If successfull, will request documents again after a predefined interval. */
export const getFiles = createAsyncThunk(
  `${prefix}/getFiles`,
  async (patientId: PrimaryKey, { rejectWithValue, dispatch, getState }) => {
    dispatch(setPatientId(patientId));
    try {
      const response = await GrdnApi.getPatientDocumentMetadata(patientId);
      if (isSuccessResponse(response)) {
        const { files } = getState() as { files: FilesState };
        if (patientId === files.activePatientId) {
          pollTimeoutId = setTimeout(() => {
            dispatch(getFiles(patientId));
          }, FILES_POLL_INTERVAL);
          return response.data;
        } else {
          return rejectWithValue({
            rejectionType: rejectionMessages.incorrectPatient,
          });
        }
      } else {
        return rejectWithValue({ rejectionType: rejectionMessages.fetchDocuments });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: `${rejectionMessages.fetchDocuments}` });
    }
  },
);

export const filesAdapter = createEntityAdapter<DocumentMetadata>({});

export const files = createSlice({
  name: prefix,
  initialState: {
    activePatientId: '',
    list: filesAdapter.getInitialState(),
    status: 'idle',
    statusMessage: '',
    categoryTypes: [],
  } as FilesState,
  reducers: {
    resetFiles(state) {
      state.activePatientId = '';
      state.status = 'idle';
      state.statusMessage = '';
      filesAdapter.removeAll(state.list);
    },
    setPatientId(state, { payload: patientId }: PayloadAction<string>) {
      state.activePatientId = patientId;
    },
    setStatus(state, { payload: status }: PayloadAction<LoadingStatus>) {
      state.status = status;
    },
    updateFile(state, { payload: update }: PayloadAction<UpdateFile>) {
      filesAdapter.updateOne(state.list, update);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFiles.fulfilled,
      (state, action: PayloadAction<PatientDocumentMetadataPayload, string>) => {
        state.status = 'success';
        state.statusMessage = '';
        filesAdapter.setAll(state.list, action.payload.documents as DocumentMetadata[]);
      },
    );
    builder.addCase(getFiles.rejected, (state, action: PayloadAction<any, string>) => {
      /** Ignore incorrectPatient errors. They are stale getFiles calls that returned
       * after changing patients. */
      if (action.payload.rejectionType !== rejectionMessages.incorrectPatient) {
        state.status = 'error';
        state.statusMessage = action.payload.rejectionType;
      }
    });
    builder.addCase(
      getDocumentCategoryTypes.fulfilled,
      (state, action: PayloadAction<DocumentCategoryType[], string>) => {
        state.categoryTypes = action.payload;
      },
    );
    builder.addCase(getDocumentCategoryTypes.rejected, (state) => {
      state.categoryTypes = [];
    });
    builder.addCase(
      updateDocumentAnnotations.fulfilled,
      (state, action: PayloadAction<UpdateFile>) => {
        filesAdapter.updateOne(state.list, action.payload);
      },
    );
  },
});

export const { setStatus, resetFiles, setPatientId } = files.actions;

// selectors
export const filesSelectors = filesAdapter.getSelectors<Store>((state) => state.files.list);
export const filesStatus = (state: Store) => state.files.status;
export const activePatientId = (state: Store) => state.files.activePatientId;
export const filesList = createSelector(filesSelectors.selectAll, (files) => {
  return files.filter((file) => file.deletedAt === null);
});

export const filesByDocumentType = createSelector(filesList, (files) => {
  const filesByType = files.reduce((byType: Record<string, DocumentMetadata[]>, file) => {
    if (!file.patientDocumentTypeId) {
      if (byType.needsType) {
        byType.needsType.push(file);
      } else {
        byType.needsType = [file];
      }
      return byType;
    }
    if (byType[file.patientDocumentTypeId]) {
      byType[file.patientDocumentTypeId].push(file);
    } else {
      byType[file.patientDocumentTypeId] = [file];
    }
    return byType;
  }, {});
  return Object.keys(filesByType).length > 0 ? filesByType : null;
});

export const categoryTypesAll = (state: Store) => state.files.categoryTypes;

/** Only managed types are displayed in the app */
export const categoryTypes = createSelector(categoryTypesAll, (types) => {
  return types.filter((type) => type.isManaged === true);
});

/** Returns only to type options present in the files list. */
export const usedCategoryTypeOptions = createSelector(
  categoryTypes,
  filesByDocumentType,
  (types, filesByType) => {
    let usedOptions: DocumentCategoryOption[] | null = null;
    if (filesByType) {
      usedOptions = types.reduce((types: DocumentCategoryOption[], type) => {
        if (filesByType[type.id]) {
          types.push({ value: type.id, label: type.name });
        }
        return types;
      }, []);

      if (filesByType.needsType) {
        usedOptions.unshift({ value: 'needsType', label: 'Needs document type' });
      }
    }
    return usedOptions;
  },
);

export const categoryTypeOptions = createSelector(categoryTypes, (options) => {
  return options.map((type) => {
    return {
      value: type.id,
      label: type.name,
    } as DocumentCategoryOption;
  });
});

export default files.reducer;

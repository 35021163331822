import React from 'react';
import cx from 'classnames';
import { EdenColors } from 'types/colors';
import css from 'components/ui/MultiDayCalendar.module.css';
import { RecurringIcon, MultiDayCalendarIcon } from 'components/ui/svg';
import { PopupDate } from 'types/tables/popups';

interface CalendarProps {
  popupDates?: PopupDate[];
  draft: boolean;
  past: boolean;
}

export const activePopupColors = [
  EdenColors.White,
  EdenColors.Grape,
  EdenColors.Slate15,
  EdenColors.White,
];

export const draftPopupColors = [
  EdenColors.Slate05,
  EdenColors.Slate25,
  EdenColors.Slate15,
  EdenColors.Slate,
];

export const pastPopupColors = [
  EdenColors.Slate15,
  EdenColors.Slate35,
  EdenColors.Slate25,
  EdenColors.Slate,
];

export const MultiDayCalendar = ({ popupDates, past, draft }: CalendarProps) => {
  const [backgroundColor, topColor, borderColor, textColor] = past
    ? pastPopupColors
    : draft
    ? draftPopupColors
    : activePopupColors;

  const testId = past
    ? 'past-multi-popup-icon'
    : draft
    ? 'draft-multi-popup-icon'
    : 'active-multi-popup-icon';
  const numOfDates = popupDates ? popupDates.length : false;
  return (
    <div className={css.container} data-testid={testId}>
      <MultiDayCalendarIcon
        className={cx(css.inner)}
        backgroundColor={backgroundColor}
        topColor={topColor}
        borderColor={borderColor}
        textColor={textColor}
      />
      {numOfDates ? (
        <div className={cx(css.inner, css.daysNumber)}>{numOfDates}</div>
      ) : (
        <div className={cx(css.inner, css.recurringIcon)}>
          <RecurringIcon />
        </div>
      )}
    </div>
  );
};

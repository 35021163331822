import React, { useState } from 'react';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { TertiaryButton } from '../../ui/Buttons';
import css from './styles.module.css';
import settingsCss from 'scenes/Main/Settings/Settings.module.css';
import { Header, ListHeaderMedium } from 'components/ui/Typography';
import { getSponsorsWithSchemas } from 'reducers/sponsors';
import Dropdown from 'components/ui/Dropdown';
import tools, { RDashTool, ToolModal } from 'components/features/RDashTools/ToolModal';
import { ChevronDownIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { Sponsor } from 'types/tables/sponsors';

interface ToolDropdownProps {
  sponsor: Sponsor;
}

interface ToolDropdownState {
  activeTool: RDashTool | undefined;
  isModalOpen: boolean;
  isDropdownOpen: boolean;
}

const initialToolDropdownState = Object.freeze({
  activeTool: undefined,
  isModalOpen: false,
  isDropdownOpen: false,
});

const ToolDropdown: React.FC<ToolDropdownProps> = (props: ToolDropdownProps) => {
  const { sponsor } = props;

  const [{ activeTool, isModalOpen, isDropdownOpen }, setState] =
    useState<ToolDropdownState>(initialToolDropdownState);

  const closeModal = (): void =>
    setState((state) => ({
      ...state,
      isModalOpen: false,
    }));

  return (
    <div data-testid={`sponsor-dropdown-${sponsor.id}`}>
      <TertiaryButton
        onClick={() => setState((state) => ({ ...state, isDropdownOpen: true }))}
        onFocus={() => setState((state) => ({ ...state, isDropdownOpen: true }))}
        className={cx(
          css.button,
          !isDropdownOpen && css.modalClosed,
          isDropdownOpen && css.modalOpen,
          isDropdownOpen && css.activeButton,
        )}
      >
        Options
        <ChevronDownIcon
          className={css.chevron}
          color={isDropdownOpen ? EdenColors.White : EdenColors.Slate}
        />
      </TertiaryButton>

      <Dropdown
        isOpen={isDropdownOpen}
        onClose={() => {
          setState((state) => ({
            ...state,
            isDropdownOpen: false,
          }));
        }}
        className={css.dropdown}
      >
        {tools.map((tool) => (
          <div
            data-testid={`${tool.displayName}-${sponsor.id}`}
            key={tool.displayName}
            onClick={() => {
              setState((state) => ({
                ...state,
                activeTool: tool,
                isModalOpen: true,
                isDropdownOpen: false,
              }));
            }}
            className={css.toolRow}
          >
            {tool.displayName}
          </div>
        ))}
      </Dropdown>
      <ToolModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        sponsorName={sponsor.name}
        override={
          activeTool?.displayName === 'Roster upload status' ? css.centeredModal : undefined
        }
      >
        {activeTool && <activeTool.form sponsor={sponsor} closeModal={closeModal} />}
      </ToolModal>
    </div>
  );
};

interface SponsorListItemProps {
  sponsor: Sponsor;
}

const SponsorListItem: React.FC<SponsorListItemProps> = (props: SponsorListItemProps) => {
  const { sponsor } = props;
  const { name } = sponsor;

  return (
    <div className={css.listItem}>
      <ListHeaderMedium className={css.sponsorName}>{name}</ListHeaderMedium>
      <ToolDropdown sponsor={sponsor} />
    </div>
  );
};

export const RDashTools = () => {
  const sponsorsWithSchemas = useSelector(getSponsorsWithSchemas);

  return (
    <div className={settingsCss.column}>
      <Header style={{ paddingBottom: 48 }}>Reporting Dashboard Tools</Header>
      <div className={css.sponsorList}>
        {sponsorsWithSchemas.map((sponsor) => (
          <div key={sponsor.id} data-testid={`sponsor-list-item-${sponsor.id}`}>
            <SponsorListItem sponsor={sponsor} />
            <div className={css.listItemDivider} />
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import { Item } from 'react-stately';
import { DropdownMenu } from '../../design-system/DropdownMenu/DropdownMenu';
import css from './CreateButton.module.css';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { BodySmall } from 'components/design-system/Text';
import { Bell, CheckCircleIcon, Plus } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import NextStepEditModal from 'components/features/CarePlan/modals/edit-modal';
import EditTicklerModal from 'components/features/TicklerOverview/EditTicklerModal';
import { getIsCloseKnitPatient } from 'reducers/providerGroups';

interface CreateButtonProps {}

interface MenuButtonProps {
  isOpen?: boolean;
}

enum MenuOptions {
  NextStep = 'NextStep',
  Tickler = 'Tickler',
}

const MenuButton = ({ isOpen }: MenuButtonProps) => {
  return (
    <div className={cx(css.button, isOpen && css.buttonOpen)}>
      <Plus height={18} width={18} color={EdenColors.SlateDarken20} />
    </div>
  );
};

const CreateButton: React.FC<CreateButtonProps> = () => {
  //TODO(@espigelman): providerShowCarePlanCloseKnit: remove when obsolete
  const { providerShowCarePlanCloseKnit } = useFlags();
  const { sponsorId } = useActivePatient();
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, sponsorId);
  const [nextStepModalIsOpen, setNextStepModalIsOpen] = useState(false);
  const [editTicklerModalIsOpen, setEditTicklerModalIsOpen] = useState(false);

  const onMenuSelect = (key: MenuOptions) => {
    switch (key) {
      case MenuOptions.NextStep:
        setNextStepModalIsOpen(true);
        break;
      case MenuOptions.Tickler:
        setEditTicklerModalIsOpen(true);
        break;
    }
  };

  return (
    <>
      <div className={css.buttonWrapper}>
        <DropdownMenu menuTrigger={<MenuButton />} onAction={onMenuSelect} placement="bottom right">
          {(providerShowCarePlanCloseKnit || !isCloseKnitPatient) && (
            <Item key={MenuOptions.NextStep} textValue="New next step">
              <div className={css.menuItem}>
                <CheckCircleIcon width={20} height={20} color={EdenColors.SlateDarken20} />
                <BodySmall style={{ color: EdenColors.SlateDarken20 }}>New next step</BodySmall>
              </div>
            </Item>
          )}
          <Item key={MenuOptions.Tickler} textValue="New tickler">
            <div className={css.menuItem}>
              <Bell width={20} height={20} color={EdenColors.SlateDarken20} />
              <BodySmall style={{ color: EdenColors.SlateDarken20 }}>New tickler</BodySmall>
            </div>
          </Item>
        </DropdownMenu>
      </div>
      {nextStepModalIsOpen && <NextStepEditModal onClose={() => setNextStepModalIsOpen(false)} />}
      {editTicklerModalIsOpen && (
        <EditTicklerModal
          isOpen={editTicklerModalIsOpen}
          closeModal={() => setEditTicklerModalIsOpen(false)}
        />
      )}
    </>
  );
};

export default CreateButton;

import React, { useState } from 'react';
import cx from 'classnames';
import { ListItem1 } from 'components/ui/Lists';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import Dropdown from 'components/ui/Dropdown';
import css from 'components/features/PopupManager/SubpopulationDropdown.module.css';
import { Checkbox, ChevronUpIcon, ChevronDownIcon, Info } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import Tooltip from 'components/design-system/Tooltip';
import { Label } from 'components/design-system/Form';
import { Division } from 'types/tables/divisions';
import { getDivisionNameById, getDivisionsWithNull } from 'lib/util';

interface SubpopulationDropdownProps {
  sponsorDivisions: Division[];
  setSelectedDivisions: (divisions: (string | null)[]) => void;
  selectedDivisions: (string | null)[];
}

interface SubpopulationFilterViewProps {
  filters: Division[];
  selectedFilters: (string | null)[];
  setSelectedFilters: (divisions: (string | null)[]) => void;
  onClearFilters: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SubpopulationFilterView = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  onClearFilters,
}: SubpopulationFilterViewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const displaySelectedFilters = (filters) => {
    return (
      selectedFilters.reduce((acc, cur, index) => {
        if (index < selectedFilters.length - 1) {
          return acc + getDivisionNameById(cur, filters) + ', ';
        } else {
          return acc + getDivisionNameById(cur, filters);
        }
      }, '') || 'Filter by Subpopulation'
    );
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handlePickDivision = (id: string | null) => {
    if (selectedFilters.indexOf(id) > -1) {
      setSelectedFilters(selectedFilters.filter((divisionId) => divisionId !== id));
    } else {
      setSelectedFilters([...selectedFilters, id]);
    }
  };

  const renderRow = (option: Division, key: number) => {
    const isChecked = selectedFilters.includes(option.id);
    return (
      <ListItem1
        key={key}
        className={css.multiFilterItem}
        onClick={(e) => {
          e.preventDefault();
          handlePickDivision(option.id);
        }}
        data-testid={`SubpopulationFilterRow-${option.id}`}
      >
        <Checkbox
          color={isChecked ? EdenColors.ActionPrimary : EdenColors.Slate15}
          checked={isChecked}
          size={14}
        />
        <div>{option.name}</div>
      </ListItem1>
    );
  };

  return (
    <div>
      <Button
        buttonType={ButtonType.transparent}
        size={ButtonSizes.small}
        active={isOpen}
        content={displaySelectedFilters(filters)}
        icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={toggleDropdown}
        data-testid={`SubpopulationFilterDropdown`}
        className={cx(css.multiFilterButton, selectedFilters.length === 0 && css.placeholder)}
      />
      <Dropdown className={css.dropdown} isOpen={isOpen} onClose={toggleDropdown}>
        <div className={css.clearFiltersContainer}>
          <Button className={css.clearFilters} onClick={onClearFilters}>
            Clear Filter
          </Button>
        </div>
        {filters.map((value, index) => renderRow(value, index))}
      </Dropdown>
    </div>
  );
};

const SubpopulationDropdown = ({
  sponsorDivisions,
  setSelectedDivisions,
  selectedDivisions,
}: SubpopulationDropdownProps) => {
  const sponsorDivisionsWithNull = getDivisionsWithNull(sponsorDivisions);

  const handleClearFilters = (event) => {
    event.preventDefault();
    setSelectedDivisions([]);
  };

  const tooltipText =
    'If no subpopulation is specified, pop-up will be available to entire sponsor.';

  return (
    <Label text="Subpopulations" style={{ position: 'relative', width: 318 }}>
      <div className={css.tooltipContainer}>
        <Tooltip content={tooltipText} placement="bottom">
          <Info color={EdenColors.Slate55} height="14" width="14" />
        </Tooltip>
      </div>
      <SubpopulationFilterView
        filters={sponsorDivisionsWithNull || []}
        selectedFilters={selectedDivisions}
        setSelectedFilters={setSelectedDivisions}
        onClearFilters={handleClearFilters}
      />
    </Label>
  );
};

export default SubpopulationDropdown;

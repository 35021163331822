import cx from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BackToWorkModal } from '../PatientPills/BackToWork/BackToWorkModal';
import css from './StatusDropdown.module.css';
import { TertiaryButton } from 'components/ui/Buttons';
import { ChevronDownIcon } from 'components/ui/svg';
import Dropdown from 'components/ui/Dropdown';
import { ListItem1 } from 'components/ui/Lists';
import {
  changeChannelStatus,
  getPatientPrimaryChannel,
  getPatientPrimaryChannelStatus,
} from 'reducers/channels';
import { getPatientProfile } from 'selectors/profiles';
import { ChannelStatus } from 'types/tables/channels';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { PatientTriageStatus, profileIsError, TriageStatus } from 'types/tables/profiles';
import { formatChannelStatus } from 'lib/util';
import { EdenColors } from 'types/colors';

interface StatusPickerProps {
  active: boolean;
  onClose: () => void;
  onReviewNeeded: () => void;
  status: ChannelStatus;
}

const StatusPicker = ({ active, onClose, onReviewNeeded, status }: StatusPickerProps) => {
  const patient = useActivePatient();
  const profile = useParameterizedSelector(getPatientProfile, patient.id);
  const triageStatus: PatientTriageStatus = !profileIsError(profile)
    ? profile.currentTriageStatus
    : {
        status: TriageStatus.NeverTaken,
      };
  const dispatch = useDispatch();

  const primaryChannel = useParameterizedSelector(getPatientPrimaryChannel, patient.id);
  const statusList = Object.keys(ChannelStatus).map((k) => ChannelStatus[k as any]);

  return (
    <>
      <Dropdown dropdownStyle={css.dropdownStyle} isOpen={active} onClose={onClose}>
        {statusList
          .filter((listItem) => listItem !== status)
          .map((listItem: ChannelStatus) => (
            <ListItem1
              key={listItem}
              onClick={async () => {
                if (!primaryChannel) {
                  return;
                } else if (
                  triageStatus.status !== TriageStatus.NeverTaken &&
                  listItem === ChannelStatus.Archived
                ) {
                  onReviewNeeded();
                } else {
                  await dispatch(changeChannelStatus({ primaryChannel, status: listItem }));
                  onClose();
                }
              }}
              className={cx(css.row, listItem === ChannelStatus.Open && css.openStatus)}
              data-testid="StatusPickerItem"
            >
              {formatChannelStatus(listItem)}
            </ListItem1>
          ))}
      </Dropdown>
    </>
  );
};

const StatusDropdown = () => {
  const { id: patientId } = useActivePatient();
  const profile = useParameterizedSelector(getPatientProfile, patientId);
  const triageStatus: PatientTriageStatus = !profileIsError(profile)
    ? profile.currentTriageStatus
    : {
        status: TriageStatus.NeverTaken,
      };

  const status = useParameterizedSelector(getPatientPrimaryChannelStatus, patientId);
  const [{ isFocused, isPickerOpen, isModalOpen }, setState] = useState({
    isFocused: false,
    isPickerOpen: false,
    isModalOpen: false,
  });
  // TODO: this color coordination should not be splitbrained with css.openStatus
  let chevronColor;
  if (isPickerOpen || isFocused) {
    chevronColor = EdenColors.White;
  } else if (status === ChannelStatus.Open) {
    chevronColor = EdenColors.Razz;
  } else {
    chevronColor = EdenColors.SlateDarken20;
  }
  return (
    <>
      <StatusPicker
        active={isPickerOpen}
        status={status || ChannelStatus.Archived}
        onClose={() => setState((state) => ({ ...state, isPickerOpen: false }))}
        onReviewNeeded={() => {
          setState((state) => ({
            ...state,
            isModalOpen: true,
            isPickerOpen: false,
          }));
        }}
      />
      {triageStatus && (
        <BackToWorkModal
          patientId={patientId}
          triageStatus={triageStatus}
          isOpen={isModalOpen}
          onClose={() =>
            setState((state) => ({
              ...state,
              isModalOpen: false,
            }))
          }
        />
      )}
      <TertiaryButton
        onClick={() => setState((state) => ({ ...state, isPickerOpen: true }))}
        onFocus={() => setState((state) => ({ ...state, isFocused: true, isPickerOpen: true }))}
        onBlur={() => setState((state) => ({ ...state, isFocused: false }))}
        className={cx(
          css.button,
          !isPickerOpen && css.modalClosed,
          isPickerOpen && css.modalOpen,
          !isFocused && status === ChannelStatus.Open && css.openStatus,
        )}
        data-testid="StatusButton"
      >
        {formatChannelStatus(status || ChannelStatus.Archived)}
        <ChevronDownIcon className={css.chevron} color={chevronColor} />
      </TertiaryButton>
    </>
  );
};

export default StatusDropdown;

import React from 'react';
import { useOverlayTriggerState } from 'react-stately';
import type { HTMLAttributes, ReactElement } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import type { ConfirmationDialogProps } from './ConfirmationDialog';
import type { ButtonActionType } from 'components/design-system/Button/Button';

interface UseConfirmationDialogReturn {
  open: () => void;
  close: () => void;
  ConfirmationDialog: () => ReactElement<null>;
}

export interface UseConfirmationDialog
  extends Omit<ConfirmationDialogProps, 'actionType' | 'onCancel' | 'onConfirm' | 'onClose'> {
  actionType?: ButtonActionType;
  confirmLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  modalProps: HTMLAttributes<Element>;
}

/**
 * Custom hook which returns an accessible confirmation dialog/modal to ask the user to confirm
 * before performing an important action.
 *
 * The hook manages the dialog's state internally, but returns `open` and `close` functions which
 * allow controlling the dialog's behavior from the outside.
 */
export const useConfirmationDialog = ({
  title,
  description,
  iconName,
  isOpen,
  actionType = 'success',
  confirmLabel,
  onCancel,
  onConfirm,
  onClose,
  modalProps,
  hideCancelButton,
}: UseConfirmationDialog): UseConfirmationDialogReturn => {
  const state = useOverlayTriggerState({ isOpen });

  return {
    open: () => state.open(),
    close: () => state.close(),
    ConfirmationDialog: () => (
      <>
        {state.isOpen && (
          <ConfirmationDialog
            title={title}
            description={description}
            iconName={iconName}
            isOpen={state.isOpen}
            confirmLabel={confirmLabel}
            onClose={() => {
              if (onClose) {
                onClose();
              }

              state.close();
            }}
            onCancel={() => {
              if (onCancel) {
                onCancel();
              }

              state.close();
            }}
            onConfirm={() => {
              if (onConfirm) {
                onConfirm();
              }

              state.close();
            }}
            modalProps={modalProps}
            actionType={actionType}
            hideCancelButton={hideCancelButton}
          />
        )}
      </>
    ),
  };
};

import type { ReactNode } from 'react';
import React, { useRef } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';
import cx from 'classnames';
import { Spinner } from '../Spinner/Spinner';
import css from './Button.module.css';

export type ButtonType = 'primary' | 'secondary';

export type ButtonSize = 'big' | 'small';

export type ButtonActionType = 'success' | 'destructive' | 'info';

export interface ButtonProps {
  /**
   * The type of the button.
   */
  type?: ButtonType;

  /**
   * The size of the button.
   */
  size?: ButtonSize;

  /**
   * The type of the action performed when the button is pressed.
   */
  actionType?: ButtonActionType;

  /**
   * Action to perform when the button is pressed.
   */
  onPress: () => void;

  /**
   * Whether the button is disabled.
   */
  isDisabled?: boolean;

  /**
   * Whether the button is in a loading state. If `true`, the `onPress` action
   * will not be executed.
   */
  isLoading?: boolean;

  children: ReactNode | ReactNode[];
}

const classNameByType: Record<ButtonType, keyof typeof css> = {
  primary: 'primary',
  secondary: 'secondary',
};

const classNameBySize: Record<ButtonSize, keyof typeof css> = {
  big: 'big',
  small: 'small',
};

const classNameByActionType: Record<ButtonActionType, keyof typeof css> = {
  success: 'success',
  destructive: 'destructive',
  info: 'info',
};

/**
 * The spinner used to convey a loading behavior on buttons is different from
 * the spinner we use elsewhere (no gradient, different stroke width).
 */
const LoadingSpinner = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.667 10A8.333 8.333 0 1 0 10 1.667"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

/**
 * An accessible button component, which handles keyboard, mouse and touch
 * interactions as well as ARIA properties and focus behavior for accessibility.
 *
 * Supports a loading state which disables its `onPress` behavior.
 */
export const Button = ({
  type = 'primary',
  size = 'big',
  actionType = 'success',
  onPress,
  isDisabled = false,
  isLoading = false,
  children,
}: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(
    {
      onPress: isDisabled || isLoading ? undefined : onPress,
      isDisabled: isDisabled || isLoading,
    },
    buttonRef,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={buttonRef}
      className={cx(
        css.button,
        css[classNameByActionType[actionType]],
        css[classNameByType[type]],
        css[classNameBySize[size]],
        isPressed && css.pressed,
        isDisabled && css.disabled,
        isLoading && css.loading,
        isFocusVisible && `focus-ring`,
      )}
    >
      {isLoading ? (
        <>
          <Spinner>
            <LoadingSpinner />
          </Spinner>
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};

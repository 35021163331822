import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import TimeAgo from 'react-timeago';
import { v4 as uuidv4 } from 'uuid';
import { Patient, VisitModality } from 'types/tables/patients';
import { Profile } from 'types/tables/profiles';
import { ChannelStatus } from 'types/tables/channels';
import {
  AdminMessageType,
  Message,
  PendingMessage,
  PendStatus,
  PrimaryMessageType,
} from 'types/tables/messages';
import { Provider, ProviderRole } from 'types/tables/providers';
import { StateKey } from 'types/geo';
import { auth } from 'lib/auth';
import { loadEnv } from 'lib/env';

export const hasProviderRole = (provider: Provider) =>
  provider.role === ProviderRole.PROVIDER ||
  provider.role === ProviderRole.CLOPS_ADMIN_PROVIDER ||
  provider.role === ProviderRole.EXTERNAL_USER_PROVIDER;

export const hasNavigatorRole = (provider: Provider) =>
  provider.role === ProviderRole.ASSOCIATE ||
  provider.role === ProviderRole.CLOPS_ADMIN_ASSOCIATE ||
  provider.role === ProviderRole.EXTERNAL_USER_ASSOCIATE;

export const isProviderLicensed = (
  provider: Provider | undefined,
  patientLocation: StateKey | null | undefined,
) =>
  Boolean(
    provider &&
      hasProviderRole(provider) &&
      provider.licenses &&
      provider.licenses.some((l) => l === patientLocation),
  );

export function dobToAge(dob: DateTime) {
  if (!dob.isValid) {
    return '--';
  }
  return `${Math.floor(-dob.diffNow('years').years)}yo`;
}

export function escapeRegExp(str: string) {
  return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const isSameDay = (currentMessage: Partial<Message>, diffMessage: Partial<Message>) => {
  const currentMessageTimestamp = currentMessage?.timestamp;
  const diffMessageTimestamp = diffMessage?.timestamp;
  return !!(
    currentMessageTimestamp &&
    diffMessageTimestamp &&
    DateTime.fromMillis(currentMessageTimestamp).hasSame(
      DateTime.fromMillis(diffMessageTimestamp),
      'day',
    )
  );
};

export const formatChannelStatus = (status: ChannelStatus) =>
  status[0].toUpperCase().concat(status.slice(1));

export function sexLabel(sex: string) {
  if (sex === 'F') {
    return 'Female';
  } else if (sex === 'M') {
    return 'Male';
  }
  return 'Intersex';
}

/*
 * Custom formatter for the `react-timeago` component -- If it's been <1min,
 * simply display "Just now", rather than updating every second. After that,
 * update every minute for periods <1hr, every hour for periods <1d, etc.
 */
export const timeAgoFormatter = (val: number, unit: string, _, millis: number) => {
  if (val < 60 && unit === 'second') return 'Just now';
  const date = DateTime.fromMillis(millis);
  if (-date.diffNow('years').years >= 1) {
    return `${Math.floor(-date.diffNow('years').years)}y`;
  } else if (-date.diffNow('months').months >= 1) {
    return `${Math.floor(-date.diffNow('months').months)}m`;
  } else if (-date.diffNow('days').days >= 1) {
    return `${Math.floor(-date.diffNow('days').days)}d`;
  } else if (-date.diffNow('hours').hours >= 1) {
    return `${Math.floor(-date.diffNow('hours').hours)}h`;
  } else {
    return `${Math.floor(-date.diffNow('minutes').minutes)}m`;
  }
};

export const formatDisplayTime = (time: DateTime) => {
  if (!time.isValid) {
    return null;
  }
  return <TimeAgo date={time} minPeriod={60} formatter={timeAgoFormatter} />;
};

export const formatPhone = (num: string) => {
  if (num && num.length === 10) {
    return ''.concat(
      '(',
      num.substring(0, 3),
      ') ',
      num.substring(3, 6),
      '-',
      num.substring(6, 10),
    );
  } else {
    return num;
  }
};

export const isToday = (date: DateTime) => {
  return date.hasSame(DateTime.local(), 'day');
};

export const isThisHour = (date: DateTime) => {
  const diff = date.diffNow('hours').hours;
  return diff < 1 && diff > -1;
};

const typesForPrimaryChannel: string[] = [
  AdminMessageType.Appointment, // legacy
  AdminMessageType.CovidVaxEligible, // legacy
  AdminMessageType.Text,
  PrimaryMessageType.ScreenerCompleted,
  PrimaryMessageType.Text,
  PrimaryMessageType.Image,
  PrimaryMessageType.Deleted,
  PrimaryMessageType.Video,
  PrimaryMessageType.InsuranceSubmitted,
  AdminMessageType.Welcome,
];

export const belongsInPrimaryChannel = (message: Message): boolean =>
  typesForPrimaryChannel.includes(message.type);

export const createPendingMessage = (provider: Provider, text: string): PendingMessage => {
  if (!provider) throw new Error('Cannot find providers for current user');
  return {
    id: uuidv4(),
    type: PrimaryMessageType.Text,
    senderId: provider.sendbirdUserId,
    data: { text },
    timestamp: DateTime.local().toMillis(),
    pendStatus: PendStatus.Pending,
  };
};

export const isPendingMessage = (message: Message | PendingMessage): message is PendingMessage => {
  return 'pendStatus' in message && message.pendStatus !== undefined;
};

// Our axios client uses the decamelize-keys-deep module to transform request parameters to kebab case,
// but it does not handle keys like "address1" in the manner grdn expects. This transformation is used
// to handle such keys explicitly.
// See api.ts for usage.
export const deepKeyTransform = (o: any, keyMap: { [key: string]: string }) =>
  _.isPlainObject(o)
    ? _.transform(
        o,
        (res, v, k: string) => {
          const key = keyMap[k] || k;
          res[key] = _.isPlainObject(v) ? deepKeyTransform(v, keyMap) : v;
        },
        {},
      )
    : o;

/**
 * Get ordinal text for specified number
 * @param n
 * @returns n with ordinal suffix
 */
export const ordinalify = (n: number): string => {
  const ones = n % 10;
  const tens = n % 100;

  let val = n.toString();
  if (ones === 1 && tens !== 11) {
    val += 'st';
  } else if (ones === 2 && tens !== 12) {
    val += 'nd';
  } else if (ones === 3 && tens !== 13) {
    val += 'rd';
  } else {
    val += 'th';
  }

  return val;
};

// Drops empty trailing rows from CSV data parsed into an array of JS Objects.
export const dropTrailingEmptyRows = (
  csvData: Record<string, string>[],
): Record<string, string>[] => {
  return _.dropRightWhile(csvData, (csvRow) => {
    return Object.keys(csvRow).every((k) => {
      return csvRow[k] === '';
    });
  });
};

export const isDueToday = (dueDate) => {
  const today = DateTime.now().startOf('day');

  return dueDate.startOf('day').equals(today);
};

export const isOverdue = (dueDate) => {
  const today = DateTime.now().startOf('day');

  return today > dueDate;
};

// TODO this needs to be tested
export const formatPreferredFullNameFor = ({
  preferredName,
  firstName,
  lastName,
}: Patient | Profile) => {
  return !firstName || !lastName
    ? ''
    : preferredName && preferredName !== firstName
    ? `"${preferredName}" ${firstName} ${lastName}`
    : `${firstName} ${lastName}`;
};

export const computeDaysFrom = (when: string) =>
  Math.ceil(DateTime.fromISO(when).diff(DateTime.now(), 'days').toObject().days || 0);

type FormDataItem = string | Blob;
type FormDataObj = Record<string, FormDataItem>;
/** Converts a simple flat object to FormData with its keys in kebab case */
export const convertToFormData = (obj: FormDataObj): FormData => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    formData.append(_.kebabCase(key), value);
  }
  return formData;
};

type ContentType = 'application/json' | 'multipart/form-data';
type RequestHeaders = {
  'Content-Type': ContentType;
  'X-Client-Bearer-Token': string;
  Authentication?: string;
  'X-Provider-Google-Token'?: string;
  'X-Google-ID'?: string;
};

/** Returns default request headers for API calls */
export const apiRequestHeaders = async () => {
  const authToken = await auth.token;
  const headers: RequestHeaders = {
    'Content-Type': 'application/json',
    'X-Client-Bearer-Token': loadEnv('REACT_APP_GRDN_BEARER_TOKEN') || '',
  };
  if (authToken) {
    headers['Authentication'] = authToken;
    headers['X-Provider-Google-Token'] = authToken;
  }
  /* istanbul ignore next */
  if ((window as any).Cypress) {
    // Maybe we can figure out how to inject a header or JWT instead
    const mockGoogleId = loadEnv('REACT_APP_MOCK_GOOGLE_ID') || 'FailedToReadMockGoogleId';
    headers['X-Google-ID'] = mockGoogleId;
  }
  return headers;
};

export const getAllDefaultPrimaryProviderIds = () => {
  const TELEHEALTH_PROVIDER_ID = loadEnv('REACT_APP_TELEHEALTH_PROVIDER');
  const CLOSEKNIT_PRIMARY_PROVIDER_ID = loadEnv('REACT_APP_CLOSEKNIT_PRIMARY_PROVIDER');

  return [TELEHEALTH_PROVIDER_ID, CLOSEKNIT_PRIMARY_PROVIDER_ID];
};

const appointmentModalityTypeIds = {
  [VisitModality.Async]: Number(loadEnv('REACT_APP_APPOINTMENT_TYPE_ASYNC')),
  [VisitModality.Video]: Number(loadEnv('REACT_APP_APPOINTMENT_TYPE_VIDEO')),
  [VisitModality.Phone]: Number(loadEnv('REACT_APP_APPOINTMENT_TYPE_PHONE')),
};

export const getAppointmentTypeIdByModality = (modality: VisitModality) =>
  appointmentModalityTypeIds[modality];

export const getModalityByAppointmentTypeId = (appointmentTypeId: number) =>
  Object.keys(appointmentModalityTypeIds).find(
    (key) => appointmentModalityTypeIds[key] == appointmentTypeId,
  );

export const getDivisionsWithNull = (divisions) =>
  divisions ? [...divisions, { id: null, name: 'No division set' }] : null;

export const getDivisionNameById = (id, divisions) =>
  divisions.find((division) => division.id === id)?.name;

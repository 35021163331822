import { useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria';
import cx from 'classnames';
import { useToggleState } from 'react-stately';
import React, { useRef } from 'react';
import css from './Switch.module.css';

export interface SwitchProps {
  /**
   * A description of what the switch represents. Invisible, but used by assistive technology.
   */
  label: string;
  /**
   * Whether the switch is on or off.
   */
  isSelected: boolean;
  /**
   * Called when the selection state of the switch changes.
   * @param isSelected - The new state of the switch.
   */
  onChange: (isSelected: boolean) => void;
}

/**
 * An accessible switch component based on `input`. Supports autofill and
 * keyboard focus management.
 *
 * The native `checkbox` remains in the DOM to be accessible to assistive technology, but is
 * visually hidden.
 */
export const Switch = ({ label, isSelected, onChange }: SwitchProps) => {
  const switchRef = useRef<HTMLInputElement>(null);
  const state = useToggleState({
    isSelected,
    onChange,
  });
  const { isFocusVisible, focusProps } = useFocusRing();
  const { inputProps } = useCheckbox({ 'aria-label': label }, state, switchRef);

  return (
    <label className={css.switch}>
      <VisuallyHidden>
        <input type="checkbox" {...inputProps} {...focusProps} />
      </VisuallyHidden>
      <div
        className={cx(css.slider, state.isSelected && css.checked, isFocusVisible && 'focus-ring')}
        aria-hidden
      />
    </label>
  );
};

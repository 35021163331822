import { loadEnv } from 'lib/env';

let intervals: number[] = [];

const GRDN_POLL_INTERVAL = Number(loadEnv('REACT_APP_GRDN_POLL_INTERVAL'));

export default {
  start: (fn: () => void, timeout = GRDN_POLL_INTERVAL) => {
    const loop = async () => {
      try {
        await fn();
      } catch (e) {}
    };
    const interval: number = window.setInterval(loop, timeout);
    intervals.push(interval);
    return interval;
  },
  stopAll: () => {
    while (intervals.length) {
      const interval = intervals.pop();
      if (interval) clearInterval(interval);
    }
  },
  stop: (interval: number) => {
    const ival = intervals.find((i) => i === interval);
    if (ival) {
      clearInterval(ival);
      intervals = intervals.filter((i) => i !== ival);
    }
  },
};

import decamelizeKeysDeep from 'decamelize-keys-deep';
import camelize from 'camelize';
import axios, { AxiosInstance, CancelToken, AxiosResponse, AxiosRequestConfig } from 'axios';
import { apiRequestHeaders } from './util';
import { APIResponse } from 'types/api';
import { captureException, captureGrdnErrorBreadcrumb } from 'lib/sentry';
import { ServerErrorCode } from 'types/http';
import { deepKeyTransform } from 'lib/util';

export default class Api {
  baseURI: string;
  instance: AxiosInstance;

  constructor(uri: string) {
    this.baseURI = uri;
    const instanceConfig: AxiosRequestConfig = {
      baseURL: this.baseURI,
    };

    instanceConfig.transformRequest = [
      (data) =>
        JSON.stringify(
          // The decamelize-keys-deep module does not handle keys named like
          // "address1" in the manner grdn expects. We therefore add another
          // transform function to handle such keys explicitly.
          deepKeyTransform(decamelizeKeysDeep(data, '-'), {
            address1: 'address-1',
            address2: 'address-2',
          }),
        ),
    ];

    this.instance = axios.create(instanceConfig);

    this.instance.interceptors.response.use(
      (resp: AxiosResponse) => ({
        ...resp,
        data: camelize(resp.data.data),
      }),
      (error: any) => {
        // any type comes from Axios docs
        // captures exception for sentry if server error OR if error has no parsed response
        const method = error?.config?.method;
        const status = error?.response?.status;
        const url = error?.config?.url;
        if (status in ServerErrorCode || (!error.response && error.message)) {
          captureException(new Error(error), error.response);
        }
        captureGrdnErrorBreadcrumb(
          {
            url,
            method,
            status,
            error,
          },
          `Call to ${method} ${url} failed with ${status}.`,
        );
        return Promise.reject(error.response);
      },
    );
  }

  async get<T>(url: string, cancelToken?: CancelToken): Promise<APIResponse<T>> {
    return await this.instance.get(url, {
      cancelToken,
      headers: await this.requestHeaders(),
    });
  }

  async post<T>(url: string, data: Record<string, any>): Promise<APIResponse<T>> {
    return await this.instance.post(url, data, { headers: await this.requestHeaders() });
  }

  async put<T>(url: string, data: Record<string, any>): Promise<APIResponse<T>> {
    return await this.instance.put(url, data, { headers: await this.requestHeaders() });
  }

  async delete<T>(url: string): Promise<APIResponse<T>> {
    return await this.instance.delete(url, { headers: await this.requestHeaders() });
  }

  async requestHeaders() {
    return apiRequestHeaders();
  }

  arrayToQuery(array: string[]) {
    return array.join(',');
  }

  objectToQuery(obj: Record<string, any>) {
    return Object.keys(obj)
      .map((k) => `${k}=${obj[k]}`)
      .join('&');
  }
}

import React from 'react';
import cx from 'classnames';
import css from './ChatErrorMessage.module.css';
import { EdenColors } from 'types/colors';
import { AlertCircleIcon } from 'components/ui/svg';

interface ErrorMessageProps {
  message: string;
  messageCSS?: string;
}

export const ChatErrorMessage = (props: ErrorMessageProps) => {
  const { message, messageCSS } = props;

  return (
    <div className={cx(css.card, css.errorCard)}>
      <span className={css.alertIcon}>
        <AlertCircleIcon width={18} height={18} color={EdenColors.Razz} />
      </span>
      <div className={`${css.errorMessage} ${messageCSS}`}>{message}</div>
    </div>
  );
};

import React from 'react';

import type { NextStep } from '../../CarePlanTypes';
import { dispositionOf } from '../../utils/dispositionOf';

import css from './PatientNote.module.css';

import { KickerHeader } from 'components/design-system/Text';

interface Props {
  show: boolean;
  step: NextStep;
}

const PatientNote = ({ show, step }: Props) => {
  if (!show) return null;

  const disposition = dispositionOf(step);

  const header = disposition.dispositionType === 'marked' ? 'Note from patient' : 'Decline reason';
  const comment =
    disposition.dispositionType === 'marked'
      ? disposition.comment
      : disposition.dispositionType === 'declined'
      ? disposition.comment
      : undefined;

  return (
    <div className={css.patientNote}>
      {comment && (
        <div className={css.section}>
          <KickerHeader className={css.kicker}>{header}</KickerHeader>
          <div className={css.comment}>{comment}</div>
        </div>
      )}
    </div>
  );
};

export default PatientNote;

import React, { ChangeEvent, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import css from './ModifyCareteamModal.module.css';
import { getMyProvider } from 'reducers/user';
import {
  getDefaultPrimaryProviderIdBySponsorId,
  getOutsidePCPIdBySponsorId,
} from 'reducers/providerGroups';
import { EdenColors } from 'types/colors';
import { PrimaryButton, SelectAccessible } from 'components/design-system/Form';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { BodyNormal, HeaderMedium, CaptionNormal } from 'components/design-system/Text';
import { XIcon } from 'components/ui/svg';
import { careteamSelectors } from 'reducers/careteams';
import { getPatientName } from 'reducers/patients';
import { getAthenaProvidersForSponsorId } from 'reducers/athenaProviders';
import { providersSelectors } from 'reducers/providers';

interface ModifyCareteamModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  updateCareteamAndClose(string, number): Promise<void>;
  currentCareteamId: string;
  primaryProviderId?: number | null;
}

const ModifyCareteamModal = ({
  isOpen,
  closeModal,
  updateCareteamAndClose,
  currentCareteamId,
  primaryProviderId,
}: ModifyCareteamModalProps) => {
  const activePatient = useActivePatient();
  const activePatientName = useParameterizedSelector(getPatientName, activePatient.id);
  const careteams = useSelector(careteamSelectors.selectAll);
  const sortedCareteams = careteams.sort((ct1, ct2) => ct1.name.localeCompare(ct2.name));
  const [selectedCareteamId, setSelectedCareteamId] = useState(currentCareteamId);

  const [selectedUsualProviderId, setSelectedUsualProviderId] = useState(primaryProviderId);
  const athenaProviders = useParameterizedSelector(
    getAthenaProvidersForSponsorId,
    activePatient.sponsorId,
  );
  const providers = useSelector(providersSelectors.selectAll);
  const myProvider = useSelector(getMyProvider);
  const myAthenaProviderId = myProvider?.athenaProviderId;

  const defaultPrimaryProviderId = useParameterizedSelector(
    getDefaultPrimaryProviderIdBySponsorId,
    activePatient.sponsorId,
  );

  const outsidePCPId = useParameterizedSelector(
    getOutsidePCPIdBySponsorId,
    activePatient.sponsorId,
  );

  interface TransformedProvider {
    displayName: string;
    athenaProviderId: number;
  }

  // Get all Athena providers that have a corresponding record in providers list
  const sortedAthenaProviders = athenaProviders
    .reduce((transformedProviders: TransformedProvider[], { athenaProviderId, entityType }) => {
      const athenaId = athenaProviderId;
      const provider = providers.find(
        ({ athenaProviderId, athenaProviderIds }) =>
          athenaProviderId === Number(athenaId) ||
          athenaProviderIds?.find(
            (athenaProvider) => athenaProvider.providerId === Number(athenaId),
          ),
      );
      if (provider && entityType === 'Person') {
        transformedProviders.push({
          displayName: provider.displayName,
          athenaProviderId: athenaProviderId,
        });
      }
      return transformedProviders;
    }, [])
    .sort((p1, p2) => {
      if (p1.athenaProviderId === myAthenaProviderId) {
        // Put logged in user on top if they have Athena provider ID
        return -1;
      } else if (p2.athenaProviderId === myAthenaProviderId) {
        return 1;
      } else {
        // Order by display name
        const a = p1.displayName || '';
        const b = p2.displayName || '';
        return a.localeCompare(b);
      }
    });

  // If the user selects this dummy provider, this indicates that an outside PCP is assigned
  const outsidePCP = {
    athenaProviderId: Number(outsidePCPId),
    displayName: 'Outside PCP',
  };
  if (outsidePCP) {
    sortedAthenaProviders.unshift(outsidePCP);
  }

  // If the user selects this dummy provider (Telehealth for Eden, or Caraway for CloseKnit), this indicates no usual provider assigned
  const defaultUsualProvider = {
    athenaProviderId: Number(defaultPrimaryProviderId),
    displayName: 'No usual provider selected',
  };
  if (defaultUsualProvider) {
    sortedAthenaProviders.unshift(defaultUsualProvider);
  }

  useEffect(() => {
    setSelectedCareteamId(currentCareteamId);
  }, [isOpen, currentCareteamId]);

  useEffect(() => {
    setSelectedUsualProviderId(primaryProviderId);
  }, [isOpen, primaryProviderId]);

  return (
    <ReactModal
      isOpen={isOpen}
      onClose={closeModal}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <BodyNormal>
        {activePatientName}&nbsp;•&nbsp;{activePatient.mrn}
      </BodyNormal>
      <div className={css.xIcon} onClick={closeModal}>
        <XIcon color={EdenColors.Slate55} />
      </div>
      <HeaderMedium style={{ marginBottom: 24 }}>Modify Care Team</HeaderMedium>
      <CaptionNormal>Careteam</CaptionNormal>
      <SelectAccessible
        value={selectedCareteamId}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedCareteamId(e.target.value)}
        style={{ marginBottom: 26 }}
        data-testid="select-careteam"
      >
        {sortedCareteams.map((ct) => (
          <option key={`careteam_${ct.id}`} value={ct.id}>
            {ct.name}
          </option>
        ))}
      </SelectAccessible>
      <CaptionNormal>Usual Provider</CaptionNormal>
      <SelectAccessible
        value={selectedUsualProviderId ? selectedUsualProviderId.toString() : undefined}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          setSelectedUsualProviderId(Number(e.target.value))
        }
        data-testid="select-usual-provider"
      >
        {sortedAthenaProviders &&
          sortedAthenaProviders.map((ap) => (
            <option
              key={`athenaProvider_${ap.athenaProviderId}`}
              value={ap.athenaProviderId.toString()}
              data-testid={`athena-provider-${ap.athenaProviderId}`}
            >
              {ap.displayName}
            </option>
          ))}
      </SelectAccessible>
      <CaptionNormal className={css.usualProviderMessage}>
        This will only update the patient&apos;s usual provider for the Telehealth department.
      </CaptionNormal>
      <div className={css.bottomNav}>
        <PrimaryButton
          type="button"
          value="Cancel"
          onClick={closeModal}
          style={{
            width: 132,
            height: 44,
            background: EdenColors.Slate05,
            color: EdenColors.SlateDarken20,
            marginRight: 16,
          }}
        />
        <PrimaryButton
          disabled={selectedCareteamId === ''}
          onClick={() => updateCareteamAndClose(selectedCareteamId, selectedUsualProviderId)}
          value="Confirm and Update"
          style={{ width: 205, height: 44 }}
        />
      </div>
    </ReactModal>
  );
};

export default ModifyCareteamModal;

import React, { useState } from 'react';
import cx from 'classnames';
//TODO: APP-8005 remove when zoom-links feature flag is removed
import { useFlags } from 'launchdarkly-react-client-sdk';
import ScheduleCallModal from './ScheduleCallModal';
import css from './VideoButton.module.css';
import { Camera } from 'components/ui/svg';
import { TertiaryButton } from 'components/ui/Buttons';
import { Channel } from 'types/tables/channels';

interface VideoButtonProps {
  channel: Channel;
  disabled: boolean;
}

const VideoButton = ({ channel, disabled }: VideoButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { zoomLinks } = useFlags();
  return (
    /* This is not a fragment because that breaks any components relying on this
             to be just one element */
    //TODO: APP-8005 remove when zoom-links feature flag is removed
    <div>
      <TertiaryButton
        data-testid="VideoButton"
        className={cx(css.button, isModalOpen && css.active, disabled && css.disabled)}
        onMouseDown={() => !disabled && setIsModalOpen(true)}
        disabled={disabled}
      >
        <Camera />
      </TertiaryButton>
      <ScheduleCallModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        channel={channel}
        zoomLinks={zoomLinks}
      />
    </div>
  );
};

export default VideoButton;

import React from 'react';
import { toast } from 'react-toastify';
import ModifyCareteamModal from './ModifyCareteamModal';
import css from './Careteam.module.css';
import { ChartElement, EmptyChartElement } from './ChartElements';
import { getProviderByAthenaId } from 'reducers/providers';
import { Body } from 'components/ui/Typography';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { getCareteamRecord } from 'reducers/careteams';
import { getPatientCareteamId, updateCareteam } from 'reducers/patients';
import { getPatientPrimaryProviderId } from 'selectors/profiles';
import { getOutsidePCPIdBySponsorId } from 'reducers/providerGroups';
import { getAllDefaultPrimaryProviderIds } from 'lib/util';
import { Provider } from 'types/tables/providers';

interface CareteamProps {
  modifyModalOpen: boolean;
  closeModifyModal: VoidFunction;
}

interface ProviderDisplayNameProps {
  primaryProviderId: string;
  primaryProvider: Provider;
  sponsorId: string;
}

const ProviderDisplayName = ({
  primaryProviderId,
  sponsorId,
  primaryProvider,
}: ProviderDisplayNameProps) => {
  const outsidePCPId = useParameterizedSelector(getOutsidePCPIdBySponsorId, sponsorId);
  const defaultPCPIds = getAllDefaultPrimaryProviderIds();

  // CloseKnit patients without a usual provider may have either the TELEHEALTH_PROVIDER_ID or
  // CLOSEKNIT_PRIMARY_PROVIDER_ID. So we need to check for both.
  if (defaultPCPIds.includes(primaryProviderId)) {
    return <Body className={css.noUsualProvider}>No usual provider assigned</Body>;
  } else if (primaryProviderId === outsidePCPId) {
    return <Body className={css.noUsualProvider}>Outside PCP</Body>;
  } else if (primaryProvider) {
    return <Body className={css.usualProviderName}>{primaryProvider.displayName}</Body>;
  }
  return <Body className={css.noUsualProvider}>No usual provider assigned</Body>;
};

const Careteam = ({ modifyModalOpen, closeModifyModal }: CareteamProps) => {
  const { id: activePatientId, sponsorId: activePatientSponsorId } = useActivePatient();
  const dispatch = useAppDispatch();

  const careteamId = useParameterizedSelector(getPatientCareteamId, activePatientId);
  const primaryProviderId = useParameterizedSelector(getPatientPrimaryProviderId, activePatientId);

  const patientCareteam = useParameterizedSelector(getCareteamRecord, careteamId);

  const primaryProvider = useParameterizedSelector(getProviderByAthenaId, primaryProviderId ?? '');

  const updateCareteamAndClose = async (newCareteamId: string, newUsualProviderId: number) => {
    closeModifyModal();

    const action = await dispatch(
      updateCareteam({
        patientId: activePatientId,
        careteamId: newCareteamId,
        athenaProviderId: newUsualProviderId,
      }),
    );

    if (updateCareteam.fulfilled.match(action)) {
      toast.success('Careteam and usual provider successfully updated.');
    } else {
      toast.error('Careteam or usual provider could not be updated. Please try again.');
    }
  };

  return (
    <div>
      {patientCareteam.id ? (
        <ChartElement>
          {/* This <div> creates a line break on the flexbox container between careteam and usual provider. */}
          <div>
            <Body className={css.careteamName}>{patientCareteam?.name}</Body>
            <ProviderDisplayName
              primaryProvider={primaryProvider}
              primaryProviderId={primaryProviderId}
              sponsorId={activePatientSponsorId}
            />
          </div>
        </ChartElement>
      ) : (
        <EmptyChartElement>This patient is not assigned to a careteam.</EmptyChartElement>
      )}
      <ModifyCareteamModal
        isOpen={modifyModalOpen}
        closeModal={closeModifyModal}
        updateCareteamAndClose={updateCareteamAndClose}
        currentCareteamId={careteamId}
        primaryProviderId={primaryProvider?.athenaProviderId}
      />
    </div>
  );
};

export default Careteam;

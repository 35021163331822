import { BaseRecord, ForeignKey, PrimaryKey } from 'types/tables/base';
import { Insurance, InsuranceCard, InsuranceLoadState, InsuranceOld } from 'types/athena/insurance';
import { Visit } from 'types/athena/visits';

export enum TriageStatus {
  Cleared = 'cleared',
  Isolate = 'isolate',
  NeverTaken = 'never-taken',
  Incomplete = 'incomplete',
}

type NeverTaken = {
  status: TriageStatus.NeverTaken;
};

type Cleared = {
  status: TriageStatus.Cleared;
};

type Isolate = {
  status: TriageStatus.Isolate;
  returnToWorkDate: string | null;
};

type Incomplete = {
  status: TriageStatus.Incomplete;
};

export type PatientTriageStatus = NeverTaken | Cleared | Isolate | Incomplete;

// We cannot use a real Typescript enum here, so I am using an
// object to just get a few enum-like properties.
// The real type of VaccinationStatusEnum is
// 'Unknown Vaccine',
// 'Not Vaccinated',
// '1st Dose Complete',
// '2nd Dose Complete',
// 'Primary Series Complete',
// 'Primary Series + n', (where n is any integer)
// Because TS cannot express infinite enums in its type system, we're forced to use
// strings to represent VaccinationStatus
export const VaccinationStatus = {
  UnknownVaccine: 'Unknown Vaccine',
  NotVaccinated: 'Not Vaccinated',
  FirstDoseComplete: '1st Dose Complete',
  SecondDoseComplete: '2nd Dose Complete',
  PrimarySeriesComplete: 'Primary Series Complete',
  PrimarySeriesPlus: 'Primary Series',
};

export type Vaccination = {
  manufacturer: string;
  vaccinatedAt: string;
};

type ProfileLocation = {
  id: PrimaryKey;
  combinedStatisticalArea: string;
  createdAt: string;
  latLong: string;
  locationValid: boolean;
  patientId: PrimaryKey;
  state: string;
  timeZoneId: string;
  updatedAt: string;
};

export type SupportedLanguage = 'en' | 'es';

export interface ProfilePayload extends BaseRecord {
  id: PrimaryKey;
  address1: string;
  address2: string;
  appLanguage: SupportedLanguage;
  athenaStatus: string;
  city: string;
  currentTriageStatus: TriageStatus;
  digitalVisit?: any;
  dob: string;
  email: string;
  employerName: string;
  firstName: string;
  hasHadVideoVisits?: boolean;
  img?: string;
  isError?: boolean;
  lastName: string;
  mobilePhone: string;
  patientId: ForeignKey;
  patientLocation: ProfileLocation;
  preferredName?: string;
  primaryInsurance?: Insurance;
  primaryInsuranceOld?: InsuranceOld;
  primaryProviderId?: ForeignKey;
  primaryInsuranceCard?: InsuranceCard;
  primaryInsuranceEligibilityLoadingState: InsuranceLoadState;
  primaryInsuranceCardLoadingState: InsuranceLoadState;
  primaryInsuranceLoadingState: InsuranceLoadState;
  pronouns?: string;
  returnToWorkDate: string | null;
  sex: string;
  sponsorId: PrimaryKey;
  state: string;
  vaccinations: Vaccination[];
  vaccinationStatus: string;
  visits?: Visit[];
  zip: string;
}

type ProfileTransformedFields = 'currentTriageStatus' | 'returnToWorkDate';

export type Profile = Omit<ProfilePayload, ProfileTransformedFields> & {
  currentTriageStatus: PatientTriageStatus;
};

/**
 * Transforms the triage status returned by Grdn into a `PatientTriageStatus`.
 * @param status The triage status returned by Grdn.
 * @param returnToWorkDate The RTW date associated to the status, as returned by Grdn.
 */
const transformTriageStatus = (
  status: TriageStatus,
  returnToWorkDate: string | null,
): PatientTriageStatus => {
  switch (status) {
    case TriageStatus.Cleared:
    case TriageStatus.NeverTaken:
    case TriageStatus.Incomplete:
      return { status };
    case TriageStatus.Isolate:
      return { status, returnToWorkDate };
  }
};

/**
 * Transforms the GRDN payload into the internal model.
 * @param payload The payload received from GRDN.
 */
export const transformPayload = (payload: ProfilePayload): Profile => {
  const { returnToWorkDate, ...keptPayloadFields } = payload;

  return {
    ...keptPayloadFields,
    currentTriageStatus: transformTriageStatus(
      keptPayloadFields.currentTriageStatus,
      returnToWorkDate,
    ),
    primaryInsuranceEligibilityLoadingState: InsuranceLoadState.NotStarted,
    primaryInsuranceCardLoadingState: InsuranceLoadState.NotStarted,
    primaryInsuranceLoadingState: InsuranceLoadState.NotStarted,
  };
};

export interface ErrorState {
  isError: boolean;
}

export type ErrorableProfile = Profile | ErrorState;

export const profileIsError = (profile: ErrorableProfile): profile is ErrorState => {
  return 'isError' in profile && !('id' in profile);
};

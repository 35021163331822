import { ScreenerType } from './screeners';
import { TriageStatus } from 'types/tables/profiles';
import { BaseRecord, ForeignKey } from 'types/tables/base';

export type MessageId = string;

export enum PrimaryMessageType {
  Image = 'message.image',
  Document = 'message.document',
  ScreenerCompleted = 'message.screener_result',
  Text = 'message.text',
  Video = 'message.video',
  VideoRequest = 'message.video_request',
  Deleted = 'message.deleted',
  AfterVisitSummary = 'message.after_visit_summary',
  InsuranceSubmitted = 'message.insurance_submitted',
}

export enum StatusEventType {
  Archived = 'event.archived',
}

export enum AssignmentEventType {
  Assigned = 'event.assigned',
  Unassigned = 'event.unassigned',
}

export type TriageType = 'event.triage_status_changed';
export const TriageType = 'event.triage_status_changed';

export enum ScreenerEventType {
  srha = 'event.patient_initiated_srha_completed',
  welcomeSurvey = 'event.patient_initiated_welcome_survey_completed',
}

export type EventType = AssignmentEventType | StatusEventType | TriageType | ScreenerEventType;

export enum AdminMessageType {
  // Do not create a new custom type unless it needs special rendering (more than markdown)
  // For simple text messages use `admin.text`.
  Appointment = 'message.appointment', // legacy
  AfterHours = 'message.afterhours', // legacy
  CovidVaxEligible = 'message.covid_vax_eligible', // legacy
  Text = 'admin.text',
  Welcome = 'message.welcome',
}

export type MessageType = EventType | PrimaryMessageType | AdminMessageType;

export type SenderId = string;

export interface BaseMessage extends BaseRecord {
  type: MessageType;
  timestamp: number;
  data: Record<string, any>;
}

export interface MessageDraft extends BaseRecord {
  id: ForeignKey; // channel.id
  draft: string;
}

export interface BasePrimaryMessage extends BaseMessage {
  type: PrimaryMessageType;
  senderId: SenderId;
}

export interface BaseAdminMessage extends BaseMessage {
  type: AdminMessageType;
}

export interface VideoPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.Video;
  data: {
    id: number;
    status: string;
    startUrl: string;
    startTime?: string;
  };
}

export interface ImagePrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.Image;
  data: {
    imageId: string;
  };
}

export interface DocumentPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.Document;
  data: {
    documentId: string;
  };
}

export interface TextPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.Text;
  senderId: SenderId;
  data: {
    text: string;
  };
}

export interface VideoVisitRequestMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.VideoRequest;
  data: {
    videoVisitId: string;
  };
}

export interface DeletedPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.Deleted;
  senderId: SenderId;
  data: {
    deletedBy: string;
  };
}

export interface AfterVisitSummaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.AfterVisitSummary;
  data: {
    visit: Visit;
  };
}

export interface WelcomeMessage extends BaseAdminMessage {
  type: AdminMessageType.Welcome;
  data: {
    text: string;
  };
}

export interface Visit {
  id: number;
  patientAppointmentTypeName: string;
  departmentId: number;
  provider: VisitProvider;
  location: VisitLocation;
  startTime: string;
  timeZone: string;
  duration: number;
  afterVisitSummary?: AfterVisitSummary;
}

export interface VisitLocation {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  instructions?: string;
}

export interface VisitProvider {
  id: number;
  name: string;
  headshotUrl: string;
}

export interface AfterVisitSummary {
  providerId: number;
  content: string;
}

export type PrimaryMessage =
  | VideoPrimaryMessage
  | ImagePrimaryMessage
  | DocumentPrimaryMessage
  | TextPrimaryMessage
  | DeletedPrimaryMessage
  | ScreenerCompletedPrimaryMessage
  | VideoVisitRequestMessage
  | AfterVisitSummaryMessage;

export interface ScreenerCompletedPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.ScreenerCompleted;
  data: {
    screenerName: ScreenerType;
    screenerResponseId: string;
    covidScreenerId: string;
    score: number;
  };
}

export interface AppointmentAdminMessage extends BaseAdminMessage {
  // legacy
  type: AdminMessageType;
  data: {
    text: string;
  };
}

export interface AfterhoursAdminMessage extends BaseAdminMessage {
  // legacy
  type: AdminMessageType;
  data: {
    text: string;
  };
}

export interface CovidVaxEligibleMessage extends BaseAdminMessage {
  // legacy
  type: AdminMessageType;
  data: {
    text: string;
  };
}

export interface InsuranceSubmittedPrimaryMessage extends BasePrimaryMessage {
  type: PrimaryMessageType.InsuranceSubmitted;
}

export interface TextAdminMessage extends BaseAdminMessage {
  type: AdminMessageType.Text;
  data: {
    text: string;
    subtype?: string;
  };
}

export interface BaseEventMessage extends BaseMessage {
  type: EventType;
}

export interface AssignmentEventMessage extends BaseEventMessage {
  type: AssignmentEventType;
  data: {
    assigneeIds: string[];
    automatic: boolean;
    by: string;
  };
}

export interface ScreenerEventMessage extends BaseEventMessage {
  type: ScreenerEventType;
  data: {
    by: string;
    score: number;
    screenerName?: string;
  };
}

export interface StatusEventMessage extends BaseEventMessage {
  type: StatusEventType;
  data: {
    by: string;
  };
}

// A message issued by Grdn following a change in the patient's triage status.
export interface TriageEventMessage extends BaseEventMessage {
  type: TriageType;
  data: {
    // The actor who's responsible for the triage status change.
    by: string;

    // The triage status in which the patient was prior to the change.
    previousStatus: TriageStatus;

    // The RTW date associated with the previous status.
    // - Can be `null` or a `YYYY-MM-DD` date if previous status is `TriageStatus.Isolate`.
    // - Is always `null` otherwise.
    previousReturnToWorkDate: string | null;

    // The triage status in which the patient is after the change.
    currentStatus: TriageStatus;

    // The RTW date associated with the current status.
    // - Can be `null` or a `YYYY-MM-DD` date if previous status is `TriageStatus.Isolate`.
    // - Is always `null` otherwise.
    currentReturnToWorkDate: string | null;
  };
}

export type EventMessage =
  | AssignmentEventMessage
  | ScreenerEventMessage
  | StatusEventMessage
  | TriageEventMessage;

export type Message =
  | PrimaryMessage
  | EventMessage
  | AppointmentAdminMessage // legacy
  | AfterhoursAdminMessage // legacy
  | CovidVaxEligibleMessage // legacy
  | TextAdminMessage
  | InsuranceSubmittedPrimaryMessage
  | WelcomeMessage;

export enum PendStatus {
  Pending = 'pending',
  Failed = 'failed',
}

export interface Pendable {
  pendStatus: PendStatus;
}

export type PendingMessage = TextPrimaryMessage & Pendable;

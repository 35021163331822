import React from 'react';
import { useParameterizedSelector } from 'lib/hooks';
import { getPatientAppLanguage } from 'selectors/profiles';
import { Profile, SupportedLanguage } from 'types/tables/profiles';
import css from 'components/features/PatientPills/AppLanguage/AppLanguageLabel.module.css';

export const getAppLanguageLabel = (lang: SupportedLanguage | undefined) => {
  switch (lang) {
    case 'es':
      return 'Spanish Speaker';
    default:
      return undefined;
  }
};
export const AppLanguageLabel = ({ profile }: { profile: Profile }) => {
  const appLanguage = useParameterizedSelector(getPatientAppLanguage, profile.patientId);

  const labelText = getAppLanguageLabel(appLanguage);
  return labelText ? (
    <div className={css.languageContainer} data-testid={'patient-app-language'}>
      {labelText}
    </div>
  ) : null;
};

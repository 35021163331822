import React from 'react';
import { EdenColors } from 'types/colors';

const CompletedIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7071 18.2929C33.0976 18.6834 33.0976 19.3166 32.7071 19.7071L21.7071 30.7071C21.3166 31.0976 20.6834 31.0976 20.2929 30.7071L15.2929 25.7071C14.9024 25.3166 14.9024 24.6834 15.2929 24.2929C15.6834 23.9024 16.3166 23.9024 16.7071 24.2929L21 28.5858L31.2929 18.2929C31.6834 17.9024 32.3166 17.9024 32.7071 18.2929Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default CompletedIcon;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import { Dispatch } from '../store';
import { formatPreferredFullNameFor, isDueToday, isOverdue } from 'lib/util';
import { Store } from 'types/redux';
import { PrimaryKey } from 'types/tables/base';
import {
  CompleteTicklerPayload,
  DeleteTicklerPayload,
  FetchNextStepsPayload,
  GetPatientTicklersPayload,
  PatientAccountInfo,
  PatientAccountInfoPayload,
  ResolveNextStepPayload,
  Tickler,
} from 'types/grdn';
import { LoadingState, Patient, PatientId } from 'types/tables/patients';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { createTickler } from 'reducers/ticklers';
import type {
  DraftNextStep,
  NextStep,
  NextStepResolution,
} from 'components/features/CarePlan/CarePlanTypes';
import { fixupAthenaDateTime } from 'lib/formatDateTime';
import { dispositionOf } from 'components/features/CarePlan/utils/dispositionOf';

const prefix = 'patient';

export const patientsAdapter = createEntityAdapter<Patient>({});

// Selectors
export const patientsSelector = patientsAdapter.getSelectors<Store>((state) => state.patients);

export const getPatients = patientsSelector.selectAll;
export const getPatientIds = patientsSelector.selectIds;
export const getPatientsEntities = patientsSelector.selectEntities;
export const getPatientById = patientsSelector.selectById;

export const getPatientRecord = createSelector(
  (_, patientId: PrimaryKey) => patientId,
  getPatientsEntities,
  (patientId, patients) => patients[patientId] || ({} as Patient),
);

export const getPatientByMrn = createSelector(
  (_, mrn: number) => mrn,
  getPatients,
  (mrn, patients) =>
    Object.values(patients).find(
      (patient: Patient) =>
        patient.mrn === mrn || (patient.secondaryMrns && patient.secondaryMrns.includes(mrn)),
    ),
);

export const getPatientAssignedProviderIds = createSelector(
  getPatientRecord,
  (patient) => patient?.assigned || [],
);

export const getPatientCurrentState = createSelector(
  getPatientRecord,
  (patient) => patient?.location?.state || undefined,
);

export const isPatientDeactivated = createSelector(
  getPatientRecord,
  (patient) => patient?.athenaStatus !== undefined && patient?.athenaStatus !== 'active',
);

export const getPatientCareteamId = createSelector(
  getPatientRecord,
  (patient) => patient?.careteamId || '',
);

export const getPatientMRN = createSelector(getPatientRecord, (patient) => patient?.mrn);

export const getPatientName = createSelector(
  getPatientRecord,
  (patient) => formatPreferredFullNameFor(patient) ?? '',
);

export const getPatientSendbirdUserId = createSelector(
  getPatientRecord,
  (patient) => patient.sendbirdUserId || undefined,
);

export const getPatientTicklers = createSelector(getPatientRecord, (patient) => {
  return Object.keys(patient).length > 0 ? patient.ticklers : null;
});

export const getPatientTicklerLoadingState = createSelector(
  getPatientRecord,
  (patient) => patient?.ticklerLoadingState,
);

export const getPatientTicklersFetchedAt = createSelector(
  getPatientRecord,
  (patient) => patient?.ticklersFetchedAt,
);

export const getPatientTicklersAnyOverdue = createSelector(getPatientTicklers, (ticklers) => {
  return ticklers?.some((tickler) => {
    const dueDateParsed = DateTime.fromFormat(tickler.dueDate, 'yyyy-MM-dd').startOf('day');

    return isOverdue(dueDateParsed);
  });
});

export const getPatientTicklersAnyDueToday = createSelector(getPatientTicklers, (ticklers) => {
  return ticklers?.some((tickler) => {
    const dueDateParsed = DateTime.fromFormat(tickler.dueDate, 'yyyy-MM-dd').startOf('day');

    return isDueToday(dueDateParsed);
  });
});

export const getPatientTicklersAnyDueWithinWeek = createSelector(getPatientTicklers, (ticklers) => {
  return ticklers?.some((tickler) => {
    const dueDateParsed = DateTime.fromFormat(tickler.dueDate, 'yyyy-MM-dd').startOf('day');
    const daysDueFromNow = dueDateParsed.diff(DateTime.now(), ['days']).toObject().days;

    return daysDueFromNow && daysDueFromNow < 7 && daysDueFromNow > 0;
  });
});

export const getPatientCompletedNextSteps = createSelector(getPatientRecord, (patient) => {
  return Object.keys(patient).length > 0 ? patient.completedNextSteps : null;
});

export const getPatientActiveNextSteps = createSelector(getPatientRecord, (patient) => {
  return Object.keys(patient).length > 0 ? patient.activeNextSteps : null;
});

export const getPatientNextStepsAnyOverdue = createSelector(
  getPatientActiveNextSteps,
  (activeNextSteps) => {
    return activeNextSteps?.some((nextStep) => {
      const dueDateParsed = DateTime.fromISO(nextStep.dueDate).startOf('day');
      return isOverdue(dueDateParsed);
    });
  },
);

export const getPatientNextStepsAnyDueToday = createSelector(
  getPatientActiveNextSteps,
  (activeNextSteps) => {
    return activeNextSteps?.some((nextStep) => {
      const dueDateParsed = DateTime.fromISO(nextStep.dueDate).startOf('day');
      return isDueToday(dueDateParsed);
    });
  },
);

export const getPatientNextStepsLoadingState = createSelector(
  getPatientRecord,
  (patient) => patient?.nextStepsLoadingState,
);

export const getPatientNextStepsFetchedAt = createSelector(
  getPatientRecord,
  (patient) => patient?.nextStepsFetchedAt,
);

export const getPatientNames = createSelector(getPatients, (patients) =>
  patients.reduce(
    (patientNames: Dictionary<string>, patient: Patient) => ({
      ...patientNames,
      [patient.id]: formatPreferredFullNameFor(patient),
    }),
    {},
  ),
);

export const getPatientCopilotSuggestion = createSelector(
  getPatientRecord,
  (patient) => patient?.copilotSuggestion,
);

export const getPatientCopilotLoadingState = createSelector(
  getPatientRecord,
  (patient) => patient?.copilotLoadingState,
);

export const getPatientCopilotCanRegenerate = createSelector(
  getPatientRecord,
  (patient) => patient?.copilotCanRegenerate,
);

export const getPatientCopilotError = createSelector(
  getPatientRecord,
  (patient) => patient?.copilotError,
);

export const getPatientCopilotMaximized = createSelector(
  getPatientRecord,
  (patient) => patient?.copilotMaximized,
);

export const getPatientAppointmentId = createSelector(
  getPatientRecord,
  (patient) => patient?.appointment?.appointmentId,
);

export const getPatientVisitAppointmentTypeId = createSelector(
  getPatientRecord,
  (patient) => patient.appointment?.appointmentTypeId,
);

// Thunks
export interface PatientAccountInfoArgs extends PatientAccountInfo {
  patientId: string;
}

export enum UpdatePatientAccountInfoRejectionType {
  Conflict = 'Unable to update patient info. Email or phone number is already in use by another account.',
  Generic = 'Unable to update patient info.',
}

export interface UpdatePatientAccountInfoRejectValue {
  rejectionType: UpdatePatientAccountInfoRejectionType;
}

export const updatePatientAccountInfo = createAsyncThunk<
  PatientAccountInfoPayload,
  Partial<PatientAccountInfoArgs>,
  {
    rejectValue: UpdatePatientAccountInfoRejectValue;
  }
>(
  `${prefix}/updatePatientAccountInfo`,
  async (arg: Partial<PatientAccountInfoArgs>, { rejectWithValue }) => {
    try {
      const payload = await GrdnApi.updatePatientAccountInfo(arg);
      if (isSuccessResponse(payload)) return payload.data;
    } catch (error) {
      if (error?.data?.data?.type === 'credential-already-in-use') {
        return rejectWithValue({
          rejectionType: UpdatePatientAccountInfoRejectionType.Conflict,
        });
      }
    }
    return rejectWithValue({
      rejectionType: UpdatePatientAccountInfoRejectionType.Generic,
    });
  },
);

const loadPatientByMrnRejectionType = 'Failed to find patient by MRN';

export const loadPatientByMrn = createAsyncThunk(
  `${prefix}/loadPatientByMrn`,
  async ({ mrn }: { mrn: number }, { getState, rejectWithValue }) => {
    try {
      const patient = getPatientByMrn(getState() as Store, mrn);
      if (patient) {
        return patient;
      }
      const patientResponse = await GrdnApi.search({ mrn });
      if (isSuccessResponse(patientResponse)) {
        return patientResponse.data as Patient;
      } else {
        return rejectWithValue({ rejectionType: loadPatientByMrnRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: loadPatientByMrnRejectionType });
    }
  },
);

export interface PatientCareteamArgs {
  patientId: string;
  careteamId: string;
  athenaProviderId?: number;
}

export const updateCareteamRejectionType =
  'Careteam or usual provider could not be updated. Please try again.';

export const updateCareteam = createAsyncThunk<
  null,
  PatientCareteamArgs,
  { rejectValue: { rejectionType: string } }
>(
  `${prefix}/updateCareteam`,
  async ({ patientId, careteamId, athenaProviderId }: PatientCareteamArgs, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.updatePatientCareteam(patientId, careteamId, athenaProviderId);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: updateCareteamRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: updateCareteamRejectionType });
    }
  },
);

export interface FetchPatientTicklersArgs {
  patientId: PrimaryKey;
}

export enum fetchPatientTicklersRejectionType {
  Generic = 'Unable to fetch patient ticklers.',
}

export const fetchPatientTicklers = createAsyncThunk<
  GetPatientTicklersPayload,
  FetchPatientTicklersArgs,
  {
    rejectValue: { rejectionType: fetchPatientTicklersRejectionType };
  }
>(`${prefix}/fetchPatientTicklers`, async ({ patientId }, { rejectWithValue }) => {
  try {
    const payload = await GrdnApi.getPatientTicklers(patientId);
    if (isSuccessResponse(payload)) return payload.data;
  } catch (error) {
    return rejectWithValue({
      rejectionType: fetchPatientTicklersRejectionType.Generic,
    });
  }

  return rejectWithValue({
    rejectionType: fetchPatientTicklersRejectionType.Generic,
  });
});

const sortTicklersByDueDateAscending = (ticklers) => {
  return ticklers.sort((first, second) => {
    if (first.dueDate > second.dueDate) {
      return 1;
    } else if (first.dueDate < second.dueDate) {
      return -1;
    } else {
      return 0;
    }
  });
};

export interface CompleteTicklerArgs {
  patientId: PrimaryKey;
  ticklerAthenaId: number;
  note: string;
}

export enum CompletePatientTicklerRejectionType {
  Generic = 'Unable to complete patient tickler.',
}

export const completeTickler = createAsyncThunk<
  CompleteTicklerPayload,
  CompleteTicklerArgs,
  {
    rejectValue: { rejectionType: CompletePatientTicklerRejectionType };
  }
>(
  `${prefix}/completeTickler`,
  async ({ patientId, ticklerAthenaId, note }, { rejectWithValue }) => {
    try {
      const payload = await GrdnApi.completeTickler({ patientId, ticklerAthenaId, note });
      if (isSuccessResponse(payload)) return payload.data;
    } catch (error) {
      return rejectWithValue({
        rejectionType: CompletePatientTicklerRejectionType.Generic,
      });
    }

    return rejectWithValue({
      rejectionType: CompletePatientTicklerRejectionType.Generic,
    });
  },
);

export interface DeleteTicklerArgs {
  patientId: PrimaryKey;
  ticklerAthenaId: number;
  note: string;
}

export enum DeletePatientTicklerRejectionType {
  Generic = 'Unable to delete patient tickler.',
}

export const deleteTickler = createAsyncThunk<
  DeleteTicklerPayload,
  DeleteTicklerArgs,
  {
    rejectValue: { rejectionType: DeletePatientTicklerRejectionType };
  }
>(`${prefix}/deleteTickler`, async ({ patientId, ticklerAthenaId, note }, { rejectWithValue }) => {
  try {
    const payload = await GrdnApi.deleteTickler({ patientId, ticklerAthenaId, note });
    if (isSuccessResponse(payload)) return payload.data;
  } catch (error) {
    return rejectWithValue({
      rejectionType: DeletePatientTicklerRejectionType.Generic,
    });
  }

  return rejectWithValue({
    rejectionType: DeletePatientTicklerRejectionType.Generic,
  });
});

export interface UpdateTicklerArgs {
  patientId: PrimaryKey;
  ticklerAthenaId: number;
  athenaProviderId: number;
  dueDate: string;
  note: string;
}

export enum UpdatePatientTicklerRejectionType {
  Conflict = 'This tickler has already been deleted',
  Generic = 'Unable to update tickler. Try again.',
}

export const updateTickler = createAsyncThunk<
  Tickler,
  UpdateTicklerArgs,
  {
    rejectValue: { rejectionType: UpdatePatientTicklerRejectionType };
  }
>(
  `${prefix}/updateTickler`,
  async ({ patientId, ticklerAthenaId, athenaProviderId, dueDate, note }, { rejectWithValue }) => {
    try {
      const payload = await GrdnApi.updateTickler(
        patientId,
        ticklerAthenaId,
        athenaProviderId,
        dueDate,
        note,
      );
      if (isSuccessResponse(payload)) {
        return payload.data;
      }
    } catch (error) {
      return rejectWithValue({
        rejectionType:
          error.status === 409
            ? UpdatePatientTicklerRejectionType.Conflict
            : UpdatePatientTicklerRejectionType.Generic,
      });
    }

    return rejectWithValue({
      rejectionType: UpdatePatientTicklerRejectionType.Generic,
    });
  },
);

const sortNextSteps = (originalSteps: NextStep[]) => {
  const nextSteps = originalSteps.map((step) => ({
    ...step,
    dueDate: fixupAthenaDateTime(step.dueDate),
  }));

  return {
    active: nextSteps
      .filter((step) => !dispositionOf(step).isComplete)
      .sort((a, b) => (a.dueDate || '').localeCompare(b.dueDate || '')),
    completed: nextSteps
      .filter((step) => dispositionOf(step).isComplete)
      .sort(
        (a, b) => -(a.response?.responsedAt || '').localeCompare(b.response?.responsedAt || ''),
      ),
  };
};

export interface FetchNextStepsArgs {
  patientId: PrimaryKey;
}

export enum FetchNextStepsRejectionType {
  Generic = 'Unable to fetch patient next steps.',
}

export const fetchNextSteps = createAsyncThunk<
  FetchNextStepsPayload,
  FetchNextStepsArgs,
  {
    rejectValue: { rejectionType: FetchNextStepsRejectionType };
  }
>(`${prefix}/fetchNextSteps`, async ({ patientId }, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.getPatientNextSteps(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchNextStepsRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchNextStepsRejectionType.Generic });
  }
});

const createNextStepRejectionType = 'Failed to create next step';
const updateNextStepRejectionType = 'Failed to update next step';
const resolveNextStepRejectionType = 'Failed to resolve next step';

export const createNextStep = createAsyncThunk<
  NextStep,
  DraftNextStep,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/createNextStep`, async (providerNextStep, { rejectWithValue }) => {
  try {
    const response = await GrdnApi.createNextStep(providerNextStep);
    return isSuccessResponse(response)
      ? response.data
      : rejectWithValue({ rejectionType: createNextStepRejectionType });
  } catch (e) {
    return rejectWithValue({ rejectionType: createNextStepRejectionType });
  }
});

export const updateNextStep = createAsyncThunk<
  NextStep,
  DraftNextStep,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/updateNextStep`, async (providerNextStep, { rejectWithValue }) => {
  try {
    const response = await GrdnApi.updateNextStep(providerNextStep);
    return isSuccessResponse(response)
      ? response.data
      : rejectWithValue({ rejectionType: updateNextStepRejectionType });
  } catch (e) {
    return rejectWithValue({ rejectionType: updateNextStepRejectionType });
  }
});

export interface ResolveNextStepArgs {
  patientId: string;
  resolution: NextStepResolution;
}

export const resolveNextStep = createAsyncThunk<
  ResolveNextStepPayload,
  ResolveNextStepArgs,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/resolveNextStep`, async ({ resolution }, { rejectWithValue, dispatch }) => {
  try {
    const response = await GrdnApi.resolveNextStep(resolution);
    if (isSuccessResponse(response)) {
      //
      // TODO: See the APP-7772 tech debut ticket for information on why this is here and what
      // it will take to remove it. tldr: the resolve endpoint does not return the changed next
      // step so we need to refetch the whole list to update the display
      //
      dispatch(fetchNextSteps({ patientId: resolution.patientId }));
      return response.data;
    } else {
      return rejectWithValue({ rejectionType: resolveNextStepRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: resolveNextStepRejectionType });
  }
});

const suggestionGenericRejectionType = 'Eve is having trouble';
const suggestionContentFilterRejectionType = 'OpenAI error - content violation in chat';

export const getCopilotSuggestion = createAsyncThunk(
  `${prefix}/getCopilotSuggestion`,
  async (patientId: PatientId, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getCopilotSuggestion(patientId);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: suggestionGenericRejectionType });
      }
    } catch (e) {
      if (e.data?.data['azure-openai-error'] === 'content_filter') {
        return rejectWithValue({ rejectionType: suggestionContentFilterRejectionType });
      } else {
        return rejectWithValue({ rejectionType: suggestionGenericRejectionType });
      }
    }
  },
);

export const removeCopilotSuggestion = (patientId: PatientId, dispatch: Dispatch) => {
  dispatch(
    patients.actions.updateOne({
      id: patientId,
      changes: {
        copilotSuggestion: undefined,
        copilotLoadingState: undefined,
        copilotMaximized: undefined,
      },
    }),
  );
};

export const setCopilotMaximizedState = (
  patientId: PatientId,
  currentState: boolean,
  dispatch: Dispatch,
) => {
  dispatch(
    patients.actions.updateOne({
      id: patientId,
      changes: {
        copilotMaximized: !currentState,
      },
    }),
  );
};

export interface StartPatientDigitalVisitArgs {
  patientId: PrimaryKey;
  appointmentTypeId: number;
}

export enum StartPatientDigitalVisitRejectionType {
  Generic = 'Failed to start digital visit. Please wait a moment and try again.',
  CheckInRequirements = "Appointment was created, but the patient's profile did not meet requirements for check-in.",
}

export const startPatientDigitalVisit = createAsyncThunk<
  void,
  StartPatientDigitalVisitArgs,
  {
    rejectValue: { rejectionType: StartPatientDigitalVisitRejectionType };
  }
>(`${prefix}/startDigitalVisit`, async ({ patientId, appointmentTypeId }, { rejectWithValue }) => {
  try {
    const payload = await GrdnApi.newDigitalVisit(patientId, appointmentTypeId);
    if (isSuccessResponse(payload)) {
      return payload.data;
    } else {
      return rejectWithValue({ rejectionType: StartPatientDigitalVisitRejectionType.Generic });
    }
  } catch (e) {
    const { type } = e;
    if (type === 'check-in') {
      return rejectWithValue({
        rejectionType: StartPatientDigitalVisitRejectionType.CheckInRequirements,
      });
    }
    return rejectWithValue({ rejectionType: StartPatientDigitalVisitRejectionType.Generic });
  }
});

export interface GetPatientDigitalVisitArgs {
  patientId: PrimaryKey;
}

export enum GetPatientDigitalVisitRejectionType {
  Generic = 'Failed to get digital visit.',
}

export const getPatientDigitalVisit = createAsyncThunk<
  any,
  GetPatientDigitalVisitArgs,
  {
    rejectValue: { rejectionType: GetPatientDigitalVisitRejectionType };
  }
>(
  `${prefix}/getDigitalVisit`,
  async ({ patientId }: GetPatientDigitalVisitArgs, { rejectWithValue }) => {
    try {
      const payload = await GrdnApi.getDigitalVisit(patientId);
      if (isSuccessResponse(payload)) {
        return payload.data;
      } else {
        return rejectWithValue({ rejectionType: GetPatientDigitalVisitRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: GetPatientDigitalVisitRejectionType.Generic });
    }
  },
);

export interface CheckoutPatientDigitalVisitArgs {
  appointmentId: string;
  patientId: PrimaryKey;
}

export enum CheckoutPatientDigitalVisitRejectionType {
  Generic = 'Failed to checkout digital visit.',
}

export const checkoutPatientDigitalVisit = createAsyncThunk<
  any,
  CheckoutPatientDigitalVisitArgs,
  {
    rejectValue: { rejectionType: CheckoutPatientDigitalVisitRejectionType };
  }
>(
  `${prefix}/checkoutDigitalVisit`,
  async ({ appointmentId, patientId }: CheckoutPatientDigitalVisitArgs, { rejectWithValue }) => {
    try {
      const payload = await GrdnApi.checkoutDigitalVisit(appointmentId, patientId);
      if (isSuccessResponse(payload)) {
        return payload.data;
      } else {
        return rejectWithValue({ rejectionType: CheckoutPatientDigitalVisitRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: CheckoutPatientDigitalVisitRejectionType.Generic });
    }
  },
);

// Reducers
export const patients = createSlice({
  name: prefix,
  initialState: patientsAdapter.getInitialState(),
  reducers: {
    updateOne: patientsAdapter.updateOne,
    upsertOne: patientsAdapter.upsertOne,
    upsertMany: patientsAdapter.upsertMany,
  },
  extraReducers: (builder) => {
    builder.addCase(updatePatientAccountInfo.fulfilled, (state, { payload }) => {
      const {
        patientId,
        postgres: { firstName, lastName },
      } = payload;
      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: { firstName: firstName, lastName: lastName },
      });
    });

    builder.addCase(updateCareteam.fulfilled, (state, { meta }) => {
      const { patientId, careteamId } = meta.arg;
      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: { careteamId },
      });
    });

    builder.addCase(fetchPatientTicklers.pending, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklerLoadingState: LoadingState.Loading,
        },
      });
    });

    builder.addCase(fetchPatientTicklers.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const sortedTicklers = sortTicklersByDueDateAscending(payload.ticklers);
      const loadingState =
        payload.failures.length === 0 ? LoadingState.Success : LoadingState.PartialFailure;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklers: sortedTicklers,
          ticklerLoadingState: loadingState,
          ticklersFetchedAt: DateTime.now().toFormat('hh:mm a'),
        },
      });
    });

    builder.addCase(fetchPatientTicklers.rejected, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklerLoadingState: LoadingState.Failure,
        },
      });
    });

    builder.addCase(completeTickler.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const currentTicklers = state.entities[patientId]?.ticklers || [];
      const updatedTicklers = currentTicklers.filter((t) => {
        return t.ticklerAthenaId !== payload.ticklerAthenaId;
      });

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklers: sortTicklersByDueDateAscending(updatedTicklers),
        },
      });
    });

    builder.addCase(createTickler.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const currentTicklers = state.entities[patientId]?.ticklers || [];
      const updatedTicklers = [...currentTicklers, payload];

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklers: sortTicklersByDueDateAscending(updatedTicklers),
        },
      });
    });

    builder.addCase(deleteTickler.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const currentTicklers = state.entities[patientId]?.ticklers || [];
      const updatedTicklers = currentTicklers.filter((t) => {
        return t.ticklerAthenaId !== payload.ticklerAthenaId;
      });

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklers: sortTicklersByDueDateAscending(updatedTicklers),
        },
      });
    });

    builder.addCase(updateTickler.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const currentTicklers = state.entities[patientId]?.ticklers || [];
      const unalteredTicklers = currentTicklers.filter((t) => {
        return t.ticklerAthenaId !== payload.ticklerAthenaId;
      });
      const updatedTicklers = [...unalteredTicklers, payload];

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          ticklers: sortTicklersByDueDateAscending(updatedTicklers),
        },
      });
    });

    builder.addCase(fetchNextSteps.pending, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Loading,
        },
      });
    });

    builder.addCase(fetchNextSteps.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;
      const { active, completed } = sortNextSteps(payload.nextSteps);

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          activeNextSteps: active,
          completedNextSteps: completed,
          nextStepsLoadingState: LoadingState.Success,
          nextStepsFetchedAt: DateTime.now().toFormat('hh:mm a'),
        },
      });
    });

    builder.addCase(fetchNextSteps.rejected, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Failure,
        },
      });
    });

    builder.addCase(createNextStep.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Success,
          activeNextSteps: sortNextSteps([
            ...(state.entities[patientId]?.activeNextSteps || []),
            payload,
          ]).active,
        },
      });
    });

    builder.addCase(createNextStep.pending, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Loading,
        },
      });
    });

    builder.addCase(createNextStep.rejected, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Failure,
        },
      });
    });

    builder.addCase(updateNextStep.fulfilled, (state, { meta, payload }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Success,
          activeNextSteps: sortNextSteps([
            ...(state.entities[patientId]?.activeNextSteps || []).filter(
              (s) => s.id !== payload.id,
            ),
            payload,
          ]).active,
        },
      });
    });

    builder.addCase(updateNextStep.pending, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Loading,
        },
      });
    });

    builder.addCase(updateNextStep.rejected, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Failure,
        },
      });
    });

    builder.addCase(resolveNextStep.rejected, (state, { meta }) => {
      const { patientId } = meta.arg;

      patientsAdapter.updateOne(state, {
        id: patientId,
        changes: {
          nextStepsLoadingState: LoadingState.Failure,
        },
      });
    });

    builder.addCase(loadPatientByMrn.fulfilled, (state, { payload: patient }) => {
      patientsAdapter.upsertOne(state, patient as Patient);
    });

    builder.addCase(getCopilotSuggestion.fulfilled, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          copilotSuggestion: action.payload.suggestion,
          copilotLoadingState: LoadingState.Success,
          copilotCanRegenerate: false,
          copilotMaximized: true,
        },
      });
    });

    builder.addCase(
      getCopilotSuggestion.rejected,
      (state, action: PayloadAction<any, string> & { meta: { arg: string } }) => {
        patientsAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            copilotLoadingState: LoadingState.Failure,
            copilotError: action.payload?.rejectionType,
          },
        });
      },
    );

    builder.addCase(getCopilotSuggestion.pending, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          copilotLoadingState: LoadingState.Loading,
          copilotError: undefined,
        },
      });
    });

    builder.addCase(startPatientDigitalVisit.fulfilled, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isStartingDigitalVisit: false,
        },
      });
    });

    builder.addCase(startPatientDigitalVisit.rejected, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isStartingDigitalVisit: false,
        },
      });
    });

    builder.addCase(startPatientDigitalVisit.pending, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isStartingDigitalVisit: true,
        },
      });
    });

    builder.addCase(getPatientDigitalVisit.fulfilled, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          appointment: action.payload,
          isGettingDigitalVisit: false,
        },
      });
    });

    builder.addCase(getPatientDigitalVisit.rejected, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isGettingDigitalVisit: false,
        },
      });
    });

    builder.addCase(getPatientDigitalVisit.pending, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isGettingDigitalVisit: true,
        },
      });
    });

    builder.addCase(checkoutPatientDigitalVisit.fulfilled, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isCheckingOutDigitalVisit: false,
        },
      });
    });

    builder.addCase(checkoutPatientDigitalVisit.rejected, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isCheckingOutDigitalVisit: false,
        },
      });
    });

    builder.addCase(checkoutPatientDigitalVisit.pending, (state, action) => {
      patientsAdapter.updateOne(state, {
        id: action.meta.arg.patientId,
        changes: {
          isCheckingOutDigitalVisit: true,
        },
      });
    });
  },
});

export default patients.reducer;

import { DateTime } from 'luxon';

const forcedDepartments = [2, 8]; // video and virtual visits

export const edenGetTimeZone = () => 'America/New_York';

//
// Is the passed string in Athena date format ("mm/dd/yyyy")
//
export const isAthenaFormat = (when: string) => {
  return !!when.match(/(\d\d?)\/(\d\d?)\/(\d\d\d\d)/);
};

const format = (when: string, pattern: string, timeZone?: string) => {
  return isAthenaFormat(when)
    ? DateTime.fromFormat(when, 'L/d/yyyy', timeZone ? { zone: timeZone } : undefined).toFormat(
        pattern,
      )
    : DateTime.fromISO(when, timeZone ? { zone: timeZone } : undefined).toFormat(pattern);
};

export function fixupAthenaDateTime(original: string) {
  return original && isAthenaFormat(original)
    ? DateTime.fromFormat(original, 'L/d/yyyy').toISO()
    : original;
}

// Usage:
//    formatDateTime(when).as(pattern).in(timeZone)
//    formatDateTime(when).as(pattern).inLocalTimezone()
//    formatDateTime(when).as(pattern).inDepartmentTimeZone(departmentId, defaultTimeZone)
//
// params:
//  when, a string in either ISO format, or the special Athena date format MM/DD/YYYY
//  pattern, a string specifying the pattern for the output using Moment's pattern specification: see https://momentjs.com/docs/#/displaying/format/
//  timeZone, a string specify the timezone, e.g. America/New_York
//  departmentId, a number specifying the department id; used to force video and virtual visits to use the timezone of the user rather than the supplied time zone (usually from a visit)
//
export const formatDateTime = (iso: string) => ({
  as: (pattern: string) => ({
    in: (timeZone: string) => format(iso, pattern, timeZone),
    inLocalTimeZone: () => format(iso, pattern),
    inDepartmentTimeZone: (departmentId: number, defaultTimeZone: string) =>
      format(
        iso,
        pattern,
        forcedDepartments.includes(departmentId) ? edenGetTimeZone() : defaultTimeZone,
      ),
  }),
});

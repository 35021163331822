import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useAppDispatch } from 'lib/hooks';
import { isSuccessResponse, SuccessResponse } from 'types/api';
import { ChannelPayload, SideBarPayload } from 'types/grdn';
import {
  Channel,
  ChannelType,
  EventChannel,
  InternalChannel,
  PrimaryChannel,
} from 'types/tables/channels';
import * as Sendbird from 'lib/sendbird';
import * as GrdnApi from 'lib/grdn';
import { getIsOnline } from 'legacy/selectors/AppSelector';
import poll from 'lib/poll';
import { channels } from 'reducers/channels';
import { patients } from 'reducers/patients';
import { State } from 'store';

export const useSideBarPoll = () => {
  const dispatch = useAppDispatch();
  const isOnline = useSelector(getIsOnline);

  useEffect(() => {
    if (isOnline) {
      dispatch(startPollSideBar());
    }
    return () => {
      dispatch(stopPollSidebar());
    };
  }, [dispatch, isOnline]);
};

export const transformSideBarChannel = (
  channel: ChannelPayload,
): Exclude<Channel, 'messages' | 'pendingMessages'> => {
  const base = {
    ...channel,
  };
  switch (channel.channelType) {
    case ChannelType.Primary:
      return {
        ...base,
        lastMessage: channel.lastMessage ? Sendbird.parseMessage(channel.lastMessage) : undefined,
      } as PrimaryChannel;
    case ChannelType.Event:
      return base as EventChannel;
    case ChannelType.Internal:
      return base as InternalChannel;
  }
};

export const processSideBarPayload = createAsyncThunk<
  number,
  Pick<SuccessResponse<SideBarPayload>, 'data'>
>('deprecated/sidebar/payload/process', (res, { dispatch }) => {
  if (!res.data) return NaN;
  const transformedChannels = res.data.channels.map(transformSideBarChannel);
  dispatch(channels.actions.upsertMany(transformedChannels));
  dispatch(patients.actions.upsertMany(res.data.patients));
  if (!res.data.patients.some((patient) => !patient.firstName || !patient.lastName)) {
    return res.data.lastUpdatedAtInMs;
  }
  return NaN;
});

export const initializeSideBar = createAsyncThunk(
  'deprecated/sidebar/initialize',
  async (_, { dispatch }) => {
    const response = await GrdnApi.sideBar();
    if (isSuccessResponse(response)) {
      dispatch(processSideBarPayload(response));
    }
  },
);

export const statefulPollSideBar = createAsyncThunk(
  'deprecated/sidebar/poll/run',
  async (_, { dispatch, getState }) => {
    const lastPoll = getLastSidebarPoll(getState() as State);
    const response = (await GrdnApi.sideBar(lastPoll)) as SuccessResponse<SideBarPayload>;
    dispatch(processSideBarPayload(response));
  },
);

export const startPollSideBar = createAsyncThunk(
  'deprecated/sidebar/poll/start',
  async (_, { dispatch }) => {
    return poll.start(async () => dispatch(statefulPollSideBar()));
  },
);

export const stopPollSidebar = createAsyncThunk(
  'deprecated/sidebar/poll/stop',
  (_, { getState }) => {
    const interval = getSidebarPollInterval(getState() as State);

    if (interval) {
      poll.stop(interval);
      return true;
    }

    return false;
  },
);

const getLastSidebarPoll = (state: State) => state.sidebar.lastSideBarUpdate;
const getSidebarPollInterval = (state: State) => state.sidebar.sideBarPollInterval;

export interface SideBar {
  lastSideBarUpdate: number;
  sideBarPollInterval: number;
}

export const initialState: SideBar = {
  lastSideBarUpdate: NaN,
  sideBarPollInterval: NaN,
};

export const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    sideBarPollInterval: (state, action: PayloadAction<number>) => ({
      ...state,
      sideBarPollInterval: action.payload,
    }),
    lastSideBarPoll: (state, action: PayloadAction<number>) => ({
      ...state,
      lastSideBarUpdate: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(stopPollSidebar.fulfilled, (state, action) => {
      if (action.payload) {
        state.lastSideBarUpdate = NaN;
        state.sideBarPollInterval = NaN;
      }
    });
    builder.addCase(startPollSideBar.pending, (state) => {
      state.lastSideBarUpdate = NaN;
    });
    builder.addCase(startPollSideBar.fulfilled, (state, action) => {
      state.sideBarPollInterval = action.payload;
    });
    builder.addCase(processSideBarPayload.fulfilled, (state, action) => {
      state.lastSideBarUpdate = action.payload;
    });
  },
});

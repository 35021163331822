import React, { useState } from 'react';
import cx from 'classnames';
import { SearchResultProps } from 'semantic-ui-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './styles.module.css';
import EditInfoModal from './EditInfoModal';
import settingsCss from 'scenes/Main/Settings/Settings.module.css';
import { AlertCircleIcon, EditIcon } from 'components/ui/svg';
import { Caption, Header } from 'components/ui/Typography';
import { Search } from 'components/ui/Search';
import { getPatientAccountInfo, search } from 'lib/grdn';
import { PatientAccountInfoPayload, PatientAccountInfoSourceData } from 'types/grdn';
import { ClientErrorCode, SuccessCode } from 'types/http';
import { BodySmall, KickerHeader } from 'components/design-system/Text';
import { EdenColors } from 'types/colors';

interface SearchResult extends SearchResultProps {
  mrn: number;
  name: string;
}

const InfoBlock = ({
  source,
  data,
}: {
  source: string;
  data: PatientAccountInfoSourceData;
}): ReturnType<React.FC> => {
  const { firstName, lastName, mobilePhone, email, error } = data;
  return (
    <div className={css.infoBlock}>
      <KickerHeader color={EdenColors.Slate55} style={{ marginBottom: 8 }}>
        {source}
      </KickerHeader>
      {error ? (
        <BodySmall className={css.errorText}>
          {error === 'No record exists'
            ? `No record exists for this patient in ${source}. Please contact engineering.`
            : `Error retrieving data from ${source}. Please try again.`}
        </BodySmall>
      ) : (
        <div>
          <BodySmall className={css.patientName}>
            {source === 'cognito'
              ? 'N/A'
              : firstName && lastName
              ? `${firstName || ''} ${lastName || ''}`
              : 'Name missing'}
          </BodySmall>
          <BodySmall className={css.email}>{email || 'Email missing'}</BodySmall>
          <BodySmall>{mobilePhone || 'Mobile phone missing'}</BodySmall>
        </div>
      )}
    </div>
  );
};

const AccountInfo = ({
  accountInfo,
  setAccountInfo,
}: {
  accountInfo: PatientAccountInfoPayload;
  setAccountInfo: (PatientAccountInfoPayload) => void;
}): ReturnType<React.FC> => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleEditButtonClick = () => {
    setIsModalOpen(true);
    setIsFocused(false);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const modalParams = { onModalClose, isModalOpen, setAccountInfo, accountInfo };
  const isEditAllowed = !(
    accountInfo?.athena?.error === 'No record exists' ||
    accountInfo?.cognito?.error === 'No record exists' ||
    accountInfo?.postgres?.error === 'No record exists'
  );

  return (
    <>
      <div className={css.mrnInfo}>
        <BodySmall>MRN {accountInfo.mrn}</BodySmall>
      </div>
      <div
        className={cx(css.accountInfo, isFocused && css.accountInfoFocused)}
        onMouseEnter={() => setIsFocused(true)}
        onMouseLeave={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      >
        <div className={cx(css.infoBlockContainer)}>
          {Object.entries(accountInfo)
            .filter(([, v]) => typeof v === 'object')
            .map(([k, v]) => {
              return <InfoBlock source={k} data={v} key={k} />;
            })}
        </div>
        {isFocused && isEditAllowed && (
          <div className={css.editButton} onClick={handleEditButtonClick}>
            <BodySmall className={css.editText}>Edit</BodySmall>
            <div style={{ height: 20 }}>
              <EditIcon color={EdenColors.SlateDarken20} />
            </div>
          </div>
        )}
      </div>
      <div className={css.footnote}>
        <Caption color={EdenColors.Slate85}>
          Phone numbers are shown exactly as they are stored in each data source to surface any
          formatting errors. The proper format for Athena is **********, for Cognito is
          +1**********, and for Postgres is (***) ***-****.
        </Caption>
      </div>
      <EditInfoModal {...modalParams} />
    </>
  );
};

export const UpdatePatientInfo = (): ReturnType<React.FC> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [accountInfo, setAccountInfo] = useState<PatientAccountInfoPayload | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const { athenaOutage } = useFlags();

  const handleSearchChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: { value: string },
  ) => {
    setValue(value);
    const searchTermAsMrn = parseInt(value);
    const resultsWithSearchTerm = [
      {
        key: `selectMrn_${searchTermAsMrn}`,
        mrn: searchTermAsMrn,
        title: searchTermAsMrn.toString(),
        name: 'Select MRN: ',
      },
    ];
    setResults(resultsWithSearchTerm);
  };

  const renderResult = (patient: SearchResult) => {
    return (
      <div>
        {patient.name} {patient.mrn}
      </div>
    );
  };

  const handleResultSelect = async (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    { result }: { result: SearchResult },
  ) => {
    setShowError(false);
    setAccountInfo(null);
    const mrn = result.mrn;
    setIsLoading(true);
    try {
      const patientRes = await search({ mrn });
      const accountInfoRes =
        patientRes.status === SuccessCode.OK
          ? await getPatientAccountInfo(patientRes.data.id)
          : null;
      accountInfoRes?.status === SuccessCode.OK && setAccountInfo(accountInfoRes.data);
    } catch (e) {
      setShowError(true);
      if (e.status === ClientErrorCode.NotFound) {
        setErrorText(`MRN ${mrn} not found.`);
      } else {
        setErrorText('Error retrieving patient info. Please try again.');
      }
    }
    setIsLoading(false);
  };

  const handleAccountInfoUpdate = (data: PatientAccountInfoPayload): void => setAccountInfo(data);

  return (
    <div className={settingsCss.column}>
      <Header style={{ paddingBottom: 48 }}>Update Patient Info</Header>
      <div className={css.warning}>
        <BodySmall style={{ paddingBottom: 12, fontWeight: 'bold' }}>
          <AlertCircleIcon color={EdenColors.Slate} height="12" />
          {' IMPORTANT NOTE FOR USE'}
        </BodySmall>
        <BodySmall>
          {"If you update a patient's phone number or email address here, it will change their" +
            ' login and account recovery credentials for the app. Please confirm this with them before using the tool.'}
        </BodySmall>
      </div>
      {athenaOutage ? (
        <span>This service is unavailable when Athena is down.</span>
      ) : (
        <>
          <Search
            data-testid="PatientAccountSearch"
            loading={isLoading}
            placeholder="Search by MRN"
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            resultRenderer={renderResult}
            results={results}
            selectFirstResult
            value={value || ''}
          />
          {showError && <BodySmall className={css.errorText}>{errorText}</BodySmall>}
          {accountInfo && (
            <AccountInfo accountInfo={accountInfo} setAccountInfo={handleAccountInfoUpdate} />
          )}
        </>
      )}
    </div>
  );
};

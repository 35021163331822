/* global gapi */
import { useDebugValue, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { captureBreadcrumb, captureException } from './sentry';
import { Profile } from 'types/tables/user';
import * as GrdnApi from 'lib/grdn';
import { EventGroupEnum, reauthorizeEventName } from 'types/grdn';
import { BreadcrumbCategory } from 'types/sentry';

// TODO move to environment variable
export const GOOGLE_CLIENT_ID =
  '23104644443-23ai4djb98ggvnmceuqs16fm1lcutvlv.apps.googleusercontent.com';
const TIMEOUT_THRESHOLD = 5; // minutes
const CLINICAL_USER_PREFIX = `provider_${uuidv4()}_`;

export enum GoogleLoginErrorCode {
  PopupClosedByUser = 'popup_closed_by_user',
}

export interface GoogleLoginError {
  error: string | GoogleLoginErrorCode;
  // these types are unknown to me
  idpId: any;
  type: any;
}

class GoogleAuthClient {
  private renewAccessToken(): Promise<gapi.auth2.AuthResponse> {
    return this.currentUser.reloadAuthResponse();
  }

  public get authInstance(): gapi.auth2.GoogleAuth {
    return gapi.auth2.getAuthInstance();
  }

  private get loggedIn(): boolean {
    return this.authInstance.isSignedIn.get();
  }

  public get token(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.loggedIn) {
        reject(new Error('[WARN] Unable to retrieve token; user is not logged in'));
      } else {
        const token = this.currentUser.getAuthResponse();
        const expiration = token.expires_at;
        const nearExpirationThreshold =
          DateTime.fromMillis(expiration).diffNow('minutes').minutes <= TIMEOUT_THRESHOLD;
        if (nearExpirationThreshold) {
          this.renewAccessToken()
            .then((authResponse) => {
              // Log chat reauth/refresh
              GrdnApi.createEvent({
                eventGroup: EventGroupEnum.chat,
                eventName: reauthorizeEventName,
                eventData: {},
              });
              resolve(authResponse.id_token);
              return authResponse.id_token;
            })
            .catch((e) => {
              captureBreadcrumb({
                level: 'debug',
                message: 'track issue with google auth error handling (app-1925)',
                category: BreadcrumbCategory.GoogleAuth,
                data: { error: e },
              });
              captureException(e);
            });
        } else {
          resolve(token.id_token);
        }
      }
    });
  }

  private get currentUser(): gapi.auth2.GoogleUser {
    return this.authInstance.currentUser.get();
  }

  private staticProfile(user: gapi.auth2.GoogleUser): Profile {
    const basicProfile = user.getBasicProfile();
    return {
      // TODO this profile is too tightly coupled to ChatEngine, consider refactoring that out
      googleId: user.getId(),
      name: basicProfile.getName(),
      username: `${CLINICAL_USER_PREFIX}${user.getId()}`,
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
    };
  }

  public signIn(user?: gapi.auth2.GoogleUser): Promise<Profile> {
    return new Promise((resolve, reject) => {
      user = user ? user : this.currentUser;
      if (!user.isSignedIn()) {
        return reject(new Error('[WARN] Not logged in to google'));
      }
      return resolve(this.staticProfile(user));
    });
  }

  /* istanbul ignore next */
  public signOut() {
    this.currentUser.disconnect();
  }
}

/* istanbul ignore next */
export const useGoogleAuthEffect = () => {
  const [isLibraryLoaded, setIsLibraryLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useDebugValue([isLibraryLoaded, isAuthenticated], ([isLoaded, isSignedIn]) => {
    if (!isLoaded) {
      return 'Loading Google API';
    } else {
      return isSignedIn ? 'Google API Loaded, Signed In' : 'Google API Loaded, Signed Out';
    }
  });

  /* eslint-disable promise/catch-or-return, promise/always-return */
  useEffect(() => {
    if (!isLibraryLoaded) {
      gapi.load('auth2', () => {
        gapi.auth2
          .init({
            client_id: GOOGLE_CLIENT_ID, // eslint-disable-line @typescript-eslint/naming-convention
            scope: 'email openid profile',
            hosted_domain: 'edenhealth.com', // eslint-disable-line @typescript-eslint/naming-convention
          })
          .then(
            // onInit
            (authInstance: gapi.auth2.GoogleAuth) => {
              // listen to changes in the global google user state
              authInstance.isSignedIn.listen((isSignedIn) => {
                setIsAuthenticated(isSignedIn);
              });
              setIsLibraryLoaded(true);
            },
            // error initializing
            ({ error, details }) => {
              const exc = new Error(error);
              captureException(exc, { details: details });
            },
          );
      });
    } else {
      const googleAuthStatus = googleAuthClient.authInstance.isSignedIn.get();
      if (isAuthenticated !== googleAuthStatus) {
        setIsAuthenticated(googleAuthStatus);
      }
    }
  }, [isLibraryLoaded, setIsLibraryLoaded, isAuthenticated, setIsAuthenticated]);

  return { isLibraryLoaded, isAuthenticated };
};

const googleAuthClient = new GoogleAuthClient();
/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  // debug helper
  (window as any).googleAuthClient = googleAuthClient;
}

export default googleAuthClient;

// The below is needed for integration tests
if (process.env.NODE_ENV === 'development') {
  (window as any).gapi = gapi;
}

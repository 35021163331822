import React from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useAppDispatch } from 'lib/hooks';
import { Store } from 'types/redux';
import { athenaProvidersSelectors } from 'reducers/athenaProviders';
import { generateDueLine } from 'components/features/TicklerOverview/TicklerCard';
import { deleteTickler } from 'reducers/patients';
import { ListBody } from 'components/ui/Typography';
import css from 'components/features/TicklerOverview/DeleteTicklerModal.module.css';
import { PrimaryButton } from 'components/design-system/Form';
import { Trash } from 'components/ui/svg';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import { Modal } from 'components/ui/Modal';
import { Tickler } from 'types/grdn';

interface DeleteTicklerModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  ticklerToDelete: Tickler;
}

const DeleteTicklerModal = (props: DeleteTicklerModalProps) => {
  const { isOpen, closeModal, ticklerToDelete } = props;
  const dispatch = useAppDispatch();

  const dueDateParsed = DateTime.fromFormat(ticklerToDelete.dueDate, 'yyyy-MM-dd').startOf('day');
  const assignedProvider = useSelector((state: Store) =>
    athenaProvidersSelectors.selectById(state, ticklerToDelete.assignedTo),
  );

  const handleDeleteTickler = async (tickler) => {
    const { ticklerAthenaId, note, patientId } = tickler;
    const response = await dispatch(deleteTickler({ patientId, ticklerAthenaId, note }));

    if (deleteTickler.fulfilled.match(response)) {
      toast.success('Tickler successfully deleted.');
    } else {
      toast.error('Unable to delete tickler. Try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <HeaderMedium style={{ marginTop: -12, marginBottom: 8 }}>
        <Trash className={css.trashIcon} />
        Delete this tickler?
      </HeaderMedium>
      <BodySmall>This will permanently delete the tickler without marking it as done.</BodySmall>
      <div className={css.tickler}>
        <ListBody className={css.dueLine}>
          {generateDueLine({
            dueDate: dueDateParsed,
            assignedProvider: assignedProvider,
          })}
        </ListBody>
        <ListBody>{ticklerToDelete.note}</ListBody>
      </div>
      <div className={css.bottomNav}>
        <PrimaryButton
          type="button"
          className={cx(css.deleteBtn, css.cancelDeleteBtn)}
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          onClick={() => {
            handleDeleteTickler(ticklerToDelete);
            closeModal();
          }}
          className={cx(css.deleteBtn, css.confirmDeleteBtn)}
          value="Delete"
          data-testid="delete-tickler-button"
        />
      </div>
    </Modal>
  );
};

export default DeleteTicklerModal;

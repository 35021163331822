import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { isSuccessResponse } from '../types/api';
import { AppointmentReason } from 'types/athena/appointmentReason';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';

const prefix = 'popupReason';
const noDeptRejectionType = 'Athena Pop-Up department not defined';
const failedFetchRejectionType = 'Failed to fetch popup reasons.';

const popupReasonsAdapter = createEntityAdapter<AppointmentReason>({
  selectId: (reason) => reason.reasonId,
});

export const fetchPopupReasons = createAsyncThunk(
  `${prefix}/fetchPopupReasons`,
  async (_, { rejectWithValue }) => {
    const popupDept = process.env.REACT_APP_ATHENA_POPUP_DEPARTMENT;
    if (!popupDept) {
      return rejectWithValue({ rejectionType: noDeptRejectionType });
    } else {
      try {
        const res = await GrdnApi.getAppointmentReasons(popupDept);
        if (isSuccessResponse(res)) {
          return res.data;
        } else {
          return rejectWithValue({ rejectionType: failedFetchRejectionType });
        }
      } catch (e) {
        return rejectWithValue({ rejectionType: failedFetchRejectionType });
      }
    }
  },
);

export const popupReasons = createSlice({
  name: prefix,
  initialState: popupReasonsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPopupReasons.fulfilled, (state, action) => {
      popupReasonsAdapter.upsertMany(state, action.payload.patientappointmentreasons);
    });
  },
});

export const popupReasonsSelectors = popupReasonsAdapter.getSelectors<Store>(
  (state) => state.popupReasons,
);

export default popupReasons.reducer;

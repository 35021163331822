import { isBoolean } from 'lodash';
import React from 'react';
import { Caption } from '../../ui/Typography';
import { EdenColors } from '../../../types/colors';
import { StateKey } from '../../../types/geo';
import { Provider } from '../../../types/tables/providers';
import { ExpandablePill } from '../../ui/Pills';
import { CheckCircleIcon, HealthcareIcon, MinusCircleIcon, UsersIcon } from '../../ui/svg';
import { isProviderLicensed } from '../../../lib/util';
import { Careteam } from '../../../types/tables/careteams';
import { ForeignKey } from '../../../types/tables/base';
import { Visit } from '../../../types/athena/visits';

export const ErrorPill = () => (
  <Caption color={EdenColors.Razz}>Error loading patient info, please refresh</Caption>
);

export const LicensingPill = (props: { patientLocationKey?: StateKey; provider?: Provider }) => {
  if (!props.patientLocationKey) {
    return <Caption color={EdenColors.Razz}>No patient location, license status unknown</Caption>;
  } else if (props.patientLocationKey === StateKey.XX) {
    return (
      <ExpandablePill
        testID="PatientAbroadPill"
        icon={MinusCircleIcon}
        text={'Patient is abroad'}
        background={EdenColors.Lemon40}
        color={EdenColors.SlateDarken20}
      />
    );
  } else if (isProviderLicensed(props.provider, props.patientLocationKey)) {
    return (
      <ExpandablePill
        testID="IsLicensedPill"
        icon={CheckCircleIcon}
        text={`Licensed in ${props.patientLocationKey}`}
        background={EdenColors.Apple40}
        color={EdenColors.EdenDarken20}
      />
    );
  } else {
    return (
      <ExpandablePill
        testID="IsNotLicensedPill"
        icon={MinusCircleIcon}
        text={`Unlicensed in ${props.patientLocationKey}`}
        background={EdenColors.Slate15}
        color={EdenColors.SlateDarken20}
      />
    );
  }
};

export const CareteamPill = (props: {
  providerCareteam: Careteam;
  patientCareteamId?: ForeignKey;
}) => {
  if (
    props.providerCareteam.id &&
    props.patientCareteamId &&
    props.providerCareteam.id === props.patientCareteamId
  ) {
    return (
      <ExpandablePill
        testID="CareteamPill"
        icon={HealthcareIcon}
        text={`Patient assigned to ` + props.providerCareteam?.name}
        background={EdenColors.Apple40}
        color={EdenColors.EdenDarken20}
      />
    );
  } else if (props.providerCareteam.id) {
    return (
      <ExpandablePill
        testID="CareteamPill"
        icon={HealthcareIcon}
        text={`Patient not assigned to ` + props.providerCareteam?.name}
        background={EdenColors.Slate15}
        color={EdenColors.SlateDarken20}
      />
    );
  } else {
    return (
      <ExpandablePill
        testID="CareteamPill"
        icon={HealthcareIcon}
        text={`You are not assigned to a careteam`}
        background={EdenColors.Lemon40}
        color={EdenColors.SlateDarken20}
      />
    );
  }
};

const isFaceToFaceComplete = (patientHasHadVideoVisit: boolean, patientVisits: Visit[]) =>
  patientHasHadVideoVisit ||
  patientVisits.some((visit) => visit.departmentId !== '2' && Number(visit.patientStatusId) > 1);

export const FaceToFacePill = (props: {
  patientHasHadVideoVisit?: boolean;
  patientVisits?: Visit[];
}) => {
  if (
    props.patientVisits &&
    isBoolean(props.patientHasHadVideoVisit) &&
    !isFaceToFaceComplete(props.patientHasHadVideoVisit, props.patientVisits)
  ) {
    return (
      <ExpandablePill
        testID="FaceToFacePill"
        icon={UsersIcon}
        text="Face to face incomplete"
        background={EdenColors.Lemon40}
        color={EdenColors.SlateDarken20}
      />
    );
  } else {
    return null;
  }
};

export const CloseKnitPill = (props: {
  isCloseKnitProvider: boolean;
  isCloseKnitPatient: boolean;
}) => {
  const { isCloseKnitProvider, isCloseKnitPatient } = props;
  if (isCloseKnitProvider && isCloseKnitPatient) {
    return (
      <ExpandablePill
        testID="CloseKnitPill"
        background={EdenColors.Grape10}
        text="CK credentialed"
        color={EdenColors.GrapeDarken20}
        icon={CheckCircleIcon}
      />
    );
  } else if (!isCloseKnitProvider && isCloseKnitPatient) {
    return (
      <ExpandablePill
        testID="CloseKnitPill"
        text="Not CK credentialed"
        icon={MinusCircleIcon}
        background={EdenColors.Slate15}
        color={EdenColors.SlateDarken20}
      />
    );
  } else {
    return null;
  }
};

import React, { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import css from './SendDocumentModal.module.css';
import { DocumentData } from './AttachButtonTypes';
import { displayDocumentTypeHelper } from './utils/DocumentTypeHelper';
import { Modal } from 'components/ui/Modal';
import { Radio } from 'components/ui/Radio';
import { FileText, SendPlane } from 'components/ui/svg';
import { HeaderMedium } from 'components/design-system/Text';
import { PrimaryButton, Label, Field } from 'components/design-system/Form';
import { useActiveChat, useActivePatient } from 'lib/hooks';
import { SelectAccessible } from 'components/design-system/Form';
import { categoryTypeOptions, categoryTypes } from 'reducers/files';

interface SendDocumentProps {
  isOpen: boolean;
  close: () => void;
  onSend: (docData: DocumentData) => void;
  file: File;
}

const SendDocumentModal = (props: SendDocumentProps) => {
  const { close, file, isOpen, onSend } = props;
  const types = useSelector(categoryTypes);
  const options = useSelector(categoryTypeOptions);
  const patient = useActivePatient();
  const { channel } = useActiveChat();
  const [isDependent, setIsDependent] = useState('false');
  const [documentType, setDocumentType] = useState<string>(options[0].value);

  const filename = file?.name || '';

  const onDocumentTypesClick = () => {
    displayDocumentTypeHelper(types);
  };

  const onDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDependent(event.target.value);
  };

  const onDocumentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setDocumentType(event.target.value);
  };

  const handleClose = () => {
    close();
  };

  const handleSendDocument = () => {
    if (file) {
      onSend({
        file: file,
        patientId: patient.id,
        channelId: channel.id,
        isDependent: isDependent,
        patientDocumentTypeId: documentType,
        title: '',
        notes: '',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <header>
        <HeaderMedium className={css.header}>
          <SendPlane className={css.sendIcon} />
          Send Document
        </HeaderMedium>
      </header>

      <main>
        <p className={css.instructions}>
          You can send a document to a patient. The document will be uploaded to the patient’s files
          in CFE after it is sent.
        </p>
        <Label text="Document" htmlFor="filename" required>
          <div className={css.fieldWithIcon}>
            <FileText width={20} height={26} />
            <Field
              id="filename"
              value={filename}
              type="text"
              name="patient"
              disabled
              className={css.filename}
            />
          </div>
        </Label>

        <div className={css.labelWithLink}>
          <div className={cx(css.label, css.required)}>Document Type</div>
          <button className={css.buttonLink} onClick={onDocumentTypesClick}>
            See document type definition
          </button>
        </div>

        <SelectAccessible
          value={documentType}
          onChange={onDocumentTypeChange}
          placeholder="Select an option"
          className={css.selector}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={`${value}`}>
              {label}
            </option>
          ))}
        </SelectAccessible>

        <div className={cx(css.label, css.required)}>Who is this document for</div>
        <div className={css.radioGroup}>
          <Radio
            checked={isDependent === 'false'}
            id={'radio-patient'}
            label={'Patient'}
            onChange={onDependentChange}
            value="false"
          />
          <Radio
            checked={isDependent === 'true'}
            id={'radio-dependent'}
            label={"Patient's dependent"}
            onChange={onDependentChange}
            value="true"
          />
        </div>
      </main>

      <footer className={css.footer}>
        <PrimaryButton
          type="button"
          value="Cancel"
          className={cx(css.button, css.cancelButton)}
          onClick={handleClose}
        />
        <PrimaryButton
          className={css.button}
          onClick={handleSendDocument}
          value="Send Document"
          data-testid="send-document-button"
        />
      </footer>
    </Modal>
  );
};

export default React.memo(SendDocumentModal);

import React from 'react';
import { EdenColors } from 'types/colors';

const DeclinedIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Razz10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7071 17.2929C31.0976 17.6834 31.0976 18.3166 30.7071 18.7071L18.7071 30.7071C18.3166 31.0976 17.6834 31.0976 17.2929 30.7071C16.9024 30.3166 16.9024 29.6834 17.2929 29.2929L29.2929 17.2929C29.6834 16.9024 30.3166 16.9024 30.7071 17.2929Z"
        fill={EdenColors.Razz}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2929 17.2929C17.6834 16.9024 18.3166 16.9024 18.7071 17.2929L30.7071 29.2929C31.0976 29.6834 31.0976 30.3166 30.7071 30.7071C30.3166 31.0976 29.6834 31.0976 29.2929 30.7071L17.2929 18.7071C16.9024 18.3166 16.9024 17.6834 17.2929 17.2929Z"
        fill={EdenColors.Razz}
      />
    </svg>
  );
};

export default DeclinedIcon;

import React from 'react';
import FileCard from './FileCard';
import css from './CategorizedFileList.module.css';
import { DocumentCategoryOption, DocumentMetadata } from 'types/tables/documents';

/** TODO: Remove OldFileList for [APP-8671] Remove File Enhancements feature flag
 * Also remove releated "old" css style */
export const OldFileList = ({ files }: { files: DocumentMetadata[] }) => {
  const listItems = files.map((file) => <FileCard {...file} key={file.id} />);
  return <div className={css.oldFiles}>{listItems}</div>;
};

const FileList = ({ files, label }: { files: DocumentMetadata[]; label: string }) => {
  if (files) {
    const listItems = files.map((file) => <FileCard {...file} key={file.id} />);
    return <>{listItems}</>;
  }
  return <>No {label} files</>;
};

interface CategoriesProps {
  categories: DocumentCategoryOption[];
  files: Record<string, DocumentMetadata[]>;
}

const CategorizedFileList = ({ categories, files }: CategoriesProps) => {
  return (
    <div>
      {categories.map((category) => {
        return (
          <div className={css.category} key={category.value}>
            <div className={css.categoryName}>{category.label}</div>
            <FileList files={files[category.value]} label={category.label} />
          </div>
        );
      })}
    </div>
  );
};

export default CategorizedFileList;

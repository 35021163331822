import { Item } from 'react-stately';
import React, { useState } from 'react';
import NextStepDeleteModal from '../../modals/delete-modal';
import NextStepEditModal from '../../modals/edit-modal';
import MarkAsCompleteModal from '../../modals/mark-as-complete-modal';
import css from './NextStepCard.module.css';
import { EdenColors } from 'types/colors';
import EllipsisMenu from 'components/design-system/EllipsisMenu';

enum MenuOptions {
  MarkAsComplete = 'Mark as complete',
  Edit = 'Edit',
  Delete = 'Delete',
}

const NextStepEllipsisMenu = ({ step }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [markModalIsOpen, setMarkModalIsOpen] = useState(false);

  const onMenuSelect = (key: MenuOptions) => {
    switch (key) {
      case MenuOptions.MarkAsComplete:
        setMarkModalIsOpen(true);
        break;
      case MenuOptions.Edit:
        setEditModalIsOpen(true);
        break;
      case MenuOptions.Delete:
        setDeleteModalIsOpen(true);
        break;
    }
  };

  return (
    <>
      <div className={css.ellipsis}>
        <EllipsisMenu ellipsisColor={EdenColors.SlateDarken20} onMenuSelect={onMenuSelect}>
          <Item key={MenuOptions.MarkAsComplete}>Mark as complete</Item>
          <Item key={MenuOptions.Edit}>Edit</Item>
          <Item key={MenuOptions.Delete}>Delete</Item>
        </EllipsisMenu>
      </div>
      {deleteModalIsOpen && (
        <NextStepDeleteModal step={step} onClose={() => setDeleteModalIsOpen(false)} />
      )}

      {editModalIsOpen && (
        <NextStepEditModal step={step} onClose={() => setEditModalIsOpen(false)} />
      )}

      {markModalIsOpen && (
        <MarkAsCompleteModal step={step} onClose={() => setMarkModalIsOpen(false)} />
      )}
    </>
  );
};

export default NextStepEllipsisMenu;

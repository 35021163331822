import React from 'react';
import { EdenColors } from 'types/colors';

const VisitIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1663 24.8332H16.4997V26.4998H18.1663V24.8332ZM16.4997 23.1665C15.5792 23.1665 14.833 23.9127 14.833 24.8332V26.4998C14.833 27.4203 15.5792 28.1665 16.4997 28.1665H18.1663C19.0868 28.1665 19.833 27.4203 19.833 26.4998V24.8332C19.833 23.9127 19.0868 23.1665 18.1663 23.1665H16.4997Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8333 24.8332H23.1667V26.4998H24.8333V24.8332ZM23.1667 23.1665C22.2462 23.1665 21.5 23.9127 21.5 24.8332V26.4998C21.5 27.4203 22.2462 28.1665 23.1667 28.1665H24.8333C25.7538 28.1665 26.5 27.4203 26.5 26.4998V24.8332C26.5 23.9127 25.7538 23.1665 24.8333 23.1665H23.1667Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5003 24.8332H29.8337V26.4998H31.5003V24.8332ZM29.8337 23.1665C28.9132 23.1665 28.167 23.9127 28.167 24.8332V26.4998C28.167 27.4203 28.9132 28.1665 29.8337 28.1665H31.5003C32.4208 28.1665 33.167 27.4203 33.167 26.4998V24.8332C33.167 23.9127 32.4208 23.1665 31.5003 23.1665H29.8337Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1663 31.5002H16.4997V33.1668H18.1663V31.5002ZM16.4997 29.8335C15.5792 29.8335 14.833 30.5797 14.833 31.5002V33.1668C14.833 34.0873 15.5792 34.8335 16.4997 34.8335H18.1663C19.0868 34.8335 19.833 34.0873 19.833 33.1668V31.5002C19.833 30.5797 19.0868 29.8335 18.1663 29.8335H16.4997Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8333 31.5002H23.1667V33.1668H24.8333V31.5002ZM23.1667 29.8335C22.2462 29.8335 21.5 30.5797 21.5 31.5002V33.1668C21.5 34.0873 22.2462 34.8335 23.1667 34.8335H24.8333C25.7538 34.8335 26.5 34.0873 26.5 33.1668V31.5002C26.5 30.5797 25.7538 29.8335 24.8333 29.8335H23.1667Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5003 31.5002H29.8337V33.1668H31.5003V31.5002ZM29.8337 29.8335C28.9132 29.8335 28.167 30.5797 28.167 31.5002V33.1668C28.167 34.0873 28.9132 34.8335 29.8337 34.8335H31.5003C32.4208 34.8335 33.167 34.0873 33.167 33.1668V31.5002C33.167 30.5797 32.4208 29.8335 31.5003 29.8335H29.8337Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.333 13.1667C11.8728 13.1667 11.4997 13.5397 11.4997 14V36.5C11.4997 36.9602 11.8728 37.3333 12.333 37.3333H35.6663C36.1254 37.3333 36.4997 36.9596 36.4997 36.4982V14.0018C36.4997 13.5404 36.1254 13.1667 35.6663 13.1667H33.1663V11.5H35.6663C37.0483 11.5 38.1663 12.6223 38.1663 14.0018V36.4982C38.1663 37.8777 37.0483 39 35.6663 39H12.333C10.9523 39 9.83301 37.8807 9.83301 36.5V14C9.83301 12.6192 10.9523 11.5 12.333 11.5H16.4997V13.1667H12.333ZM29.833 13.1667H19.833V11.5H29.833V13.1667Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 20.6667H11.5V19H36.5V20.6667Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M16.5 9.83333C16.5 9.3731 16.8731 9 17.3333 9C17.7936 9 18.1667 9.3731 18.1667 9.83333V15.6667C18.1667 16.1269 17.7936 16.5 17.3333 16.5C16.8731 16.5 16.5 16.1269 16.5 15.6667V9.83333Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M29.833 9.83333C29.833 9.3731 30.2061 9 30.6663 9C31.1266 9 31.4997 9.3731 31.4997 9.83333V15.6667C31.4997 16.1269 31.1266 16.5 30.6663 16.5C30.2061 16.5 29.833 16.1269 29.833 15.6667V9.83333Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default VisitIcon;

import * as React from 'react';
import { ReactNode } from 'react';
import cx from 'classnames';
import { EdenColors } from '../../types/colors';
import { ErrorMessage } from './Typography';
import css from './Fields.module.css';
import { Ellipse } from 'components/ui/svg';

export const Input = (props: any) => {
  return (
    <div>
      <input
        {...props}
        className={cx(css.input, props.className, props.error && css.error)}
        onChange={(ev) => props.onChange(ev, ev.target)}
      />
      {props.error && <ErrorMessage className={props.errorclassname}>{props.error}</ErrorMessage>}
    </div>
  );
};

export class TextArea extends React.Component<any> {
  getSelectionStart = () => {
    return this.textarea.selectionStart;
  };
  textarea: HTMLTextAreaElement;

  render() {
    const { error } = this.props;
    return (
      <div>
        <textarea
          {...this.props}
          ref={(textarea) => (this.textarea = textarea as HTMLTextAreaElement)}
          className={cx(css.textarea, this.props.className, error && css.error)}
          onChange={(ev) => {
            this.props.onChange(ev, ev.target);
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  }
}

export const OptionWrapper = ({
  selected,
  children,
  testId,
}: {
  selected: boolean;
  children: ReactNode;
  testId?: string;
}) => (
  <div className={cx(css.optionWrapper, selected && css.selected)} data-testid={testId}>
    {children}
  </div>
);

export const Option = ({
  children,
  onClick,
  ellipseColor,
  hideEllipse = false,
  testId,
}: {
  children: ReactNode;
  onClick: () => void;
  ellipseColor?: EdenColors;
  hideEllipse?: boolean;
  testId?: string;
}) => {
  return (
    <div className={css.option} onClick={onClick} data-testid={testId}>
      {!hideEllipse && <Ellipse className={css.optionEllipse} color={ellipseColor} />}
      {children}
    </div>
  );
};

import React from 'react';

import { DateTime } from 'luxon';

import css from './VisitDateAndTime.module.css';

import { BodySmall } from 'components/design-system/Text';
import { Calendar } from 'components/ui/svg';

interface Props {
  date: string;
  timeZone?: string;
}

const VisitDateAndTime = ({ date, timeZone }: Props) => {
  const instant = DateTime.fromISO(date, { zone: timeZone });

  return (
    <div className={css.visitDateAndTime}>
      <Calendar className={css.icon} width={18} height={18} />

      <div className={css.body}>
        <BodySmall className={css.dayAndDate}>
          {instant.toFormat('EEEE')}, {instant.toFormat('MMMM d')}
        </BodySmall>

        <BodySmall className={css.time}>{instant.toFormat('h:mm a ZZZZ')}</BodySmall>
      </div>
    </div>
  );
};

export default VisitDateAndTime;

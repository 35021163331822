import React, { useState } from 'react';
import ReactModal from 'react-modal';
import css from 'components/features/VisitOverview/SubmitAVSModal.module.css';
import { BodySmall } from 'components/design-system/Text';
import { EdenColors } from 'types/colors';
import { LoaderAnimated, XIcon } from 'components/ui/svg';
import { Button, ButtonType } from 'components/ui/Buttons';

export interface SubmitAVSModalProps {
  header: string;
  body: string;
  isOpen: boolean;
  isSaving: boolean;
  onClose: (shouldFinishEditing: boolean) => void;
  onSubmit: () => Promise<void>;
}

export interface SaveAVSModalProps {
  isOpen: boolean;
  onClose: (shouldFinishEditing: boolean) => void;
  onSave: () => Promise<void>;
}

export interface DeleteAVSModalProps {
  isOpen: boolean;
  onClose: (shouldFinishEditing: boolean) => void;
  onDelete: () => Promise<void>;
}

export const SubmitAVSModal = ({
  header,
  body,
  isOpen,
  isSaving,
  onClose,
  onSubmit,
}: SubmitAVSModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
    onClose(true);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      className={css.submitAVSContainer}
      overlayClassName={css.submitAVSOverlay}
    >
      <div className={css.topNav}>
        <div className={css.header}>{header}</div>
        <div onClick={() => !isSubmitting && onClose(false)} style={{ cursor: 'pointer' }}>
          <XIcon color={EdenColors.Slate55} height={24} width={24} />
        </div>
      </div>
      <BodySmall className={css.body}>{body}</BodySmall>
      <div className={css.bottomNav}>
        <Button
          buttonType={ButtonType.transparent}
          onClick={() => onClose(false)}
          className={`${css.cancelButton} ${isSubmitting && css.cancelButtonDisabled}`}
          disabled={isSubmitting}
          content="Cancel"
        />
        <Button
          buttonType={isSaving ? ButtonType.primary : ButtonType.red}
          onClick={submit}
          disabled={isSubmitting}
          icon={
            isSubmitting &&
            LoaderAnimated({ className: css.svgCircleSpinner, width: 20, height: 20 })
          }
          content={isSaving ? 'Save' : 'Delete'}
        />
      </div>
    </ReactModal>
  );
};

export const SaveAVSModal = ({ isOpen, onClose, onSave }: SaveAVSModalProps) => {
  const header = 'Please confirm you would like to save your AVS.';
  const body =
    'Saving your AVS in CFE will override it in Athena. Please be sure you want to make that change.';

  return (
    <SubmitAVSModal
      header={header}
      body={body}
      isOpen={isOpen}
      isSaving
      onClose={onClose}
      onSubmit={onSave}
    />
  );
};

export const DeleteAVSModal = ({ isOpen, onClose, onDelete }: DeleteAVSModalProps) => {
  const header = 'Are you sure you want to delete your AVS?';
  const body =
    'Deleting your AVS in CFE will also delete it in Athena. Please be sure you want to make that change.';

  return (
    <SubmitAVSModal
      header={header}
      body={body}
      isOpen={isOpen}
      isSaving={false}
      onClose={onClose}
      onSubmit={onDelete}
    />
  );
};

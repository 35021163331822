import { createSelector } from 'reselect';
import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { getPatientProfile, getPatientProfileId } from 'selectors/profiles';
import { Visit, ZoomMeetingPayload } from 'types/athena/visits';
import { Dispatch } from 'store';
import { AsyncAction, GetState } from 'types/redux';
import * as GrdnApi from 'lib/grdn';
import { captureGrdnErrorBreadcrumb } from 'lib/sentry';
import { isSuccessResponse } from 'types/api';
import { profiles } from 'reducers/profiles';
import { profileIsError } from 'types/tables/profiles';
import { PrimaryKey } from 'types/tables/base';
import { SaveAVSPayload } from 'types/grdn';

// This file contains selectors and actions pertaining to the visits data type
// in the profiles slice, and is an accessory to reducers/profiles.ts. This was
// done to keep files relatively short.

const prefix = 'visits';

enum StartMeetingRejectionType {
  Generic = 'Something went wrong. Could not start meeting.',
}

enum GenerateAVSRejectionType {
  Generic = 'Failed to generate AVS.',
}

enum SaveAVSRejectionType {
  Generic = 'Failed to save AVS.',
}

interface GenerateAVSArgs {
  patientId: PrimaryKey;
  encounterId: PrimaryKey;
  startTime: string;
  endTime: string;
}

interface SaveAVSArgs {
  encounterId: PrimaryKey;
  content: string;
}

// selectors
export const getPatientVisits = createSelector(getPatientProfile, (profile) =>
  profileIsError(profile) ? undefined : profile.visits,
);

// actions
const visitKey = (visit: Visit) => visit.encounterId || visit.patientCaseId || visit.appointmentId;

const isUpdateCapable = (visit: Partial<Visit>): visit is Pick<Visit, 'sortDate'> => {
  return 'sortDate' in visit && typeof visit.sortDate === 'string';
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _updateVisits = (
  patientId: PrimaryKey,
  visits: Partial<Visit>[],
  dispatch: Dispatch,
  getState: GetState,
) => {
  // Private function to update visit records on an existing Patient Profile
  // will merge changes & insert new visits into the profile
  const profileId = getPatientProfileId(getState(), patientId);
  if (!profileId) {
    throw new Error('Cannot update visits without existing profile');
  }
  const existingVisits = getPatientVisits(getState(), patientId);
  const validatedUpdateVisits = visits.filter(isUpdateCapable);
  if (!existingVisits) {
    return dispatch(
      profiles.actions.updateOne({ id: profileId, changes: { visits: validatedUpdateVisits } }),
    );
  }
  const updateVisits = existingVisits
    .concat(validatedUpdateVisits)
    .reduce((acc: Visit[], visit) => {
      const existingVisit = acc.find((v) => visitKey(v) === visitKey(visit));
      if (existingVisit) {
        const idx = acc.indexOf(existingVisit);
        acc[idx] = { ...existingVisit, ...visit };
      } else {
        acc.push(visit);
      }
      return acc;
    }, []);

  return dispatch(profiles.actions.updateOne({ id: profileId, changes: { visits: updateVisits } }));
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _updateEncounter = (
  patientId: PrimaryKey,
  encounterId: PrimaryKey,
  encounter: Partial<Visit>,
  dispatch: Dispatch,
  getState: GetState,
) => {
  /* Private function that modifies a "type" of Visit: encounter
  this wrapper for _updateVisits is required because the grdn endpoint that
  returns information for an encounter does not include the sortDate which is the
  only required property on the Visit interface
   */
  const existingVisits = getPatientVisits(getState(), patientId);
  const existingVisit = existingVisits?.find((visit) => visit.encounterId === encounterId);
  if (!existingVisit) {
    throw new Error('Cannot update an encounter without existing visit');
  }
  return _updateVisits(patientId, [{ ...existingVisit, ...encounter }], dispatch, getState);
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _updateAppointment = (
  patientId: PrimaryKey,
  appointmentId: PrimaryKey,
  appointment: Partial<Visit>,
  dispatch: Dispatch,
  getState: GetState,
) => {
  /* Private function that modifies a "type" of Visit: appointment
  this wrapper for _updateVisits is required because the grdn endpoint that
  returns information for an appointment does not include the sortDate which is the
  only required property on the Visit interface
   */
  const existingVisits = getPatientVisits(getState(), patientId);
  if (!existingVisits || !existingVisits.find((visit) => visit.appointmentId === appointmentId)) {
    throw new Error('Cannot update an appointment without existing visit');
  }
  const existingVisit = existingVisits.find((visit) => visit.appointmentId === appointmentId);
  return _updateVisits(patientId, [{ ...existingVisit, ...appointment }], dispatch, getState);
};

export const fetchVisits =
  (patientId: PrimaryKey): AsyncAction =>
  async (dispatch, getState) => {
    let response;
    try {
      response = await GrdnApi.visits(patientId);
    } catch (e) {
      captureGrdnErrorBreadcrumb({ error: e, patientId }, 'Visit fetch failure.');
    }
    if (response && isSuccessResponse(response)) {
      // TODO: Fix type
      // eslint-disable-next-line
      // @ts-ignore
      _updateVisits(patientId, response.data, dispatch, getState);
    }
  };

export const fetchEncounter =
  (patientId: PrimaryKey, encounterId: PrimaryKey): AsyncAction =>
  async (dispatch, getState) => {
    const response = await GrdnApi.encounter(patientId, encounterId);
    if (isSuccessResponse(response)) {
      // TODO: Fix type
      // eslint-disable-next-line
      // @ts-ignore
      _updateEncounter(patientId, encounterId, response.data, dispatch, getState);
    }
  };

export const updateVisitWithAfterVisitSummary =
  (patientId, encounterId, encounter): AsyncAction =>
  async (dispatch, getState) => {
    // TODO: Fix type
    // eslint-disable-next-line
    // @ts-ignore
    _updateEncounter(patientId, encounterId, encounter, dispatch, getState);
  };

export const startVisit =
  (patientId, appointmentId): AsyncAction =>
  async (dispatch, getState) => {
    _updateAppointment(
      patientId,
      appointmentId,
      {
        isStarted: true,
      },
      // TODO: Fix type
      // eslint-disable-next-line
      // @ts-ignore
      dispatch,
      getState,
    );
  };

export const fetchAppointmentNote =
  (patientId: PrimaryKey, appointmentId: PrimaryKey): AsyncAction =>
  async (dispatch, getState) => {
    const response = await GrdnApi.appointmentNote(appointmentId);
    if (isSuccessResponse(response)) {
      _updateAppointment(
        patientId,
        appointmentId,
        {
          appointmentNote: response.data,
        },
        // TODO: Fix type
        // eslint-disable-next-line
        // @ts-ignore
        dispatch,
        getState,
      );
    }
  };

export type ZoomMeetingId = string;

interface CreateMeetingArgs {
  appointmentId: EntityId;
  patientId: string;
}

export const scheduleMeeting = createAsyncThunk<
  ZoomMeetingPayload,
  CreateMeetingArgs,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/createMeeting`, async ({ appointmentId, patientId }, { rejectWithValue }) => {
  try {
    const response = await GrdnApi.scheduleMeeting({
      appointmentId: appointmentId.toString(),
      patientId,
    });
    if (isSuccessResponse(response)) {
      return response.data;
    } else {
      return rejectWithValue({ rejectionType: StartMeetingRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: StartMeetingRejectionType.Generic });
  }
});

export const generateAndSaveAVS = createAsyncThunk<SaveAVSPayload, GenerateAVSArgs>(
  `${prefix}/generateAndSaveAVS`,
  async (args: GenerateAVSArgs, { rejectWithValue }) => {
    try {
      const generateResponse = await GrdnApi.generateAVS(args);
      if (!isSuccessResponse(generateResponse)) {
        return rejectWithValue({ rejectionType: GenerateAVSRejectionType.Generic });
      } else {
        const saveResponse = await GrdnApi.saveAVS({
          encounterId: args.encounterId,
          content: generateResponse.data.summary,
        });
        if (isSuccessResponse(saveResponse)) {
          return saveResponse.data;
        } else {
          return rejectWithValue({ rejectionType: GenerateAVSRejectionType.Generic });
        }
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: GenerateAVSRejectionType.Generic });
    }
  },
);

export const saveAVS = createAsyncThunk<SaveAVSPayload, SaveAVSArgs>(
  `${prefix}/saveAVS`,
  async (args: SaveAVSArgs, { rejectWithValue }) => {
    try {
      const response = await GrdnApi.saveAVS(args);
      if (isSuccessResponse(response)) {
        return response.data;
      } else {
        return rejectWithValue({ rejectionType: SaveAVSRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: SaveAVSRejectionType.Generic });
    }
  },
);

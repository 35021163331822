import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './ExternalAddons.module.css';
import { ChartElement } from './ChartElements';
import MedicalSummaryItem from './MedicalSummaryItem';
import { useActivePatient } from 'lib/hooks';
import { ExternalAddonWithCategory, ExternalAddonState } from 'types/tables/addons';
import { LinkWithIcon } from 'components/design-system/Form';
import { ChevronDownIcon, ChevronUpIcon, Link } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import {
  fetchCategories,
  fetchExternalAddons,
  getExternalAddonsWithCategoryByCategory,
} from 'reducers/addons';
import { opsAssetPath } from 'lib/env';
import { captureException } from 'lib/sentry';

const CategorizedExternalAddons = ({
  categorizedAddons,
}: {
  categorizedAddons: Map<string, ExternalAddonWithCategory[]>;
}) => {
  const categories = Array.from(categorizedAddons.keys()).sort();
  return (
    <ChartElement>
      <div className={css.container}>
        {categories.map((categoryName, index) => {
          const addons = categorizedAddons.get(categoryName);
          return (
            addons && <AddonsForCategory categoryName={categoryName} addons={addons} key={index} />
          );
        })}
      </div>
    </ChartElement>
  );
};

const CategoryHeader = ({ name }: { name: string }) => {
  return <div className={css.categoryHeader}>{name}</div>;
};

const AddonsForCategory = ({
  categoryName,
  addons,
}: {
  categoryName: string;
  addons: ExternalAddonWithCategory[];
}) => {
  if (!addons) {
    return <></>;
  }
  return (
    <>
      <div className={css.categoryHeader}>
        <CategoryHeader name={categoryName} />
      </div>
      {addons.map((addon, index) => (
        <ExternalAddonDetails addon={addon} key={index} />
      ))}
    </>
  );
};

const ExternalAddonDetails = ({ addon }: { addon: ExternalAddonWithCategory }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClickExpand = () => setExpanded(!expanded);

  return addon.state !== ExternalAddonState.enabled ? null : (
    <div className={css.categoryContainer}>
      <div className={css.addonHeader}>
        <div className={css.addonHeaderLeft}>
          <img
            src={opsAssetPath(addon.category.imageUrl)}
            alt={`${addon.category.name} category icon`}
          />
        </div>
        <div className={css.addonHeaderRight}>
          <div className={css.addonSubheader}>
            <div className={css.companyName}>{addon.companyName}</div>
            <div className={css.addonCollapse} onClick={handleClickExpand}>
              {expanded ? (
                <ChevronUpIcon height="20" width="20" color={EdenColors.SlateDarken20} />
              ) : (
                <ChevronDownIcon height="20" width="20" color={EdenColors.SlateDarken20} />
              )}
            </div>
          </div>
          <p className={css.sectionBody}>{addon.benefitOverview}</p>
          <div className={css.tagContainer}>
            {addon.tags.map((tag, key) => (
              <Tag text={tag} key={key} />
            ))}
          </div>
        </div>
      </div>
      {expanded && (
        <div className={css.sectionBody}>
          <ul className={css.bulletContainer}>
            {addon.bulletList.map((bullet, key) => (
              <Bullet text={bullet} key={key} />
            ))}
          </ul>
          <h2 className={css.sectionHeader}>Enrollment Instructions</h2>
          <div className={css.sectionBody}>{addon.enrollmentInstructions}</div>
          <div className={css.enrollmentUrl}>
            <LinkWithIcon
              href={addon.enrollmentUrl}
              target="_blank"
              text={addon.enrollmentUrl}
              icon={Link({ height: 16, width: 16, color: EdenColors.Slate45 })}
              className={css.enrollmentUrl}
            />
          </div>
          {addon.notes && (
            <>
              <h2 className={css.sectionHeader}>Notes</h2>
              <div className={css.sectionBody}>{addon.notes}</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const Tag = ({ text }: { text: string }) => {
  return <div className={css.tag}>{text}</div>;
};

const Bullet = ({ text }: { text: string }) => {
  return <li>{text}</li>;
};

const ExternalAddons = () => {
  const dispatch = useDispatch();

  const { sponsorId } = useActivePatient();

  const categorizedAddons = useSelector(getExternalAddonsWithCategoryByCategory);

  useEffect(() => {
    const loadExternalAddons = async () => {
      await dispatch(fetchCategories());
      await dispatch(fetchExternalAddons(sponsorId));
    };
    loadExternalAddons().catch((e) => {
      captureException(e, { sponsorId });
    });
  }, [dispatch, sponsorId]);

  return categorizedAddons.size > 0 ? (
    <MedicalSummaryItem label="Health Hub">
      <CategorizedExternalAddons categorizedAddons={categorizedAddons} />
    </MedicalSummaryItem>
  ) : (
    <></>
  );
};

export default ExternalAddons;

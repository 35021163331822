import React, { useState } from 'react';
import cx from 'classnames';
import css from 'components/features/Sidebar/MultiFilter.module.css';
import Dropdown from 'components/ui/Dropdown';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import { FilledUpArrow, FilledDownArrow, Checkbox } from 'components/ui/svg';
import { ListItem1 } from 'components/ui/Lists';
import { Bucket, BucketFilterOption } from 'types/sendbird/chat';
import { EdenColors } from 'types/colors';

export enum MultiFilterViewSize {
  small = 'small',
  large = 'large',
}

interface MultiFilterViewProps {
  filters: BucketFilterOption[];
  selectedFilters: string[];
  onSelectFilter: (filter: string) => void;
  placeholder: string;
  bucket?: Bucket;
  size?: MultiFilterViewSize;
  showItemCount?: boolean;
}

const MultiFilterView = ({
  filters,
  selectedFilters,
  onSelectFilter,
  placeholder,
  bucket,
  size = MultiFilterViewSize.small,
  showItemCount = false,
}: MultiFilterViewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const displaySelectedFilters = () => {
    return (
      selectedFilters.reduce((acc, cur, index) => {
        if (index < selectedFilters.length - 1) {
          return acc + cur + ', ';
        } else {
          return acc + cur;
        }
      }, '') || placeholder
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderRow = (option: BucketFilterOption, key: number) => {
    const isChecked = selectedFilters.includes(option.filter);
    return (
      <ListItem1
        key={key}
        className={css.multiFilterItem}
        onClick={() => onSelectFilter(option.filter)}
        data-testid={`MultiFilterRow-${option.filter}`}
      >
        <Checkbox
          color={isChecked ? EdenColors.ActionPrimary : EdenColors.Slate15}
          checked={isChecked}
        />
        <div>{option.filter}</div>
        {showItemCount && <div className={css.count}>{`${option.patients.length}`}</div>}
      </ListItem1>
    );
  };

  return (
    <div>
      <Button
        buttonType={ButtonType.transparent}
        size={ButtonSizes.small}
        active={isOpen}
        content={displaySelectedFilters()}
        icon={isOpen ? <FilledUpArrow /> : <FilledDownArrow />}
        onClick={toggleDropdown}
        data-testid={bucket ? `MultiFilterDropdown-${bucket}` : 'MultiFilterDropdown'}
        className={cx(
          css.multiFilterButton,
          css[size],
          selectedFilters.length === 0 && css.placeholder,
        )}
      />
      <Dropdown className={css[`${size}Dropdown`]} isOpen={isOpen} onClose={toggleDropdown}>
        {filters.map((value, index) => renderRow(value, index))}
      </Dropdown>
    </div>
  );
};

export default MultiFilterView;

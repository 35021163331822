import React from 'react';

import { formatDateTime } from 'lib/formatDateTime';

interface Props {
  date: string | undefined;
}

const DateDisplay = ({ date }: Props) => {
  return <>{!!date ? formatDateTime(date).as('LLL d, yyyy').inLocalTimeZone() : 'invalid date'}</>;
};

export default DateDisplay;

import React, { useState } from 'react';
import cx from 'classnames';
import css from './ChartAlert.module.css';
import { AlertCircleIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

export const ChartAlert = ({ chartAlert }) => {
  const [isHovered, setHovered] = useState(false);
  const noAlert = !chartAlert || !chartAlert.noteText || chartAlert.noteText === '';
  const alertTextColor = noAlert ? EdenColors.Slate55 : EdenColors.RazzDarken20;
  const text = noAlert
    ? 'There is no flag for this patient. To add a flag, update the patient profile in Athena.'
    : chartAlert.noteText;

  return (
    <div
      className={cx(css.container)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <button className={cx(css.innerButton, noAlert ? css.noAlert : css.alert)}>
        <AlertCircleIcon color={alertTextColor} height={12} width={12} />
      </button>
      {isHovered && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ul className={css.popup}>
            <div>
              {!noAlert && <div className={css.title}>Important</div>}
              <div className={css.body}>{text}</div>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../lib/hooks';
import css from './MacroDeleteModal.module.css';

import markdown from 'lib/markdown';
import { TrashIcon } from 'components/ui/svg';
import { Button, ButtonType } from 'components/ui/Buttons';
import { Body1, Body2, Header2, Header3 } from 'components/ui/Typography';

import { Macro } from 'types/tables/macros';
import { Provider } from 'types/tables/providers';
import { deleteMacro } from 'reducers/macros';

interface DeleteMacroModalProps {
  title: string;
  text: string;
  myProvider?: Provider;
  isOpen: boolean;
  macro: Macro | null;
  onKeepEditing: () => void;
  onClose: () => void;
}

const MacroModalDelete = ({
  title,
  text,
  myProvider,
  isOpen = false,
  macro,
  onKeepEditing,
  onClose,
}: DeleteMacroModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    try {
      if (macro) {
        setSubmitting(true);
        const res = await dispatch(deleteMacro({ id: macro.id }));
        if (deleteMacro.rejected.match(res)) {
          toast.error(res.payload?.rejectionType);
        }
      }
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} size="tiny" onClose={onClose}>
      <Modal.Content className={css.modal}>
        <Header2 className={css.header}>
          <TrashIcon height={24} width={16} />
          <span className={css.headerText}>Delete this macro forever?</span>
        </Header2>
        <Body1 className={css.subheader}>
          This will remove the macro for all care team members.
        </Body1>
        <Header3 className={css.title}>{title}</Header3>
        <div className={css.textContainer}>
          <Body2
            dangerouslySetInnerHTML={{
              __html: markdown({
                text,
              }),
              providerName: myProvider && myProvider.displayName,
            }}
          />
        </div>
        <div className={css.buttonsContainer}>
          <Button
            buttonType={ButtonType.grey}
            onClick={onKeepEditing}
            className={css.keepEditingButton}
            content="Keep editing"
          />
          <Button
            buttonType={ButtonType.red}
            onClick={handleDelete}
            disabled={submitting}
            className={css.delete}
            content="Delete Macro"
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default MacroModalDelete;

interface Copay {
  copaytype: string;
  copayamount: string;
}

export enum InsuranceLoadState {
  NotStarted = 'NotStarted',
  Loading = 'Loading',
  Success = 'Success',
  Failure = 'Failure',
}

export enum InsuranceEligibilityStatus {
  Ineligible = 'Ineligible',
  Eligible = 'Eligible',
  Unverified = 'Unverified',
}

export interface InsuranceCard {
  image: string; // b64 image
}

export interface InsuranceRecord {
  id: string; // ID of insurance record in Athena
  displayName?: string; // display name for insurance (carrier/plan)
  memberId: string; // member ID
  policyholderName: string;
  policyholderDob: string;
  planExpirationDate: string; // date plan expires
  providerServicesPhone: string; // insurance package/plan phone number
  eligibilityStatus: InsuranceEligibilityStatus;
  eligibilityLastCheckedDate: string;
  eligibilityMessage?: string;
}

export interface Insurance {
  athenaRecordExists: boolean; // do we have a record of the insurance in athena?
  documentExists: boolean; // do we have a primary insurance card image for the patient?
  athenaRecord?: InsuranceRecord;
}

// todo: Remove after removing feature flag
export interface InsuranceOld {
  insuranceCard: { payload: string };
  insuranceId?: string;
  ircName?: string;
  eligibilityStatus: string;
  eligibilityLastChecked: string;
  insuranceIdNumber: string;
  insurancePhone: string;
  insurancepolicyholder: string;
  insurancepolicyholderdob: string;
  copays: Copay[];
}

import { createSelector } from 'reselect';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { AddonCategory, ExternalAddon, ExternalAddonWithCategory } from 'types/tables/addons';
import { PrimaryKey } from 'types/tables/base';
import { isSuccessResponse } from 'types/api';
import { AddonCategoriesPayload, ExternalAddonsPayload } from 'types/grdn';

const prefix = 'addon';
const externalAddonInitializationRejectionType = 'Failed to initialize external add-ons.';
const addonCategoryInitializationRejectionType = 'Failed to initialize add-on categories.';

export const fetchCategories = createAsyncThunk<
  AddonCategoriesPayload,
  void,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/fetchCategories`, async (_, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.getAddonCategories();
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: addonCategoryInitializationRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: addonCategoryInitializationRejectionType });
  }
});

export const fetchExternalAddons = createAsyncThunk<
  ExternalAddonsPayload,
  string,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/fetchExternalAddons`, async (sponsorId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.getExternalAddons(sponsorId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: externalAddonInitializationRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: externalAddonInitializationRejectionType });
  }
});

export const addonCategoriesAdapter = createEntityAdapter<AddonCategory>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const externalAddonsAdapter = createEntityAdapter<ExternalAddon>({
  sortComparer: (a, b) => a.companyName.localeCompare(b.companyName),
});

export const addons = createSlice({
  name: prefix,
  initialState: {
    categories: addonCategoriesAdapter.getInitialState(),
    external: externalAddonsAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      addonCategoriesAdapter.setAll(state.categories, action.payload);
    });
    builder.addCase(fetchExternalAddons.fulfilled, (state, action) => {
      externalAddonsAdapter.setAll(state.external, action.payload);
    });
  },
});

export const addonCategoriesSelectors = addonCategoriesAdapter.getSelectors<Store>(
  (state) => state.addons.categories,
);

export const externalAddonsSelectors = externalAddonsAdapter.getSelectors<Store>(
  (state) => state.addons.external,
);

export const getAddonCategories = addonCategoriesSelectors.selectAll;

export const getAddonCategoryById = createSelector(
  (_, categoryId: PrimaryKey) => categoryId,
  getAddonCategories,
  (categoryId, addonCategories) => {
    return addonCategories.find((category) => category.id === categoryId);
  },
);

export const getExternalAddons = externalAddonsSelectors.selectAll;

export const getExternalAddonById = createSelector(
  (_, externalAddonId: PrimaryKey) => externalAddonId,
  getExternalAddons,
  (externalAddonId, externalAddons) => {
    return externalAddons.find((externalAddon) => externalAddon.id === externalAddonId);
  },
);

export const getExternalAddonsWithCategory = createSelector(
  [getExternalAddons, getAddonCategories],
  (externalAddons, categories) => {
    return externalAddons
      .map((addon) => {
        const category = categories.find((c) => c.id === addon.categoryId);
        return {
          ...addon,
          category,
        };
      })
      .filter((addon): addon is ExternalAddonWithCategory => !!addon.category);
  },
);

export const getExternalAddonsWithCategoryByCategory = createSelector(
  [getExternalAddonsWithCategory],
  (externalAddonsWithCategory) => {
    const data = new Map<string, ExternalAddonWithCategory[]>();
    externalAddonsWithCategory.map((addon) => {
      const category = addon.category.name;
      const addons = data.get(category);
      if (addons && addons.length > 0) {
        addons.push(addon);
      } else {
        data.set(category, [addon]);
      }
    });
    return data;
  },
);

export default addons.reducer;

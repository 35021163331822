import * as React from 'react';
import cx from 'classnames';
import css from './Placeholder.module.css';

interface PlacholderProps {
  wide?: boolean;
  full?: boolean;
}

export const Placeholder = ({ wide, full }: PlacholderProps) => (
  <div className={cx(css.placeholder, wide && css.wide, full && css.full)}></div>
);

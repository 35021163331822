import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const Archive = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.667c.368 0 .667.298.667.667v8h10.666v-8a.667.667 0 0 1 1.334 0V14a.667.667 0 0 1-.667.667H2A.667.667 0 0 1 1.333 14V5.334c0-.369.299-.667.667-.667Z"
      fill="#586E74"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2c0-.368.298-.667.667-.667h14.666c.368 0 .667.299.667.667v3.333a.667.667 0 0 1-.667.667H.667A.667.667 0 0 1 0 5.333V2Zm1.333.667v2h13.334v-2H1.333ZM6 8c0-.368.298-.667.667-.667h2.666a.667.667 0 1 1 0 1.334H6.667A.667.667 0 0 1 6 8Z"
      fill="#586E74"
    />
  </svg>
);

export default Archive;

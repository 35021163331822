import React from 'react';
import cx from 'classnames';
import { Item } from 'react-stately';
import css from './FileCardOptionsMenu.module.css';
import { DropdownMenu } from 'components/design-system/DropdownMenu/DropdownMenu';
import { EllipsisFeather } from 'components/ui/svg';

export const fileCardMenuOptions = {
  annotate: 'Annotate',
};

interface FileCardOptionsMenu {
  onSelect: (key: string) => void;
}

const MenuButton = ({ isOpen }: { isOpen?: boolean }) => {
  return (
    <div className={cx(css.overflowMenuTrigger, isOpen && css.open)}>
      <EllipsisFeather className={cx(css.icon, css.ellipsis)} />
    </div>
  );
};

const FileCardOptionsMenu = ({ onSelect }: FileCardOptionsMenu) => {
  return (
    <DropdownMenu menuTrigger={<MenuButton />} onAction={onSelect} placement="bottom right">
      {Object.entries(fileCardMenuOptions).map(([key, value]) => (
        <Item textValue={value} key={key}>
          <div className={css.item} data-testid={key}>
            {value}
          </div>
        </Item>
      ))}
    </DropdownMenu>
  );
};

export default FileCardOptionsMenu;

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const ArrowLeft = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.707 4.293a1 1 0 0 1 0 1.414L6.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z"
      fill="#2E4349"
    />
    <rect x="4" y="11" width="16" height="2" rx="1" fill="#2E4349" />
  </svg>
);

export default ArrowLeft;

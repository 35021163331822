import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { Tab } from 'semantic-ui-react';
import { ToolModal, ToolModalProps, ToolModalPurpose } from './ToolModal';
import css from './PopupManager.module.css';
import {
  DetailsModal,
  DetailsModalProps,
  formatPopupAddress,
  formatPopupTimes,
  isRecurringPopup,
} from './DetailsModal';
import { ActivateModal, ActivateModalProps, DeactivateModal } from './ActivateModal';
import { DeleteModal, DeleteModalProps } from './DeleteModal';
import settingsCss from 'scenes/Main/Settings/Settings.module.css';
import { Header } from 'components/ui/Typography';
import { captureException } from 'lib/sentry';
import {
  fetchActivePopups,
  fetchDraftPopups,
  fetchPastPopups,
  selectActivePopups,
  selectDraftPopups,
  selectPastPopups,
} from 'reducers/popups';
import { Calendar } from 'components/ui/Calendar';
import { MultiDayCalendar } from 'components/ui/MultiDayCalendar';
import { Popup, PopupState, PopupTypes } from 'types/tables/popups';
import { Spinner } from 'components/ui/svg';
import { PrimaryButton } from 'components/design-system/Form';
import { HeaderMedium } from 'components/design-system/Text';
import { fetchPopupReasons, popupReasonsSelectors } from 'reducers/popupReasons';
import { Store } from 'types/redux';

export type SimpleStateDispatch = (type: string, payload?: any) => void;

interface PopupListItemProps {
  popup: Popup;
  selected: boolean;
  past: boolean;
  simpleDispatch: SimpleStateDispatch;
}

export const formatRecurringPopupDates = (date?: string, endDate?: string) => {
  if (date && endDate) {
    const dt = DateTime.fromISO(date, { setZone: true }).toFormat('MMM. d');
    const edt = DateTime.fromISO(endDate, { setZone: true }).toFormat('MMM. d');
    return `${dt} - ${edt}`;
  } else {
    return '';
  }
};

const PopupListItem = ({ popup, selected, past, simpleDispatch }: PopupListItemProps) => {
  const popupReasons = useSelector((state: Store) => popupReasonsSelectors.selectEntities(state));
  const testId = () => {
    if (past) return 'past-popup';
    if (popup.popupState === PopupState.draft) return 'draft-popup';
    return 'active-popup';
  };
  return (
    <div
      className={cx(css.listItem, selected && css.selected, past && css.pastPopup)}
      onClick={(e) => {
        e.stopPropagation();
        simpleDispatch('openDetails', { popup, past });
      }}
      data-testid={testId()}
    >
      {isRecurringPopup(popup) ? (
        <MultiDayCalendar
          popupDates={popup?.popupDates}
          past={past}
          draft={popup.popupState === PopupState.draft}
        />
      ) : (
        <Calendar date={popup.date} inactive={popup.popupState === PopupState.draft} past={past} />
      )}
      <div className={css.listItemDetails}>
        <div className={cx(css.sponsorName, css.overflowEllipses)}>{popup?.name || ''}</div>
        <div className={css.times}>
          {isRecurringPopup(popup)
            ? formatRecurringPopupDates(popup?.date, popup?.endDate)
            : formatPopupTimes(popup?.startTime, popup?.endTime, popup?.timeZoneId)}
        </div>
        <div className={cx(css.address, css.overflowEllipses)}>{formatPopupAddress(popup)}</div>
        <div className={css.popupType}>{popupReasons[popup.athenaReasonId]?.description}</div>
      </div>
    </div>
  );
};

interface PopupListProps {
  popups: Popup[];
  simpleDispatch: SimpleStateDispatch;
  selectedPopup?: Popup;
  testId: string;
  past?: boolean;
  orderDescending?: boolean;
  contextualDates?: boolean;
}

const PopupList = ({
  popups,
  simpleDispatch,
  selectedPopup,
  testId,
  past,
  orderDescending,
  contextualDates,
}: PopupListProps) => {
  const sortedPopups = sortBy(popups, (p) => p.date + (p.startTime || ''));
  if (orderDescending) sortedPopups.reverse();
  const weekGroups = {};
  const weeksOrdering: string[] = [];
  for (let i = 0; i < sortedPopups.length; i++) {
    const date = DateTime.fromISO(sortedPopups[i].date, { setZone: true });
    const year = contextualDates && date.hasSame(DateTime.local(), 'years') ? undefined : 'numeric';
    let weekString =
      date
        .startOf('week')
        .toLocaleString({
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year,
        })
        .toUpperCase() + ' WEEK';
    if (contextualDates) {
      if (date.hasSame(DateTime.local(), 'weeks')) {
        weekString = 'THIS WEEK';
      } else if (date.hasSame(DateTime.local().plus({ weeks: 1 }), 'weeks')) {
        weekString = 'NEXT WEEK';
      } else if (date.hasSame(DateTime.local().minus({ weeks: 1 }), 'weeks')) {
        weekString = 'LAST WEEK';
      }
    }
    if (!(weekString in weekGroups)) {
      weekGroups[weekString] = [];
      weeksOrdering.push(weekString);
    }
    weekGroups[weekString].push(sortedPopups[i]);
  }

  return (
    <>
      {weeksOrdering.map((week, idx) => (
        <div className={css.list} data-testid={`${testId}-${idx}`} key={`${testId}-${idx}`}>
          <div className={css.listTitle}>{week}</div>
          {weekGroups[week].map((popup, idx) => (
            <PopupListItem
              popup={popup}
              key={`popup_${idx}`}
              simpleDispatch={simpleDispatch}
              selected={popup === selectedPopup}
              past={!!past}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export interface PopupManagerState {
  loading: boolean;
  activeLoading: boolean;
  draftLoading: boolean;
  pastLoading: boolean;
  selectedPopup?: Popup;
  toolModal: Omit<ToolModalProps, 'children'>;
  detailsModal: Omit<DetailsModalProps, 'children' | 'simpleStateDispatch'>;
  activateModal: Omit<ActivateModalProps, 'children'>;
  deactivateModal: Omit<ActivateModalProps, 'children'>;
  deleteModal: Omit<DeleteModalProps, 'children'>;
}

export const PopupManager = () => {
  const dispatch = useDispatch();

  const stateReducer = (state: PopupManagerState, action) => {
    switch (action.type) {
      case 'loadingOn':
        return { ...state, loading: true };
      case 'loadingOff':
        return { ...state, loading: false };
      case 'activeLoadingOn':
        return { ...state, activeLoading: true };
      case 'activeLoadingOff':
        return { ...state, activeLoading: false };
      case 'draftLoadingOn':
        return { ...state, draftLoading: true };
      case 'draftLoadingOff':
        return { ...state, draftLoading: false };
      case 'pastLoadingOn':
        return { ...state, pastLoading: true };
      case 'pastLoadingOff':
        return { ...state, pastLoading: false };
      case 'openDetails':
        return {
          ...state,
          selectedPopup: action.payload.popup,
          detailsModal: {
            ...state.detailsModal,
            isOpen: true,
            popup: action.payload.popup,
            past: action.payload.past,
          },
        };
      case 'closeDetails':
        return {
          ...state,
          selectedPopup: undefined,
          detailsModal: { ...state.detailsModal, isOpen: false },
        };
      case 'openCreatePopup':
        return {
          ...state,
          toolModal: { ...state.toolModal, isOpen: true, purpose: ToolModalPurpose.Create },
        };
      case 'openEditPopup':
        return {
          ...state,
          detailsModal: { ...state.detailsModal, isOpen: false },
          toolModal: {
            ...state.toolModal,
            isOpen: true,
            purpose: ToolModalPurpose.Edit,
            popup: action.payload.popup,
          },
        };
      case 'closeTools':
        return {
          ...state,
          toolModal: { ...state.toolModal, isOpen: false },
        };
      case 'openActivateModal':
        return {
          ...state,
          detailsModal: { ...state.detailsModal, isOpen: false },
          activateModal: { ...state.activateModal, isOpen: true, popup: action.payload },
        };
      case 'closeActivateModal':
        return { ...state, activateModal: { ...state.activateModal, isOpen: false } };
      case 'openDeactivateModal':
        return {
          ...state,
          detailsModal: { ...state.detailsModal, isOpen: false },
          deactivateModal: { ...state.deactivateModal, isOpen: true, popup: action.payload },
        };
      case 'closeDeactivateModal':
        return { ...state, deactivateModal: { ...state.deactivateModal, isOpen: false } };
      case 'openDeleteModal':
        return {
          ...state,
          detailsModal: { ...state.detailsModal, isOpen: false },
          deleteModal: { ...state.deleteModal, isOpen: true, popup: action.payload },
        };
      case 'closeDeleteModal':
        return { ...state, deleteModal: { ...state.deleteModal, isOpen: false } };
      default:
        return state;
    }
  };
  const [popupManagerState, stateDispatch] = useReducer(stateReducer, {
    loading: true,
    activeLoading: true,
    draftLoading: true,
    pastLoading: true,
    selectedPopup: undefined,
    toolModal: {
      isOpen: false,
      closeModal: () => simpleStateDispatch('closeTools'),
      popup: undefined,
      purpose: ToolModalPurpose.Create,
    },
    detailsModal: {
      isOpen: false,
      closeModal: () => simpleStateDispatch('closeDetails'),
      popup: undefined,
      past: false,
    },
    activateModal: {
      isOpen: false,
      closeModal: () => simpleStateDispatch('closeActivateModal'),
      popup: undefined,
    },
    deactivateModal: {
      isOpen: false,
      closeModal: () => simpleStateDispatch('closeDeactivateModal'),
      popup: undefined,
    },
    deleteModal: {
      isOpen: false,
      closeModal: () => simpleStateDispatch('closeDeleteModal'),
      popup: undefined,
    },
  });

  const simpleStateDispatch = useCallback(
    (type: string, payload?: Record<string, any>) => stateDispatch({ type, payload }),
    [stateDispatch],
  );

  useEffect(() => {
    const getReasons = async () => {
      simpleStateDispatch('loadingOn');
      await dispatch(fetchPopupReasons());
      simpleStateDispatch('loadingOff');
    };
    const getActive = async () => {
      simpleStateDispatch('activeLoadingOn');
      await dispatch(fetchActivePopups());
      simpleStateDispatch('activeLoadingOff');
    };
    const getDrafts = async () => {
      simpleStateDispatch('draftLoadingOn');
      await dispatch(fetchDraftPopups());
      simpleStateDispatch('draftLoadingOff');
    };
    const getPast = async () => {
      simpleStateDispatch('pastLoadingOn');
      await dispatch(fetchPastPopups());
      simpleStateDispatch('pastLoadingOff');
    };

    getReasons().catch(captureException);
    getActive().catch(captureException);
    getDrafts().catch(captureException);
    getPast().catch(captureException);
  }, [dispatch, simpleStateDispatch]);

  const pastPopups = useSelector(selectPastPopups);
  const activePopups = useSelector(selectActivePopups);
  const draftPopups = useSelector(selectDraftPopups);

  const renderNoPopups = (popupType: PopupTypes) => {
    return <div className={css.noPopups}>No {popupType} Events</div>;
  };

  const renderSpinner = () => {
    return (
      <div className={css.spinnerContainer}>
        <div className={css.spacer} />
        <Spinner size={48} />
        <HeaderMedium>Loading Pop-ups</HeaderMedium>
        <div className={css.spacer} />
      </div>
    );
  };

  const renderActive = () => {
    if (popupManagerState.loading || popupManagerState.activeLoading) {
      return renderSpinner();
    }
    if (!activePopups.length) {
      return renderNoPopups(PopupTypes.active);
    }
    return (
      <PopupList
        popups={activePopups}
        simpleDispatch={simpleStateDispatch}
        selectedPopup={popupManagerState.selectedPopup}
        testId="active-popups"
        contextualDates
      />
    );
  };

  const renderDraft = () => {
    if (popupManagerState.loading || popupManagerState.draftLoading) {
      return renderSpinner();
    }
    if (!draftPopups.length) {
      return renderNoPopups(PopupTypes.draft);
    }
    return (
      <PopupList
        popups={draftPopups}
        simpleDispatch={simpleStateDispatch}
        selectedPopup={popupManagerState.selectedPopup}
        testId="draft-popups"
        orderDescending
        contextualDates
      />
    );
  };

  const renderPast = () => {
    if (popupManagerState.loading || popupManagerState.pastLoading) {
      return renderSpinner();
    }
    if (!pastPopups.length) {
      return renderNoPopups(PopupTypes.past);
    }
    return (
      <PopupList
        popups={pastPopups}
        simpleDispatch={simpleStateDispatch}
        selectedPopup={popupManagerState.selectedPopup}
        testId="past-popups"
        past
        orderDescending
      />
    );
  };

  const panes = [
    {
      menuItem: 'Active Events',
      render: renderActive,
    },
    {
      menuItem: 'Drafts',
      render: renderDraft,
    },
    {
      menuItem: 'Past Events',
      render: renderPast,
    },
  ];

  return (
    <div className={cx(css.container, settingsCss.column)}>
      <div className={css.topNav}>
        <Header>Pop-up Manager</Header>
        <div className={css.spacer} />
        <PrimaryButton
          className={css.newButton}
          onClick={() => simpleStateDispatch('openCreatePopup')}
          value="New Event"
          data-testid="new-event"
        />
      </div>
      <div className={css.tabContainer}>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} defaultActiveIndex={0} />
      </div>
      <ToolModal {...popupManagerState.toolModal} />
      <DetailsModal simpleStateDispatch={simpleStateDispatch} {...popupManagerState.detailsModal} />
      <ActivateModal {...popupManagerState.activateModal} />
      <DeactivateModal {...popupManagerState.deactivateModal} />
      <DeleteModal {...popupManagerState.deleteModal} />
    </div>
  );
};

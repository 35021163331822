export interface CustomTimeFrameFormData {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export enum TimeFrameOption {
  LastHour = 'Last hour',
  Last3Hours = 'Last 3 hours',
  Last24Hours = 'Last 24 hours',
  CustomTimeFrame = 'Custom time frame',
}

import React from 'react';
import { EdenColors } from 'types/colors';

const InjectionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="20" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0334 14.5385L13.205 17.3669L21.2998 25.4617C22.0361 26.1981 23.23 26.1981 23.9663 25.4617L24.1283 25.2998C24.8646 24.5635 24.8646 23.3697 24.1283 22.6333L16.0334 14.5385ZM11.3193 17.3669L20.357 26.4045C21.6141 27.6616 23.6521 27.6616 24.9091 26.4045L25.0711 26.2426C26.3281 24.9856 26.3281 22.9475 25.0711 21.6905L16.0334 12.6529L11.3193 17.3669Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M14.8005 20.8481L19.5146 16.134L20.4574 17.0768L15.7433 21.7909L14.8005 20.8481Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8005 20.8481L15.7433 21.7909L20.4574 17.0768L19.5146 16.134L14.8005 20.8481Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7908 12.1813L10.848 13.1242L13.2051 15.4812L14.1479 14.5384L11.7908 12.1813ZM8.9624 13.1242L13.2051 17.3668L16.0335 14.5384L11.7908 10.2957L8.9624 13.1242Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M8.49092 14.5384C8.23057 14.2781 8.23057 13.856 8.49092 13.5956L12.2622 9.82438C12.5225 9.56403 12.9446 9.56403 13.205 9.82438C13.4653 10.0847 13.4653 10.5068 13.205 10.7672L9.43373 14.5384C9.17338 14.7988 8.75127 14.7988 8.49092 14.5384Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49092 13.5956C8.23057 13.856 8.23057 14.2781 8.49092 14.5384C8.75127 14.7988 9.17338 14.7988 9.43373 14.5384L13.205 10.7672C13.4653 10.5068 13.4653 10.0847 13.205 9.82438C12.9446 9.56403 12.5225 9.56403 12.2622 9.82438L8.49092 13.5956Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M25.4616 27.7377C25.2013 27.4774 25.2013 27.0553 25.4616 26.7949C25.722 26.5346 26.1441 26.5346 26.4044 26.7949L28.2901 28.6805C28.5504 28.9409 28.5504 29.363 28.2901 29.6233C28.0297 29.8837 27.6076 29.8837 27.3473 29.6233L25.4616 27.7377Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4044 26.7949L28.2901 28.6805C28.5504 28.9409 28.5504 29.363 28.2901 29.6233C28.0297 29.8837 27.6076 29.8837 27.3473 29.6233L25.4616 27.7377C25.2013 27.4774 25.2013 27.0553 25.4616 26.7949C25.722 26.5346 26.1441 26.5346 26.4044 26.7949Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M23.1045 26.3236L24.9901 24.438L26.4043 25.8522C26.925 26.3729 26.925 27.2171 26.4043 27.7378C25.8836 28.2585 25.0394 28.2585 24.5187 27.7378L23.1045 26.3236Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1045 26.3236L24.5187 27.7378C25.0394 28.2585 25.8836 28.2585 26.4043 27.7378C26.925 27.2171 26.925 26.3729 26.4043 25.8522L24.9901 24.438L23.1045 26.3236Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M17.6291 23.6763C17.3687 23.4159 17.3687 22.9938 17.6291 22.7335L19.0433 21.3193C19.3037 21.0589 19.7258 21.0589 19.9861 21.3193C20.2465 21.5796 20.2465 22.0017 19.9861 22.2621L18.5719 23.6763C18.3115 23.9366 17.8895 23.9366 17.6291 23.6763Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6291 22.7335C17.3687 22.9938 17.3687 23.4159 17.6291 23.6763C17.8895 23.9366 18.3115 23.9366 18.5719 23.6763L19.9861 22.2621C20.2465 22.0017 20.2465 21.5796 19.9861 21.3193C19.7258 21.0589 19.3037 21.0589 19.0433 21.3193L17.6291 22.7335Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M19.5146 25.562C19.2543 25.3016 19.2543 24.8796 19.5146 24.6192L20.9289 23.205C21.1892 22.9446 21.6113 22.9446 21.8717 23.205C22.132 23.4654 22.132 23.8874 21.8717 24.1478L20.4575 25.562C20.1971 25.8224 19.775 25.8224 19.5146 25.562Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5146 24.6192C19.2543 24.8796 19.2543 25.3016 19.5146 25.562C19.775 25.8224 20.1971 25.8224 20.4575 25.562L21.8717 24.1478C22.132 23.8874 22.132 23.4654 21.8717 23.205C21.6113 22.9446 21.1892 22.9446 20.9289 23.205L19.5146 24.6192Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M10.3767 19.2523C10.1163 18.992 10.1163 18.5698 10.3767 18.3095L16.9763 11.7098C17.2367 11.4495 17.6588 11.4495 17.9191 11.7098C18.1795 11.9702 18.1795 12.3923 17.9191 12.6526L11.3195 19.2523C11.0591 19.5126 10.637 19.5126 10.3767 19.2523Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3767 18.3095C10.1163 18.5698 10.1163 18.992 10.3767 19.2523C10.637 19.5126 11.0591 19.5126 11.3195 19.2523L17.9191 12.6526C18.1795 12.3923 18.1795 11.9702 17.9191 11.7098C17.6588 11.4495 17.2367 11.4495 16.9763 11.7098L10.3767 18.3095Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default InjectionIcon;

import React from 'react';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { Ellipse } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { getPatientNextStepsAnyOverdue, getPatientNextStepsAnyDueToday } from 'reducers/patients';
import css from 'components/features/InfoPane/NextStepPresenceDot.module.css';

export const NextStepPresenceDot = () => {
  const activePatient = useActivePatient();
  const areNextStepsOverdue = useParameterizedSelector(
    getPatientNextStepsAnyOverdue,
    activePatient.id,
  );
  const areNextStepsDueToday = useParameterizedSelector(
    getPatientNextStepsAnyDueToday,
    activePatient.id,
  );

  const calculateColor = ({ areNextStepsDueToday }) => {
    if (areNextStepsOverdue) {
      return EdenColors.RazzDarken20;
    } else if (areNextStepsDueToday) {
      return EdenColors.Lemon;
    } else {
      return EdenColors.Transparent;
    }
  };

  const color = calculateColor({ areNextStepsDueToday });

  return <Ellipse className={css.nextStepDot} color={color} />;
};

export default NextStepPresenceDot;

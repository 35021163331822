import * as React from 'react';
import { ReactNode } from 'react';
import cx from 'classnames';
import { EdenColors } from '../../types/colors';
import css from './Alert.module.css';
import { AlertCircleIcon } from './svg';

interface Props {
  severity: 'error' | 'info';
  children: ReactNode;
  icon?: ReactNode;
  hideIcon?: boolean;
  center?: boolean;
  inline?: boolean;
}

const classNames: Record<Props['severity'], string> = {
  error: css.error,
  info: css.info,
};

const defaultIcons: Record<Props['severity'], ReactNode> = {
  error: <AlertCircleIcon color={EdenColors.Razz} />,
  info: <AlertCircleIcon color={EdenColors.Lemon20} />,
};

const Alert = ({
  severity,
  children,
  icon,
  hideIcon = false,
  center = false,
  inline = true,
}: Props) => {
  return (
    <div
      className={cx(css.alert, classNames[severity], center && css.center, inline && css.inline)}
    >
      {!hideIcon && <div className={css.icon}>{icon || defaultIcons[severity]}</div>} {children}
    </div>
  );
};

export default Alert;

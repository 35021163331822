import React, { cloneElement, forwardRef, isValidElement, useRef } from 'react';
import type { CSSProperties, Key, ReactNode, RefObject } from 'react';
import cx from 'classnames';
import { useMenuTriggerState, useTreeState } from 'react-stately';
import type { TreeState } from 'react-stately';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useButton,
  useFocusRing,
  useMenu,
  useMenuItem,
  useMenuTrigger,
  useOverlay,
  useOverlayPosition,
} from 'react-aria';
import type { FocusStrategy, Node } from '@react-types/shared';
import type { Placement } from '@react-types/overlays';
import css from './DropdownMenu.module.css';

export interface MenuItemOptionProps<T> {
  item: Node<T>;
  state: TreeState<T>;
  onAction: (key: Key) => void;
}

const DropdownMenuItem = <T,>({ item, state, onAction }: MenuItemOptionProps<T>) => {
  const itemRef = useRef<HTMLLIElement>(null);
  const isDisabled = state.disabledKeys.has(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled,
      onAction,
    },
    state,
    itemRef,
  );

  return (
    <li {...menuItemProps} ref={itemRef} className={cx(css.item, isFocused && css.focusedItem)}>
      {item.rendered}
    </li>
  );
};

export interface DropdownMenuPopupProps {
  className?: string;
  children: any;
  onClose: () => void;
  onAction: (key: Key) => void;
  autoFocus: FocusStrategy;
  style?: CSSProperties;
}

const DropdownMenuPopup = forwardRef<HTMLDivElement, DropdownMenuPopupProps>(
  ({ onAction, onClose, style, ...props }, overlayRef) => {
    const state = useTreeState({ ...props, selectionMode: 'none' });
    const menuRef = useRef<HTMLUListElement>(null);
    const { menuProps } = useMenu(props, state, menuRef);
    const { overlayProps } = useOverlay(
      {
        onClose,
        shouldCloseOnBlur: true,
        isOpen: true,
        isDismissable: true,
      },
      overlayRef as RefObject<HTMLDivElement>,
    );

    // TODO: Fix type
    return (
      <FocusScope contain restoreFocus>
        {/* eslint-disable-next-line */
        /* @ts-ignore */}
        <div {...mergeProps(overlayProps, props)} style={style} ref={overlayRef}>
          <DismissButton onDismiss={onClose} />
          <ul {...menuProps} ref={menuRef} className={css.items}>
            {[...state.collection].map((item) => (
              <DropdownMenuItem key={item.key} item={item} state={state} onAction={onAction} />
            ))}
          </ul>
          <DismissButton onDismiss={onClose} />
        </div>
      </FocusScope>
    );
  },
);
DropdownMenuPopup.displayName = 'DropdownMenuPopup';

export interface DropdownMenuProps {
  /**
   * Component to click on to open the menu.
   * Gets a prop `isOpen` passed to it.
   */
  menuTrigger: any; //ReactNode;
  /**
   * Label for assistive devices.
   */
  label?: string;
  /**
   * Placement of the popup when the dropdown is open.
   */
  placement?: Placement;
  /**
   * Called when the user chooses an option.
   * @param key - The key of the item that was chosen.
   */
  onAction: (key: Key) => void;
  /**
   * Called when the dropdown popup is closed.
   */
  onClose?: () => void;
  children: ReactNode | ReactNode[];
}

/**
 * An accessible menu of actions in a dropdown popup, with full keyboard support.
 *
 * Any component can be passed in as the `menuTrigger` prop to be used to open
 * the dropdown popup. The component gets a boolean prop `isOpen` passed to it
 * that keeps track of whether the popup is open or closed.
 *
 * The popup is a global element created through a portal and positioned
 * dynamically to support scenarios where the asked `placement` might lead to
 * an overflow, for example if the popup is too close to a border, or if the
 * viewport is resized.
 *
 * The popup captures the focus so that the user can cycle through the options with `↓` and `↑`
 * without leaving the component. Focus is restored back to the dropdown button once the
 * popup is closed.
 *
 * The width of the popup is calculated to fit the widest item.
 */
export const DropdownMenu = ({
  menuTrigger,
  label = 'menu',
  onAction,
  placement = 'bottom left',
  onClose,
  children,
}: DropdownMenuProps) => {
  const ref = useRef(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const state = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({ type: 'menu' }, state, ref);

  const { buttonProps } = useButton({ ...menuTriggerProps, elementType: 'div' }, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: ref,
    overlayRef,
    placement,
    offset: 8,
    isOpen: state.isOpen,
  });

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        {...buttonProps}
        {...focusProps}
        aria-label={label}
        ref={ref}
        className={cx(css.menuTrigger, isFocusVisible && `focus-ring`)}
      >
        {
          // TODO: Fix type
          isValidElement(menuTrigger)
            ? // eslint-disable-next-line
              // @ts-ignore
              cloneElement(menuTrigger, { isOpen: state.isOpen })
            : menuTrigger
        }
      </div>
      {state.isOpen && (
        <OverlayContainer>
          <DropdownMenuPopup
            ref={overlayRef}
            {...positionProps}
            {...menuProps}
            autoFocus={state.focusStrategy}
            onClose={() => {
              state.close();

              if (onClose) {
                onClose();
              }
            }}
            onAction={onAction}
          >
            {children}
          </DropdownMenuPopup>
        </OverlayContainer>
      )}
    </div>
  );
};

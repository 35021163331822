import React, { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import css from './ToolModal.module.css';
import { EdenColors } from 'types/colors';
import { useAppDispatch } from 'lib/hooks';
import { EligibleMembers, Popup, PopupState, TimeZone } from 'types/tables/popups';
import {
  DayPicker,
  Field,
  formatDateForDayPicker,
  Label,
  PrimaryButton,
  SelectAccessible,
  TextArea,
  Checkbox,
} from 'components/design-system/Form';
import { fetchSponsorDivisions, getSponsorDivisionsById, getSponsors } from 'reducers/sponsors';
import { HeaderMedium, Warning } from 'components/design-system/Text';
import { StateKey } from 'types/geo';
import { createPopup, updatePopup } from 'reducers/popups';
import { popupReasonsSelectors } from 'reducers/popupReasons';
import { Store } from 'types/redux';
import { athenaProvidersSelectors } from 'reducers/athenaProviders';
import { XIcon, FileText } from 'components/ui/svg';
import formCss from 'components/design-system/Form.module.css';
import SubpopulationDropdown from 'components/features/PopupManager/SubpopulationDropdown';

export enum ToolModalPurpose {
  Create = 'Create',
  Edit = 'Edit',
}

export interface ToolModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  purpose: ToolModalPurpose;
  popup?: Popup;
}

export interface ToolModalText {
  title: string;
  submitButtonText: string;
  submitButtonTestId: string;
}

const toolText: Record<string, ToolModalText> = {
  [ToolModalPurpose.Create]: {
    title: 'Create a New Pop-up',
    submitButtonText: 'Create ',
    submitButtonTestId: 'create-button',
  },
  [ToolModalPurpose.Edit]: {
    title: 'Edit Pop-up',
    submitButtonText: 'Save',
    submitButtonTestId: 'edit-button',
  },
};

type ToolFormState = {
  popup: Popup;
  recurringPopup: boolean;
  zipError: boolean;
  dateError: boolean;
  endDateError: boolean;
  divisionsError: boolean;
  submitting: boolean;
};

const initialFormState: ToolFormState = {
  popup: {
    id: '',
    sponsorId: '',
    date: '',
    endDate: '',
    athenaReasonId: 0,
    athenaSchedulingProviderId: 0,
    popupState: PopupState.draft,
    instructions: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    name: '',
    timeZoneId: TimeZone.Eastern,
    eligibleMembers: EligibleMembers.EmployeesOnly,
    divisionIds: [],
  },
  recurringPopup: false,
  zipError: false,
  dateError: false,
  endDateError: false,
  divisionsError: false,
  submitting: false,
};

export const validZip = (z: string) => z.match(/^\d{5}$/) !== null;
export const validDate = (d: string) =>
  DateTime.fromISO(d) >= DateTime.now().toUTC().startOf('day');
export const validEndDate = (start: string, end: string) =>
  DateTime.fromISO(end) >= DateTime.fromISO(start);

export const formValid = (formState) =>
  formState.popup.sponsorId &&
  validDate(formState.popup.date) &&
  validEndDate(formState.popup.date, formState.popup.endDate) &&
  formState.popup.athenaReasonId &&
  formState.popup.address1 &&
  formState.popup.city &&
  formState.popup.state &&
  formState.popup.zip &&
  validZip(formState.popup.zip) &&
  formState.popup.instructions &&
  formState.popup.athenaSchedulingProviderId &&
  formState.popup.timeZoneId &&
  formState.popup.eligibleMembers &&
  formState.popup.name;

export const ToolModal = ({ isOpen, closeModal, purpose, popup }: ToolModalProps) => {
  if (purpose === ToolModalPurpose.Edit && !popup)
    throw 'Attempt to use ToolModal to edit without popup';

  const initialForm: ToolFormState =
    purpose === ToolModalPurpose.Edit && popup
      ? {
          ...initialFormState,
          popup: { ...popup, address2: popup.address2 ?? '' },
        }
      : initialFormState;

  const dispatch = useAppDispatch();

  const stateReducer = (state: ToolFormState, action): ToolFormState => {
    switch (action.type) {
      case 'date':
      case 'endDate':
      case 'sponsorId':
      case 'address1':
      case 'address2':
      case 'city':
      case 'state':
      case 'zip':
      case 'athenaReasonId':
      case 'athenaSchedulingProviderId':
      case 'instructions':
      case 'timeZoneId':
      case 'eligibleMembers':
      case 'name':
      case 'divisionIds':
        return { ...state, popup: { ...state.popup, [action.type]: action.payload } };
      case 'recurringPopup':
      case 'dateError':
      case 'endDateError':
      case 'zipError':
      case 'divisionsError':
        return { ...state, [action.type]: action.payload };
      case 'submittingOn':
        return { ...state, submitting: true };
      case 'submittingOff':
        return { ...state, submitting: false };
      case 'reset':
        return initialForm;
      default:
        return state;
    }
  };

  const [formState, stateDispatch] = useReducer(stateReducer, initialFormState);
  const simpleStateDispatch = useCallback(
    (type: string, payload?: any) => stateDispatch({ type, payload }),
    [stateDispatch],
  );
  useEffect(() => simpleStateDispatch('reset'), [simpleStateDispatch, purpose, popup]);

  const sponsors = useSelector(getSponsors);

  const fetchDivisions = useCallback(async () => {
    simpleStateDispatch('divisionsError', false);
    if (formState.popup.sponsorId) {
      const divisionsResp = await dispatch(fetchSponsorDivisions(formState.popup.sponsorId));
      if (fetchSponsorDivisions.rejected.match(divisionsResp)) {
        simpleStateDispatch('divisionsError', true);
      }
    }
  }, [dispatch, formState.popup.sponsorId, simpleStateDispatch]);

  useEffect(() => {
    fetchDivisions();
  }, [fetchDivisions]);

  const sponsorDivisions = useSelector((state: Store) =>
    getSponsorDivisionsById(state, formState.popup.sponsorId),
  );

  const schedulingProviders = useSelector((state: Store) =>
    athenaProvidersSelectors.selectAll(state),
  );
  const sortedSchedulingProviders = schedulingProviders.sort((p1, p2) => {
    const a = p1.displayName || '';
    const b = p2.displayName || '';
    return a.localeCompare(b);
  });
  const popupReasons = useSelector((state: Store) => popupReasonsSelectors.selectAll(state));
  const sortedSponsors = sponsors.sort((s1, s2) => s1.name.localeCompare(s2.name));

  const create = async () => {
    simpleStateDispatch('submittingOn');
    const res = await dispatch(createPopup(formState.popup));
    simpleStateDispatch('submittingOff');
    if (createPopup.fulfilled.match(res)) {
      closeModal();
    } else toast.error('Error creating pop-up, please try again.');
  };

  const update = async () => {
    simpleStateDispatch('submittingOn');
    if (!popup) throw 'Attempt to update undefined popup.';
    // Payload is limited to what can be edited on this page.
    const payload = _.pick(formState.popup, [
      'date',
      'endDate',
      'athenaReasonId',
      'athenaSchedulingProviderId',
      'instructions',
      'address1',
      'address2',
      'city',
      'state',
      'zip',
      'timeZoneId',
      'eligibleMembers',
      'name',
      'divisionIds',
    ]);
    const res = await dispatch(updatePopup({ popupId: popup.id, payload }));
    simpleStateDispatch('submittingOff');
    if (!updatePopup.fulfilled.match(res)) {
      toast.error('Changes could not be saved, please try again.');
      simpleStateDispatch('submittingOff');
      return;
    }
    simpleStateDispatch('submittingOff');
    closeModal();
  };

  const savePopup = async () => {
    if (purpose === ToolModalPurpose.Create) await create();
    else await update();
  };

  const checkDateError = (d: string) => {
    simpleStateDispatch('dateError', !validDate(d));
  };
  const checkEndDateError = (start: string, end: string) => {
    simpleStateDispatch('endDateError', !validEndDate(start, end));
  };
  const checkZipError = (s: string) => {
    simpleStateDispatch('zipError', !validZip(s));
  };
  const setRecurringPopup = (popup: Popup) => {
    if (purpose !== ToolModalPurpose.Create) {
      if (popup.date != popup.endDate) {
        simpleStateDispatch('recurringPopup', true);
      }
    }
  };

  return (
    <ReactModal
      onClose={closeModal}
      onAfterClose={() => simpleStateDispatch('reset')}
      isOpen={isOpen}
      onAfterOpen={() => setRecurringPopup(formState.popup)}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <div className={css.topNav} data-testid="popup-tool-top-nav">
        <div className={css.fileIcon}>
          <FileText />
        </div>
        <div className={css.title}>
          <HeaderMedium>{toolText[purpose].title}</HeaderMedium>
        </div>
        <div className={css.xIcon}>
          <XIcon onClick={closeModal} />
        </div>
      </div>
      <div className={css.content}>
        <form>
          <div className={css.requiredText}>
            <Warning>* indicates required field </Warning>
          </div>
          <Label text="Pop-up Title" required>
            <Field
              value={formState.popup.name}
              className={css.fullWidth}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                simpleStateDispatch('name', e.target.value)
              }
              data-testid="name"
              placeholder="Sponsor - Pop-up Event Type"
            />
          </Label>
          <Label
            text="Employer Sponsor"
            required
            errorText={formState.divisionsError ? 'Unable to pull subpopulation data.' : ''}
          >
            <SelectAccessible
              className={css.commonSelect}
              value={formState.popup.sponsorId}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                simpleStateDispatch('sponsorId', e.target.value);
                simpleStateDispatch('divisionIds', []);
              }}
              data-testid="sponsor"
              disabled={purpose !== ToolModalPurpose.Create}
            >
              {sortedSponsors.map((s) => (
                <option key={s.id} value={s.id} data-testid={`sponsor-${s.id}`}>
                  {s.name}
                </option>
              ))}
            </SelectAccessible>
          </Label>
          {sponsorDivisions && sponsorDivisions.length > 0 && (
            <SubpopulationDropdown
              sponsorDivisions={sponsorDivisions}
              selectedDivisions={formState.popup.divisionIds || []}
              setSelectedDivisions={(divisionIds) =>
                simpleStateDispatch('divisionIds', divisionIds)
              }
            />
          )}
          <div className={formState.dateError ? css.startDateGridError : css.startDateGrid}>
            <Label
              text={formState.recurringPopup ? 'Date Range Start' : 'Event Date'}
              required
              style={{ width: 254 }}
              errorText={formState.dateError ? 'Date must be in the future' : ''}
            >
              <DayPicker
                data-testid="day-picker"
                className={css.day}
                error={formState.dateError}
                value={formState.popup.date ? formatDateForDayPicker(formState.popup.date) : ''}
                disabled={formState.popup && formState.popup.popupState === 'active'}
                min={formatDateForDayPicker(DateTime.now().toISO())}
                max={formatDateForDayPicker(DateTime.now().plus({ days: 90 }).toISO())}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const isoDate = DateTime.fromFormat(e.target.value, 'yyyy-MM-dd').toISO();
                  simpleStateDispatch('date', isoDate);
                  //if single day popup, set endDate to date
                  if (!formState.recurringPopup) {
                    simpleStateDispatch('endDate', isoDate);
                  } else if (formState.popup.endDate) {
                    checkEndDateError(isoDate, formState.popup.endDate);
                  }
                  checkDateError(isoDate);
                }}
              />
            </Label>
            {formState.popup.popupState != 'active' && (
              <Label text="Multi-day Popup?">
                <Checkbox
                  checked={formState.recurringPopup}
                  onClick={() => {
                    simpleStateDispatch('recurringPopup', !formState.recurringPopup);
                    //reset endDate error on toggle
                    if (!formState.recurringPopup) {
                      simpleStateDispatch('endDate', formState.popup.date);
                      simpleStateDispatch('endDateError', false);
                    }
                  }}
                  data-testid="recurring-checkbox"
                  height={16}
                  width={16}
                  color={EdenColors.Slate25}
                />
              </Label>
            )}
          </div>

          {formState.recurringPopup && (
            <Label
              text="Date Range End"
              style={{ width: 254 }}
              required
              errorText={
                formState.endDateError ? 'Date Range End cannot be before Date Range Start' : ''
              }
            >
              <DayPicker
                data-testid="end-day-picker"
                className={css.day}
                error={formState.endDateError}
                value={
                  formState.popup.endDate ? formatDateForDayPicker(formState.popup.endDate) : ''
                }
                disabled={formState.popup && formState.popup.popupState === 'active'}
                min={formatDateForDayPicker(DateTime.now().toISO())}
                max={formatDateForDayPicker(DateTime.now().plus({ days: 90 }).toISO())}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const isoDate = DateTime.fromFormat(e.target.value, 'yyyy-MM-dd').toISO();
                  simpleStateDispatch('endDate', isoDate);
                  checkEndDateError(formState.popup.date, isoDate);
                }}
              />
            </Label>
          )}

          <Label text="Booking Timezone" required>
            <SelectAccessible
              className={css.commonSelect}
              value={formState.popup.timeZoneId}
              disabled={formState.popup && formState.popup.popupState === 'active'}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                simpleStateDispatch('timeZoneId', e.target.value)
              }
              data-testid="timeZone"
            >
              {Object.values(TimeZone).map((tz) => (
                <option key={tz} value={tz} data-testid={`tz-${tz}`}>
                  {tz}
                </option>
              ))}
            </SelectAccessible>
          </Label>

          <div className={css.addressGrid}>
            <Label text="Street Address" required>
              <Field
                value={formState.popup.address1}
                className={css.fullWidth}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  simpleStateDispatch('address1', e.target.value)
                }
                data-testid="address1"
              />
            </Label>
            <Label text="Room/Suite/Floor">
              <Field
                value={formState.popup.address2}
                className={css.fullWidth}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  simpleStateDispatch('address2', e.target.value)
                }
                data-testid="address2"
              />
            </Label>
          </div>
          <div className={css.cityStateZipGrid}>
            <Label text="City" required>
              <Field
                value={formState.popup.city}
                className={css.fullWidth}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  simpleStateDispatch('city', e.target.value)
                }
                data-testid="city"
              />
            </Label>
            <Label text="State" required>
              <SelectAccessible
                style={{ height: 40 }}
                value={formState.popup.state}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  simpleStateDispatch('state', e.target.value)
                }
                data-testid="state"
              >
                {Object.keys(StateKey).map((k) => (
                  <option
                    key={`stateKey_${StateKey[k]}`}
                    value={StateKey[k]}
                    data-testid={`state-${StateKey[k]}`}
                  >
                    {StateKey[k]}
                  </option>
                ))}
              </SelectAccessible>
            </Label>
            <Label text="Zipcode" required errorText={formState.zipError ? 'Must be 5 digits' : ''}>
              <Field
                type="number"
                maxLength={5}
                value={formState.popup.zip}
                className={css.fullWidth}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  simpleStateDispatch('zip', e.target.value);
                  checkZipError(e.target.value);
                }}
                error={formState.zipError}
                data-testid="zip"
              />
            </Label>
          </div>
          <Label text="Pop-up Type" required>
            <SelectAccessible
              value={
                formState.popup.athenaReasonId
                  ? formState.popup.athenaReasonId.toString()
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                simpleStateDispatch('athenaReasonId', e.target.value)
              }
              className={css.commonSelect}
              data-testid="popup-type"
              disabled={formState.popup && formState.popup.popupState === 'active'}
            >
              {popupReasons.map((r) => (
                <option
                  key={`athenaReason_${r.reasonId}`}
                  value={r.reasonId.toString()}
                  data-testid={`popupReason-${r.reasonId}`}
                >
                  {r.description}
                </option>
              ))}
            </SelectAccessible>
          </Label>
          <Label text="Eligible Members" required>
            <SelectAccessible
              value={formState.popup.eligibleMembers}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                simpleStateDispatch('eligibleMembers', e.target.value)
              }
              className={css.commonSelect}
              data-testid="eligible-members"
              disabled={formState.popup && formState.popup.popupState === 'active'}
            >
              {Object.values(EligibleMembers).map((em) => (
                <option key={em} value={em} data-testid={`em-${em}`}>
                  {_.startCase(em)}
                </option>
              ))}
            </SelectAccessible>
          </Label>
          <Label
            text="Scheduling Provider"
            required
            captionText="Only scheduling providers already in Athena will appear in this list."
          >
            <SelectAccessible
              value={
                formState.popup.athenaSchedulingProviderId
                  ? formState.popup.athenaSchedulingProviderId.toString()
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                simpleStateDispatch('athenaSchedulingProviderId', e.target.value)
              }
              data-testid="scheduling-provider"
              className={css.schedulingProvider}
              disabled={formState.popup && formState.popup.popupState === 'active'}
            >
              {sortedSchedulingProviders.map((ap) => (
                <option
                  key={`athenaProvider_${ap.athenaProviderId}`}
                  value={ap.athenaProviderId.toString()}
                  data-testid={`scheduling-provider-${ap.athenaProviderId}`}
                >
                  {ap.displayName}
                </option>
              ))}
            </SelectAccessible>
          </Label>
          <Label text="Instructions" required captionText="240 characters max">
            <TextArea
              className={cx(formCss.field, css.instructions)}
              value={formState.popup.instructions}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                simpleStateDispatch('instructions', e.target.value)
              }
              maxLength={240}
              data-testid="instructions"
            />
          </Label>
        </form>
      </div>
      <div className={css.bottomNav}>
        <PrimaryButton
          className={cx(css.button, css.cancel)}
          type="button"
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          type="submit"
          className={css.button}
          onClick={savePopup}
          value={toolText[purpose].submitButtonText}
          disabled={!formValid(formState)}
          loading={formState.submitting}
          data-testid={toolText[purpose].submitButtonTestId}
        />
      </div>
    </ReactModal>
  );
};

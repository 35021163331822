import React from 'react';

import css from './VisitLocation.module.css';

import { MapPin, MessageSquare, Video } from 'components/ui/svg';
import { VisitLocation as Location } from 'types/tables/messages';
import { BodySmall } from 'components/design-system/Text';

interface Props {
  location: Location;
  departmentId: number;
}

const departmentIcons = {
  2: <MessageSquare className={css.icon} width={18} height={18} />,
  8: <Video className={css.icon} width={18} height={18} />,
};

const departmentsWithNoAddress = [2, 8];

const VisitLocation = ({ location, departmentId }: Props) => {
  const showAddress = !departmentsWithNoAddress.includes(departmentId);

  return (
    <div className={css.visitLocation}>
      {departmentIcons[departmentId] || <MapPin className={css.icon} width={18} height={18} />}

      <div className={css.body}>
        <BodySmall className={css.name}>{location.name}</BodySmall>

        {showAddress && (
          <>
            {!!location.address1 && <BodySmall className={css.line}>{location.address1}</BodySmall>}
            {!!location.address2 && <BodySmall className={css.line}>{location.address2}</BodySmall>}
            {!!location.city && (
              <BodySmall className={css.line}>
                {location.city}, {location.state} {location.zip}
              </BodySmall>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VisitLocation;

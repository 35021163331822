import { Member } from 'sendbird';
import { BaseRecord, ForeignKey, PrimaryKey } from 'types/tables/base';
import { MessageId, PrimaryMessage } from 'types/tables/messages';
import { Patient, PatientId } from 'types/tables/patients';
import { ChannelUrl } from 'types/sendbird';

export enum ChannelStatus {
  Open = 'open',
  Pending = 'pending',
  Archived = 'archived',
}

export enum ChannelType {
  Event = 'event',
  Internal = 'internal',
  Primary = 'primary',
}

export interface BaseChannelForeignKeys {
  messages: ForeignKey[];
  pendingMessages: ForeignKey[];
}

export type SerializedMember = Pick<Member, 'userId'>;

export type PrimaryChannelId = string;
export type EventChannelId = string;

export interface BaseChannel extends BaseRecord, BaseChannelForeignKeys {
  id: PrimaryKey;
  patientId: ForeignKey;
  lastRead: number;
  typingMembers: SerializedMember[];
  channelType: ChannelType;
  sendbirdChannelUrl: string;
  updatedAt: string;
  createdAt?: number;
  hasMoreMessages: boolean;
}

export interface PrimaryChannel extends BaseChannel {
  channelType: ChannelType.Primary;
  status: ChannelStatus;
  isPatientOnline: boolean;
  lastMessage?: PrimaryMessage;
}

export interface EventChannel extends BaseChannel {
  channelType: ChannelType.Event;
}

export interface InternalChannel extends BaseChannel {
  channelType: ChannelType.Internal;
}

export type Channel = PrimaryChannel | EventChannel | InternalChannel;

export interface SidebarChannel {
  patient: Patient;
  patientName: string;
  channel: PrimaryChannel;
}

/**
 * Relationship data about Sendbird channels provided by Grdn.
 */
export interface GrdnSendbirdChannel {
  /**
   * Establishes a denormalized many-to-one relationship with patients. Each
   * patient can have many Sendbird channels.
   */
  patientId: PatientId;

  /**
   * The Sendbird channel URL. The Sendbird SDK uses this to subscribe to events
   * for this channel.
   */
  channelUrl: ChannelUrl;
}

/**
 * Sendbird channel data solely maintained by the Sendbird SDK.
 */
export interface SendbirdManagedChannelData {
  /**
   * Set of Sendbird user IDs that are currently typing in the Sendbird channel.
   */
  typingUserIds: Member['userId'][];

  /**
   * List of delivered message IDs currently loaded in the `messages` slice for
   * this channel.
   */
  messageIds: MessageId[];

  /**
   * List of message IDs currently loaded in the `messages` slice and marked as
   * pending for this channel.
   */
  pendingMessageIds: MessageId[];

  /**
   * Timestamp of the last message the patient read from this channel.
   */
  lastReadAt: number;
}

/**
 * Sendbird-managed channel data.
 *
 * Sendbird channels are not the same as our internal concept of channels,
 * although there is a one-to-one relationship between these concepts.
 */
export type SendbirdManagedChannel = GrdnSendbirdChannel & SendbirdManagedChannelData;

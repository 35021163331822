import React from 'react';
import { useSelector } from 'react-redux';

import css from '../Settings.module.css';
import { getUser } from 'reducers/user';

const ProfileView = () => {
  const user = useSelector(getUser);
  const { name, email } = user;
  return (
    <div className={css.column}>
      You are logged in as {name} ({email})
    </div>
  );
};

export default ProfileView;

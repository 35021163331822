import React, { useState } from 'react';
import cx from 'classnames';
import css from 'components/features/MedicalSummary/MedicalSummary.module.css';
import itemCss from 'components/features/MedicalSummary/MedicalSummaryItem.module.css';
import Careteam from 'components/features/MedicalSummary/Careteam';
import ChartOverview from 'components/features/MedicalSummary/ChartOverview';
import ExternalAddons from 'components/features/MedicalSummary/ExternalAddons';
import HealthQuestionnaires from 'components/features/MedicalSummary/HealthQuestionnaires';
import Insurance from 'components/features/MedicalSummary/Insurance';
import PharmacyOverview from 'components/features/MedicalSummary/PharmacyOverview';

const MedicalSummary = () => {
  const [modifyCareteamModalOpen, setModifyCareteamModalOpen] = useState(false);
  const closeModifyCareteamModal = () => {
    setModifyCareteamModalOpen(false);
  };

  return (
    <div className={css.container}>
      <div>
        <div className={cx(itemCss.label, itemCss.careteamLabel)}>
          <div>Care Team</div>
          <div
            className={css.modifyButton}
            onClick={() => {
              setModifyCareteamModalOpen(true);
            }}
          >
            Modify
          </div>
        </div>
        <Careteam
          modifyModalOpen={modifyCareteamModalOpen}
          closeModifyModal={closeModifyCareteamModal}
        />
      </div>

      <ChartOverview />
      <PharmacyOverview />
      <Insurance />
      <HealthQuestionnaires />
      <ExternalAddons />
    </div>
  );
};

export default MedicalSummary;

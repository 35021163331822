import React from 'react';
import { EdenColors } from 'types/colors';

const HealthAssessmentIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5996 17.6001C33.5996 16.2746 34.6741 15.2001 35.9996 15.2001C37.3251 15.2001 38.3996 16.2746 38.3996 17.6001V33.8423L35.9996 37.4423L33.5996 33.8423V17.6001ZM35.9996 16.8001C35.5578 16.8001 35.1996 17.1582 35.1996 17.6001V33.3578L35.9996 34.5578L36.7996 33.3578V17.6001C36.7996 17.1582 36.4414 16.8001 35.9996 16.8001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5999 20.8001H34.3999V19.2001H37.5999V20.8001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7996 11.2001C11.916 11.2001 11.1996 11.9164 11.1996 12.8001V35.1995C11.1996 36.0831 11.916 36.7995 12.7996 36.7995H28.7996C29.6833 36.7995 30.3996 36.0831 30.3996 35.1995V12.8001C30.3996 11.9164 29.6833 11.2001 28.7996 11.2001H12.7996ZM9.59961 12.8001C9.59961 11.0328 11.0323 9.6001 12.7996 9.6001H28.7996C30.5669 9.6001 31.9996 11.0328 31.9996 12.8001V35.1995C31.9996 36.9668 30.5669 38.3995 28.7996 38.3995H12.7996C11.0323 38.3995 9.59961 36.9668 9.59961 35.1995V12.8001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7998 16.8C20.7998 16.3582 21.158 16 21.5998 16H27.9998C28.4416 16 28.7998 16.3582 28.7998 16.8C28.7998 17.2418 28.4416 17.6 27.9998 17.6H21.5998C21.158 17.6 20.7998 17.2418 20.7998 16.8Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7998 20.0001C20.7998 19.5582 21.158 19.2001 21.5998 19.2001H27.9998C28.4416 19.2001 28.7998 19.5582 28.7998 20.0001C28.7998 20.4419 28.4416 20.8001 27.9998 20.8001H21.5998C21.158 20.8001 20.7998 20.4419 20.7998 20.0001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7998 27.2C20.7998 26.7582 21.158 26.4 21.5998 26.4H27.9998C28.4416 26.4 28.7998 26.7582 28.7998 27.2C28.7998 27.6419 28.4416 28 27.9998 28H21.5998C21.158 28 20.7998 27.6419 20.7998 27.2Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7998 30.4001C20.7998 29.9583 21.158 29.6001 21.5998 29.6001H27.9998C28.4416 29.6001 28.7998 29.9583 28.7998 30.4001C28.7998 30.8419 28.4416 31.2001 27.9998 31.2001H21.5998C21.158 31.2001 20.7998 30.8419 20.7998 30.4001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3998 27.2001V29.6001H16.7998V27.2001H14.3998ZM13.5998 25.6001H17.5998C18.0416 25.6001 18.3998 25.9583 18.3998 26.4001V30.4001C18.3998 30.8419 18.0416 31.2001 17.5998 31.2001H13.5998C13.158 31.2001 12.7998 30.8419 12.7998 30.4001V26.4001C12.7998 25.9583 13.158 25.6001 13.5998 25.6001Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9655 16.2344C19.2779 16.5468 19.2779 17.0534 18.9655 17.3658L15.1998 21.1315L13.0341 18.9658C12.7217 18.6534 12.7217 18.1468 13.0341 17.8344C13.3465 17.522 13.8531 17.522 14.1655 17.8344L15.1998 18.8687L17.8341 16.2344C18.1465 15.922 18.6531 15.922 18.9655 16.2344Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default HealthAssessmentIcon;

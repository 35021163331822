//
// TODO: Drugs are bad. So are files with dozens of components jammed into them. Propose splitting this monolith into smaller files.
//
import React, { HTMLProps, ReactElement, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps, NavbarElementProps } from 'react-day-picker/types/Props';
import css from './Form.module.css';
import { BodySmall, CaptionError, CaptionNormal, Link } from './Text';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SpinnerMedium,
  Square,
  SquareChecked,
  SVGProps,
} from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import 'react-day-picker/lib/style.css';

interface Errorable {
  error?: boolean;
}

interface Loadable {
  loading?: boolean;
}

export const ErrorText: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div className={css.errorText} {...props} />
);

export const Field: React.FC<HTMLProps<HTMLInputElement> & Errorable> = ({
  error = false,
  className,
  ...props
}) => <input className={cx(error && css.error, css.field, className)} {...props} />;

interface OptionProps {
  value: string;
}

export const Option: React.FC<OptionProps & HTMLProps<HTMLDivElement>> = ({ value, ...props }) => (
  <div className={css.option} key={value} {...props} />
);

interface SelectOnlyProps {
  arrow?: boolean;
  values: ReactElement<{ value: string }>[];
  change: (value: string) => void;
  openUp?: boolean;
  classNames?: {
    modalOuter?: string;
    modalInner?: string;
  };
  placeholder?: string;
  'data-testid'?: string;
  disabled?: boolean;
}

export type SelectProps = HTMLProps<HTMLDivElement> & SelectOnlyProps;

export const Select: React.FC<SelectProps> = ({
  className,
  arrow = true,
  values,
  value,
  change,
  openUp,
  style,
  classNames,
  placeholder = 'Select',
  'data-testid': testId,
  disabled = false,
  ...props
}) => {
  const [isOpen, changeOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const valueNode = values.find((node) => node.props.value === `${value}`);

  const select = (
    <div
      style={{
        borderRadius: 4,
        backgroundColor: !isOpen ? EdenColors.White : EdenColors.Slate15,
      }}
      ref={selectRef}
      data-testid={testId}
    >
      <div
        className={cx(
          css.select,
          !arrow && css.arrowless,
          disabled && css.disabled,
          !valueNode && css.placeholder,
        )}
        onClick={() => changeOpen((state) => !state)}
        {...props}
      >
        {valueNode ?? placeholder}
      </div>
    </div>
  );

  const modal = (
    <div
      style={{
        top:
          selectRef.current && selectRef.current.offsetParent
            ? selectRef.current.getBoundingClientRect().y -
              (selectRef.current.offsetParent.getBoundingClientRect().y ?? 0) +
              selectRef.current.clientHeight
            : 'unset',
        visibility: isOpen ? 'visible' : 'hidden',
        bottom:
          selectRef.current && modalRef.current && openUp
            ? // Account for margins that push this down
              selectRef.current.clientHeight + modalRef.current.clientHeight + 24
            : 'unset',
      }}
      className={css.modalOuter}
    >
      <div
        ref={modalRef}
        style={{
          // Account for padding with width setting
          minWidth: selectRef.current ? selectRef.current.clientWidth : 0,
        }}
        className={cx(css.selectModal, classNames?.modalInner)}
      >
        {values.map((v) => (
          <BodySmall
            className={css.selectValue}
            key={v.props.value}
            onClick={() => {
              change(v.props.value);
              changeOpen(false);
            }}
          >
            {v}
          </BodySmall>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {isOpen && <div onClick={() => changeOpen(false)} className={css.selectCover} />}
      <div
        className={className}
        style={{
          ...style,
        }}
      >
        {select}
        {modal}
      </div>
    </>
  );
};

export const SelectAccessible: React.FC<HTMLProps<HTMLSelectElement> & Errorable> = ({
  className,
  children,
  error = false,
  value = '',
  placeholder = 'Select',
  ...props
}) => {
  return (
    <select className={cx(error && css.error, css.selectNew, className)} value={value} {...props}>
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {children}
    </select>
  );
};

export const formatDateForDayPicker = (date?: string) => {
  return date ? DateTime.fromISO(date, { setZone: true }).toFormat('yyyy-MM-dd') : '';
};

export const DayPicker: React.FC<HTMLProps<HTMLInputElement> & Errorable> = ({
  className,
  children,
  error = false,
  ...props
}) => {
  return (
    <input
      type="date"
      className={cx(error && css.error, css.dateInput, css.field, className)}
      {...props}
    >
      {children}
    </input>
  );
};

export const TimePicker: React.FC<HTMLProps<HTMLInputElement> & Errorable> = ({
  className,
  children,
  error = false,
  ...props
}) => {
  return (
    <input
      type="time"
      className={cx(error && css.error, css.timeInput, css.field, className)}
      {...props}
    >
      {children}
    </input>
  );
};

export interface EdenDayPickerProps extends DayPickerInputProps {
  disableBeforeToday: boolean;

  // Whether or not the date picker should close when the user clicks outside of the wrapper.
  closeOnOutsideClick?: boolean;
}

const EdenDayPickerNavbar = ({
  onPreviousClick,
  onNextClick,
  showPreviousButton,
  showNextButton,
}: NavbarElementProps) => (
  <div className={css.dayPickerNavigation}>
    <button
      className={css.monthSelector}
      disabled={!showPreviousButton}
      style={{ marginRight: 8 }}
      onClick={() => onPreviousClick()}
    >
      <ChevronLeftIcon color={showPreviousButton ? EdenColors.SlateDarken20 : EdenColors.Slate55} />
    </button>
    <button className={css.monthSelector} disabled={!showNextButton} onClick={() => onNextClick()}>
      <ChevronRightIcon color={showNextButton ? EdenColors.SlateDarken20 : EdenColors.Slate55} />
    </button>
  </div>
);

export const EdenDayPicker: React.FC<EdenDayPickerProps> = ({
  classNames = { container: '', overlayWrapper: '', overlay: '' },
  onDayChange,
  value,
  disableBeforeToday,
  dayPickerProps,
  closeOnOutsideClick = false,
  ...props
}) => {
  const [isOpen, changeOpen] = useState(false);
  const disabledDays = disableBeforeToday ? [{ before: new Date() }] : [];

  const dayPickerInputRef = useRef<DayPickerInput>(null);

  const hideDayPickerOnOutsideClick = (e) => {
    const dayPicker = dayPickerInputRef.current?.getDayPicker();

    if (dayPicker && !dayPicker.dayPicker.contains(e.target))
      dayPickerInputRef.current?.hideDayPicker();
  };

  useEffect(() => {
    if (closeOnOutsideClick) document.addEventListener('mousedown', hideDayPickerOnOutsideClick);

    return () => {
      if (closeOnOutsideClick)
        document.removeEventListener('mousedown', hideDayPickerOnOutsideClick);
    };
  }, [closeOnOutsideClick, dayPickerInputRef]);

  return (
    <DayPickerInput
      ref={dayPickerInputRef}
      classNames={{
        container: cx(css.fieldContainer, classNames.container, isOpen && css.fieldContainerOpen),
        overlay: cx(css.dayPickerOverlay, classNames.overlay),
        overlayWrapper: cx(css.dayPickerOverlayWrapper, classNames.overlayWrapper),
      }}
      dayPickerProps={{
        disabledDays,
        navbarElement: EdenDayPickerNavbar,
        ...dayPickerProps,
      }}
      onDayChange={onDayChange}
      onDayPickerShow={() => changeOpen(true)}
      onDayPickerHide={() => changeOpen(false)}
      value={value}
      {...props}
    />
  );
};

export const PrimaryButton: React.FC<HTMLProps<HTMLInputElement> & Loadable> = ({
  className,
  loading = false,
  style,
  type = 'button',
  ...props
}) =>
  loading ? (
    <div style={style} className={cx(css.buttonLoading, className)}>
      <div className={css.inner} style={{ width: 24, height: 24 }}>
        <SpinnerMedium height={24} width={24} color={EdenColors.White} />
      </div>
    </div>
  ) : (
    <input
      className={cx(css.button, css.primaryButton, className)}
      style={style}
      type={type}
      {...props}
    />
  );

export const SecondaryButton: React.FC<HTMLProps<HTMLInputElement> & Loadable> = ({
  className,
  loading = false,
  style,
  type = 'button',
  ...props
}) =>
  loading ? (
    <div style={style} className={cx(css.primaryButtonLoading, className)}>
      <div className={css.inner} style={{ width: 24, height: 24 }}>
        <SpinnerMedium height={24} width={24} color={EdenColors.White} />
      </div>
    </div>
  ) : (
    <input
      className={cx(css.button, css.secondaryButton, className)}
      style={style}
      type={type}
      {...props}
    />
  );

export interface LabelProps extends HTMLProps<HTMLLabelElement> {
  text: string;
  required?: boolean;
}

//
// TODO: this is much more of a form group than a simple label, so the name Label is rather misleading. Propose renaming to FormGroup when refactoring makes sense. It's not only clearer, but inline with nomenclature of other UI toolkits.
//
export const Label: React.FC<LabelProps & { errorText?: string; captionText?: string }> = ({
  text,
  className,
  required = false,
  children,
  errorText = '',
  captionText = '',
  ...props
}) => (
  <label {...props} className={css.label}>
    <div className={cx(className, css.labelText, required && css.required)}>{text}</div>
    {children}
    {errorText ? (
      <CaptionError style={{ marginTop: 8 }}>{errorText}</CaptionError>
    ) : captionText ? (
      <CaptionNormal>{captionText}</CaptionNormal>
    ) : (
      <></>
    )}
  </label>
);

export interface LinkWithIconProps extends HTMLProps<HTMLDivElement> {
  text: string;
  href?: string;
  target?: string;
  icon?: ReactElement;
}

export const LinkWithIcon: React.FC<LinkWithIconProps> = ({
  text,
  className,
  href,
  target,
  icon,
  ...props
}) => (
  <div className={cx(css.linkWithIcon, className)} {...props}>
    {icon || (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={EdenColors.Eden}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 2.49992C5.85803 2.49992 2.50016 5.85778 2.50016 9.99992C2.50016 14.1421 5.85803 17.4999 10.0002 17.4999C14.1423 17.4999 17.5002 14.1421 17.5002 9.99992C17.5002 5.85778 14.1423 2.49992 10.0002 2.49992ZM0.833496 9.99992C0.833496 4.93731 4.93755 0.833252 10.0002 0.833252C15.0628 0.833252 19.1668 4.93731 19.1668 9.99992C19.1668 15.0625 15.0628 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0625 0.833496 9.99992Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99984 5.83325C10.4601 5.83325 10.8332 6.20635 10.8332 6.66659V13.3333C10.8332 13.7935 10.4601 14.1666 9.99984 14.1666C9.5396 14.1666 9.1665 13.7935 9.1665 13.3333V6.66659C9.1665 6.20635 9.5396 5.83325 9.99984 5.83325Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.8335 10.0001C5.8335 9.53984 6.20659 9.16675 6.66683 9.16675H13.3335C13.7937 9.16675 14.1668 9.53984 14.1668 10.0001C14.1668 10.4603 13.7937 10.8334 13.3335 10.8334H6.66683C6.20659 10.8334 5.8335 10.4603 5.8335 10.0001Z"
        />
      </svg>
    )}
    <Link style={{ paddingLeft: 14 }} href={href} target={target}>
      {text}
    </Link>
  </div>
);

export interface ListItemProps extends HTMLProps<HTMLDivElement> {
  selected: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({ selected, children, className, ...props }) => (
  <div {...props} className={cx(css.listItemOuter, className)}>
    {selected ? (
      <SquareChecked height={24} width={24} />
    ) : (
      <Square height={24} width={24} color={EdenColors.SlateDarken20} />
    )}
    <div className={css.listItemInner}>{children}</div>
  </div>
);

type CheckboxExtendedType = HTMLProps<HTMLDivElement> & SVGProps;

export interface CheckboxProps extends CheckboxExtendedType {
  checked: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  height = 20,
  width = 20,
  color = EdenColors.Slate85,
  style,
  className,
  ...props
}) => (
  <div
    {...props}
    className={className}
    style={{ ...style, maxHeight: height, maxWidth: width, minHeight: height, minWidth: width }}
  >
    {checked ? (
      <SquareChecked height={height} width={width} />
    ) : (
      <Square color={color} height={height} width={width} />
    )}
  </div>
);

export const TextArea: React.FC<HTMLProps<HTMLTextAreaElement> & Errorable> = ({
  children,
  error = false,
  className,
  ...props
}) => {
  return (
    <textarea className={cx(error && css.error, css.textArea, className)} {...props}>
      {children}
    </textarea>
  );
};

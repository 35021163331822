import React from 'react';
import cx from 'classnames';
import css from './StatusMessage.module.css';
import { Body } from 'components/ui/Typography';
import { EdenColors } from 'types/colors';
import { AlertCircleIcon, Loader, RecurringIcon } from 'components/ui/svg';

interface StatusMessageProps {
  message: string;
  show: boolean;
  onClick?: () => void;
  type: 'loading' | 'error-reload';
}

const StatusMessage = ({ message, show, onClick, type }: StatusMessageProps) => {
  const LoadingMessage = () => {
    return (
      <div className={cx(css.container, css.loading)} onClick={() => onClick && onClick()}>
        <Loader height="20" width="20" color={EdenColors.SlateDarken20} />
        <Body className={css.loadingStatusMessage}>{message}</Body>
      </div>
    );
  };

  const ErrorReloadMessage = () => {
    return (
      <>
        <button className={cx(css.container, css.error)} onClick={() => onClick && onClick()}>
          <AlertCircleIcon height="18" width="18" color={EdenColors.Razz} />
          <Body className={css.loadingStatusMessage}>{message}</Body>
          <div className={css.errorReload} data-testid="error-reload">
            <RecurringIcon height="20" width="20" color={EdenColors.Slate} />
          </div>
        </button>
      </>
    );
  };

  if (show && type === 'loading') {
    return <LoadingMessage />;
  }

  if (show && type === 'error-reload') {
    return <ErrorReloadMessage />;
  }

  return null;
};

export default StatusMessage;

import React from 'react';
import { Segment } from 'semantic-ui-react';
import { getAppName, getEnvironment, getRelease } from 'lib/env';

const About = () => {
  const release = getRelease();
  const environment = getEnvironment();
  const appName = getAppName();

  return (
    <Segment className="About">
      <div>App Name: {appName} </div>
      <div> Environment: {environment} </div>
      <div> Version: {release} </div>
    </Segment>
  );
};

export default About;

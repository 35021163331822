import { DeprecatedPatient } from 'legacy/types/deprecatedPatient';
import { logout } from 'reducers/user';

export const initialState: DeprecatedPatient = {
  mrn: -1,
  id: '',
  sub: '',
  chatId: '',
  firstName: '',
  lastName: '',
  preferredName: '',
  pronouns: '',
  email: '',
  dob: '',
  sex: '',
  mobilePhone: '',
  employerName: '',
  isLoading: false,
  isCheckingOutDigitalVisit: false,
  isGettingDigitalVisit: false,
  isStartingDigitalVisit: false,
  digitalVisit: undefined,
  primaryInsurance: undefined,
  hasHadVideoVisit: false,
  visits: {},
  visitsLoading: false,
  visitsLoadFailure: false,
};

export default function app(state = initialState, action: Record<string, any> = { type: '' }) {
  const { type } = action;

  switch (type) {
    // TODO: When this file gets implemented with redux toolkit, this case needs to be handled in extraReducers
    case logout.fulfilled.type:
      return initialState;

    default:
      return state;
  }
}

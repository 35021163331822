import React, { useState } from 'react';
import { throttle } from 'lodash';
import { Search, SearchProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import settingsCss from '../Settings.module.css';
import css from './Macros.module.css';
import { getMyProvider } from 'reducers/user';
import MacroComponent from 'components/features/Macro/Macro';
import MacroModal from 'components/features/MacroModal/MacroModal';
import MacroDeleteModal from 'components/features/MacroModal/MacroDeleteModal';
import MacroPreviewModal from 'components/features/MacroModal/MacroPreviewModal';
import { AddIcon } from 'components/ui/svg';
import { Header1 } from 'components/ui/Typography';
import { PrimaryButton } from 'components/ui/Buttons';
import { Macro } from 'types/tables/macros';
import { macroSelectors } from 'reducers/macros';

interface MacrosState {
  text: string;
  title: string;
  isOpen: boolean;
  isDeleting: boolean;
  isPreviewing: boolean;
  expandedMacroId: string | undefined;
  editId: string | undefined;
  search: string;
}

const initialMacroState = Object.freeze({
  text: '',
  title: '',
  isOpen: false,
  expandedMacroId: undefined,
  editId: undefined,
  isDeleting: false,
  isPreviewing: false,
  search: '',
});

const Macros = () => {
  const macros = useSelector(macroSelectors.selectAll);
  const myProvider = useSelector(getMyProvider);
  const [
    { text, title, isOpen, expandedMacroId, editId, isDeleting, isPreviewing, search },
    setState,
  ] = useState<MacrosState>(initialMacroState);

  const handleSearchChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    setState((state) => ({
      ...state,
      search: data.value || '',
    }));
  };

  const handleClickMacro = (macro: Macro) => {
    setState((state) => ({
      ...state,
      editId: macro.id,
      isOpen: true,
      text: macro.text,
      title: macro.title,
    }));
  };
  const handleRequestPreview = (text: string, title: string) => {
    setState((state) => ({
      ...state,
      isPreviewing: true,
      text: text,
      title: title,
    }));
  };

  const handleRequestDelete = () => setState((state) => ({ ...state, isDeleting: true }));

  const handleKeepEditing = () =>
    setState((state) => ({
      ...state,
      isDeleting: false,
      isPreviewing: false,
    }));
  const handleModalClose = () => {
    setState((state) => ({
      ...state,
      isOpen: false,
      isDeleting: false,
      isPreviewing: false,
      title: '',
      text: '',
      editId: undefined,
    }));
  };
  const handleClickCreate = () =>
    setState((state) => ({
      ...state,
      isOpen: true,
      editId: undefined,
    }));

  const filterMacros = (macros: Macro[]): Macro[] => {
    const lowerCaseSearch = search.toLowerCase();
    return macros.filter((macro) => {
      return (
        macro.title.toLowerCase().includes(lowerCaseSearch) ||
        macro.text.toLowerCase().includes(lowerCaseSearch)
      );
    });
  };
  const toggleMacroExpand = (macroId: string) =>
    setState((state) => ({
      ...state,
      expandedMacroId: state.expandedMacroId === macroId ? '' : macroId,
    }));
  const renderSearch = () => {
    return (
      <Search
        placeholder="Search by keyword"
        onSearchChange={throttle(handleSearchChange, 500)}
        results={[]}
        input={{ fluid: true }}
        className={css.search}
        open={false}
      />
    );
  };

  const currentMacro = editId ? macros.find((macro) => macro.id === editId) || null : null;
  return (
    <div className={settingsCss.column}>
      <Header1 className={css.header}>Team Macros</Header1>
      <div className={css.top}>
        <div className={css.menu}>{renderSearch()}</div>
        <div>
          <PrimaryButton
            onClick={handleClickCreate}
            content="Create"
            icon={<AddIcon />}
            data-testid="create-macro"
          />
        </div>
      </div>
      {macros &&
        filterMacros(macros).map((macro) => (
          <MacroComponent
            key={macro.id}
            macro={macro}
            myProvider={myProvider}
            expanded={macro.id === expandedMacroId}
            onClickEdit={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              ev.stopPropagation();
              handleClickMacro(macro);
            }}
            onClick={() => toggleMacroExpand(macro.id)}
          />
        ))}
      {isDeleting ? (
        <MacroDeleteModal
          title={currentMacro ? currentMacro.title : ''}
          text={currentMacro ? currentMacro.text : ''}
          myProvider={myProvider}
          isOpen={isOpen}
          onKeepEditing={handleKeepEditing}
          onClose={handleModalClose}
          macro={currentMacro}
        />
      ) : isPreviewing ? (
        <MacroPreviewModal
          title={title}
          text={text}
          myProvider={myProvider}
          isOpen={isOpen}
          onKeepEditing={handleKeepEditing}
          onClose={handleModalClose}
          macro={currentMacro}
        />
      ) : isOpen ? (
        <MacroModal
          isOpen={isOpen}
          inputText={text}
          inputTitle={title}
          onRequestPreview={handleRequestPreview}
          onRequestDelete={handleRequestDelete}
          onClose={handleModalClose}
          macro={currentMacro}
        />
      ) : undefined}
    </div>
  );
};

export default Macros;

import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { GoogleLogin } from 'react-google-login';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { EdenColors } from 'types/colors';
import { captureBreadcrumb, captureException } from 'lib/sentry';
import { BreadcrumbCategory } from 'types/sentry';

import { GOOGLE_CLIENT_ID, GoogleLoginError, GoogleLoginErrorCode } from 'lib/google';
import GoogleLoginButton from 'components/features/Login/GoogleLoginButton';
import { useAuth } from 'lib/auth';

const Login = () => {
  const { signIn } = useAuth();
  const location = useLocation();

  const onLoginFailure = (err: Error | GoogleLoginError) => {
    if (err instanceof Error) {
      captureException(err);
    } else {
      const { error, ...extra } = err;
      if (error !== GoogleLoginErrorCode.PopupClosedByUser) {
        captureBreadcrumb({
          level: 'debug',
          message: 'track issue with google auth error handling (app-1925)',
          category: BreadcrumbCategory.ChromeExtension,
          data: { error: Error(error) },
        });
        captureException(Error(error), extra);
      }
    }
  };

  const onLoginSuccess = async (authObj: Record<string, any>) => {
    const pathname = location.pathname && location.pathname !== '/' ? location.pathname : '/app';
    try {
      await signIn(pathname, authObj);
    } catch (e) {
      onLoginFailure(e);
    }
  };

  return (
    <Form className="Login" size="large" data-testid="LoginForm">
      <Header
        style={{
          marginTop: 8,
          marginBottom: 16,
          color: EdenColors.Slate85,
          fontWeight: 300,
        }}
      >
        Please log in to continue.
      </Header>

      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        cookiePolicy={'single_origin_host'}
        render={(renderProps) => (
          <GoogleLoginButton onClick={renderProps ? renderProps.onClick : _.noop} />
        )}
      />
    </Form>
  );
};

export default Login;

import _ from 'lodash';
import { PrimaryKey } from 'types/tables/base';
import { TableRecord, TableRecordType, TableStore } from 'legacy/types/tables';
import {
  InsertAction,
  RemoveAction,
  TableAction,
  TableActionType,
  UpdateAction,
} from 'legacy/types/actions';

export const initialState: TableStore = {
  messageDrafts: { allIds: [], byId: {} },
  pendingMessages: { allIds: [], byId: {} },
};

const insertAccumulator = (acc: Record<string, TableRecord>, curr: TableRecord) => {
  acc[curr.id] = curr;
  return acc;
};

const genericInsert = (
  state: TableStore,
  tableRecordType: TableRecordType,
  records: TableRecord[],
) => {
  return {
    ...state,
    [tableRecordType]: {
      ...state[tableRecordType],
      byId: records.reduce(insertAccumulator, { ...state[tableRecordType].byId }),
      allIds: _.uniq([
        ...state[tableRecordType].allIds,
        ...records.map((record: TableRecord): PrimaryKey => record.id),
      ]),
    },
  };
};

const insert = (state: TableStore, action: InsertAction): TableStore => {
  return genericInsert(state, action.tableRecordType, action.records);
};

const genericRemove = (
  state: TableStore,
  tableRecordType: TableRecordType,
  idsToRemove: PrimaryKey[],
) => {
  const allIdsOfRecordType = state[tableRecordType].allIds;
  const byIdOfRecordType = state[tableRecordType].byId;
  return {
    ...state,
    [tableRecordType]: {
      allIds: allIdsOfRecordType.filter((id) => !idsToRemove.includes(id)),
      byId: (allIdsOfRecordType as PrimaryKey[]).reduce(
        (acc: Record<string, TableRecord>, id: string) => {
          if (!idsToRemove.includes(id)) {
            acc[id] = byIdOfRecordType[id];
          }
          return acc;
        },
        {},
      ),
    },
  };
};

const remove = (state: TableStore, action: RemoveAction): TableStore => {
  return genericRemove(state, action.tableRecordType, action.recordIds);
};

const genericUpdate = (
  state: TableStore,
  tableRecordType: TableRecordType,
  recordId: PrimaryKey,
  record: Partial<
    TableStore[typeof tableRecordType]['byId'][keyof TableStore[typeof tableRecordType]['byId']]
  >,
): TableStore => {
  const existing = { ...state[tableRecordType] };
  return {
    ...state,
    [tableRecordType]: {
      ...existing,
      byId: {
        ...existing.byId,
        [recordId]: {
          ...existing.byId[recordId],
          ...record,
        },
      },
    },
  };
};

const update = (state: TableStore, action: UpdateAction): TableStore => {
  switch (action.tableRecordType) {
    case TableRecordType.MessageDrafts:
      return genericUpdate(state, action.tableRecordType, action.recordId, action.record);
    default:
      return state;
  }
};

const db = (state: TableStore = initialState, action: TableAction): TableStore => {
  switch (action.type) {
    case TableActionType.Insert:
      return insert(state, action);
    case TableActionType.Remove:
      return remove(state, action);
    case TableActionType.Update:
      return update(state, action);
    default:
      return state;
  }
};

export default db;

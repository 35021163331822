import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

export const EdenLogoCircle = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5006 25.4255C34.5006 23.9665 33.8747 22.7032 32.6711 21.6821C31.4682 20.613 30.0716 20.0784 28.3868 20.0784C26.6056 20.0784 25.0181 20.613 23.718 21.6333C22.4186 22.6545 21.6969 23.9178 21.5521 25.4255H34.5006ZM15.6797 28.0492C15.6797 24.1611 16.8833 21.0505 19.2407 18.6686C21.6479 16.2867 24.681 15.1201 28.3868 15.1201C31.9011 15.1201 34.7887 16.2867 37.0036 18.6686C39.266 21.0505 40.3723 23.9662 40.3723 27.4659C40.3723 28.1947 40.3248 28.8754 40.2758 29.5075H21.4073C21.5996 33.3956 24.4397 35.9229 28.7232 35.9229C31.2269 35.9229 33.3935 34.9992 35.2706 33.1039L38.7358 36.5062C35.9923 39.4226 32.6221 40.8801 28.5316 40.8801C24.7293 40.8801 21.5996 39.7135 19.2407 37.4291C16.8833 35.1447 15.6797 32.0828 15.6797 28.2434V28.0492Z"
      fill="#6ED69A"
    />
  </svg>
);

import { AxiosResponse } from 'axios';
import {
  ClientErrorCode,
  InformationalCode,
  RedirectCode,
  ServerErrorCode,
  SuccessCode,
} from 'types/http';

export interface GrdnError {
  message?: string;
  type?: string;
  cause?: string;
}

// to-do: update all grdn endpoints to return consistent grdnerror
export type ErrorObject = string | GrdnError | Record<string, any>;

export interface ErrorPayload {
  error: ErrorObject;
}

export interface BaseAPIResponse extends AxiosResponse {
  status: InformationalCode | SuccessCode | RedirectCode | ClientErrorCode | ServerErrorCode;
}

export interface SuccessResponse<T> extends BaseAPIResponse {
  status: InformationalCode | SuccessCode | RedirectCode;
  data: T;
}

export interface ErrorResponse extends BaseAPIResponse {
  status: ClientErrorCode | ServerErrorCode;
  data: ErrorPayload;
}

export type APIResponse<T> = SuccessResponse<T> | ErrorResponse;

export const isSuccessResponse = (response: APIResponse<any>): response is SuccessResponse<any> =>
  (response as SuccessResponse<any>).status in SuccessCode;

export const isGrdnError = (error: ErrorObject): error is GrdnError =>
  !!(error as GrdnError).type || !!(error as GrdnError).message || !!(error as GrdnError).cause;

import { getDocument } from './grdn';
import { DownloadLocation } from 'types/tables/documents';

/** Automatically downloads a document with the specified filename */
export const downloadDocument = async (
  documentId: string,
  filename: string,
  location: DownloadLocation,
) => {
  const response = await getDocument(documentId, location);
  const blob = new Blob([response.data as BlobPart], { type: response.headers['content-type'] });
  const url = window.URL.createObjectURL(blob);
  if (url && filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  } else {
    throw new Error('Unable to download file');
  }
};

import * as React from 'react';
import css from './Macro.module.css';
import markdown from 'lib/markdown';
import { Body1, Body2 } from 'components/ui/Typography';
import { EditIcon, Minus, Plus } from 'components/ui/svg';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import { Macro } from 'types/tables/macros';
import { Provider } from 'types/tables/providers';

interface MacroProps {
  macro: Macro;
  expanded: boolean;
  myProvider?: Provider;
  onClick: () => void;
  onClickEdit: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MacroComponent = ({
  macro,
  onClick,
  onClickEdit,
  expanded = false,
  myProvider,
}: MacroProps) => {
  const text = macro.text;

  return (
    <div key={macro.id} className={css.macro} onClick={onClick} data-testid={'macro'}>
      <div className={css.plusMinusContainer}>{expanded ? <Minus /> : <Plus />}</div>
      <div className={css.detailsAndEdit}>
        <div className={css.details}>
          <Body1 className={css.title}>{macro.title}</Body1>
          {expanded ? (
            <Body2
              className={css.textExpanded}
              dangerouslySetInnerHTML={{
                __html: markdown({
                  text,
                  providerName: myProvider && myProvider.displayName,
                }),
              }}
            />
          ) : (
            <Body2 className={css.text}>
              {text.substr(0, 80) + (text.length >= 80 ? '...' : '')}
            </Body2>
          )}
        </div>
        <Button
          buttonType={ButtonType.transparent}
          size={ButtonSizes.small}
          content="Edit"
          onClick={onClickEdit}
          icon={<EditIcon />}
          className={css.editButton}
        />
      </div>
    </div>
  );
};

export default MacroComponent;

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const ChevronDown = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47075 11.138C8.2104 11.3983 7.78829 11.3983 7.52794 11.138L3.52794 7.13799C3.26759 6.87764 3.26759 6.45553 3.52794 6.19518C3.78829 5.93483 4.2104 5.93483 4.47075 6.19518L7.99935 9.72378L11.5279 6.19518C11.7883 5.93483 12.2104 5.93483 12.4708 6.19518C12.7311 6.45553 12.7311 6.87764 12.4708 7.13799L8.47075 11.138Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDown;

//
// Reason ids known to the system.
// Reference: https://edenhealth.atlassian.net/wiki/spaces/PE/pages/2683666441/Appointment+Types+and+Reasons
// Note: not all reasons are supported by Care PLan. Care Plan reasons support is limited to
// those that can be booked in the SDK/patient app.
//
const ANNUAL_PREVENTATIVE_VISIT = 1;
const BLOOD_OR_LAB_WORK = 9;
const COVID_19_PCR_TEST = 101;
const COVID_19_RAPID_TEST = 102;
const COVID_19_VACCINATION = 105;
const COVID_19_VACCINATION_JANAJ_BOOSTER = 169;
const COVID_19_VACCINATION_JANDJ = 166;
const COVID_19_VACCINATION_MODERNA = 167;
const COVID_19_VACCINATION_MODERNA_BOOSTER = 168;
const DISCUSS_A_MEDICATION = 41;
const FLU_SHOT = 8;
const FOCUSED_VISIT = 42;
const MENTAL_HEALTH_CONSULT = 145;
const NEW_PARENT_SUPPORT = 165;
const PHYSICAL_THERAPY = 61;
const PROBLEM = 12;
const TELEHEALTH = 125;
const TELEHEALTH_PROBLEM = 126;
const WOMENS_HEALTH_VISIT = 21;

export const Reason = {
  ANNUAL_PREVENTATIVE_VISIT,
  BLOOD_OR_LAB_WORK,
  COVID_19_PCR_TEST,
  COVID_19_RAPID_TEST,
  COVID_19_VACCINATION,
  COVID_19_VACCINATION_JANAJ_BOOSTER,
  COVID_19_VACCINATION_JANDJ,
  COVID_19_VACCINATION_MODERNA,
  COVID_19_VACCINATION_MODERNA_BOOSTER,
  DISCUSS_A_MEDICATION,
  FLU_SHOT,
  FOCUSED_VISIT,
  MENTAL_HEALTH_CONSULT,
  NEW_PARENT_SUPPORT,
  PHYSICAL_THERAPY,
  PROBLEM,
  TELEHEALTH,
  TELEHEALTH_PROBLEM,
  WOMENS_HEALTH_VISIT,
};

//
// Reasons supported by Care Plan; limited to those that can be booked in the SDK/patient app
//
export type CarePlanReason =
  | typeof ANNUAL_PREVENTATIVE_VISIT
  | typeof BLOOD_OR_LAB_WORK
  | typeof COVID_19_PCR_TEST
  | typeof COVID_19_RAPID_TEST
  | typeof COVID_19_VACCINATION_JANDJ
  | typeof COVID_19_VACCINATION_MODERNA
  | typeof COVID_19_VACCINATION_MODERNA_BOOSTER
  | typeof FLU_SHOT
  | typeof FOCUSED_VISIT
  | typeof PHYSICAL_THERAPY
  | typeof WOMENS_HEALTH_VISIT;

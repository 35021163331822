import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import css from './BackToWorkModal.module.css';
import { StatusPill } from './BackToWorkLabel';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { EdenDayPicker } from 'components/design-system/Form';
import { calendarDate } from 'lib/time';
import { AlertCircleIcon, Spinner, XIcon } from 'components/ui/svg';
import { changeChannelStatus, getPatientPrimaryChannel } from 'reducers/channels';
import { ChannelStatus } from 'types/tables/channels';
import { PatientTriageStatus, TriageStatus } from 'types/tables/profiles';
import { Option, OptionWrapper } from 'components/ui/Fields';
import { ButtonSizes, PrimaryButton } from 'components/ui/Buttons';
import { updateTriageStatus } from 'reducers/profiles';
import { PrimaryKey } from 'types/tables/base';
import Dropdown from 'components/ui/Dropdown';
import { EdenColors } from 'types/colors';

enum OptionType {
  TriageStatus,
  KeepStatus,
}

type TriageStatusOption = {
  type: OptionType.TriageStatus;
  value: PatientTriageStatus;
};

type KeepStatusOption = {
  type: OptionType.KeepStatus;
};

type SelectionType = TriageStatusOption | KeepStatusOption;

/**
 * Indicates whether or not the submit button should be disabled.
 */
const shouldDisableButton = (
  initialStatus: PatientTriageStatus,
  disableByStatus: boolean,
  currentSelection?: SelectionType,
): boolean => {
  if (!currentSelection) return true;

  if (currentSelection.type === OptionType.TriageStatus) {
    const isolateButNoReturnToWorkDate =
      currentSelection.value.status === TriageStatus.Isolate &&
      currentSelection.value.returnToWorkDate === null;

    if (isolateButNoReturnToWorkDate) return true;

    const initiallyIsolateAndDateChanged =
      initialStatus.status === TriageStatus.Isolate &&
      currentSelection.value.status === TriageStatus.Isolate &&
      initialStatus.returnToWorkDate !== currentSelection.value.returnToWorkDate;

    const unchangedStatusOrNotEvaluated =
      currentSelection.value.status === initialStatus.status ||
      currentSelection.value.status === TriageStatus.NeverTaken;

    if (disableByStatus && unchangedStatusOrNotEvaluated && !initiallyIsolateAndDateChanged)
      return true;
  }

  return false;
};

const BackToWorkForm = ({
  patientId,
  triageStatus = { status: TriageStatus.NeverTaken },
  onSubmit,
  onClose,
  onError,
  buttonSize,
  buttonText,
  spinnerSize,
  disableByStatus = true,
  isOpen,
  showKeepCurrentStatusOption,
  preselectCurrentStatus,
}: {
  patientId: PrimaryKey;
  triageStatus: PatientTriageStatus;
  onSubmit?: () => void;
  onClose: () => void;
  onError: () => void;
  buttonText: string;
  buttonSize: ButtonSizes;
  spinnerSize?: number;
  disableByStatus?: boolean;
  isOpen: boolean;
  showKeepCurrentStatusOption: boolean;
  preselectCurrentStatus: boolean;
}) => {
  const dispatch = useDispatch();
  const { id } = useActivePatient();

  const didMountRef = useRef(false);

  const initialState = useMemo(
    () => ({
      selected: preselectCurrentStatus
        ? { type: OptionType.TriageStatus, value: triageStatus }
        : undefined,
      loading: false,
    }),
    [triageStatus, preselectCurrentStatus],
  );

  const [{ selected, loading }, setState] = useState<{
    selected?: SelectionType;
    loading: boolean;
  }>(initialState);

  useEffect(() => {
    // Reset state when the form gets closed.
    if (!isOpen) setState(() => initialState);
  }, [initialState, isOpen]);

  useEffect(() => {
    // Prevent accidentally setting the status at mount time if preselection is disabled.
    if (!preselectCurrentStatus && !didMountRef.current) return;

    setState((state) => ({
      ...state,
      selected: {
        type: OptionType.TriageStatus,
        value: triageStatus || { status: TriageStatus.NeverTaken },
      },
    }));
  }, [initialState, preselectCurrentStatus, id, triageStatus]);

  return (
    <>
      <div className={css.options} data-testid="BackToWorkFormOptions">
        {showKeepCurrentStatusOption && (
          <OptionWrapper
            selected={selected?.type === OptionType.KeepStatus}
            testId="BackToWorkFormOptionWrapperKeepCurrentStatus"
          >
            <Option
              onClick={() =>
                setState((state) => ({
                  ...state,
                  selected: { type: OptionType.KeepStatus },
                }))
              }
              hideEllipse
              testId="BackToWorkFormOptionKeepCurrentStatus"
            >
              Keep current status
            </Option>
          </OptionWrapper>
        )}
        <OptionWrapper
          selected={
            selected?.type === OptionType.TriageStatus &&
            selected.value.status === TriageStatus.Cleared
          }
          testId="BackToWorkFormOptionWrapperCleared"
        >
          <Option
            onClick={() =>
              setState((state) => ({
                ...state,
                selected: {
                  type: OptionType.TriageStatus,
                  value: { status: TriageStatus.Cleared },
                },
              }))
            }
            ellipseColor={EdenColors.Eden}
            testId="BackToWorkFormOptionCleared"
          >
            Cleared for work
          </Option>
        </OptionWrapper>
        <OptionWrapper
          selected={
            selected?.type === OptionType.TriageStatus &&
            selected.value.status === TriageStatus.Isolate
          }
          testId="BackToWorkFormOptionWrapperIsolate"
        >
          <EdenDayPicker
            dayPickerProps={{
              fromMonth: new Date(),
            }}
            closeOnOutsideClick
            disableBeforeToday
            value={
              selected?.type === OptionType.TriageStatus &&
              selected.value.status === TriageStatus.Isolate &&
              selected.value.returnToWorkDate
                ? selected.value.returnToWorkDate
                : new Date()
            }
            onDayChange={(date) =>
              setState((state) => ({
                ...state,
                selected: {
                  type: OptionType.TriageStatus,
                  value: {
                    status: TriageStatus.Isolate,
                    returnToWorkDate: DateTime.fromJSDate(date).toISODate(),
                  },
                },
              }))
            }
            onDayPickerShow={() => {
              const previouslySelectedDate =
                selected?.type === OptionType.TriageStatus &&
                selected.value.status === TriageStatus.Isolate
                  ? selected.value.returnToWorkDate
                  : null;

              setState((state) => ({
                ...state,
                selected: {
                  type: OptionType.TriageStatus,
                  value: {
                    status: TriageStatus.Isolate,
                    returnToWorkDate: previouslySelectedDate,
                  },
                },
              }));
            }}
            inputProps={{ ref: null }}
            component={(props) => (
              <Option
                ellipseColor={EdenColors.Lemon}
                {...props}
                testId="BackToWorkFormOptionIsolate"
              >
                Isolate{' '}
                {selected?.type === OptionType.TriageStatus &&
                selected.value.status === TriageStatus.Isolate &&
                selected.value.returnToWorkDate
                  ? `RTW on ${calendarDate(selected.value.returnToWorkDate)}`
                  : `set RTW date`}
              </Option>
            )}
          />
        </OptionWrapper>
      </div>

      <div className={css.buttonWrapper}>
        {loading ? (
          <Spinner className={css.buttonSpinner} size={spinnerSize} />
        ) : (
          <PrimaryButton
            disabled={shouldDisableButton(triageStatus, disableByStatus, selected)}
            size={buttonSize}
            onClick={async () => {
              if (onSubmit) onSubmit();
              setState((state) => ({ ...state, loading: true }));
              try {
                if (selected && selected.type !== OptionType.KeepStatus) {
                  await dispatch(updateTriageStatus({ patientId, triageStatus: selected.value }));
                }

                setState((state) => ({ ...state, loading: false }));
              } catch (e) {
                onError();
                setState((state) => ({ ...state, loading: false }));
              }
              onClose();
            }}
            data-testid="BackToWorkFormSubmit"
          >
            {buttonText}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export const NoStatusSetMessage = () => {
  return (
    <div className={css.noStatusSetMessageContainer} data-testid="NoStatusSetMessage">
      <div>Please advise the patient to complete their Daily COVID-19 screener.</div>
      <div>
        This status <strong>cannot</strong> be set manually until a screener has been completed
        first.
      </div>
    </div>
  );
};

export const BackToWorkDropdown = ({
  patientId,
  triageStatus,
  onClose,
  onSubmit,
  isOpen,
}: {
  patientId: PrimaryKey;
  triageStatus: PatientTriageStatus;
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}) => {
  return (
    <Dropdown isOpen={isOpen} onClose={onClose} className={css.dropdown}>
      <div className={css.dropdownHeader} data-testid="BackToWorkDropdownHeader">
        Back to Work Status
      </div>
      {triageStatus.status === TriageStatus.NeverTaken ? (
        <NoStatusSetMessage />
      ) : (
        <BackToWorkForm
          patientId={patientId}
          triageStatus={triageStatus}
          onSubmit={onSubmit}
          onClose={onClose}
          onError={() => toast.error(`An error occurred when updating triage status.`)}
          buttonText={'Save'}
          buttonSize={ButtonSizes.dropdown}
          disableByStatus
          preselectCurrentStatus
          showKeepCurrentStatusOption={false}
          isOpen={isOpen}
        />
      )}
    </Dropdown>
  );
};

export const BackToWorkModal = ({
  patientId,
  triageStatus,
  isOpen,
  onClose,
}: {
  patientId: PrimaryKey;
  triageStatus: PatientTriageStatus;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean>(false);
  const primaryChannel = useParameterizedSelector(getPatientPrimaryChannel, patientId);
  return (
    <Modal open={isOpen} size="tiny" onClose={onClose}>
      <Modal.Content className={'backToWorkOverride'}>
        <div className={css.headerContainer}>
          <div className={css.modalHeader}>Update Patient Status?</div>
          <XIcon className={css.xicon} onClick={onClose} />
        </div>
        <div className={error ? css.error : css.errorHidden}>
          <AlertCircleIcon className={css.errorIcon} />
          <div>An error occurred, please try again.</div>
        </div>
        <div className={css.subheader}>Back to Work Status</div>
        <div className={css.body}>
          This employer requires active monitoring of back to work status. Please make sure the
          correct status is displayed before archiving.
        </div>
        <div className={css.currentStatusContainer} data-testid={`currentStatus`}>
          <span>Current status</span> <StatusPill triageStatus={triageStatus} />
        </div>

        <BackToWorkForm
          patientId={patientId}
          triageStatus={triageStatus}
          onClose={async () => {
            setError(false);
            await dispatch(changeChannelStatus({ primaryChannel, status: ChannelStatus.Archived }));
            onClose();
          }}
          buttonText={'Confirm and Archive'}
          buttonSize={ButtonSizes.large}
          onError={() => {
            toast.error(`An error occurred when updating triage status.`);
            setError(true);
          }}
          spinnerSize={24}
          isOpen={isOpen}
          showKeepCurrentStatusOption
          preselectCurrentStatus={false}
          disableByStatus={false}
        />
      </Modal.Content>
    </Modal>
  );
};

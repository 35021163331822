import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import About from './About/About';
import Login from './Login/Login';
import css from './Public.module.css';
import { EdenStackedLogo } from 'components/ui/svg';

const Public = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const renderNavBar = () => {
    const menuBarItems = [
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'About',
        path: '/about',
      },
    ];
    const menuBarItemComponents = menuBarItems.map((item) => {
      const isActive = location.pathname === item.path;
      return (
        <Menu.Item
          className={isActive ? css.menuBarItemActive : css.menuBarItem}
          key={item.name}
          name={item.name}
          active={isActive}
          onClick={() => {
            navigate(item.path);
          }}
        />
      );
    });
    return (
      <Menu className={css.publicNavbar} text fluid widths={2}>
        {menuBarItemComponents}
      </Menu>
    );
  };

  return (
    <div className={css.publicContainer}>
      <Grid className={css.public} verticalAlign="middle" textAlign="center">
        <Grid.Row>
          <Grid.Column className={css.publicContent}>
            <EdenStackedLogo />

            <div className={css.panel}>
              <h1 className={css.publicHeader}>Eden Health Clinical Patient Management</h1>

              <Routes>
                <Route path="/*" element={<Login />} />
                <Route path="/about" element={<About />} />
              </Routes>
            </div>

            <p className={css.acceptableUse}>
              Only authorized individuals shall use this computer network system
              (&ldquo;System&rdquo;) and such use shall be in accordance with Company policy.
              Individuals using this System are subject to monitoring and recording of all activity
              on this System by Company administrator(s) and such individuals expressly consent to
              such monitoring and recording. Such individuals are further advised that if such
              monitoring and recording reveals criminal conduct or inappropriate use, the Company
              may provide evidence of such activity to law enforcement or enforce disciplinary
              actions.
            </p>
            {renderNavBar()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Public;

import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Comment, Header } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { ChannelType } from 'types/tables/channels';
import * as GrdnApi from 'lib/grdn';
import { useActiveChat } from 'lib/hooks';
import { ErrorMessage } from 'components/ui/Typography';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import { PrimaryButton } from 'components/design-system/Form';
import { Trash } from 'components/ui/svg';
import css from 'components/features/Chat/DeleteMessageModal.module.css';
import messageContainerCss from 'components/features/Chat/MessageContainer.module.css';
import chatMessageCss from 'components/features/Chat/ChatMessage.module.css';
import { Message, PrimaryMessageType } from 'types/tables/messages';
import { Modal } from 'components/ui/Modal';
import { Label } from 'components/design-system/Form';
import { Radio } from 'components/ui/Radio';

interface DeleteMessageModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  message: Message;
  name: string;
  patientId?: string;
  content?: ReactElement;
  lastReadTimestamp?: number;
  providerSenderId?: string;
}

interface DeleteDocumentReasonSelectProps {
  reason?: ReasonType;
  onReasonChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const reasons = {
  'wrong-patient': 'Added to the wrong patient’s files',
  irrelevant: 'Irrelevant document',
  duplicate: 'Duplicate document',
};

type ReasonType = keyof typeof reasons | undefined;

const DeleteDocumentReasonSelect = ({
  reason,
  onReasonChange,
}: DeleteDocumentReasonSelectProps) => {
  return (
    <Label className={css.label} text="Reason for deleting" required>
      <div className={css.radioGroup}>
        {Object.keys(reasons).map((key) => (
          <Radio
            checked={reason === key}
            id={key}
            label={reasons[key]}
            onChange={onReasonChange}
            value={key}
            key={key}
            testID={`DeletedReason-${key}`}
          />
        ))}
      </div>
    </Label>
  );
};

const DeleteMessageModal = (props: DeleteMessageModalProps) => {
  const {
    isOpen,
    closeModal,
    message,
    name,
    patientId,
    content,
    lastReadTimestamp,
    providerSenderId,
  } = props;

  const { channel } = useActiveChat();
  const text = message.type === PrimaryMessageType.Text ? message.data.text : undefined;
  const imageId = message.type === PrimaryMessageType.Image ? message.data.imageId : undefined;
  const documentId =
    message.type === PrimaryMessageType.Document ? message.data.documentId : undefined;
  const time = DateTime.fromMillis(message.timestamp).toFormat('h:mma');
  const unread = lastReadTimestamp ? message.timestamp > lastReadTimestamp : true;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isPrimaryChannel = channel.channelType === ChannelType.Primary;

  const [deletedReason, setDeletedReason] = useState<ReasonType>(undefined);

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeletedReason(event.target.value as ReasonType);
  };

  useEffect(() => {
    setDeletedReason(undefined);
  }, [setDeletedReason]);

  const handleDeleteClick = async () => {
    setLoading(true);
    setError(false);
    try {
      await GrdnApi.deleteMessage(
        channel.sendbirdChannelUrl,
        message.id,
        patientId ? patientId : '',
        message.timestamp,
        unread,
        text,
        imageId,
        documentId,
        deletedReason,
        providerSenderId,
      );
      closeModal();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const handleClose = () => {
    closeModal();
    setLoading(false);
    setError(false);
  };

  const timeSection = (
    <Comment.Text className={classNames(messageContainerCss.time, messageContainerCss.commentText)}>
      {time}
    </Comment.Text>
  );

  const header = (
    <Comment.Text className={messageContainerCss.commentText}>
      <Header as="h5" color="pink" className={messageContainerCss.header}>
        {name}
      </Header>
    </Comment.Text>
  );

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <HeaderMedium style={{ marginTop: -12, marginBottom: 8 }}>
        <Trash className={css.trashIcon} />
        {documentId ? 'Delete Document' : 'Delete this message?'}
      </HeaderMedium>
      {documentId ? (
        <BodySmall>This will delete the document from the patient&apos;s files.</BodySmall>
      ) : isPrimaryChannel ? (
        <BodySmall>The patient will see that you deleted this message.</BodySmall>
      ) : (
        <BodySmall>Other Care Team members will see that you deleted this message.</BodySmall>
      )}
      <div className={css.deletedMessage}>
        <Comment className={classNames(css.comment, messageContainerCss.comment)}>
          {timeSection}
          <Comment.Content
            className={classNames(
              chatMessageCss.deletedMessageContent,
              messageContainerCss.content,
            )}
          >
            {header}
            {content}
          </Comment.Content>
        </Comment>
      </div>
      {documentId && (
        <DeleteDocumentReasonSelect reason={deletedReason} onReasonChange={handleReasonChange} />
      )}
      {error && (
        <ErrorMessage className={css.error} data-testid="DeleteMessageError">
          This message could not be deleted.
        </ErrorMessage>
      )}
      <div className={css.bottomNav}>
        <PrimaryButton
          type="button"
          className={classNames(css.confirmBtn, css.cancelDeleteBtn)}
          value="Cancel"
          onClick={handleClose}
        />
        <PrimaryButton
          className={classNames(css.confirmBtn, css.confirmDeleteBtn)}
          value="Delete"
          onClick={handleDeleteClick}
          loading={loading}
          disabled={documentId ? !deletedReason : false}
        />
      </div>
    </Modal>
  );
};

export default DeleteMessageModal;

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { useAppDispatch } from 'lib/hooks';
import { updatePatientAccountInfo } from 'reducers/patients';
import {
  Field,
  FormHeader,
  Label,
  SubmitButton,
  validateEmail,
  validateMobilePhone,
} from 'components/ui/Forms';
import { EditIcon } from 'components/ui/svg';
import { PatientAccountInfoPayload } from 'types/grdn';
import { Modal } from 'components/ui/Modal';

interface EditInfoModalState {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  firstNameError?: string;
  lastNameError?: string;
  emailError?: string;
  mobilePhoneError?: string;
  isFormSubmittable: boolean;
}

interface EditInfoParams {
  onModalClose: () => void;
  setAccountInfo: (updatedInfo: PatientAccountInfoPayload) => void;
  isModalOpen: boolean;
  accountInfo: PatientAccountInfoPayload;
}

const EditInfo = (params: EditInfoParams): ReturnType<React.FC> => {
  const {
    accountInfo: { patientId, postgres },
    onModalClose,
    isModalOpen,
    setAccountInfo,
  } = params;

  const initialState = {
    firstName: postgres.firstName || '',
    lastName: postgres.lastName || '',
    mobilePhone: postgres.mobilePhone || '',
    email: postgres.email || '',
    isFormSubmittable: false,
  };

  const dispatch = useAppDispatch();

  const [
    {
      firstName,
      lastName,
      email,
      isFormSubmittable,
      mobilePhone,
      firstNameError,
      lastNameError,
      emailError,
      mobilePhoneError,
    },
    setState,
  ] = useState<EditInfoModalState>(initialState);

  const handleModalClose = () => {
    onModalClose();
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const action = await dispatch(
      updatePatientAccountInfo({
        patientId,
        firstName,
        lastName,
        email,
        mobilePhone,
      }),
    );
    if (updatePatientAccountInfo.fulfilled.match(action)) {
      toast.success('Patient info successfully updated.');
      setAccountInfo(action.payload);
    } else {
      toast.error(action?.payload?.rejectionType);
    }

    handleModalClose();
  };

  const validateForm = ({ firstName, lastName, email, mobilePhone }: EditInfoModalState): boolean =>
    !!firstName && !!lastName && !validateEmail(email) && !validateMobilePhone(mobilePhone);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field) => {
    const val = e.target.value;
    setState((state) => ({
      ...state,
      [field]: val,
      isFormSubmittable: validateForm({ ...state, [field]: val }),
    }));
  };

  return (
    <Modal closeModal={handleModalClose} isOpen={isModalOpen}>
      <FormHeader formName="Update patient info" icon={EditIcon} />
      <form onSubmit={submitForm}>
        <Label text="First name">
          <Field
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'firstName')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                firstNameError: firstName ? undefined : 'Please enter a first name.',
              }))
            }
            error={firstNameError}
          />
        </Label>
        <Label text="Last name">
          <Field
            value={lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'lastName')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                lastNameError: lastName ? undefined : 'Please enter a last name.',
              }))
            }
            error={lastNameError}
          />
        </Label>
        <Label text="Email">
          <Field
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'email')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                emailError: validateEmail(email),
              }))
            }
            error={emailError}
          />
        </Label>
        <Label text="Mobile phone">
          <NumberFormat
            customInput={Field}
            value={mobilePhone}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'mobilePhone')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                mobilePhoneError: validateMobilePhone(mobilePhone),
              }))
            }
            error={mobilePhoneError}
            format="(###) ###-####"
          />
        </Label>
        <SubmitButton disabled={!isFormSubmittable}>Update</SubmitButton>
      </form>
    </Modal>
  );
};

export default EditInfo;

import * as React from 'react';
import cx from 'classnames';
import { EdenLogoCircle } from '../../icons/EdenLogo';
import ChevronDown from '../../icons/ChevronDown';
import ArrowLeft from '../../icons/ArrowLeft';
import PlusCircle from '../../icons/PlusCircle';
import Link from '../../icons/Link';
import Archive from '../../icons/Archive';
import EyeOn from '../../icons/EyeOn';
import Edit from '../../icons/Edit';
import CheckMark from '../../icons/CheckMark';
import AlertCircle from '../../icons/AlertCircle';
import CrossMark from '../../icons/CrossMark';
import BellFilled from '../../icons/BellFilled';
import CheckCircle from '../../icons/CheckCircle';
import css from './Icon.module.css';

export type IconName =
  | 'alert-circle'
  | 'archive'
  | 'arrow-left'
  | 'bell-filled'
  | 'check-circle'
  | 'check-mark'
  | 'chevron-down'
  | 'cross-mark'
  | 'eden-logo-circle'
  | 'edit'
  | 'eye-on'
  | 'link'
  | 'plus-circle';

export interface IconProps {
  name: IconName;
}

export interface IconStylingProps {
  /**
   * Class name to override styling of the icon.
   * This can be used to e.g. change the icon's size or color.
   */
  className?: string;
}

const icons: Record<IconName, React.FC<IconStylingProps>> = {
  'alert-circle': AlertCircle,
  archive: Archive,
  'arrow-left': ArrowLeft,
  'bell-filled': BellFilled,
  'check-circle': CheckCircle,
  'check-mark': CheckMark,
  'chevron-down': ChevronDown,
  'cross-mark': CrossMark,
  'eden-logo-circle': EdenLogoCircle,
  edit: Edit,
  'eye-on': EyeOn,
  link: Link,
  'plus-circle': PlusCircle,
};

/**
 * A shared interface to display SVG icons.
 *
 * This interface does not provide `color`, `width` or `height` parameters.
 * Instead, icon styling should be done by assigning the icon a dedicated class
 * name and modifying its CSS properties. The default size for all icons is
 * `16` x `16` pixels, which fits most in-line use cases.
 *
 * Relevant colors are overridable via the `color` or `fill` CSS properties,
 * which means that an inline icon's default color will always be that of the
 * text surrounding it.
 *
 * All icons have the `aria-hidden` attribute.
 */
export const Icon = ({ name, className }: IconProps & IconStylingProps) => {
  const Icon = icons[name];

  return <Icon className={cx(css.icon, className)} />;
};

import React, { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import css from './SelectFile.module.css';
import { SecondaryButton } from './Buttons';

interface SelectFileProps {
  setFileText: (fileText: string, fileName: string) => void;
  fileFormat: string;
  prompt: string;
  className?: string;
  maxFileSize?: number;
}

const SelectionStatus = ({ readError, fileName }: { readError: string; fileName: string }) => {
  let status, style;
  if (readError) {
    status = readError;
    style = css.error;
  } else if (fileName) {
    status = fileName.length > 40 ? fileName.slice(0, 20) + '...' + fileName.slice(-17) : fileName;
    style = css.success;
  } else {
    status = 'No file selected';
  }
  return <div className={cx(css.statusText, style)}>{status}</div>;
};

const SelectFile = (props: SelectFileProps) => {
  const { setFileText, fileFormat, prompt, maxFileSize } = props;
  const inputElement = React.useRef<HTMLInputElement | null>();
  const [readError, setReadError] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileClick = () => {
    if (inputElement.current) {
      inputElement.current.click();
    }
  };

  const loadFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileText = reader.result;
      if (fileText && typeof fileText === 'string') {
        setFileText(fileText, file.name);
        setFileName(file.name);
      } else {
        setReadError('Cannot read file');
      }
    };
    reader.readAsText(file);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileText('', '');
    setFileName('');
    setReadError('');
    const file = e?.target?.files && e.target.files[0];
    if (!file) {
      return;
    } else if (file.type !== fileFormat) {
      setReadError(`File must be in ${fileFormat} format`);
    } else if (maxFileSize && file.size > maxFileSize) {
      setReadError(`File cannot be larger than ${maxFileSize / 1000000} MB`);
    } else {
      await loadFile(file);
    }
  };

  return (
    <div className={cx(props.className, css.container)}>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={(input) => (inputElement.current = input)}
        data-testid="SelectFileInput"
        onChange={handleFileChange}
      />
      <SecondaryButton
        data-testid="SelectFileButton"
        onMouseDown={handleFileClick}
        active={!!fileName}
      >
        {prompt}
      </SecondaryButton>
      <SelectionStatus readError={readError} fileName={fileName} />
    </div>
  );
};

export default SelectFile;

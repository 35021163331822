import { Event } from '@sentry/types';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import * as ChannelSelectors from 'reducers/channels';
import * as PatientSelectors from 'reducers/patients';
import { getSentryEnvironment, sentryMiddleware, setupSentry } from 'lib/sentry';
import { getEnvironment } from 'lib/env';

const addExtraEvents = (event: Event) => {
  const currentState = store.getState();
  // scrub messages, macros, patients, events & patient image to reduce payload size
  const state = {
    ...currentState,
    chat: {
      ...currentState.macros,
      macros: currentState.macros,
    },
    patient: {
      ...currentState.patient,
      img: 'SCRUBBED',
    },
  };
  event.extra = { ...event.extra, 'Redux State': state };

  return event;
};

const sentryEnv = getSentryEnvironment();
setupSentry({ sentryEnv, extraEventProcessors: [addExtraEvents] });

const reduxLogger = (store) => (next) => (action) => {
  // eslint-disable-next-line no-console
  console.info(`Dispatching ${action.type}`, action);
  const result = next(action);
  // eslint-disable-next-line no-console
  console.info('next state', store.getState());
  return result;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sentryMiddleware)
      .concat(getEnvironment() === 'Development' && process.env.LOG_REDUX ? [reduxLogger] : []),
});

export default store;

if (getEnvironment() === 'Development') {
  (window as any).store = store;
  (window as any).select = (selector) => {
    return selector(store.getState());
  };
  (window as any).selectors = {
    channel: ChannelSelectors,
    patient: PatientSelectors,
  };
}

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const Edit = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.666 3.333A.667.667 0 0 0 1.999 4v9.333a.667.667 0 0 0 .667.667h9.333a.667.667 0 0 0 .667-.667v-3.56a.667.667 0 0 1 1.333 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.528.862c.26-.26.682-.26.943 0l2.666 2.666c.26.26.26.683 0 .943l-6.666 6.667a.667.667 0 0 1-.472.195H5.333a.667.667 0 0 1-.667-.667V8c0-.177.07-.347.195-.472L11.528.862ZM5.999 8.276V10h1.724l6-6L12 2.276l-6 6Z"
      fill="currentColor"
    />
  </svg>
);

export default Edit;

import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { DocumentCategoryType } from 'types/tables/documents';
interface DocumentTypeHelperProps {
  types: DocumentCategoryType[];
}

/** This component generates HTML for a helper document which opens in a new tab.
 * Because our Content-Security-Policy doesn't allow it, we can't use inline
 * styles or a stylesheet in the header.  So, we're linking to a stylesheet in
 * the Public directory. */
const DocumentTypeHelperHTML = ({ types }: DocumentTypeHelperProps) => {
  const location = window.location.origin;
  return (
    <>
      <head>
        <title>Document Type Definition</title>
        <link href={`${location}/DocumentTypeHelper.css`} rel="stylesheet" />
      </head>
      <h1>Document upload type description</h1>
      <div className="types">
        {types?.map((type) => (
          <p key={type.id}>
            <strong>{type.name}</strong> = {type.description}
          </p>
        ))}
      </div>
    </>
  );
};

export const displayDocumentTypeHelper = (types: DocumentCategoryType[]) => {
  const newWindow = window.open('about:blank', '_blank');
  if (newWindow) {
    const htmlString = ReactDOMServer.renderToString(<DocumentTypeHelperHTML types={types} />);
    newWindow.document.getElementsByTagName('html')[0].innerHTML = htmlString;
  }
};

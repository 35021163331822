import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';
import cx from 'classnames';
import css from './VisitOverview.module.css';
import { renderLoading, renderLoadFailure } from 'components/features/VisitOverview/VisitOverview';
import CheckedInVisit from 'components/features/VisitOverview/CheckedInVisit';
import UpcomingVisit from 'components/features/VisitOverview/UpcomingVisit';
import { Caption } from 'components/ui/Typography';
import Tooltip from 'components/design-system/Tooltip';
import { HelpCircle } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { Appointment, Visit } from 'types/athena/visits';
import { useAppDispatch } from 'lib/hooks';
import { getAppointmentScreeners } from 'reducers/appointmentScreeners';

interface UpcomingVisitsProps {
  visits: Visit[];
}

const UpcomingVisits = ({ visits }: UpcomingVisitsProps) => {
  const visitList = sortBy(visits, (visit) => DateTime.fromISO(visit.sortDate).toMillis())
    .slice(0, 3)
    .map((visit, index) => {
      const { appointmentId } = visit;
      return (
        <div key={appointmentId || index} className={css.visitContainerWrapper}>
          <div className={css.upcomingVisitContainer} key={visit.encounterId}>
            <UpcomingVisit visit={visit as Appointment} />
          </div>
        </div>
      );
    });
  return (
    <div className={css.container}>
      {visitList}
      {visits.length > 3 && (
        <Caption color={EdenColors.Slate85} className={css.moreApptsCaption}>
          View {visits.length - 3} more in Athena
        </Caption>
      )}
    </div>
  );
};

interface CheckedInVisitProps {
  visits: Visit[];
}

const CheckedInVisits = ({ visits }: CheckedInVisitProps) => {
  const visitList = sortBy(visits, (visit) => DateTime.fromISO(visit.sortDate).toMillis()).map(
    (visit, index) => {
      const { appointmentId } = visit;
      return (
        <div key={appointmentId || index} className={css.visitContainerWrapper}>
          <div className={css.upcomingVisitContainer} key={visit.encounterId}>
            <CheckedInVisit visit={visit as Appointment} />
          </div>
        </div>
      );
    },
  );

  return <div className={css.container}>{visitList}</div>;
};

interface CurrentVisitsProps {
  upcomingVisits: Visit[];
  checkedInVisits: Visit[];
}

type LoadingState = 'loading' | 'success' | 'failure';

const CurrentVisits = ({ upcomingVisits, checkedInVisits }: CurrentVisitsProps) => {
  const dispatch = useAppDispatch();

  const [screenersLoadingState, setScreenersLoadingState] = useState<LoadingState>('loading');

  // convert to string to avoid comparing by reference in callback's dependency array
  // which causes breaking extra rerenders
  const fetchIds = JSON.stringify(
    [...upcomingVisits, ...checkedInVisits].map(({ appointmentId }) => `${appointmentId}`).sort(),
  );

  const fetchAppointmentScreeners = useCallback(async () => {
    const appointmentIds = JSON.parse(fetchIds) as string[];
    if (appointmentIds.length > 0) {
      setScreenersLoadingState('loading');
      const responses = await Promise.all(
        appointmentIds.map((appointmentId) => {
          return dispatch(getAppointmentScreeners({ appointmentId }));
        }),
      );
      setScreenersLoadingState('success');
      responses.forEach((resp) => {
        if (getAppointmentScreeners.rejected.match(resp)) {
          setScreenersLoadingState('failure');
        }
      });
    } else {
      setScreenersLoadingState('success');
    }
  }, [dispatch, fetchIds]);

  useEffect(() => {
    fetchAppointmentScreeners().catch();
  }, [fetchAppointmentScreeners]);

  return (
    <div>
      {upcomingVisits.length > 0 && (
        <>
          <div className={cx(css.label, css.upcomingLabel)}>Upcoming Visits</div>
          {screenersLoadingState === 'loading' && renderLoading('Loading upcoming visits...')}
          {screenersLoadingState === 'failure' &&
            renderLoadFailure(
              'Error loading upcoming visits. Try again',
              fetchAppointmentScreeners,
            )}
          {screenersLoadingState === 'success' && <UpcomingVisits visits={upcomingVisits} />}
        </>
      )}
      {checkedInVisits.length > 0 && (
        <>
          <div className={cx(css.label, css.checkedInLabel)}>
            Checked-In Visits
            <Tooltip content="Checked-in visits do not retain the same appointment type information from Athena as upcoming or past visits.">
              <HelpCircle className={css.checkedInTooltip} width={15} height={15} />
            </Tooltip>
          </div>
          {screenersLoadingState === 'loading' && renderLoading('Loading checked-in visits...')}
          {screenersLoadingState === 'failure' &&
            renderLoadFailure(
              'Error loading checked-in visits. Try again',
              fetchAppointmentScreeners,
            )}
          {screenersLoadingState === 'success' && <CheckedInVisits visits={checkedInVisits} />}
        </>
      )}
    </div>
  );
};

export default CurrentVisits;

// @ts-nocheck
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Profile from './Profile/Profile';
import Macros from './Macros/Macros';
import css from './Settings.module.css';
import { getPermissions } from 'reducers/user';
import { List1, ListItem3 } from 'components/ui/Lists';

import { Logout } from 'components/ui/svg';
import { Button, ButtonType } from 'components/ui/Buttons';
import { useAuth } from 'lib/auth';
import { useReleaseName } from 'lib/env';
import { RDashTools } from 'components/features/RDashTools/RDashTools';
import { PopupManager } from 'components/features/PopupManager/PopupManager';
import { UpdatePatientInfo } from 'components/features/UpdatePatientInfo/UpdatePatientInfo';
import { BradControl } from 'components/features/BradControl/BradControl';
import { Permission } from 'types/tables/providers';

export const SettingsView = () => {
  const location = useLocation();
  const { signOut } = useAuth();
  const release = useReleaseName();
  const pathname = location.pathname;

  const permissions = useSelector(getPermissions);
  const { athenaOutage } = useFlags();

  return (
    <div data-testid="SettingsView" className={css.settingsView}>
      <div className={css.menuColumn}>
        <List1 className={css.menu}>
          <Link to="/app/settings/profile">
            <ListItem3 active={pathname === '/app/settings/profile'}>Profile</ListItem3>
          </Link>
          {permissions &&
            permissions.includes(
              Permission.MACRO_CREATE || Permission.MACRO_UPDATE || Permission.MACRO_DELETE,
            ) && (
              <Link to="/app/settings/macros">
                <ListItem3 active={pathname === '/app/settings/macros'}>Team Macros</ListItem3>
              </Link>
            )}
          {permissions &&
            permissions.includes(Permission.RDASH_READ || Permission.RDASH_UPDATE) && (
              <Link to={'/app/settings/rdash-tools'}>
                <ListItem3 active={pathname.match('/app/settings/rdash-tools')}>
                  RDash Tools
                </ListItem3>
              </Link>
            )}
          {permissions &&
            permissions.includes(
              Permission.PATIENT_INFO_READ || Permission.PATIENT_INFO_UPDATE,
            ) && (
              <Link to={!athenaOutage ? '/app/settings/update-patient-info' : '#'}>
                <ListItem3
                  active={pathname.match('/app/settings/update-patient-info')}
                  disabled={athenaOutage}
                >
                  Update Patient Info
                </ListItem3>
              </Link>
            )}
          {permissions &&
            permissions.includes(
              Permission.POP_UP_CREATE ||
                Permission.POP_UP_READ ||
                Permission.POP_UP_UPDATE ||
                Permission.POP_UP_DELETE,
            ) && (
              <Link to="/app/settings/popup-manager">
                <ListItem3 active={pathname.match('/app/settings/popup-manager')}>
                  Pop-up Manager
                </ListItem3>
              </Link>
            )}
          {permissions && permissions.includes(Permission.BRAD_UPDATE) && (
            <Link to={'/app/settings/brad-control'}>
              <ListItem3 active={pathname.match('/app/settings/brad-control')}>
                BRAD Control
              </ListItem3>
            </Link>
          )}
        </List1>
        <Button
          buttonType={ButtonType.transparent}
          className={css.logoutButton}
          onClick={signOut}
          icon={<Logout />}
          content="Logout"
        />
      </div>
      <div className={css.contentColumn}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="macros" element={<Macros />} />
          <Route path="rdash-tools" element={<RDashTools />} />
          <Route path="update-patient-info" element={<UpdatePatientInfo />} />
          <Route path="popup-manager" element={<PopupManager />} />
          <Route path="brad-control" element={<BradControl />} />
          <Route path="/" element={<Profile />} /> {/* load profile by default */}
        </Routes>
        <div className={css.version}>Version: {release}</div>
      </div>
    </div>
  );
};

export default SettingsView;

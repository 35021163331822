import React from 'react';
import { Modal } from 'semantic-ui-react';
import css from './InsuranceCardModal.module.css';
import { Profile } from 'types/tables/profiles';
import { EdenColors } from 'types/colors';

type InsuranceCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  profile: Profile;
  insuranceCardImage: string;
};

const InsuranceCardModal: React.FC<InsuranceCardModalProps> = ({
  isOpen,
  onClose,
  profile,
  insuranceCardImage,
}: InsuranceCardModalProps) => {
  const renderCloseButton = () => {
    return (
      <div className={css.closeButton} onClick={onClose}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill={EdenColors.Grey55}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.03363 1.10115C1.60449 0.493 2.54084  0.482242 3.12503 1.07712L16.5234 14.7206C17.1076 15.3155 17.1184 16.2907 16.5475 16.8989C15.9767 17.507 15.0403 17.5178 14.4561 16.9229L1.05777 3.27941C0.473581 2.68453 0.462773 1.70929 1.03363 1.10115Z" />
          <path d="M16.9664 1.10115C16.3955 0.493 15.4592 0.482242 14.875 1.07712L1.4766 14.7206C0.892414 15.3155 0.881607 16.2907 1.45246 16.8989C2.02332 17.507 2.95967 17.5178 3.54386 16.9229L16.9422 3.27941C17.5264 2.68453 17.5372 1.70929 16.9664 1.10115Z" />
        </svg>
      </div>
    );
  };

  const renderHeader = () => {
    const fullName = `${profile.firstName} ${profile.lastName}`;
    return (
      <div className={css.header}>
        <div className={css.patientInfo}>
          <div className={css.patientInfoInner}>
            <div className={css.patientName}>{fullName}</div>
            <div className={css.bullet}>•</div>
            <div className={css.dob}>{profile.dob}</div>
          </div>
        </div>
        {renderCloseButton()}
      </div>
    );
  };

  const renderImg = () => {
    const src = `data:image/jpeg;base64, ${insuranceCardImage}`;
    return <img alt="insurance card" className={css.img} src={src} />;
  };

  return (
    <Modal open={isOpen} size="tiny" className={css.insuranceCardModal}>
      <Modal.Content className={css.modal}>
        {renderHeader()}
        <div className={css.content}>
          <div className={css.card}>{renderImg()}</div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default InsuranceCardModal;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Message } from 'types/tables/messages';
import { Store } from 'types/redux';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';

const prefix = 'message';

const requestVideoVisitError = 'request video visit error';

export const requestVideoVisit = createAsyncThunk(
  `${prefix}/requestVideoVisit`,
  async (
    { patientId, appointmentReasonId }: { patientId: string; appointmentReasonId: number },
    { rejectWithValue },
  ) => {
    try {
      const res = await GrdnApi.requestVideoVisit(patientId, appointmentReasonId);
      if (isSuccessResponse(res)) {
        return;
      } else {
        return rejectWithValue(requestVideoVisitError);
      }
    } catch (e) {
      return rejectWithValue(requestVideoVisitError);
    }
  },
);

export const messagesAdapter = createEntityAdapter<Message>({});

// Our AddManyAction type is almost the same as the PayloadAction generated by default
// by the messagesAdapter.addMany function, however, ours adds a channelId, which is
// used by a reducer on the Channels reducer
export type AddManyAction = PayloadAction<{ entities: Message[]; channelId: string }>;

export const messages = createSlice({
  name: prefix,
  initialState: messagesAdapter.getInitialState(),
  reducers: {
    upsertMany: (state, action: AddManyAction) => {
      return messagesAdapter.upsertMany(state, action.payload.entities);
    },
  },
});

export const messagesSelector = messagesAdapter.getSelectors<Store>((state) => state.messages);

export const getMessageIds = messagesSelector.selectIds;

// NOTE this may be highly inefficient in use in channels selectors
export const getMessages = messagesSelector.selectEntities;

export default messages.reducer;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './Files.module.css';
import CategorizedFileList, { OldFileList } from './CategorizedFileList';
import StatusMessage from './StatusMessage';
import FilesFilterMenu, { defaultSelection } from './FilesFilterMenu';
import { useActivePatient, useAppDispatch } from 'lib/hooks';
import {
  getFiles,
  getInitialFiles,
  filesByDocumentType,
  filesList,
  filesStatus,
  usedCategoryTypeOptions,
} from 'reducers/files';
import { DocumentCategoryOption } from 'types/tables/documents';

const messages = {
  loading: 'Loading files',
  errorLoading: 'Error loading files. Try again.',
  errorUpdating: 'Error updating file list. Try again.',
  noFilesFound: 'No files found for this patient.',
};

const Files = () => {
  const [selectedOption, setSelectedOption] = useState(defaultSelection.value);
  const [selectedCategories, setSelectedCategories] = useState<DocumentCategoryOption[] | null>(
    null,
  );
  const filesByType = useSelector(filesByDocumentType);
  const files = useSelector(filesList);
  const options = useSelector(usedCategoryTypeOptions);
  const status = useSelector(filesStatus);
  const dispatch = useAppDispatch();
  const { id: patientId } = useActivePatient();
  const filesWrapper = useRef<HTMLDivElement>(null);
  /** TODO:  [APP-8671] Remove File Enhancements feature flag */
  const { fileEnhancementsEnabled } = useFlags();

  useEffect(() => {
    const validOption = options?.find((option) => option.value === selectedOption);
    if (validOption) {
      setSelectedCategories([validOption]);
    } else {
      setSelectedOption(defaultSelection.value);
      setSelectedCategories(options);
    }
  }, [filesByType, selectedOption, options]);

  useEffect(() => {
    setSelectedOption(defaultSelection.value);
  }, [patientId]);

  const handelRetry = useCallback(() => {
    dispatch(getFiles(patientId));
  }, [dispatch, patientId]);

  const handelRetryLoading = useCallback(() => {
    dispatch(getInitialFiles(patientId));
  }, [dispatch, patientId]);

  const handleFilterMenuSelect = (key: string) => {
    setSelectedOption(key);
    if (filesWrapper.current) {
      // needed in order to close the filter menu
      filesWrapper.current.focus({ preventScroll: true });
    }
  };

  if (status === 'loading') {
    return (
      <div className={css.filesWrapper}>
        <StatusMessage type="loading" message={messages.loading} show />
      </div>
    );
  }

  if (!filesByType && status === 'error') {
    return (
      <div className={css.filesWrapper}>
        <StatusMessage
          type="error-reload"
          message={messages.errorLoading}
          onClick={handelRetryLoading}
          show
        />
      </div>
    );
  }

  if (filesByType && selectedCategories && options) {
    return (
      <div className={css.filesWrapper} ref={filesWrapper} tabIndex={0}>
        {status === 'error' && (
          <StatusMessage
            type="error-reload"
            message={messages.errorUpdating}
            onClick={handelRetry}
            show
          />
        )}

        {fileEnhancementsEnabled ? (
          <>
            <div className={css.filterMenu}>
              <FilesFilterMenu
                options={options}
                selected={selectedOption}
                onSelect={handleFilterMenuSelect}
              />
            </div>
            <CategorizedFileList categories={selectedCategories} files={filesByType} />
          </>
        ) : (
          <OldFileList files={files} />
        )}
      </div>
    );
  }

  return (
    <div className={css.filesWrapper}>
      <div className={css.files}>{messages.noFilesFound}</div>
    </div>
  );
};

export default React.memo(Files);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { providersSelectors } from './providers';
import { Store } from 'types/redux';
import { User } from 'types/tables/user';
import { captureException, scopeUser } from 'lib/sentry';
import poll from 'lib/poll';
import { disconnectFromSendbird } from 'lib/sendbird';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { Auth } from 'lib/auth';

const prefix = 'user';

const authorizationErrorMessage = 'authorization error';
const loginErrorMessage = 'login error';

export const initialState: User = {
  id: -1,
  name: '',
  email: '',
  username: '',
  givenName: '',
  googleId: '',
  isLoggedIn: false,
  isAuthorized: false,
};

export const authorize = createAsyncThunk(
  `${prefix}/authorize`,
  async (_arg, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.authorize();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue(authorizationErrorMessage);
      }
    } catch (e) {
      return rejectWithValue(authorizationErrorMessage);
    }
  },
);

export const login = createAsyncThunk(
  `${prefix}/login`,
  async (
    { authInstance, user }: { authInstance: Auth; user?: Record<string, any> },
    { rejectWithValue },
  ) => {
    try {
      const profile = await authInstance.signIn(user);
      scopeUser(profile);
      return profile;
    } catch (e) {
      return rejectWithValue(loginErrorMessage);
    }
  },
);

export const logout = createAsyncThunk(`${prefix}/logout`, async () => {
  try {
    poll.stopAll();
    await disconnectFromSendbird();
  } catch (e) {
    captureException(e);
  }
});

export const users = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(authorize.fulfilled, (state) => {
      state.isAuthorized = true;
    });
    builder.addCase(authorize.rejected, (state) => {
      state.isAuthorized = false;
    });
    builder.addCase(login.fulfilled, (state, { payload: user }) => {
      Object.assign(state, { ...state, ...user, isLoggedIn: true });
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const getMyGoogleId = (state: Store) => state.user.googleId;
export const getUser = (state: Store) => state.user;
export const getMyProvider = createSelector(
  getMyGoogleId,
  providersSelectors.selectAll,
  (googleId, providers) => providers.find(({ googleId: gId }) => gId === googleId),
);
export const getMyProviderId = createSelector(getMyProvider, (user) => user && user.id);

export const getPermissions = createSelector(getMyProvider, (user) => user && user.permissions);

export default users.reducer;

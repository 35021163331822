import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Divider } from 'semantic-ui-react';
import ChatMessage from './ChatMessage';
import {
  InsuranceSubmitted,
  Metadata,
  ScreenerCompleted,
  VideoCallCard,
  VideoVisitRequestCard,
} from './Events';
import css from './MessageElement.module.css';
import { profileIsError } from 'types/tables/profiles';
import { markdownFormatter } from 'lib/markdown';
import { belongsInPrimaryChannel, isToday } from 'lib/util';
import { Store } from 'types/redux';
import { getPatientProfile } from 'selectors/profiles';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import {
  AdminMessageType,
  AssignmentEventType,
  Message,
  PendingMessage,
  PrimaryMessage,
  PrimaryMessageType,
  ScreenerEventType,
  StatusEventType,
  TriageType,
} from 'types/tables/messages';
import { shouldDivideChat } from 'components/features/Chat/util';
import WelcomeCard from 'components/features/Chat/WelcomeCard';

interface Props {
  message: Message | PendingMessage;
  previousMessage?: Message;
  previousPrimaryMessage?: Message;
  isLastMessage: boolean;
  showLastRead: boolean;
  lastRead?: number;
  refSetMessage?: (arg: any) => void;
}

const MessageElement = ({
  message,
  previousMessage,
  previousPrimaryMessage,
  isLastMessage,
  showLastRead,
  lastRead,
  refSetMessage,
}: Props) => {
  const activePatient = useActivePatient();
  const profile = useParameterizedSelector(getPatientProfile, activePatient.id);
  const user = useSelector((state: Store) => state.user);

  const isDiffDay = shouldDivideChat(message, previousMessage);
  const previousTimestamp =
    previousPrimaryMessage &&
    (previousPrimaryMessage as PrimaryMessage).senderId !== activePatient.sendbirdUserId &&
    previousPrimaryMessage.timestamp;
  const lastReadTimestamp = lastRead ?? 0;
  const senderId = belongsInPrimaryChannel(message) && (message as PrimaryMessage).senderId;
  const showUnreadDivider =
    showLastRead &&
    senderId &&
    senderId !== activePatient.sendbirdUserId &&
    message.timestamp > lastReadTimestamp &&
    (previousTimestamp ? previousTimestamp <= lastReadTimestamp : true);

  const component = () => {
    switch (message.type) {
      case ScreenerEventType.srha:
      case ScreenerEventType.welcomeSurvey:
      case AssignmentEventType.Assigned:
      case AssignmentEventType.Unassigned:
      case StatusEventType.Archived:
      case TriageType:
        return <Metadata message={message} ref={refSetMessage} />;

      case PrimaryMessageType.ScreenerCompleted:
        return (
          <ScreenerCompleted
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
            ref={refSetMessage}
          />
        );

      case PrimaryMessageType.InsuranceSubmitted:
        return (
          <InsuranceSubmitted
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
            ref={refSetMessage}
          />
        );

      case PrimaryMessageType.Video:
        return (
          <VideoCallCard
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
            ref={refSetMessage}
          />
        );

      case PrimaryMessageType.VideoRequest:
        return (
          <VideoVisitRequestCard
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
          />
        );

      case AdminMessageType.Welcome:
        return (
          <WelcomeCard
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
            ref={refSetMessage}
          />
        );

      case PrimaryMessageType.Text:
      case PrimaryMessageType.Image:
      case PrimaryMessageType.Document:
      case AdminMessageType.AfterHours: // legacy
      case AdminMessageType.CovidVaxEligible: // legacy
      case AdminMessageType.Appointment: // legacy
      case AdminMessageType.Text:
      case PrimaryMessageType.Deleted:
      case PrimaryMessageType.AfterVisitSummary:
        return (
          <ChatMessage
            lastSentMessage={isLastMessage}
            markdownFormatter={markdownFormatter}
            patient={activePatient}
            message={message}
            profile={!profileIsError(profile) ? profile : undefined}
            previous={previousMessage}
            user={user}
            ref={refSetMessage}
            lastReadTimestamp={lastReadTimestamp}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div key={message.id}>
      {isDiffDay && (
        <Divider horizontal className={css.divider}>
          {isToday(DateTime.fromMillis(message.timestamp))
            ? 'Today'
            : DateTime.fromMillis(message.timestamp).toFormat('DDDD')}
        </Divider>
      )}

      {showUnreadDivider && (
        <Divider horizontal className={css.dividerUnread}>
          Unread
        </Divider>
      )}

      {component()}
    </div>
  );
};

export default MessageElement;

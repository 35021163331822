import classNames from 'classnames';
import MarkdownIt from 'markdown-it';
import React from 'react';
import { Comment } from 'semantic-ui-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ChatImage from './ChatImage';
import ChatDocument from './ChatDocument';
import css from './ChatMessage.module.css';
import MessageContainer, { MessageContainerProps, useProviderName } from './MessageContainer';
import AfterVisitSummaryCard from './AfterVisitSummaryCard';
import { User } from 'types/tables/user';
import { getProviderById, getProviderBySendbirdUserId } from 'reducers/providers';
import {
  AdminMessageType,
  ImagePrimaryMessage,
  Message,
  PendStatus,
  PrimaryMessageType,
  TextPrimaryMessage,
} from 'types/tables/messages';
import { Patient } from 'types/tables/patients';
import { isPendingMessage } from 'lib/util';
import { useParameterizedSelector, useSendTextMessage } from 'lib/hooks';
import { RotateCWFeather } from 'components/ui/svg';

interface ChatMessageProps {
  markdownFormatter: MarkdownIt;
  message: Message;
  lastSentMessage: boolean;
  user: User;
  patient: Patient;
}

const ChatMessage = React.forwardRef<HTMLDivElement, ChatMessageProps & MessageContainerProps>(
  (
    {
      markdownFormatter,
      message,
      previous,
      lastSentMessage,
      user,
      profile,
      patient,
      lastReadTimestamp,
    }: ChatMessageProps & MessageContainerProps,
    ref,
  ) => {
    const providerSender = useParameterizedSelector(
      getProviderBySendbirdUserId,
      'senderId' in message ? message.senderId : '',
    );
    const sendTextMessage = useSendTextMessage(patient);

    const providerSenderName = useProviderName(message, profile);
    const providerDeleterId = message.type === PrimaryMessageType.Deleted && message.data.deletedBy;
    const providerDeleter = useParameterizedSelector(getProviderById, providerDeleterId);

    // If there's a record of who deleted the message, display it
    // Otherwise, this message was deleted prior to the document delete feature,
    // when providers could only delete documents they sent themselves
    const providerName = providerDeleter ? providerDeleter.displayName : providerSenderName;

    // TODO: [APP-8481] Remove when pdfUploadEnabled feature flag removed
    const { pdfUploadEnabled } = useFlags();

    const renderUnderText = () => {
      let underText = <div />;
      if (isPendingMessage(message) && message.pendStatus === PendStatus.Failed) {
        underText = (
          <div className={css.notSent}>
            <span className={css.notSentText}>Not sent</span>
            <span
              className={css.retryText}
              onClick={() => {
                sendTextMessage(message);
              }}
            >
              <RotateCWFeather className={css.redoIcon} strokeWidth={3} />
              <span>Retry</span>
            </span>
          </div>
        );
      } else if (lastSentMessage && providerSender && providerSender?.googleId === user.googleId) {
        underText = (
          <div>
            <span>Sent</span>
          </div>
        );
      }
      return <div className={css.underText}>{underText}</div>;
    };

    const textClass = isPendingMessage(message)
      ? message.pendStatus === 'failed'
        ? css.textFailed
        : css.textPending
      : message.type === PrimaryMessageType.Deleted
      ? css.textDeleted
      : css.text;

    /* eslint-disable react/no-danger */
    // Convert Markdown in comment text to HTML.
    const textSection =
      (message as TextPrimaryMessage).data.text &&
      (message.type === PrimaryMessageType.Text ||
        message.type === AdminMessageType.Appointment || // legacy
        message.type === AdminMessageType.AfterHours || // legacy
        message.type === AdminMessageType.CovidVaxEligible || // legacy
        message.type === AdminMessageType.Text ||
        message.type === AdminMessageType.Welcome) ? (
        <Comment.Text className={classNames(css.commentText, textClass)}>
          <span
            dangerouslySetInnerHTML={{
              __html: markdownFormatter.render((message as TextPrimaryMessage).data.text),
            }}
          />
          {renderUnderText()}
        </Comment.Text>
      ) : null;

    /* eslint-enable react/no-danger */
    const imageSection =
      message.type === PrimaryMessageType.Image ? (
        <ChatImage imageId={(message as ImagePrimaryMessage).data.imageId} />
      ) : null;

    // TODO: [APP-8481] Remove when pdfUploadEnabled feature flag removed
    const documentSection =
      message.type === PrimaryMessageType.Document && pdfUploadEnabled ? (
        <ChatDocument documentId={message.data.documentId} />
      ) : null;

    const deletedMessageSection =
      message.type === PrimaryMessageType.Deleted ? (
        <Comment.Text className={classNames(css.commentText, textClass)}>
          Message was deleted by {providerName}
        </Comment.Text>
      ) : null;

    const afterVisitSummarySection =
      message.type === PrimaryMessageType.AfterVisitSummary ? (
        <AfterVisitSummaryCard message={message} />
      ) : null;

    const content = (
      <>
        {textSection}
        {imageSection}
        {documentSection}
        {deletedMessageSection}
        {afterVisitSummarySection}
      </>
    );

    return textSection ||
      imageSection ||
      documentSection ||
      deletedMessageSection ||
      afterVisitSummarySection ? (
      <div ref={ref}>
        <MessageContainer
          message={message}
          previous={previous}
          user={user}
          profile={profile}
          content={content}
          lastReadTimestamp={lastReadTimestamp}
        />
      </div>
    ) : null;
  },
);

ChatMessage.displayName = 'ChatMessage';

export default ChatMessage;

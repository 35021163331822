import React from 'react';
import { EdenColors } from 'types/colors';

const DeletedIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="20" fill={EdenColors.Slate05} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14C10 13.4477 10.4477 13 11 13H29C29.5523 13 30 13.4477 30 14C30 14.5523 29.5523 15 29 15H11C10.4477 15 10 14.5523 10 14Z"
        fill={EdenColors.Slate}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11C17.7348 11 17.4804 11.1054 17.2929 11.2929C17.1054 11.4804 17 11.7348 17 12V13H23V12C23 11.7348 22.8946 11.4804 22.7071 11.2929C22.5196 11.1054 22.2652 11 22 11H18ZM25 13V12C25 11.2043 24.6839 10.4413 24.1213 9.87868C23.5587 9.31607 22.7956 9 22 9H18C17.2044 9 16.4413 9.31607 15.8787 9.87868C15.3161 10.4413 15 11.2043 15 12V13H13C12.4477 13 12 13.4477 12 14V28C12 28.7957 12.3161 29.5587 12.8787 30.1213C13.4413 30.6839 14.2043 31 15 31H25C25.7957 31 26.5587 30.6839 27.1213 30.1213C27.6839 29.5587 28 28.7957 28 28V14C28 13.4477 27.5523 13 27 13H25ZM14 15V28C14 28.2652 14.1054 28.5196 14.2929 28.7071C14.4804 28.8946 14.7348 29 15 29H25C25.2652 29 25.5196 28.8946 25.7071 28.7071C25.8946 28.5196 26 28.2652 26 28V15H14Z"
        fill={EdenColors.Slate}
      />
    </svg>
  );
};

export default DeletedIcon;

import React from 'react';
import css from './ChartElements.module.css';

export const ChartElement = ({ children }: { children: React.ReactNode }) => (
  <div className={css.container}>{children}</div>
);

export const EmptyChartElement = ({ children }: { children: React.ReactNode }) => (
  <ChartElement>
    <div className={css.alertContainer}>
      <div className={css.alert}>!</div>
    </div>
    <div className={css.text}>{children}</div>
  </ChartElement>
);

import React, { ChangeEvent, useState } from 'react';

import cx from 'classnames';
import { toast } from 'react-toastify';

import { DeletedNextStepResolution, DeleteReasonType, NextStep } from '../../CarePlanTypes';

import css from './NextStepDeleteModal.module.css';

import { HeaderMedium, BodySmall } from 'components/design-system/Text';
import { Modal } from 'components/ui/Modal';
import { Trash } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { PrimaryButton } from 'components/design-system/Form';
import { resolveNextStep } from 'reducers/patients';
import { useActivePatient, useAppDispatch } from 'lib/hooks';
import NextStepContent from 'components/features/CarePlan/next-steps/card/NextStepContent';

interface Props {
  step: NextStep;
  onClose: () => void;
}

const NextStepDeleteModal = ({ step, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const activePatient = useActivePatient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteReasonType, setDeleteReasonType] = useState<DeleteReasonType>();

  const onReason = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case 'irrelevant':
        setDeleteReasonType('irrelevant');
        break;
      case 'mistake':
        setDeleteReasonType('mistake');
        break;
      case 'wrong-patient':
        setDeleteReasonType('wrong-patient');
        break;
      default:
        throw new Error(`unsupported delete reason type: ${e.target.value}`);
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    const resolution: DeletedNextStepResolution = {
      nextStepId: step.id,
      replyType: 'deleted',
      patientId: activePatient.id,
      deleteReasonType,
    };

    const response = await dispatch(resolveNextStep({ patientId: activePatient.id, resolution }));
    if (resolveNextStep.fulfilled.match(response)) {
      toast.success(`Next step successfully deleted.`);
    } else {
      toast.error(
        'Unable to delete next step. Please try again, and if the problem persists please report the issue.',
      );
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal isOpen closeModal={onClose}>
      <HeaderMedium className={css.header}>
        <Trash className={css.headerIcon} width={24} height={24} color={EdenColors.SlateDarken20} />{' '}
        Delete Patient Next Step
      </HeaderMedium>

      <BodySmall>This will permanently delete the next step from the care plan</BodySmall>

      <div className={css.card}>
        <NextStepContent step={step} />
      </div>

      <BodySmall className={css.label}>Reason for deleting (optional)</BodySmall>

      <ul className={css.reasonList}>
        <li>
          <label>
            <input type="radio" name="reason" value="wrong-patient" onChange={onReason} />
            <BodySmall className={css.reasonLabel}>
              Added to the wrong patient&apos;s care plan
            </BodySmall>
          </label>
        </li>

        <li>
          <label>
            <input type="radio" name="reason" value="mistake" onChange={onReason} />
            <BodySmall className={css.reasonLabel}> Created on mistake</BodySmall>
          </label>
        </li>

        <li>
          <label>
            <input type="radio" name="reason" value="irrelevant" onChange={onReason} />
            <BodySmall className={css.reasonLabel}>No longer relevant</BodySmall>
          </label>
        </li>
      </ul>

      <div className={css.buttonBar}>
        <PrimaryButton
          type="button"
          className={cx(css.button, css.cancelButton)}
          value="Cancel"
          onClick={onClose}
        />

        <PrimaryButton
          onClick={onSubmit}
          value="Delete"
          className={cx(css.button, css.deleteButton)}
          loading={isSubmitting}
          data-testid="step-edit-submit-button"
        />
      </div>
    </Modal>
  );
};

export default NextStepDeleteModal;

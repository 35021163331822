import React, { useEffect } from 'react';

import EmptyNextStepsCard from './next-steps/EmptyNextStepsCard';
import NextStepLoadingCard from './next-steps/NextStepLoadingCard';
import NextStepsList from './next-steps/NextStepsList';

import {
  fetchNextSteps,
  getPatientActiveNextSteps,
  getPatientCompletedNextSteps,
  getPatientNextStepsLoadingState,
} from 'reducers/patients';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { LoadingState } from 'types/tables/patients';

import css from 'components/features/CarePlan/CarePlan.module.css';

const CarePlan = () => {
  const activePatient = useActivePatient();

  const dispatch = useAppDispatch();

  const loadingState = useParameterizedSelector(getPatientNextStepsLoadingState, activePatient.id);

  const activeNextSteps = useParameterizedSelector(getPatientActiveNextSteps, activePatient.id);
  const completedNextSteps = useParameterizedSelector(
    getPatientCompletedNextSteps,
    activePatient.id,
  );

  useEffect(() => {
    if (loadingState === undefined) {
      dispatch(fetchNextSteps({ patientId: activePatient.id }));
    }
  }, [dispatch, activePatient.id, loadingState]);

  return (
    <div className={css.container}>
      <div className={css.section}>
        <div className={css.header}>
          <div className={css.title}>Active Next Steps</div>
        </div>

        {!loadingState || loadingState === LoadingState.Loading ? (
          <NextStepLoadingCard />
        ) : (
          <>
            {activeNextSteps && activeNextSteps.length ? (
              <NextStepsList steps={activeNextSteps} />
            ) : (
              <EmptyNextStepsCard type="active" />
            )}
          </>
        )}
      </div>

      <div className={css.section}>
        <div className={css.header}>
          <div className={css.title}>Completed Next Steps</div>
        </div>

        {!loadingState || loadingState === LoadingState.Loading ? (
          <NextStepLoadingCard />
        ) : (
          <>
            {completedNextSteps && completedNextSteps.length ? (
              <NextStepsList steps={completedNextSteps} />
            ) : (
              <EmptyNextStepsCard type="completed" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CarePlan;

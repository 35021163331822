import React, { useCallback, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import { uniq } from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import css from './AttachButton.module.css';
import SendDocumentModal from './SendDocumentModal';
import { DocumentData, FileType } from './AttachButtonTypes';
import { getMyProviderId } from 'reducers/user';
import { Paperclip } from 'components/ui/svg';
import { TertiaryButton } from 'components/ui/Buttons';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { ChannelStatus } from 'types/tables/channels';
import { useActiveChat, useActivePatient } from 'lib/hooks';
import { channels } from 'reducers/channels';
import { patients } from 'reducers/patients';
import { captureException } from 'lib/sentry';

const maximumDocumentSizeMb = 25; // 25Mb

export interface AttachButtonProps {
  isPatientChat: boolean;
  updateIsUploading: (newStatus: boolean) => void;
  disabled: boolean;
}

const errorMessages = {
  documentSize: `There was an error uploading your PDF. Please ensure that it is a valid PDF under ${maximumDocumentSizeMb}MB.`,
  documentType: 'Unable to upload document. Please select a PDF, JPEG, or PNG file.',
  // TODO: [APP-8481] Remove  documentTypeTemp when pdfUploadEnabled feature flag removed
  documentTypeTemp: 'Unable to upload document. Please select a JPEG or PNG file.',
  imageUpload:
    'There was an error uploading your image. Please ensure that it is a valid image under 5MB.',
  pdfUpload: 'There was an error uploading your PDF document.',
};

type ErrorType = keyof typeof errorMessages;

const acceptableFileTypes = Object.values(FileType).toString();

const AttachButton = (props: AttachButtonProps) => {
  const { updateIsUploading, disabled, isPatientChat } = props;
  const dispatch = useDispatch();
  const { channel } = useActiveChat();
  const [sendDocumentModalOpen, setSendDocumentModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const inputElement = React.useRef<HTMLInputElement | null>();
  const patient = useActivePatient();
  const myProviderId = useSelector(getMyProviderId);
  // TODO: [APP-8481] Remove when pdfUploadEnabled feature flag removed
  const { pdfUploadEnabled } = useFlags();

  const onImageUploadSuccess = useCallback(() => {
    setSelectedFile(null);
    updateIsUploading(false);
  }, [updateIsUploading]);

  const onUploadError = useCallback(
    (e: Error, errorType?: ErrorType) => {
      if (!(e && e.message === 'authentication') && errorType) {
        toast.error(errorMessages[errorType]);
      }
      setSelectedFile(null);
      updateIsUploading(false);
      setSendDocumentModalOpen(false);
      captureException(e);
    },
    [updateIsUploading],
  );

  const handleAttachClick = () => {
    if (inputElement.current && !disabled) {
      inputElement.current.click();
    }
  };

  const uploadImage = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const readerResult = reader.result;
      if (typeof readerResult === 'string') {
        const srcData = readerResult.split(/,(.+)/); // this is the b64 image
        const b64Data = srcData[1];
        GrdnApi.uploadImage(patient.id, channel.id, b64Data)
          .then((response) => {
            if (isSuccessResponse(response)) {
              onImageUploadSuccess();
              return;
            } else {
              throw new Error('upload-failed');
            }
          })
          .catch((e) => onUploadError(e, 'imageUpload'));
        if (isPatientChat) {
          dispatch(
            channels.actions.updateOne({
              id: channel.id,
              changes: {
                status: ChannelStatus.Pending,
              },
            }),
          );
          dispatch(
            patients.actions.updateOne({
              id: patient.id,
              changes: {
                assigned: uniq([...patient.assigned, myProviderId || '']),
              },
            }),
          );
        }
      }
    };
    reader.readAsDataURL(file);
  };

  const handleDocumentChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (inputElement.current) inputElement.current.value = '';

    if (file) {
      if (file.type === FileType.PNG || file.type === FileType.JPEG) {
        updateIsUploading(true);
        uploadImage(file);
        return;
      }
      // TODO: [APP-8481] Remove when pdfUploadEnabled feature flag removed
      if (file.type === FileType.PDF && pdfUploadEnabled) {
        if (file.size <= maximumDocumentSizeMb * 1_000_000) {
          updateIsUploading(true);
          setSelectedFile(file);
          setSendDocumentModalOpen(true);
        } else {
          const typeError = new Error('document-size-error');
          onUploadError(typeError, 'documentSize');
        }
        return;
      }
    }

    const typeError = new Error('document-type-error');
    if (pdfUploadEnabled) {
      onUploadError(typeError, 'documentType');
    } else {
      // TODO: [APP-8481] Remove when pdfUploadEnabled feature flag removed
      onUploadError(typeError, 'documentTypeTemp');
    }
  };

  const handleSend = useCallback(
    (docData: DocumentData) => {
      setSendDocumentModalOpen(false);
      GrdnApi.uploadDocument(docData)
        .then((response) => {
          if (isSuccessResponse(response)) {
            onImageUploadSuccess();
            return;
          } else {
            throw new Error('upload-failed');
          }
        })
        .catch((e) => {
          onUploadError(e, 'pdfUpload');
        });
    },
    [onImageUploadSuccess, onUploadError],
  );

  const closeSendDocumentMessageModal = useCallback(() => {
    setSendDocumentModalOpen(false);
    updateIsUploading(false);
  }, [updateIsUploading]);

  return (
    <div>
      <input
        style={{ display: 'none' }}
        type="file"
        accept={acceptableFileTypes}
        ref={(input) => (inputElement.current = input)}
        data-testid="AttachInput"
        onChange={handleDocumentChange}
      />
      <TertiaryButton
        data-testid="AttachButton"
        disabled={disabled}
        className={cx(css.button, disabled && css.disabled)}
        onMouseDown={handleAttachClick}
        aria-label="Attach Image or Document"
      >
        <Paperclip />
      </TertiaryButton>
      {sendDocumentModalOpen && selectedFile && (
        <SendDocumentModal
          isOpen={sendDocumentModalOpen}
          close={closeSendDocumentMessageModal}
          onSend={handleSend}
          file={selectedFile}
        />
      )}
    </div>
  );
};

export default React.memo(AttachButton);

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Item } from 'react-stately';
import { DateTime } from 'luxon';
import css from './Navigation.module.css';
import { DropdownMenu } from 'components/design-system/DropdownMenu/DropdownMenu';
import { getMyProvider } from 'reducers/user';
import { EdenCircleLogo, MenuInactive, MenuActive, MenuClose } from 'components/ui/svg';
import { ProfilePicture } from 'components/ui/Pills';
import { loadEnv } from 'lib/env';
import { getProviderTicklersUrl, getHomeUrl } from 'lib/athenaUrls';

interface NavigationProps {
  menuActive: boolean;
  toggleSettings: (_) => void;
  goHome: () => void;
  testId: string;
}

type MenuOption = 'Ticklers' | 'Home';

const Navigation = ({ menuActive, toggleSettings, goHome, testId }: NavigationProps) => {
  const myProvider = useSelector(getMyProvider);

  const onMenuSelect = (key: MenuOption) => {
    switch (key) {
      case 'Ticklers':
        if (myProvider && myProvider.athenaProviderId) {
          const startDate = DateTime.now()
            .minus({ weeks: 1 })
            .startOf('week')
            .minus({ days: 1 })
            .toFormat('MM/dd/yyyy');
          const endDate = DateTime.now()
            .plus({ weeks: 1 })
            .endOf('week')
            .minus({ days: 1 })
            .toFormat('MM/dd/yyyy');

          window.open(
            getProviderTicklersUrl(`${myProvider.athenaProviderId}`, startDate, endDate),
            '_blank',
            'noopener, noreferrer',
          );
        }
        break;
      case 'Home':
        window.open(getHomeUrl(), '_blank', 'noopener, noreferrer');
        break;
    }
  };

  return (
    // NOTE: MenuActive hides on hover, as a css hack to get around no react hover listener.
    // Mouse event listeners can get in a bad state.
    <div className={css.nav} data-testid={testId}>
      {loadEnv('REACT_APP_LOCAL_PROD') && (
        <div className={css.localProdModeWarning}>🚨 Production Environment 🚨</div>
      )}
      <div className={css.logo} onClick={goHome}>
        <EdenCircleLogo />
      </div>
      <div className={css.menu} onClick={() => toggleSettings(menuActive)}>
        {menuActive ? <MenuActive className={css.hideOnHover} /> : <MenuInactive />}
        {menuActive && <MenuClose className={css.showOnHover} />}
      </div>
      <div className={css.profilePicture}>
        <DropdownMenu
          menuTrigger={<ProfilePicture image={myProvider?.headshotUrl} />}
          onAction={onMenuSelect}
          placement="bottom right"
        >
          {myProvider && myProvider.athenaProviderId && <Item key="Ticklers">My Ticklers</Item>}
          <Item key="Home">Athena Home</Item>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Navigation;

import React from 'react';
import { Item } from 'react-stately';
import cx from 'classnames';
import css from './FilesFilterMenu.module.css';
import { DropdownMenu } from 'components/design-system/DropdownMenu/DropdownMenu';
import { DocumentCategoryOption } from 'types/tables/documents';
import { DownArrow } from 'components/ui/svg';

export const defaultSelection: DocumentCategoryOption = { value: 'all', label: 'All files' };

interface SelectedOptionProps {
  label: string;
  isOpen?: boolean;
}

const SelectedOption = ({ label, isOpen }: SelectedOptionProps) => {
  return (
    <div className={cx(css.selectedOption, isOpen && css.open)}>
      <span>{label}</span>
      <DownArrow className={css.icon} width={10.5} height={6} />
    </div>
  );
};

interface FilesFilterMenuProps {
  onSelect: (key: string) => void;
  options: DocumentCategoryOption[];
  selected: string;
}

const FilesFilterMenu = ({ onSelect, options, selected }: FilesFilterMenuProps) => {
  const handleSelect = (key: React.Key) => {
    onSelect(key.toString());
  };

  const allOptions = [defaultSelection, ...options];
  const label = allOptions.find((option) => option.value === selected)?.label || '';

  return (
    <DropdownMenu
      menuTrigger={<SelectedOption label={label} />}
      onAction={handleSelect}
      placement="bottom right"
    >
      {allOptions.map((option) => (
        <Item textValue={option.label} key={option.value}>
          <div className={css.item} data-testid={option.label}>
            {option.label}
          </div>
        </Item>
      ))}
    </DropdownMenu>
  );
};

export default FilesFilterMenu;

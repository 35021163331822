import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { TertiaryButton } from 'components/ui/Buttons';
import { TrashSmall } from 'components/ui/svg';
import css from 'components/features/Chat/MessageContainer.module.css';
import DeleteMessageModal from 'components/features/Chat/DeleteMessageModal';
import { Message } from 'types/tables/messages';

interface MessageControlsProps {
  message: Message;
  patientId?: string;
  name: string;
  content?: ReactElement;
  lastReadTimestamp?: number;
  providerSenderId?: string;
}

const MessageControls = (props: MessageControlsProps) => {
  const { message, patientId, name, content, lastReadTimestamp, providerSenderId } = props;

  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);

  const closeDeleteMessageModal = () => {
    setDeleteMessageModalOpen(false);
  };

  return (
    <>
      <TertiaryButton
        className={classNames(css.deleteBtn)}
        data-testid="MessageDeleteButton"
        onClick={() => {
          setDeleteMessageModalOpen(true);
        }}
      >
        <TrashSmall />
      </TertiaryButton>
      {deleteMessageModalOpen && (
        <DeleteMessageModal
          isOpen
          closeModal={closeDeleteMessageModal}
          message={message}
          patientId={patientId}
          name={name}
          content={content}
          lastReadTimestamp={lastReadTimestamp}
          providerSenderId={providerSenderId}
        />
      )}
    </>
  );
};
export default MessageControls;

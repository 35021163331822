import React from 'react';
import type { ReactNode } from 'react';
import cx from 'classnames';
import type { IconName } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import css from './Alert.module.css';

type AlertSeverity = 'error' | 'info';

export interface AlertProps {
  severity: AlertSeverity;
  children: ReactNode;
  hideIcon?: boolean;
  center?: boolean;
  inline?: boolean;
}

const classNameBySeverity: Record<AlertSeverity, keyof typeof css> = {
  error: 'error',
  info: 'info',
};

const iconNameBySeverity: Record<AlertSeverity, IconName> = {
  error: 'alert-circle',
  info: 'alert-circle',
};

/**
 * An accessible container to communicate an important message that requires the user's immediate
 * attention.
 */
export const Alert = ({
  severity,
  children,
  hideIcon = false,
  center = false,
  inline = true,
}: AlertProps) => {
  return (
    <div
      role="alert"
      className={cx(
        css.alert,
        css[classNameBySeverity[severity]],
        center && css.center,
        inline && css.inline,
        hideIcon && css.withoutIcon,
      )}
    >
      {!hideIcon && <Icon name={iconNameBySeverity[severity]} className={css.icon} />}
      {children}
    </div>
  );
};

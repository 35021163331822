import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import css from './MacroPreviewModal.module.css';
import { useAppDispatch } from 'lib/hooks';
import { Macro } from 'types/tables/macros';
import { createMacro, editMacro, getMacros } from 'reducers/macros';
import markdown from 'lib/markdown';
import { DocumentIcon } from 'components/ui/svg';
import { Button, ButtonType } from 'components/ui/Buttons';
import { Body2, Header2, Header3 } from 'components/ui/Typography';
import { Provider } from 'types/tables/providers';

interface MacroModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  myProvider?: Provider;
  macro: Macro | null;
  onKeepEditing: () => void;
  onClose: () => void;
}

export const MacroPreviewModal = ({
  title,
  text,
  isOpen,
  myProvider,
  macro,
  onKeepEditing,
  onClose,
}: MacroModalProps) => {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (macro) {
        const res = await dispatch(editMacro({ id: macro.id, title: title, text: text }));
        if (editMacro.fulfilled.match(res)) {
          await dispatch(getMacros());
        } else {
          toast.error(res.payload?.rejectionType);
        }
      } else {
        const res = await dispatch(createMacro({ title: title, text: text }));
        if (createMacro.rejected.match(res)) {
          toast.error(res.payload?.rejectionType);
        }
      }
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} size="tiny" onClose={onClose}>
      <Modal.Content className={css.modal}>
        <Header2 className={css.header}>
          <>
            <DocumentIcon />
            <span className={css.headerText}>Preview</span>
          </>
        </Header2>
        <Header3 className={css.title}>{title}</Header3>
        <div className={css.textContainer}>
          <Body2
            dangerouslySetInnerHTML={{
              __html: markdown({
                text,
                providerName: myProvider && myProvider.displayName,
              }),
            }}
          />
        </div>
        <div className={css.buttonsContainer}>
          <Button
            buttonType={ButtonType.grey}
            onClick={onKeepEditing}
            className={css.keepEditingButton}
            content="Keep editing"
          />

          <Button
            onClick={handleSubmit}
            disabled={submitting}
            className={css.submit}
            content={macro ? 'Save' : 'Create'}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default MacroPreviewModal;

import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import 'semantic-ui-css/semantic.min.css';

import RootNode from './rootNode';

ReactDOM.render(<RootNode />, document.getElementById('root'));

ReactModal.setAppElement('#root');

import React from 'react';
import { EdenColors } from 'types/colors';

const LabWorkIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4218 37.333C23.9126 36.5166 24.1933 35.5641 24.1933 34.5446C24.1933 31.5397 21.7363 29.0832 18.68 29.0832C15.6237 29.0832 13.1667 31.5397 13.1667 34.5446C13.1667 35.5641 13.4474 36.5166 13.9382 37.333H23.4218ZM24.2852 38.9997C25.2706 37.7793 25.86 36.2303 25.86 34.5446C25.86 30.6078 22.6454 27.4165 18.68 27.4165C14.7146 27.4165 11.5 30.6078 11.5 34.5446C11.5 36.2303 12.0894 37.7793 13.0748 38.9997H24.2852Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8165 20.0629L24.0477 13.5821C23.8162 13.1843 23.305 13.0473 22.9057 13.2762L19.4711 15.2448C19.0718 15.4737 18.9357 15.9818 19.167 16.3796L22.9359 22.8603C23.1672 23.2582 23.6786 23.3951 24.0778 23.1663L27.5125 21.1976C27.9117 20.9688 28.0479 20.4607 27.8165 20.0629ZM25.4936 12.7534C24.7995 11.5598 23.2657 11.1488 22.0678 11.8354L18.6332 13.8041C17.4353 14.4907 17.0269 16.0149 17.7211 17.2084L21.4899 23.6891C22.1841 24.8827 23.7178 25.2936 24.9157 24.607L28.3503 22.6384C29.5482 21.9518 29.9566 20.4276 29.2625 19.234L25.4936 12.7534Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M20.5499 10.8719C21.1915 10.5042 22.0129 10.7243 22.3847 11.3635C22.7564 12.0028 22.5378 12.8191 21.8962 13.1869L19.5559 14.5282C18.9144 14.896 18.0929 14.6758 17.7212 14.0365C17.3494 13.3973 17.5682 12.581 18.2097 12.2133L20.5499 10.8719Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.137 13.8078L21.4773 12.4665C21.7195 12.3276 21.8021 12.0193 21.6618 11.7779C21.5214 11.5365 21.2111 11.4534 20.9688 11.5923L18.6287 12.9336C18.3864 13.0725 18.3038 13.3808 18.4442 13.6222C18.5845 13.8635 18.8948 13.9467 19.137 13.8078ZM22.3847 11.3635C22.0129 10.7243 21.1915 10.5042 20.5499 10.8719L18.2097 12.2133C17.5682 12.581 17.3494 13.3973 17.7212 14.0365C18.0929 14.6758 18.9144 14.896 19.5559 14.5282L21.8962 13.1869C22.5378 12.8191 22.7564 12.0028 22.3847 11.3635Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7384 12.9353L17.1475 10.0846L19.3384 8.87988L20.9293 11.7306L18.7384 12.9353Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9796 25.2692L24.9697 23.3175L27.198 22.1821L28.2077 24.1339L25.9796 25.2692Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7303 26.5267L21.9137 34.3066L21.0869 32.8595L34.9036 25.0796L35.7303 26.5267Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4365 38.1668C21.4365 37.7066 21.8096 37.3335 22.2699 37.3335H34.9631C35.4234 37.3335 35.7964 37.7066 35.7964 38.1668C35.7964 38.6271 35.4234 39.0002 34.9631 39.0002H22.2699C21.8096 39.0002 21.4365 38.6271 21.4365 38.1668Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1682 22.9206C14.2286 20.8151 16.1873 19.1516 18.7323 17.7734L19.526 19.239C17.1347 20.5339 15.5035 21.989 14.6567 23.6703C13.8239 25.3239 13.6913 27.3139 14.5342 29.8242L12.9542 30.3547C12.002 27.5189 12.0938 25.0539 13.1682 22.9206Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default LabWorkIcon;

import React, { useState } from 'react';
import * as _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import css from './ScheduleCallModal.module.css';
import { startVisit } from 'reducers/visits';
import { getPatientProfile } from 'selectors/profiles';
import { getMyProviderId } from 'reducers/user';
import { ButtonSizes, PrimaryButton, SecondaryButton } from 'components/ui/Buttons';
import { Description, Header } from 'components/ui/Typography';
import { VideoIcon, XIcon } from 'components/ui/svg';
import { MockVideoCallCard } from 'components/features/Chat/Events';
import { profiles } from 'reducers/profiles';
import * as GrdnApi from 'lib/grdn';
import { EdenToast } from 'lib/toast';
import { Channel, ChannelStatus } from 'types/tables/channels';
import { getPatientName, getPatientRecord, patients } from 'reducers/patients';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { profileIsError } from 'types/tables/profiles';
import { isSuccessResponse } from 'types/api';
import { channels } from 'reducers/channels';

interface ScheduleCallModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  channel: Channel;
  //TODO: APP-8005 remove when zoom-links feature flag is removed
  zoomLinks: boolean;
  zoomMeetingId?: string;
  appointmentId?: EntityId;
}

const ScheduleCallModal = ({
  isOpen,
  onClose,
  channel,
  zoomLinks,
  zoomMeetingId,
  appointmentId,
}: ScheduleCallModalProps) => {
  const defaultScheduleFailureMessage = 'Failed to schedule call. Please try again.';
  const noZoomIdMessage = 'Failed to schedule call. No zoom user associated to your account.';
  const dispatch = useDispatch();
  const { id: activePatientId } = useActivePatient();
  const [isLoading, setIsLoading] = useState(false);

  const profile = useParameterizedSelector(getPatientProfile, activePatientId);
  const patientRecord = useParameterizedSelector(getPatientRecord, activePatientId);
  const patientName = useParameterizedSelector(getPatientName, patientRecord.id);
  const myProviderId = useSelector(getMyProviderId);
  const isScheduledMeeting = !!appointmentId;

  const scheduleCall = async () => {
    setIsLoading(true);
    if (profileIsError(profile)) throw new Error('Cannot schedule call without patient profile');
    try {
      //TODO: APP-8005 remove when zoom-links feature flag is removed
      const resp = zoomLinks
        ? await GrdnApi.startVideoCall(activePatientId, profile.email, zoomMeetingId)
        : await GrdnApi.startVideoCallDeprecated(activePatientId, profile.email);
      if (isSuccessResponse(resp)) {
        const { joinUrl } = resp.data;
        if (isScheduledMeeting) {
          dispatch(startVisit(activePatientId, appointmentId));
        }
        dispatch(
          channels.actions.updateOne({
            id: channel.id,
            changes: {
              status: ChannelStatus.Pending,
            },
          }),
        );
        dispatch(
          patients.actions.updateOne({
            id: patientRecord.id,
            changes: { assigned: _.uniq([...patientRecord.assigned, myProviderId || '']) },
          }),
        );
        dispatch(
          profiles.actions.updateOne({ id: profile.id, changes: { hasHadVideoVisits: true } }),
        );
        window.open(joinUrl);
      }
    } catch (e) {
      if (e?.type) {
        if (e.type === 'zoom-id') {
          EdenToast.error({ message: noZoomIdMessage, error: e });
        } else {
          EdenToast.error({ message: defaultScheduleFailureMessage, error: e });
        }
      } else {
        EdenToast.error({ message: defaultScheduleFailureMessage, error: e });
      }
    }
    setIsLoading(false);
    onClose();
    return;
  };

  return (
    <div className={css.modal} data-testid="ScheduleCallModal">
      <Modal open={isOpen} size="tiny" onClose={onClose}>
        <Modal.Content>
          <div className={css.modalInner}>
            <div className={css.header}>
              <VideoIcon className={css.icon} />
              <Header className={css.headerText}>Start Video Visit</Header>
              <XIcon className={css.close} onClick={onClose} />
            </div>
            <Description className={css.description}>
              {zoomLinks ? (
                <>
                  Are you sure you want to begin a video visit with {patientName}? This will open a
                  Zoom video call for you. It will also send the patient a video call link in their
                  messages and a push notification.
                </>
              ) : (
                <>
                  Are you sure you want to begin a video visit with {patientName}? This will open a
                  Zoom video call for you and send the patient a video call link in their messages.
                </>
              )}
            </Description>
            <div className={css.cardContainer}>
              <MockVideoCallCard />
            </div>
            <div className={css.buttonRow}>
              <SecondaryButton size={ButtonSizes.large} onClick={onClose}>
                {"Don't Start"}
              </SecondaryButton>
              <PrimaryButton
                disabled={isLoading}
                size={ButtonSizes.large}
                onClick={async () => {
                  await scheduleCall();
                  onClose();
                }}
                data-testid="start-zoom-button"
              >
                Start Visit Now
              </PrimaryButton>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ScheduleCallModal;

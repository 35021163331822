import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import css from './DigitalVisitButton.module.css';
import * as GrdnApi from 'lib/grdn';
import { DigitalVisitEvenName, EventGroupEnum } from 'types/grdn';
import { useActivePatient, useParameterizedSelector, useAppDispatch } from 'lib/hooks';
import {
  StopButton,
  LoaderAnimated,
  StartButton,
  DownArrow,
  UpArrow,
  PhoneIcon,
  MessageIcon,
  VideoIcon,
} from 'components/ui/svg';
import {
  startPatientDigitalVisit,
  getPatientDigitalVisit,
  checkoutPatientDigitalVisit,
  getPatientAppointmentId,
  getPatientVisitAppointmentTypeId,
} from 'reducers/patients';
import Dropdown from 'components/ui/Dropdown';
import { ListItem1 } from 'components/ui/Lists';
import { EdenColors } from 'types/colors';
import { VisitModality } from 'types/tables/patients';
import { getAppointmentTypeIdByModality, getModalityByAppointmentTypeId } from 'lib/util';

interface DigitalVisitButtonProps {}

export enum DigitalVisitStatus {
  Loading = 'Loading',
  StartVisit = 'Start Visit',
  StopVisit = 'Stop Visit',
}

export enum Modality {
  Async = 'Async',
  Video = 'Video',
  Phone = 'Phone',
}

const DigitalVisitButton: React.FC<DigitalVisitButtonProps> = () => {
  const dispatch = useAppDispatch();
  const patient = useActivePatient();
  const digitalVisitId = patient.appointment?.appointmentId;
  const digitalVisitStartDate = patient.appointment?.date;
  const digitalVisitStartTime = patient.appointment?.startTime;
  const { isStartingDigitalVisit, isGettingDigitalVisit, isCheckingOutDigitalVisit } = patient;
  const { athenaOutage } = useFlags();

  useEffect(() => {
    dispatch(getPatientDigitalVisit({ patientId: patient.id }));
  }, [dispatch, patient.id]);

  const loading = isStartingDigitalVisit || isGettingDigitalVisit || isCheckingOutDigitalVisit;

  const datestring = digitalVisitStartDate
    ? DateTime.fromFormat(digitalVisitStartDate, 'MM/dd/yyyy').toFormat('ccc, M/d/y')
    : 'unknown';

  const timestring = digitalVisitStartTime
    ? DateTime.fromFormat(digitalVisitStartTime, 'H:m').toFormat('h:mm a')
    : 'unknown';

  const tooltipText = `Started ${datestring} ${timestring}`;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const appointmentTypeId = useParameterizedSelector(getPatientVisitAppointmentTypeId, patient.id);

  const digitalVisitStatus = loading
    ? DigitalVisitStatus.Loading
    : digitalVisitId
    ? DigitalVisitStatus.StopVisit
    : DigitalVisitStatus.StartVisit;

  const labelText = loading
    ? 'Loading'
    : digitalVisitId || isCheckingOutDigitalVisit
    ? `Stop ${getModalityByAppointmentTypeId(appointmentTypeId)} Visit`
    : 'Start Visit';

  const buttonWrapperClasses = loading
    ? `${css.buttonWrapper} ${css.loading}`
    : digitalVisitId
    ? `${css.buttonWrapper} ${css.hasHover} ${css.stopVisit}`
    : `${css.buttonWrapper} ${css.startVisit} ${isOpen && css.startVisitOpen}`;

  const buttonSVG = loading
    ? LoaderAnimated({ className: css.svgCircleSpinner, width: 20, height: 20 })
    : digitalVisitId
    ? StopButton()
    : StartButton();

  const appointmentId = useParameterizedSelector(getPatientAppointmentId, patient.id);

  const logAthenaOutageEvent = async () => {
    await GrdnApi.createEvent({
      eventGroup: EventGroupEnum.audit,
      eventName: DigitalVisitEvenName.clickDuringAthenaOutage,
      eventData: { patientId: patient.id },
    });
  };

  const startDigitalVisit = async (appointmentTypeId: number) => {
    if (athenaOutage) {
      logAthenaOutageEvent();
      return;
    }

    const startResp = await dispatch(
      startPatientDigitalVisit({
        patientId: patient.id,
        appointmentTypeId: appointmentTypeId,
      }),
    );
    if (startPatientDigitalVisit.rejected.match(startResp)) {
      toast.error(startResp.payload?.rejectionType);
    }
    const getResp = await dispatch(getPatientDigitalVisit({ patientId: patient.id }));
    if (getPatientDigitalVisit.rejected.match(getResp)) {
      toast.error(getResp.payload?.rejectionType);
    }
  };

  const checkoutDigitalVisit = async () => {
    if (athenaOutage) {
      logAthenaOutageEvent();
      return;
    }

    const checkoutResp = await dispatch(
      checkoutPatientDigitalVisit({ appointmentId: appointmentId, patientId: patient.id }),
    );
    if (checkoutPatientDigitalVisit.rejected.match(checkoutResp)) {
      toast.error(checkoutResp.payload?.rejectionType);
    }
    const getResp = await dispatch(getPatientDigitalVisit({ patientId: patient.id }));
    if (getPatientDigitalVisit.rejected.match(getResp)) {
      toast.error(getResp.payload?.rejectionType);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePickModality = (modality: VisitModality) => {
    startDigitalVisit(getAppointmentTypeIdByModality(modality));
    toggleDropdown();
  };

  const handleClickDigitalVisitButton = () => {
    digitalVisitStatus === DigitalVisitStatus.StartVisit
      ? toggleDropdown()
      : checkoutDigitalVisit();
  };

  return (
    <>
      <div
        className={cx(athenaOutage && css.disabled, buttonWrapperClasses)}
        onClick={handleClickDigitalVisitButton}
        data-testid="DigitalVisitButton"
      >
        <div className={css.buttonInner}>
          {buttonSVG}
          <span className={css.labelText}>{labelText}</span>
          {digitalVisitStatus === DigitalVisitStatus.StartVisit &&
            (isOpen ? <UpArrow fill={EdenColors.White} /> : <DownArrow fill={EdenColors.White} />)}
        </div>
        {!athenaOutage && <span className={css.tooltip}>{tooltipText}</span>}
        <Dropdown isOpen={isOpen} className={css.dropdown} onClose={toggleDropdown}>
          <ListItem1
            onClick={() => handlePickModality(VisitModality.Async)}
            className={css.listItem}
            data-testid="AsyncModalityOption"
          >
            <MessageIcon className={css.messageIcon} />
            <span className={css.listText}>{VisitModality.Async}</span>
          </ListItem1>
          <ListItem1
            onClick={() => handlePickModality(VisitModality.Video)}
            className={css.listItem}
            data-testid="VideoModalityOption"
          >
            <VideoIcon width={14} height={14} color={EdenColors.SlateDarken20} />
            <span className={css.listText}>{VisitModality.Video}</span>
          </ListItem1>
          <ListItem1
            onClick={() => handlePickModality(VisitModality.Phone)}
            className={css.listItem}
            data-testid="PhoneModalityOption"
          >
            <PhoneIcon color={EdenColors.SlateDarken20} />
            <span className={css.listText}>{VisitModality.Phone}</span>
          </ListItem1>
        </Dropdown>
      </div>
    </>
  );
};

export default DigitalVisitButton;

import React from 'react';

import type { NextStep } from '../../CarePlanTypes';

import { dispositionOf } from '../../utils/dispositionOf';
import css from './NextStepCard.module.css';
import NextStepContent from './NextStepContent';
import NextStepEllipsisMenu from 'components/features/CarePlan/next-steps/card/NextStepEllipsisMenu';

interface Props {
  step: NextStep;
}

const NextStepCard = ({ step }: Props) => {
  const disposition = dispositionOf(step);

  const showEllipsisMenuControl =
    (step.type === 'custom' || step.type === 'followup') && // provider-created next steps only
    disposition.dispositionType === 'active'; // active only

  return (
    <div className={css.card}>
      <NextStepContent step={step} />
      {showEllipsisMenuControl && <NextStepEllipsisMenu step={step} />}
    </div>
  );
};

export default NextStepCard;

import React, { ChangeEvent } from 'react';

import { SelectAccessible } from 'components/design-system/Form';
import { CarePlanReason, Reason } from 'components/features/CarePlan/utils/reasons';

interface Props {
  value: CarePlanReason | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const options: { value: number; label: string }[] = [
  { value: Reason.ANNUAL_PREVENTATIVE_VISIT, label: 'Annual preventative visit' },
  { value: Reason.BLOOD_OR_LAB_WORK, label: 'Blood or lab work' },
  { value: Reason.COVID_19_PCR_TEST, label: 'COVID-19 PCR test' },
  { value: Reason.COVID_19_RAPID_TEST, label: 'COVID-19 rapid test' },
  { value: Reason.COVID_19_VACCINATION_JANDJ, label: 'COVID-19 vaccination: Johnson & Johnson' },
  { value: Reason.COVID_19_VACCINATION_MODERNA, label: 'COVID-19 vaccination: Moderna' },
  { value: Reason.COVID_19_VACCINATION_MODERNA_BOOSTER, label: 'COVID-19 booster: Moderna' },
  { value: Reason.FLU_SHOT, label: 'Flu shot' },
  { value: Reason.FOCUSED_VISIT, label: 'Focused visit' },
  { value: Reason.PHYSICAL_THERAPY, label: 'Physical therapy' },
  { value: Reason.WOMENS_HEALTH_VISIT, label: "Women's health visit" },
];

const VisitTypeSelector = ({ value, onChange }: Props) => {
  return (
    <SelectAccessible value={value} onChange={onChange} placeholder="Select an option">
      {options.map(({ value, label }) => (
        <option key={value} value={`${value}`}>
          {label}
        </option>
      ))}
    </SelectAccessible>
  );
};

export default VisitTypeSelector;

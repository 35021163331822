export enum FileType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
}

export type DocumentData = {
  file: File;
  patientId: string;
  channelId: string;
  isDependent: string;
  patientDocumentTypeId: string;
  title: string;
  notes: string;
};

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const Link = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.518 1.834a4 4 0 0 1 5.656 5.656l-.008.008-2 2a4 4 0 0 1-6.032-.432.667.667 0 0 1 1.067-.799 2.667 2.667 0 0 0 4.022.288l1.995-1.995a2.667 2.667 0 0 0-3.77-3.77L8.303 3.926a.667.667 0 1 1-.94-.945l1.147-1.14.007-.007Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.267 5.582a4 4 0 0 1 4.601 1.352.667.667 0 1 1-1.068.799 2.667 2.667 0 0 0-4.021-.288L2.783 9.44a2.667 2.667 0 0 0 3.77 3.77l1.136-1.135a.667.667 0 0 1 .943.943l-1.14 1.14-.008.008A4 4 0 0 1 1.828 8.51l.008-.008 2-2a4 4 0 0 1 1.43-.92Z"
      fill="currentColor"
    />
  </svg>
);

export default Link;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import cx from 'classnames';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { useFlags } from 'launchdarkly-react-client-sdk';

import type { NextStep, DraftNextStep } from '../../CarePlanTypes';
import { CarePlanReason, Reason } from '../../utils/reasons';
import VisitTypeSelector from './visit-type-selector';

import css from './NextStepEditModal.module.css';
import { Switch } from 'components/design-system/Switch/Switch';
import { ScrollModal } from 'components/design-system/ScrollModal/ScrollModal';

import { getUser } from 'reducers/user';
import formCss from 'components/design-system/Form.module.css';

import DatePickerWithDay from 'components/design-system/date-picker-with-day';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { Checkbox, ErrorText, Field, Label, TextArea } from 'components/design-system/Form';
import { EdenColors } from 'types/colors';
import { computeDaysFrom, formatPreferredFullNameFor } from 'lib/util';
import { createNextStep, updateNextStep } from 'reducers/patients';
import { createTickler } from 'reducers/ticklers';
import { getProviderByGoogleId } from 'reducers/providers';
import { ListBody, ListBodySmall } from 'components/ui/Typography';
import { NoteInput, ProviderSelector } from 'components/features/TicklerOverview/TicklerFormFields';

interface FormData {
  dueDate: string;
  title: string;
  note: string;
  inPersonOnly: boolean;
  bookWithMe: boolean;
  visitType?: CarePlanReason;
  athenaProviderId: number;
  ticklerDueDate: string;
  ticklerNote: string;
}

interface Props {
  onClose: () => void;
  step?: NextStep;
}

const emptyForm: FormData = {
  dueDate: '',
  title: '',
  note: '',
  inPersonOnly: false,
  bookWithMe: false,
  athenaProviderId: 0,
  ticklerDueDate: '',
  ticklerNote: '',
};

type View = 'custom' | 'followup';

function isInPast(dueDate: string) {
  return DateTime.fromISO(dueDate).startOf('day') < DateTime.now().startOf('day');
}

const NextStepEditModal = ({ onClose, step }: Props) => {
  const dispatch = useAppDispatch();
  const { providerFollowupNextSteps } = useFlags();

  const isEdit = !!step;

  const [formData, setFormData] = useState<FormData>({
    ...emptyForm,
    ...(step &&
      step.type === 'custom' && {
        dueDate: step.dueDate,
        days: computeDaysFrom(step.dueDate),
        title: step.title,
        note: step.note,
      }),
    ...(step &&
      step.type === 'followup' && {
        dueDate: step.dueDate,
        days: computeDaysFrom(step.dueDate),
        title: step.title,
        note: step.note,
        inPersonOnly: step.inPersonOnly,
        visitType: step.visitReasonId,
        bookWithMe: !!step.withProviderId,
      }),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDueDateInvalid, setIsDueDateInvalid] = useState(false); // TODO: when scheduled date is added, might want to generalize this
  const [isFormValid, setIsFormValid] = useState(false);
  const [view, setView] = useState<View>(
    step
      ? step.type === 'custom'
        ? 'custom'
        : 'followup'
      : providerFollowupNextSteps
      ? 'followup'
      : 'custom',
  );
  const [isTicklerForm, setIsTicklerForm] = useState(false);
  const scrollToRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isTicklerForm && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [isTicklerForm]);

  useEffect(() => {
    setIsDueDateInvalid(isInPast(formData.dueDate));
    setIsFormValid(
      !!formData.dueDate &&
        !!formData.title &&
        !!formData.note &&
        !isInPast(formData.dueDate) &&
        (view !== 'followup' || !!formData.visitType) &&
        (!isTicklerForm ||
          (!!formData.athenaProviderId && !!formData.ticklerDueDate && !!formData.ticklerNote)),
    );
  }, [formData, isTicklerForm, view]);

  const activePatient = useActivePatient();
  const { id: patientId } = activePatient;

  const provider = useParameterizedSelector(getProviderByGoogleId, useSelector(getUser).googleId);

  const onDataChange = (value: string | number | boolean, field: keyof FormData) => {
    setFormData((data) => ({
      ...data,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    if (!isFormValid) return;

    const {
      dueDate,
      title,
      note,
      inPersonOnly,
      visitType,
      bookWithMe,
      athenaProviderId,
      ticklerDueDate,
      ticklerNote,
    } = formData;

    setIsSubmitting(true);

    const thunk = isEdit ? updateNextStep : createNextStep;

    const providerNextStep: DraftNextStep = {
      type: view === 'custom' ? 'custom' : 'followup',
      ...(isEdit && { nextStepId: step?.id }),
      patientId,
      dueDate,
      title,
      note,
      inPersonOnly,
      withProviderId: provider && bookWithMe ? provider.id : null,
      visitReasonId: visitType || (Reason.FOCUSED_VISIT as CarePlanReason), // CI doesn't like non-null assertions, so assigning a fallback that should never be used
    };

    const response = await dispatch(thunk(providerNextStep));

    if (thunk.fulfilled.match(response)) {
      let ticklerResponse;
      if (isTicklerForm) {
        ticklerResponse = await dispatch(
          createTickler({
            patientId,
            athenaProviderId,
            dueDate: ticklerDueDate,
            note: ticklerNote,
          }),
        );
      }
      toast.success(`Next step successfully ${isEdit ? 'updated' : 'created'}.`);
      if (isTicklerForm) {
        if (createTickler.fulfilled.match(ticklerResponse)) {
          toast.success('Tickler successfully created.');
        } else {
          toast.error('Unable to create tickler. Try again.');
        }
      }
      setFormData(emptyForm);
      onClose();
    } else {
      toast.error(
        `Unable to create next step${
          isTicklerForm ? ' and associated tickler' : ''
        }. Please try again, and if the problem persists please report the issue.`,
      );
    }
    setIsSubmitting(false);
  };

  return (
    <ScrollModal
      isOpen
      onClose={onClose}
      onSubmit={onSubmit}
      title={`${isEdit ? 'Edit' : 'Create'} Patient Next Step`}
      subtitle="Next steps are sent directly to the patient's care plan"
      submitLabel={isEdit ? 'Save' : 'Add to Care Plan'}
      isLoading={isSubmitting}
      isSubmitDisabled={!isFormValid}
      iconName="check-circle"
      className={css.modalWindow}
    >
      {/* TODO: consider creating a radio buttonset design system component for this  */}
      {providerFollowupNextSteps && !isEdit && (
        <div className={css.typeSelector}>
          <ListBody>Next Step Type</ListBody>

          <div className={css.viewSelector}>
            <div
              className={view === 'followup' ? css.selected : ''}
              onClick={() => setView('followup')}
            >
              Follow-up visit
            </div>
            <div
              className={view === 'custom' ? css.selected : ''}
              onClick={() => setView('custom')}
            >
              Custom
            </div>
          </div>
        </div>
      )}

      <div className={css.container}>
        <Label text="Patient" htmlFor="step-edit-patient">
          <Field
            id="step-edit-patient"
            data-testid="form-patient"
            value={formatPreferredFullNameFor(activePatient)}
            type="text"
            name="patient"
            disabled
            className={css.wide}
          />
        </Label>

        {view === 'followup' && (
          <Label text="Book with me">
            <div className={css.inPersonPane}>
              <ListBody>Patient will only see your schedule when booking appointment</ListBody>

              <Switch
                label="book with me"
                isSelected={formData.bookWithMe}
                onChange={() => {
                  onDataChange(!formData.bookWithMe, 'bookWithMe');
                }}
              />
            </div>
          </Label>
        )}

        {view === 'followup' && (
          <Label text="In-person visit">
            <div className={css.inPersonPane}>
              <ListBody>Limit booking to only in-person options</ListBody>

              <Switch
                label="in-person visit"
                isSelected={formData.inPersonOnly}
                onChange={() => {
                  onDataChange(!formData.inPersonOnly, 'inPersonOnly');
                }}
              />
            </div>
          </Label>
        )}

        <Label text="Due date" required>
          <DatePickerWithDay
            value={formData.dueDate}
            onChange={(value) => {
              onDataChange(value, 'dueDate');
            }}
            testId="next-step-due-date"
            futureOnly
          />

          {isDueDateInvalid && <ErrorText>Due dates must be in the future</ErrorText>}
        </Label>

        {view === 'followup' && (
          <Label text="Visit type" required>
            <VisitTypeSelector
              value={formData.visitType}
              onChange={(e) => onDataChange(e.target.value, 'visitType')}
            />
          </Label>
        )}

        <Label text="Next step title" htmlFor="step-edit-title" required>
          <Field
            id="step-edit-title"
            data-testid="step-edit-title"
            value={formData.title}
            type="text"
            name="title"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onDataChange(e.target.value, 'title');
            }}
            className={css.wide}
          />
        </Label>

        <Label text="Note to patient" required htmlFor="step-edit-note" />
        <TextArea
          id="step-edit-note"
          data-testid="step-edit-note"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            onDataChange(e.target.value, 'note');
          }}
          value={formData.note}
          type="text"
          name="note"
          className={cx(formCss.field, css.note, css.wide)}
          maxLength={65000}
        />

        {!isEdit && (
          <div className={css.ticklerForm}>
            <label
              htmlFor="tickler-form-checkbox"
              className={css.ticklerFormCheckboxLabel}
              onClick={() => setIsTicklerForm((isTicklerForm) => !isTicklerForm)}
            >
              <Checkbox
                className={css.ticklerFormCheckbox}
                checked={isTicklerForm}
                height={20}
                width={20}
                color={EdenColors.Slate15}
                id="tickler-form-checkbox"
                data-testid="tickler-form-checkbox"
              />
              <ListBodySmall>Create an associated tickler</ListBodySmall>
            </label>

            {isTicklerForm && (
              <div className={css.ticklerFormFields}>
                <ProviderSelector
                  label="Assign tickler to"
                  required
                  value={
                    formData.athenaProviderId ? formData.athenaProviderId.toString() : undefined
                  }
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onDataChange(e.target.value, 'athenaProviderId');
                  }}
                />
                <div ref={scrollToRef} />
                <Label text="Tickler due date" required>
                  <DatePickerWithDay
                    value={formData.ticklerDueDate}
                    onChange={(value) => {
                      onDataChange(value, 'ticklerDueDate');
                    }}
                    testId="tickler-day-picker"
                    futureOnly
                  />
                </Label>

                <NoteInput
                  label="Tickler note"
                  required
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    onDataChange(e.target.value, 'ticklerNote');
                  }}
                  value={formData.ticklerNote}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </ScrollModal>
  );
};

export default NextStepEditModal;

import React from 'react';
import css from './Visit.module.css';
import { ListBody, ListHeaderMedium } from 'components/ui/Typography';
import { Appointment } from 'types/athena/visits';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { selectAppointmentScreeners } from 'reducers/appointmentScreeners';
import { AppointmentScreeners } from 'types/tables/appointmentScreeners';
import { formatDateTime } from 'lib/formatDateTime';
import VisitInfoBar from 'components/features/VisitOverview/VisitInfoBar';
import { getAppointmentURL } from 'lib/athenaUrls';
import MeetingActions from 'components/features/VisitOverview/MeetingActions';
import { getIsCloseKnitPatient, getVideoVisitDepartmentBySponsorId } from 'reducers/providerGroups';

type MenuItemOption = 'Document visit';

const CheckedInVisit = ({ visit }: { visit: Appointment }) => {
  const { appointmentId, departmentName, providerId, providerName, sortDate } = visit;

  const appointmentScreeners: AppointmentScreeners | undefined = useParameterizedSelector(
    selectAppointmentScreeners,
    appointmentId,
  );

  const onMenuSelect = (key: MenuItemOption) => {
    switch (key) {
      case 'Document visit':
        window.open(getAppointmentURL(`${appointmentId}`), '_blank', 'noopener, noreferrer');
        break;
    }
  };

  const formattedDate = formatDateTime(sortDate).as('cccc, MMM d').inLocalTimeZone();
  const isProviderAssigned = `${providerId}` !== '3';
  const hasScreeners = !!(appointmentScreeners && appointmentScreeners.screenerNames.length > 0);

  const { sponsorId } = useActivePatient();
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, sponsorId);
  const videoVisitDepartmentId = useParameterizedSelector(
    getVideoVisitDepartmentBySponsorId,
    sponsorId,
  );
  const isVideoVisit = Number(visit.departmentId) === videoVisitDepartmentId;

  return (
    <div className={css.checkedInCard} data-testid="CheckedInVisitCard">
      <div className={css.checkedInHeaderContainer}>
        <ListHeaderMedium className={css.checkedInHeader}>
          {isVideoVisit
            ? `Video Visit`
            : departmentName
            ? `Office Visit at ${departmentName}`
            : 'Office Visit'}
        </ListHeaderMedium>
        <VisitInfoBar
          tags={hasScreeners ? ['ScreenersAssigned'] : []}
          menuItems={[{ content: 'Document visit', testId: 'DocumentVisit' }]}
          onMenuSelect={onMenuSelect}
        />
      </div>
      <div className={css.visitContents}>
        <div className={css.subDetails}>
          <ListBody>{formattedDate}</ListBody>
          {isProviderAssigned && (
            <div className={css.subDetails}>
              <ListBody className={css.separator}>•</ListBody>
              <ListBody>{providerName}</ListBody>
            </div>
          )}
        </div>
      </div>
      {isVideoVisit && !isCloseKnitPatient && (
        <MeetingActions
          appointmentId={visit.appointmentId}
          zoomMeetingId={visit.zoomMeetingId}
          isStarted={visit.isStarted}
        />
      )}
    </div>
  );
};

export default CheckedInVisit;

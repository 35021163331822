import { toast } from 'react-toastify';
import { captureException } from 'lib/sentry';

const error = ({ error, message }: { error: Error; message: string }) => {
  captureException(error);
  toast.error(message);
};

export const EdenToast = {
  error,
};

import React, { useState } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import { toast } from 'react-toastify';
import css from './ActivateModal.module.css';
import {
  formatPopupAddress,
  formatPopupDate,
  formatPopupTimes,
  isRecurringPopup,
} from './DetailsModal';
import { Popup, PopupState } from 'types/tables/popups';
import { BodyNormal, BodySmall, HeaderMedium } from 'components/design-system/Text';
import { Flag, PauseCircle, XIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { PrimaryButton } from 'components/design-system/Form';
import { useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { updatePopup } from 'reducers/popups';
import { getSponsorById } from 'reducers/sponsors';

export interface ActivateModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  popup?: Popup;
}

export const ActivateModal = ({ isOpen, closeModal, popup }: ActivateModalProps) => {
  const sponsor = useParameterizedSelector(getSponsorById, popup?.sponsorId ?? '');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const activatePopup = async () => {
    if (!popup) return;
    setSubmitting(true);
    const res = await dispatch(
      updatePopup({ popupId: popup.id, payload: { popupState: PopupState.active } }),
    );
    setSubmitting(false);
    if (updatePopup.fulfilled.match(res)) closeModal();
    else toast.error('Unable to activate pop-up, please try again.');
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeModal={closeModal}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <div className={css.topNav}>
        <div className={css.icon}>
          <Flag color={EdenColors.SlateDarken20} width={24} height={32} />
        </div>
        <HeaderMedium>Activate this pop-up?</HeaderMedium>
        <div className={css.spacer} />
        <div onClick={closeModal} style={{ cursor: 'pointer' }}>
          <XIcon color={EdenColors.Slate55} height={24} width={24} />
        </div>
      </div>
      <BodySmall className={css.instructions}>
        Activating this pop-up event will enable appointment booking to all members under the
        {` ${sponsor?.name} `}
        sponsor. Members will see the details outlined below.
        <br />
        You may still edit event details after an event is activated.
      </BodySmall>
      <div className={css.details}>
        <BodyNormal
          style={{ color: EdenColors.SlateDarken20, fontWeight: 600, marginBottom: '6px' }}
        >
          {popup?.name}
        </BodyNormal>
        <BodySmall>
          {isRecurringPopup(popup)
            ? `${formatPopupDate(popup?.date)} - ${formatPopupDate(popup?.endDate)}`
            : formatPopupDate(popup?.date)}
        </BodySmall>
        <BodySmall>{formatPopupAddress(popup)}</BodySmall>
        {!isRecurringPopup(popup) && (
          <BodySmall>
            {formatPopupTimes(popup?.startTime, popup?.endTime, popup?.timeZoneId)}
          </BodySmall>
        )}
        <p />
        <BodySmall>{popup?.instructions}</BodySmall>
      </div>
      <div className={css.bottomNav}>
        <PrimaryButton
          className={cx(css.button, css.cancel)}
          type="button"
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          className={css.button}
          type="button"
          value="Activate"
          onClick={activatePopup}
          loading={submitting}
          data-testid="activate-button"
        />
      </div>
    </ReactModal>
  );
};

export const DeactivateModal = ({ isOpen, closeModal, popup }: ActivateModalProps) => {
  const sponsor = useParameterizedSelector(getSponsorById, popup?.sponsorId ?? '');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const deactivatePopup = async () => {
    if (!popup) return;
    setSubmitting(true);
    const res = await dispatch(
      updatePopup({ popupId: popup.id, payload: { popupState: PopupState.draft } }),
    );
    setSubmitting(false);
    if (updatePopup.fulfilled.match(res)) closeModal();
    else toast.error('Unable to deactivate pop-up, please try again.');
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeModal={closeModal}
      className={css.container}
      overlayClassName={css.overlay}
    >
      <div className={css.topNav}>
        <div className={css.icon}>
          <PauseCircle color={EdenColors.SlateDarken20} width={24} height={32} />
        </div>
        <HeaderMedium>Deactivate this pop-up?</HeaderMedium>
        <div className={css.spacer} />
        <div onClick={closeModal} style={{ cursor: 'pointer' }}>
          <XIcon color={EdenColors.Slate55} height={24} width={24} />
        </div>
      </div>
      <BodySmall className={css.instructions}>
        Deactivating this pop-up will:
        <ul>
          <li>Move this event to pop-up drafts.</li>
          <li>Remove booking for all members under the {` ${sponsor?.name} `} sponsor.</li>
        </ul>
        <br />
        Patient appointments that have already been booked must be manually reconciled in Athena.
      </BodySmall>
      <div className={css.bottomNav}>
        <PrimaryButton
          className={cx(css.button, css.cancel)}
          type="button"
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          className={css.button}
          type="button"
          value="Deactivate Now"
          onClick={deactivatePopup}
          loading={submitting}
          data-testid="deactivate-button"
        />
      </div>
    </ReactModal>
  );
};

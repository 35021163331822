import { SidebarChannel } from 'types/tables/channels';

export enum RoomType {
  Patient = 'patient',
  Provider = 'provider',
}

export enum Bucket {
  assigned = 'Assigned',
  unassigned = 'Unassigned',
  archived = 'Archived',
  routed = 'Routed',
}

// Filter option for whole sidebar
export interface SidebarFilterOption {
  filter: string;
}

// Filter option for individual bucket
export interface BucketFilterOption extends SidebarFilterOption {
  patients: SidebarChannel[];
}

interface BaseMessage {
  timestamp: number;
  timetoken: string;
  version: string;
  status?: string;
}

type EventMessageType = 'event.assigned' | 'event.archived' | 'event.unassigned';

interface EventMessage extends BaseMessage {
  type: EventMessageType;
  senderId: string;
  senderUuid: string;
  meta: {
    assigneeId?: string;
    automatic?: boolean;
  };
}

interface TextMessage extends BaseMessage {
  senderId: string;
  text: string;
}

interface ImageMessage extends BaseMessage {
  senderId: string;
  imageId: string;
}

type PatientMessage = TextMessage | ImageMessage;

export type ProviderMessageType = 'message.text' | 'message.image' | 'zoom.created';

interface ProviderTextMessage extends TextMessage {
  type: ProviderMessageType;
  name: string;
  pushEmail: string;
  badge: number;
}

interface ProviderImageMessage extends ImageMessage {
  type: ProviderMessageType;
  name: string;
  pushEmail: string;
  badge: number;
}

interface ProviderZoomMessage extends BaseMessage {
  senderId: string;
  type: ProviderMessageType;
  data: Record<string, any>;
}

export type ProviderMessage = ProviderTextMessage | ProviderImageMessage | ProviderZoomMessage;

interface EdenNoticeMessage extends BaseMessage {
  name: string;
  text: string;
  id?: number;
  startTime?: string;
  departmentId?: number;
  providerId?: number;
}

interface Divider {
  key: string;
  divider: boolean;
}

export type UnreadDivider = Divider & { unread: boolean };
export type DayDivider = Divider & { day: string };
export type DividerMessage = UnreadDivider | DayDivider;
export type PublishedMessage = PatientMessage | ProviderMessage | EdenNoticeMessage | EventMessage;
export type QueuedMessage = ProviderTextMessage & { timeToSend: number };
export type PendingMessage = ProviderMessage & { timestamp: number };
export type Message = PublishedMessage | QueuedMessage | PendingMessage;
export type MessageStream = Message | DividerMessage;

import { ScreenerResponse } from './tables/screeners';
import { AppointmentReason } from 'types/athena/appointmentReason';
import { SendbirdMessage } from 'types/sendbird';
import { ForeignKey, PrimaryKey } from 'types/tables/base';
import { ChannelStatus, ChannelType } from 'types/tables/channels';
import { PatientLocation } from 'types/tables/patients';
import { TriageStatus } from 'types/tables/profiles';
import { Pharmacy } from 'types/tables/pharmacies';
import { NextStep } from 'components/features/CarePlan/CarePlanTypes';
import { AddonCategory, ExternalAddon } from 'types/tables/addons';
import { DocumentMetadata } from 'types/tables/documents';
import { Division } from 'types/tables/divisions';

export interface PatientPayload {
  mrn: number;
  id: string;
  firstName: string | null;
  lastName: string | null;
  preferredName?: string;
  assigned: string[];
  secondaryMrns?: number[];
  sendbirdUserId: string;
  sponsorId: ForeignKey;
  location?: PatientLocation;
  careteamId?: ForeignKey;
  carePodId?: ForeignKey;
  athenaStatus: string;
}

export interface DocumentPayload {
  patientDocumentId: string;
}

export interface UpdateDocumentPayload {
  isDependent: boolean;
  internalNotes?: string | null;
  patientDocumentTypeId?: string | null;
  title: string | null;
}

export interface ImagePayload {
  // TODO https://edenhealth.atlassian.net/browse/APP-2016
  image?: string; // base64 encoded image
  mrn: number;
  imageId: string;
}

export interface PatientDocumentMetadataPayload {
  documents: DocumentMetadata[];
  stats: {
    count: number;
    latestUploadedAt: string | null;
  };
}

export interface HeadshotPayload {
  contentType: string;
  payload: string;
}

export interface VideoCallPayload {
  id: number;
  joinUrl: string;
  timezone: string;
  startTime: string;
  type: number;
  duration: number;
  topic: string;
  hostId: string;
  uuid: string;
  createdAt: string;
}

export interface AuthPayload {
  sendbirdUserId: string;
  sendbirdSessionToken: string;
}

export interface LDHashPayload {
  ldUserKey: string;
  ldHash: string;
}

export enum EventGroupEnum {
  chat = 'chat',
  audit = 'audit',
}

export enum DigitalVisitEvenName {
  clickDuringAthenaOutage = 'digital_visit.click_during_outage',
}

export enum AiEventName {
  clickToGenerateSuggestion = 'ai_click_to_generate_suggestion',
  clickToRegenerateSuggestion = 'ai_click_to_regenerate_suggestion',
  insertSuggestion = 'ai_insert_suggestion',
}

export const reconnectEventName = 'reconnect';
export const reauthorizeEventName = 'reauthorize';

export interface EventPayload {
  eventGroup: EventGroupEnum;
  eventName: string;
  eventData: Record<string, any>;
}

export interface ChartSectionErrorPayload {
  error: string;
}

export interface ChartEntryPayload {
  name: string;
  active: boolean;
  startDate: string; // date
  endDate: string | null; // date or null
}

interface MedicationListPayload {
  lastUpdated: string | null; // date or null
  noMedicationsReported: boolean;
  medications: ChartEntryPayload[];
}

export type MedicationsPayload = MedicationListPayload | ChartSectionErrorPayload;

interface AllergiesListPayload {
  nkda: boolean;
  lastUpdated: string | null;
  allergies: ChartEntryPayload[];
}

export type AllergiesPayload = AllergiesListPayload | ChartSectionErrorPayload;

interface ChartAlertPayload {
  noteText: string;
}

export type AlertPayload = ChartAlertPayload | ChartSectionErrorPayload;

interface ProblemsListPayload {
  noKnownProblems: boolean;
  lastUpdated: string | null; // date or null
  problems: ChartEntryPayload[];
}

export type ProblemsPayload = ProblemsListPayload | ChartSectionErrorPayload;

export interface ChartPayload {
  // TODO https://edenhealth.atlassian.net/browse/APP-2016
  mrn: string;
  alert: AlertPayload;
  allergies: AllergiesPayload;
  medications: MedicationsPayload;
  problems: ProblemsPayload;
}

export interface JoinedChannelPayload {
  id: PrimaryKey;
  createdAt: string;
  updatedAt: string;
  patientId: ForeignKey;
  status?: ChannelStatus;
  channelType: ChannelType;
  sendbirdChannelUrl: ForeignKey;
}

export interface JoinPatientChannelsPayload {
  channels: JoinedChannelPayload[];
}

export interface UpdateTriageStatusPayload {
  id: PrimaryKey;
  patientId: PrimaryKey;
  createdAt: string;
  triageStatus: TriageStatus;
  returnToWorkDate: string;
}

export interface PatientHasHadVideoVisitsPayload {
  patientId: ForeignKey;
  hasHadVideoVisits: boolean;
}

export interface EmptyPharmacyPayload {
  id: string;
  patientId: string; // NOTE: this is the MRN, DON'T USE IT
}

export type PharmacyPayload = EmptyPharmacyPayload | Pharmacy;

interface BaseChannelPayload {
  id: PrimaryKey;
  patientId: PrimaryKey;
  channelType: ChannelType;
  sendbirdChannelUrl: string;
  updatedAt: string;
}

interface PrimaryChannelPayload extends BaseChannelPayload {
  channelType: ChannelType.Primary;
  status: ChannelStatus;
  lastMessage?: SendbirdMessage;
  isPatientOnline: boolean;
}

interface EventChannelPayload extends BaseChannelPayload {
  channelType: ChannelType.Event;
}

interface InternalChannelPayload extends BaseChannelPayload {
  channelType: ChannelType.Internal;
}

export interface SidebarChannelPayload {
  id: PrimaryKey;
  patientId: PrimaryKey;
  channelType: ChannelType;
  updatedAt: string;
  lastMessage: SendbirdMessage;
  status: string;
  isPatientOnline: boolean;
}
export interface ScreenerMultipleChoiceQuestion {
  answer: number;
  key: string;
  possibleAnswers: string[];
  question: string;
  questionId: string;
  questionType: 'MultipleChoice';
  preamble?: string;
}

export type MultipleSelectOption = {
  value: 1 | 0;
  name: string;
};

export interface ScreenerMultipleSelectQuestion {
  answer: number[];
  key: string;
  possibleAnswers: MultipleSelectOption[];
  question: string;
  questionId: string;
  questionType: 'MultipleSelect';
  preamble?: string;
}

export type ScreenerQuestion = ScreenerMultipleChoiceQuestion | ScreenerMultipleSelectQuestion;

export interface ScreenerPayload {
  questionList: ScreenerQuestion[];
  screenerName: string;
  timestamp: string;
  screenerUpdatedAt: string;
}

export interface EdenScreenerResponsePayload {
  screenerResponses: ScreenerResponse[];
}

export type ChannelPayload =
  | PrimaryChannelPayload
  | EventChannelPayload
  | InternalChannelPayload
  | SidebarChannelPayload;

export interface ChannelUpdatePayload {
  channel: {
    id: PrimaryKey;
    status: ChannelStatus;
  };
  event?: any;
}

export interface SideBarPayload {
  channels: ChannelPayload[];
  patients: PatientPayload[];
  lastUpdatedAtInMs: number;
}

export interface VideoVisitPayload {
  id: PrimaryKey;
  createdAt: string;
  updatedAt: string;
  patientId: PrimaryKey;
  appointmentId: string | null;
}

export interface AppointmentReasonsPayload {
  patientappointmentreasons: AppointmentReason[];
}

export interface PatientAccountInfo {
  firstName: string;
  lastName: string;
  email: string | null;
  mobilePhone: string | null;
}

export interface PatientAccountInfoSourceData extends PatientAccountInfo {
  error: string | null;
}

export interface PatientAccountInfoPayload {
  patientId: PrimaryKey;
  mrn: number;
  athena: PatientAccountInfoSourceData;
  cognito: PatientAccountInfoSourceData;
  postgres: PatientAccountInfoSourceData;
}

export interface CareteamPayload {
  id: string;
}

export enum RosterIngestionStatus {
  Uploaded = 'uploaded',
  IngestionSuccess = 'ingestion_success',
  IngestionError = 'ingestion_error',
}

export interface RosterStatus {
  sponsorId: string;
  providerId: string;
  providerDisplayName: string;
  status: RosterIngestionStatus;
  errors?: string[];
  uploadedAt: string;
  processedAt?: string;
}

export interface Tickler {
  dueDate: string;
  assignedTo: number;
  note: string;
  departmentId: number;
  ticklerAthenaId: number;
  patientId: PrimaryKey;
}

export interface GetPatientTicklersPayload {
  failures: number[];
  ticklers: Tickler[];
}

export interface CompleteTicklerPayload {
  ticklerAthenaId: number;
}

export interface DeleteTicklerPayload {
  ticklerAthenaId: number;
}

export type ExternalAddonsPayload = ExternalAddon[];

export type AddonCategoriesPayload = AddonCategory[];

export interface FetchNextStepsPayload {
  nextSteps: NextStep[];
}

export interface ResolveNextStepPayload {
  nextStep: NextStep;
}

export enum BradState {
  Active = 'active',
  Inactive = 'inactive',
}

export interface BradStatusPayload {
  status: BradState;
}

export interface GenerateAVSPayload {
  summary: string;
}

export interface SaveAVSPayload {
  content: string;
  status: string;
}

export interface SuggestionPayload {
  suggestion: string;
}

export interface GetSponsorDivisionsPayload {
  divisions: Division[];
}

import React from 'react';
import { EdenColors } from 'types/colors';

const CustomNextStepIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="20" fill={EdenColors.Apple10} />
      <path
        d="M11.333 24.0002C11.333 23.632 11.6315 23.3335 11.9997 23.3335H21.333C21.7012 23.3335 21.9997 23.632 21.9997 24.0002C21.9997 24.3684 21.7012 24.6668 21.333 24.6668H11.9997C11.6315 24.6668 11.333 24.3684 11.333 24.0002Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M11.9997 26.6665C11.6315 26.6665 11.333 26.965 11.333 27.3332C11.333 27.7014 11.6315 27.9998 11.9997 27.9998H21.333C21.7012 27.9998 21.9997 27.7014 21.9997 27.3332C21.9997 26.965 21.7012 26.6665 21.333 26.6665H11.9997Z"
        fill={EdenColors.Eden}
      />
      <path
        d="M13.333 18V16H14.6663V18H16.6663V19.3333H14.6663V21.3333H13.333V19.3333H11.333V18H13.333Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C12.8954 8 12 8.89543 12 10H10C8.89543 10 8 10.8954 8 12V30C8 31.1046 8.89543 32 10 32H23.3333C24.4379 32 25.3333 31.1046 25.3333 30V12C25.3333 10.8954 24.4379 10 23.3333 10H21.3333C21.3333 8.89543 20.4379 8 19.3333 8H14ZM19.3333 12C19.7015 12 20 11.7015 20 11.3333V10C20 9.63181 19.7015 9.33333 19.3333 9.33333H14C13.6318 9.33333 13.3333 9.63181 13.3333 10V11.3333C13.3333 11.7015 13.6318 12 14 12H19.3333ZM12 11.3333C12 12.4379 12.8954 13.3333 14 13.3333H19.3333C20.4379 13.3333 21.3333 12.4379 21.3333 11.3333H23.3333C23.7015 11.3333 24 11.6318 24 12V30C24 30.3682 23.7015 30.6667 23.3333 30.6667H10C9.63181 30.6667 9.33333 30.3682 9.33333 30V12C9.33333 11.6318 9.63181 11.3333 10 11.3333H12Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 15.3335C28 14.2289 28.8954 13.3335 30 13.3335C31.1046 13.3335 32 14.2289 32 15.3335V28.8687L30 31.8687L28 28.8687V15.3335ZM30 14.6668C29.6318 14.6668 29.3333 14.9653 29.3333 15.3335V16.6668H30.6667V15.3335C30.6667 14.9653 30.3682 14.6668 30 14.6668ZM30 29.465L30.6667 28.465V18.0002H29.3333V28.465L30 29.465Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default CustomNextStepIcon;

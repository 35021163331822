import React from 'react';
import css from 'components/ui/Progress.module.css';

interface ProgressProps {
  style: React.CSSProperties;
  percent: number;
}

export const Progress: React.FC<ProgressProps> = (props) => {
  return (
    <div
      className={css.progressContainer}
      style={{ visibility: props.style.visibility || 'visible' }}
    >
      <div className={css.progress} style={{ width: `${props.percent}%` }}></div>
    </div>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { truncate } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './FileCard.module.css';
import FileCardOptionsMenu, { fileCardMenuOptions } from './FileCardOptionsMenu';
import AnnotateDocumentModal from './AnnotateDocumentModal';
import { getMyProviderId } from 'reducers/user';
import { getProviderById } from 'reducers/providers';
import { useParameterizedSelector } from 'lib/hooks';
import { DocumentIcon, RecurringIcon } from 'components/ui/svg';
import { formatDateTime } from 'lib/formatDateTime';
import { uploadedBy } from 'types/tables/documents';
import { downloadDocument } from 'lib/document';

const INTERNAL_NOTES_LIMIT = 200;

type activeModalOptions = keyof typeof fileCardMenuOptions | '';

interface FileCardProps {
  id: string;
  createdAt: string;
  filename: string;
  internalNotes: string | null;
  isDependent: boolean;
  lastUpdatedByProviderId: string | null;
  lastUpdatedByProviderName: string | null;
  updatedAt: string | null;
  patientDocumentTypeId: string | null;
  providerId: string | null;
  title: string | null;
  uploadedBy: uploadedBy;
}

const FileCard = (props: FileCardProps) => {
  const {
    createdAt,
    filename,
    id,
    internalNotes,
    isDependent,
    lastUpdatedByProviderId,
    lastUpdatedByProviderName,
    patientDocumentTypeId,
    providerId,
    title,
    uploadedBy,
    updatedAt,
  } = props;

  const [activeModal, setActiveModal] = useState<activeModalOptions>('');
  const [error, setError] = useState(false);
  const activeUserProviderId = useSelector(getMyProviderId) || '';
  const fileProviderName =
    useParameterizedSelector(getProviderById, providerId)?.displayName || '?';
  const { fileEnhancementsEnabled } = useFlags();

  let uploadByLabel;
  if (uploadedBy === 'provider') {
    if (providerId === activeUserProviderId) {
      uploadByLabel = 'me';
    } else {
      uploadByLabel = fileProviderName.substring(0, fileProviderName.indexOf(','));
    }
  } else {
    uploadByLabel = 'patient';
  }

  const annotatedLabel =
    lastUpdatedByProviderId === activeUserProviderId ? 'me' : lastUpdatedByProviderName;

  const handleClick = async () => {
    setError(false);
    if (filename) {
      try {
        await downloadDocument(id, filename, 'files');
      } catch (e) {
        setError(true);
      }
    }
  };

  const handleModalClose = () => {
    setActiveModal('');
  };

  const onOptionMenuSelect = (key: activeModalOptions) => {
    setActiveModal(key);
  };

  const NotesAndUpdates = () => {
    /* The updatedAt date is unreliable as a way to determine if it was updated */
    const isUpdated = lastUpdatedByProviderId !== null;
    const updatedSameDay = updatedAt?.slice(0, 10) === createdAt.slice(0, 10);

    if (internalNotes || updatedAt) {
      return (
        <div className={css.notesUpdates}>
          {internalNotes && (
            <div className={css.notes}>
              {truncate(internalNotes, { length: INTERNAL_NOTES_LIMIT, omission: '...' })}
            </div>
          )}

          {isUpdated && updatedAt && (
            <div className={css.updated}>
              {!updatedSameDay && (
                <> {formatDateTime(updatedAt).as('LLL d, yyyy').inLocalTimeZone()} &bull; </>
              )}
              Annotated by {annotatedLabel}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const Error = () => {
    if (error) {
      return (
        <div className={css.error}>
          <span>Unable to download document</span>
          <RecurringIcon height="14" width="14" className={css.errorIcon} />
        </div>
      );
    }
    return null;
  };

  /** TODO: Remove OldCard for [APP-8671] Remove File Enhancements feature flag
   * Also remove "old" css styles */
  const OldCard = () => (
    <div className={css.oldCardWrapper}>
      <button className={css.oldCard} onClick={handleClick}>
        <div className={css.docIcon}>
          <DocumentIcon height={18} width={15} />
        </div>
        <div className={css.info}>
          <div className={css.oldFilename}>{filename}</div>
          <div className={css.oldCreated}>
            Uploaded {formatDateTime(createdAt).as('LLL d, yyyy').inLocalTimeZone()} by{' '}
            {uploadByLabel} {isDependent && <>&bull; For dependent</>}
          </div>
          {error && (
            <div className={css.error}>
              <span>Unable to download document</span>
              <RecurringIcon height="14" width="14" className={css.errorIcon} />
            </div>
          )}
        </div>
      </button>
    </div>
  );

  return (
    <>
      {fileEnhancementsEnabled ? (
        <div className={css.cardWrapper}>
          <div className={css.card}>
            <div className={css.docIcon}>
              <DocumentIcon height={18} width={15} />
            </div>
            <div className={css.info}>
              <button className={css.filename} onClick={handleClick}>
                {title ? title : filename}
              </button>
              <div className={css.created}>
                Uploaded {formatDateTime(createdAt).as('LLL d, yyyy').inLocalTimeZone()} by{' '}
                {uploadByLabel} {isDependent && <>&bull; For dependent</>}
              </div>
              <NotesAndUpdates />
              <Error />
            </div>
          </div>

          <div className={css.cardMenu}>
            <FileCardOptionsMenu onSelect={onOptionMenuSelect} />
          </div>
        </div>
      ) : (
        <OldCard />
      )}

      {activeModal === 'annotate' && (
        <AnnotateDocumentModal
          isOpen
          closeModal={handleModalClose}
          filename={filename}
          id={id}
          internalNotes={internalNotes}
          isDependent={isDependent}
          title={title}
          patientDocumentTypeId={patientDocumentTypeId}
        />
      )}
    </>
  );
};

export default React.memo(FileCard);

import type { HTMLAttributes, ReactNode } from 'react';
import { mergeProps, OverlayProvider, useButton, useFocusRing } from 'react-aria';
import React, { useRef } from 'react';
import cx from 'classnames';
import css from './Dialog.module.css';
import { Dialog } from './Dialog';
import { Button } from 'components/design-system/Button/Button';
import type { ButtonActionType } from 'components/design-system/Button/Button';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import type { IconName } from 'components/design-system/Icon/Icon';
import { Icon } from 'components/design-system/Icon/Icon';

const CloseIconButton = ({ onClose }: { onClose: () => void }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress: onClose, 'aria-label': 'Cancel' }, buttonRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      className={cx(css.closeButton, isFocusVisible && `focus-ring`)}
      ref={buttonRef}
    >
      <Icon name="cross-mark" className={css.closeIcon} />
    </button>
  );
};

type DialogBodyProps = Pick<
  ConfirmationDialogProps,
  'iconName' | 'hideCloseCrossMark' | 'title' | 'onClose'
>;

const DialogBodyWithDescription = ({
  title,
  iconName,
  description,
  hideCloseCrossMark,
  onClose,
}: DialogBodyProps & { description: ReactNode | ReactNode[]; hideCloseCrossMark: boolean }) => (
  <>
    <HeaderMedium className={cx(css.title, css.withDescription)}>
      {iconName && <Icon name={iconName} className={css.icon} />}
      {title}
      {!hideCloseCrossMark && <CloseIconButton onClose={onClose} />}
    </HeaderMedium>

    {description && <BodySmall className={css.description}>{description}</BodySmall>}
  </>
);

const DialogBody = ({ title, iconName, hideCloseCrossMark, onClose }: DialogBodyProps) => (
  <>
    {!hideCloseCrossMark && <CloseIconButton onClose={onClose} />}

    <HeaderMedium className={css.title}>
      {iconName && <Icon name={iconName} className={css.icon} />}
      {title}
    </HeaderMedium>
  </>
);

export interface ConfirmationDialogProps {
  /**
   * Title displayed inside the dialog.
   */
  title: string;

  /**
   * Optional description of the action.
   */
  description?: ReactNode | ReactNode[];

  /**
   * Name of an optional icon to display before the title.
   */
  iconName?: IconName;

  /**
   * Whether the dialog is open.
   */
  isOpen?: boolean;

  /**
   * Optional label for the confirm button.
   */
  confirmLabel?: string;

  /**
   * Action to perform when the cancel button is pressed.
   */
  onCancel: () => void;

  /**
   * Action to perform when the confirm button is pressed.
   */
  onConfirm: () => void;

  /**
   * Action to perform when the dialog is closed another way than clicking the
   * cancel button.
   */
  onClose: () => void;

  /**
   * Properties returned by the `useModal` hook. This hook _must_ be executed
   * within the consumer application, or it will be unable to locate the
   * consumer's `OverlayProvider` context.
   */
  modalProps?: HTMLAttributes<Element>;

  /**
   * Type of the action performed when the confirm button is pressed.
   */
  actionType: ButtonActionType;

  /**
   * Whether the cross mark to cose the dialog should be hidden.
   */
  hideCloseCrossMark?: boolean;

  /**
   * Whether the cancel button should be hidden.
   */
  hideCancelButton?: boolean;
}

/**
 * An accessible confirmation dialog/modal to ask the user to confirm before performing an important
 * action. Handles keyboard interactions, e.g. closing the dialog with `Esc`. The dialog disables
 * scrolling on the page, and keeps the focus until it's closed, so cycling through elements with
 * `Tab` loops until an action is performed.
 */
export const ConfirmationDialog = ({
  title,
  description,
  iconName,
  isOpen = false,
  confirmLabel,
  onCancel,
  onConfirm,
  onClose,
  modalProps,
  actionType,
  hideCloseCrossMark = false,
  hideCancelButton = false,
}: ConfirmationDialogProps) => {
  return (
    <OverlayProvider>
      <Dialog isOpen={isOpen} onClose={onClose} modalProps={modalProps} isDismissable>
        {description ? (
          <DialogBodyWithDescription
            title={title}
            iconName={iconName}
            description={description}
            onClose={onClose}
            hideCloseCrossMark={hideCloseCrossMark}
          />
        ) : (
          <DialogBody
            title={title}
            iconName={iconName}
            onClose={onClose}
            hideCloseCrossMark={hideCloseCrossMark}
          />
        )}

        <div className={css.buttons}>
          {!hideCancelButton && (
            <Button onPress={onCancel} type="secondary" size="small">
              Cancel
            </Button>
          )}
          <Button onPress={onConfirm} type="primary" size="small" actionType={actionType}>
            {confirmLabel ? confirmLabel : 'Confirm'}
          </Button>
        </div>
      </Dialog>
    </OverlayProvider>
  );
};

import React, { useCallback, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import { EntityId } from '@reduxjs/toolkit';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { EdenColors } from 'types/colors';
import { joinVideoCall } from 'lib/grdn';
import { Button } from 'components/design-system/Button/Button';
import ScheduleCallModal from 'components/features/Composer/ScheduleCallModal';
import { getPatientPrimaryChannel } from 'reducers/channels';
import { isSuccessResponse } from 'types/api';
import css from 'components/features/VisitOverview/Visit.module.css';
import { scheduleMeeting, ZoomMeetingId } from 'reducers/visits';
import { Spinner, VideoIcon } from 'components/ui/svg';

interface ActionsProps {
  appointmentId: EntityId;
  zoomMeetingId?: ZoomMeetingId;
  isStarted?: boolean;
}

interface JoinVideoVisitButtonProps {
  zoomMeetingId: string;
}

const JoinVideoVisitButton = ({ zoomMeetingId }: JoinVideoVisitButtonProps) => {
  const handlePress = useCallback(async () => {
    const resp = await joinVideoCall(zoomMeetingId);
    if (isSuccessResponse(resp)) {
      window.open(resp.data.joinUrl);
    } else {
      toast.error('Unable to join meeting. Please try again.');
    }
  }, [zoomMeetingId]);

  return (
    <>
      <Button actionType="success" size="small" onPress={handlePress}>
        <VideoIcon className={css.icon} color={EdenColors.White} />
        Join Video Call
      </Button>
    </>
  );
};

interface StartVideoVisitButtonProps {
  appointmentId: EntityId;
  zoomMeetingId: string;
}

const StartVideoVisitButton = ({ zoomMeetingId, appointmentId }: StartVideoVisitButtonProps) => {
  const { zoomLinks } = useFlags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: patientId } = useActivePatient();
  const primaryChannel = useParameterizedSelector(getPatientPrimaryChannel, patientId);

  return (
    <>
      <Button
        actionType="success"
        size="small"
        onPress={() => {
          setIsModalOpen(true);
        }}
      >
        <VideoIcon className={css.icon} color={EdenColors.White} />
        Start Call Now
      </Button>
      <ScheduleCallModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        channel={primaryChannel}
        zoomLinks={zoomLinks}
        zoomMeetingId={zoomMeetingId}
        appointmentId={appointmentId}
      />
    </>
  );
};

interface CreateVideoVisitButtonProps {
  appointmentId: EntityId;
}

const CreateVideoVisitButton = ({ appointmentId }: CreateVideoVisitButtonProps) => {
  const dispatch = useAppDispatch();
  const { id: patientId } = useActivePatient();
  const [isLoading, setIsLoading] = useState(false);

  const handlePress = useCallback(async () => {
    setIsLoading(true);
    const resp = await dispatch(scheduleMeeting({ appointmentId, patientId }));
    if (scheduleMeeting.rejected.match(resp)) {
      toast.error('Unable to create meeting. Try again.');
    }
    setIsLoading(false);
  }, [appointmentId, dispatch, patientId]);

  if (isLoading) {
    return (
      <Button actionType="success" size="small" onPress={handlePress}>
        <Spinner size={14} color={EdenColors.White} />
        <div className={css.loadingSpinner}> Loading </div>
      </Button>
    );
  } else {
    return (
      <>
        <Button actionType="success" size="small" onPress={handlePress}>
          <VideoIcon className={css.icon} color={EdenColors.White} /> Create Video Link
        </Button>
      </>
    );
  }
};

const MeetingActions = ({ appointmentId, zoomMeetingId, isStarted }: ActionsProps) => {
  const { zoomLinks } = useFlags();

  return (
    zoomLinks && (
      <div className={css.actions}>
        {zoomMeetingId ? (
          isStarted ? (
            <JoinVideoVisitButton zoomMeetingId={zoomMeetingId} />
          ) : (
            <StartVideoVisitButton zoomMeetingId={zoomMeetingId} appointmentId={appointmentId} />
          )
        ) : (
          <CreateVideoVisitButton appointmentId={appointmentId} />
        )}
      </div>
    )
  );
};

export default MeetingActions;

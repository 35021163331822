import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Home from './Home/Home';
import Patient from './Patient/Patient';
import Settings from './Settings/Settings';
import css from './Main.module.css';
import Alert from 'components/ui/Alert';
import { initializeSponsors } from 'reducers/sponsors';
import { getMacros } from 'reducers/macros';
import { initializeCareteams } from 'reducers/careteams';
import { initializeCarepods } from 'reducers/carepods';
import { initializeProviderGroups } from 'reducers/providerGroups';
import { fetchAthenaProviders } from 'reducers/athenaProviders';
import Sidebar from 'components/features/Sidebar/Sidebar';
import Empty from 'components/ui/Empty';
import { getPatientByMrn } from 'reducers/patients';
import {
  AppContext,
  connectToPatient,
  useActiveMRN,
  useNavigateToPatient,
  useParameterizedSelector,
} from 'lib/hooks';
import { initializeSideBar } from 'reducers/sidebar';
import { initializeClinicianDetails } from 'reducers/providers';
import { captureException } from 'lib/sentry';
import { SendbirdConnectionProvider } from 'lib/sendbird';
import { RoomType } from 'types/sendbird/chat';
import { getIsOnline } from 'legacy/selectors/AppSelector';
import { BradBanner } from 'scenes/Main/Banners';
import { getDocumentCategoryTypes } from 'reducers/files';

enum InitializeStatus {
  started,
  readyForChat,
  finished,
}

const Main = () => {
  const dispatch = useDispatch();
  const pathMrn = useActiveMRN();
  const navigate = useNavigate();
  const isOnline = useSelector(getIsOnline);
  const [initializing, setInitializing] = useState(InitializeStatus.started);

  useEffect(() => {
    const initializer = async () => {
      await Promise.all([
        dispatch(initializeSideBar()),
        dispatch(getMacros()),
        dispatch(initializeClinicianDetails()),
        dispatch(initializeSponsors()),
        dispatch(initializeCareteams()),
        dispatch(initializeCarepods()),
        dispatch(initializeProviderGroups()),
        dispatch(fetchAthenaProviders()),
        dispatch(getDocumentCategoryTypes()),
      ]);
      setInitializing(InitializeStatus.readyForChat);
    };
    if (initializing === InitializeStatus.started) {
      initializer().catch(captureException);
    }
  }, [dispatch, initializing]);

  // when CFE is opened to a URL for a patient, we must attempt to load the channels and patient
  // if that fails, we need to redirect to a URL that should successfully load
  // this effect can likely be cleaned up
  useEffect(() => {
    const patientInitializer = async () => {
      if (pathMrn) {
        try {
          await dispatch(connectToPatient({ mrn: pathMrn }));
          setInitializing(InitializeStatus.finished);
        } catch (e) {
          toast.error(`Unable to connect to ${pathMrn}`);
          navigate('/app');
        }
      } else {
        setInitializing(InitializeStatus.finished);
      }
    };
    if (initializing === InitializeStatus.readyForChat) {
      patientInitializer().catch(captureException);
    }
  }, [dispatch, navigate, initializing, pathMrn]);

  const loaded = initializing === InitializeStatus.finished && isOnline;
  const loadingMessage = !isOnline
    ? 'Waiting for connection to return...'
    : 'Connecting to Clinical Messenger...';
  return (
    <Dimmer.Dimmable className={css.main} dimmed={!loaded}>
      <Dimmer active={!loaded}>
        <Loader size="huge">{loadingMessage}</Loader>
      </Dimmer>
      {loaded && (
        <SendbirdConnectionProvider>
          <ClinicalFrontend pathMrn={pathMrn} />
        </SendbirdConnectionProvider>
      )}
    </Dimmer.Dimmable>
  );
};

interface ClinicalFrontendProps {
  pathMrn: number;
}

const ClinicalFrontend = ({ pathMrn }: ClinicalFrontendProps) => {
  // TODO: Remove any type
  const currentPatient: any = useParameterizedSelector(getPatientByMrn, pathMrn);
  const [mobileChatRoom, setMobileChatRoom] = useState(RoomType.Patient);
  const { patientLoadState, selectPatient } = useNavigateToPatient(pathMrn);

  const toggleMobileChatRoom = (mobileChatRoom: RoomType) => {
    setMobileChatRoom(mobileChatRoom === RoomType.Patient ? RoomType.Provider : RoomType.Patient);
  };

  const { athenaOutage } = useFlags();

  return (
    <AppContext.Provider
      value={{
        mobileChatRoom,
        activePatientId: currentPatient?.id,
        toggleMobileChatRoom,
      }}
    >
      <div className={css.wrapper}>
        <div>
          {athenaOutage && (
            <Alert severity="error" center inline={false}>
              <p>
                Athena is currently down (view status{' '}
                <a href="https://edenhealth.statuspage.io/">here</a>). Functionality will be
                limited, you can still message patients at this time.
              </p>
            </Alert>
          )}
        </div>
        <div>
          <BradBanner />
        </div>
        <div className={css.content}>
          <Sidebar selectPatient={debounce(selectPatient, 500, { maxWait: 2500 })} />
          <div className={css.mainContent}>
            <Routes>
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/messages/:mrn" element={<Patient loadState={patientLoadState} />} />
              <Route path="/" element={<Home />} />
              <Route element={<Empty />} />
            </Routes>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default Main;

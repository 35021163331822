import { createSelector } from 'reselect';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { Sponsor } from 'types/tables/sponsors';
import { PrimaryKey } from 'types/tables/base';
import { isSuccessResponse } from 'types/api';

const prefix = 'sponsor';
const initializationRejectionType = 'Failed to initialize sponsors.';
const divisionRejectionType = 'Failed to get sponsor divisions.';

export const initializeSponsors = createAsyncThunk(
  `${prefix}/initializeSponsors`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.sponsors();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: initializationRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: initializationRejectionType });
    }
  },
);

export const fetchSponsorDivisions = createAsyncThunk(
  `${prefix}/getSponsorDivisions`,
  async (sponsorId: string, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getSponsorDivisions(sponsorId);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: divisionRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: divisionRejectionType });
    }
  },
);

export const sponsorsAdapter = createEntityAdapter<Sponsor>({});

export const sponsors = createSlice({
  name: prefix,
  initialState: sponsorsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeSponsors.fulfilled, (state, action) => {
      sponsorsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(fetchSponsorDivisions.fulfilled, (state, action) => {
      sponsorsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          divisions: action.payload.divisions,
        },
      });
    });
  },
});

export const sponsorsSelectors = sponsorsAdapter.getSelectors<Store>((state) => state.sponsors);

export const getSponsors = sponsorsSelectors.selectAll;

export const getSponsorById = createSelector(
  (_, sponsorId: PrimaryKey) => sponsorId,
  getSponsors,
  (sponsorId, sponsors) => {
    return sponsors.find((sponsor) => sponsor.id === sponsorId);
  },
);

export const getSponsorsWithSchemas = createSelector(getSponsors, (sponsorsArray) =>
  sponsorsArray.filter(({ schemaName }) => !!schemaName),
);

export const getSponsorDivisionsById = createSelector(getSponsorById, (sponsor) =>
  sponsor && sponsor.divisions ? sponsor.divisions : null,
);

export default sponsors.reducer;

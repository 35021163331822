import React from 'react';
import { Button, ButtonType } from 'components/ui/Buttons';
import { SendIcon } from 'components/ui/svg';

interface SendButtonProps {
  handleSendButtonClick: VoidFunction;
}

const SendButton = ({ handleSendButtonClick }: SendButtonProps) => {
  return (
    <Button onClick={handleSendButtonClick} buttonType={ButtonType.send} data-testid="SendButton">
      <SendIcon />
    </Button>
  );
};

export default SendButton;

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Carepod } from 'types/tables/carepods';
import { isSuccessResponse } from 'types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';

const prefix = 'carepod';
const initializationRejectionType = 'Unable to initialize carepods.';

export const initializeCarepods = createAsyncThunk(
  `${prefix}/getAllCarepods`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getAllCarepods();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: initializationRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: initializationRejectionType });
    }
  },
);

export const carepodsAdapter = createEntityAdapter<Carepod>({});

export const carepods = createSlice({
  name: prefix,
  initialState: carepodsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeCarepods.fulfilled, (state, action) => {
      carepodsAdapter.upsertMany(state, action.payload);
    });
  },
});

export const carepodsSelectors = carepodsAdapter.getSelectors<Store>((state) => state.carepods);

export default carepods.reducer;

import React, { useState, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { displayDocumentTypeHelper } from '../Composer/utils/DocumentTypeHelper';
import css from './AnnotateDocumentModal.module.css';
import { useAnnotateFileForm } from './util/useAnnotateFileForm';
import { useAppDispatch } from 'lib/hooks';
import { Modal } from 'components/ui/Modal';
import { HeaderMedium } from 'components/design-system/Text';
import {
  Field,
  Label,
  PrimaryButton,
  SelectAccessible,
  TextArea,
} from 'components/design-system/Form';
import { ErrorMessage } from 'components/ui/Typography';
import { EditIcon } from 'components/ui/svg';
import { Radio } from 'components/ui/Radio';
import { updateDocumentAnnotations } from 'reducers/files';
import { categoryTypes, categoryTypeOptions } from 'reducers/files';
import { UpdateDocumentPayload } from 'types/grdn';

interface AnnotateDocumentModalProps {
  closeModal: VoidFunction;
  filename: string;
  id: string;
  internalNotes: string | null;
  isDependent: boolean;
  isOpen: boolean;
  title: string | null;
  patientDocumentTypeId: string | null;
}

const AnnotateDocumentModal = (props: AnnotateDocumentModalProps) => {
  const {
    closeModal,
    filename,
    id,
    internalNotes,
    isDependent,
    isOpen,
    title,
    patientDocumentTypeId,
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasPatientDocumentTypeId, setHasPatientDocumentTypeId] = useState(false);
  const { form, isDirty, isValid, setFormValue, validateForm, validateFormValue } =
    useAnnotateFileForm({
      title,
      isDependent,
      internalNotes,
      patientDocumentTypeId,
    });
  const types = useSelector(categoryTypes);
  const options = useSelector(categoryTypeOptions);
  const dispatch = useAppDispatch();

  /** TODO: Add useEffect to the SelectAccessible component.
   *  This is only so we can style the select/dropdown differently
   * if the placeholder option is showing */
  useEffect(() => {
    if (form.patientDocumentTypeId.value !== null) {
      setHasPatientDocumentTypeId(true);
    } else {
      setHasPatientDocumentTypeId(false);
    }
  }, [form.patientDocumentTypeId.value]);

  const onDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValue(form.isDependent.key, event.target.value === 'true' ? true : false);
  };

  const handleSaveChanges = async () => {
    if (isDirty && validateForm()) {
      setLoading(true);
      setError(false);
      const changes: UpdateDocumentPayload = {
        internalNotes: form.internalNotes.value,
        isDependent: form.isDependent.value,
        patientDocumentTypeId: form.patientDocumentTypeId.value,
        title: form.title.value,
      };
      const response = await dispatch(updateDocumentAnnotations({ id, changes }));
      setLoading(false);
      if (updateDocumentAnnotations.fulfilled.match(response)) {
        closeModal();
      } else {
        setError(true);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} override={css.modalStyleOverride}>
      <HeaderMedium className={css.header}>
        <EditIcon width="24" height="24" className={css.editIcon} />
        Annotate Document
      </HeaderMedium>
      <div className={css.instructions}>Changes made here will only be reflected in the CFE.</div>

      <div className={css.field}>
        <Label className={css.topField} text="File name">
          <Field value={filename || ''} disabled />
          <div className={css.fieldNote}>
            The file name is the original name of the file and it cannot be changed.
          </div>
        </Label>
      </div>

      <div className={css.field}>
        <Label text="Document title" errorText={form.title.error}>
          <Field
            value={form.title.value || ''}
            placeholder="Type a document title"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormValue(form.title.key, e.target.value)
            }
            error={!!form.title.error}
          />
          <div className={css.fieldNote}>The document title is for internal reference.</div>
        </Label>
      </div>

      <div className={css.labelWithLink}>
        <label htmlFor={form.patientDocumentTypeId.key} className={css.required}>
          Document type
        </label>
        <button className={css.buttonLink} onClick={() => displayDocumentTypeHelper(types)}>
          See document type definition
        </button>
      </div>
      <div className={css.selectorWrapper}>
        <SelectAccessible
          id={form.patientDocumentTypeId.key}
          value={form.patientDocumentTypeId.value || ''}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setFormValue(form.patientDocumentTypeId.key, event.target.value);
          }}
          onBlur={(e) => {
            validateFormValue(form.patientDocumentTypeId.key, e.target.value);
          }}
          placeholder="Select an option"
          className={cx(css.selector, !hasPatientDocumentTypeId && css.placeholder)}
          error={!!form.patientDocumentTypeId.error}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={`${value}`}>
              {label}
            </option>
          ))}
        </SelectAccessible>
        {form.patientDocumentTypeId.error && (
          <div className={css.fieldError}>{form.patientDocumentTypeId.error}</div>
        )}
      </div>

      <Label text="Who is this document for" required>
        <div className={css.radioGroup}>
          <Radio
            checked={form.isDependent.value === false}
            id={'radio-patient'}
            label={'Patient'}
            onChange={onDependentChange}
            value="false"
          />
          <Radio
            checked={form.isDependent.value === true}
            id={'radio-dependent'}
            label={"Patient's dependent"}
            onChange={onDependentChange}
            value="true"
          />
        </div>
      </Label>

      <Label text="Description" errorText={form.internalNotes.error}>
        <TextArea
          value={form.internalNotes.value || ''}
          placeholder="Describe the document..."
          className={css.textArea}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setFormValue(form.internalNotes.key, e.target.value)
          }
          error={!!form.internalNotes.error}
        />
      </Label>

      {error && (
        <ErrorMessage className={css.error} data-testid="DeleteMessageError">
          These changes could not be saved. Try again or cancel.
        </ErrorMessage>
      )}

      <div className={css.bottomNav}>
        <PrimaryButton
          type="button"
          className={cx(css.btn, css.cancelBtn)}
          value="Cancel"
          onClick={closeModal}
        />
        <PrimaryButton
          className={cx(css.btn, css.saveBtn)}
          value="Save Changes"
          onClick={handleSaveChanges}
          loading={loading}
          disabled={!isDirty || !isValid}
        />
      </div>
    </Modal>
  );
};
export default React.memo(AnnotateDocumentModal);

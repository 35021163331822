import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import css from './Insurance.module.css';
import InsuranceCardModal from './InsuranceCardModal';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { getPatientPrimaryInsuranceCard } from 'selectors/profiles';
import { fetchPrimaryInsuranceCard } from 'reducers/profiles';
import { Profile, profileIsError } from 'types/tables/profiles';
import { InsuranceLoadState } from 'types/athena/insurance';
import { PhotoIcon, Loader, AlertCircleIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

type InsuranceCardButtonProps = {
  patientProfile: Profile | { isError: boolean };
  automatedInsuranceCardUpload: boolean;
};

const InsuranceCardButton: React.FC<InsuranceCardButtonProps> = ({
  patientProfile,
  automatedInsuranceCardUpload,
}: InsuranceCardButtonProps) => {
  const { id: activePatientId } = useActivePatient();
  const dispatch = useAppDispatch();
  const primaryInsuranceCard = useParameterizedSelector(
    getPatientPrimaryInsuranceCard,
    activePatientId,
  );

  const [modalOpen, setModalOpen] = useState(false);

  const loadInsuranceCard = useCallback(async () => {
    await dispatch(fetchPrimaryInsuranceCard(activePatientId));
  }, [dispatch, activePatientId]);

  const showInsuranceCard = useCallback(async () => {
    if (
      automatedInsuranceCardUpload &&
      !profileIsError(patientProfile) &&
      patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.NotStarted
    ) {
      await loadInsuranceCard();
    }
    setModalOpen(true);
  }, [automatedInsuranceCardUpload, loadInsuranceCard, patientProfile]);

  const renderPhotoButton = () => {
    if (!profileIsError(patientProfile)) {
      if (
        primaryInsuranceCard?.image ||
        patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.NotStarted ||
        patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.Loading
      ) {
        return (
          <div>
            <div className={css.buttonContainer}>
              <button
                className={cx(
                  patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.Loading &&
                    css.buttonInactive,
                  css.button,
                )}
                onClick={showInsuranceCard}
                disabled={
                  patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.Loading
                }
              >
                <PhotoIcon color={EdenColors.Slate} /> View Photo
              </button>
            </div>
            {patientProfile.primaryInsuranceCardLoadingState === InsuranceLoadState.Loading && (
              <div className={css.smallMessageContainer}>
                <div className={css.smallLoadingIcon}>
                  <Loader color={EdenColors.SlateDarken20} width={14} height={14} />
                </div>
                <div className={css.smallMessage}>
                  <div>Loading...</div>
                </div>
              </div>
            )}
            <InsuranceCardModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              profile={patientProfile}
              insuranceCardImage={primaryInsuranceCard?.image || ''}
            />
          </div>
        );
      } else {
        return (
          <div className={css.noPhotoContainer}>
            <div className={css.noPhoto}>
              <AlertCircleIcon />
              No Photo
            </div>
          </div>
        );
      }
    } else {
      return '';
    }
  };

  // TODO: remove when removing staging feature flag
  const renderPhotoButtonOld = () => {
    if (!profileIsError(patientProfile) && patientProfile.primaryInsuranceOld?.insuranceCard) {
      return (
        <div>
          <div className={css.viewPhotoButton} onClick={() => setModalOpen(true)}>
            View Photo
          </div>
          <InsuranceCardModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            profile={patientProfile}
            insuranceCardImage={patientProfile.primaryInsuranceOld.insuranceCard.payload}
          />
        </div>
      );
    } else {
      return <div className={css.noPhotoOld}> No Photo Available </div>;
    }
  };

  return <>{automatedInsuranceCardUpload ? renderPhotoButton() : renderPhotoButtonOld()}</>;
};

export default InsuranceCardButton;

import React from 'react';
import css from './Home.module.css';
import { Body } from 'components/ui/Typography';
import { LineMessages } from 'components/ui/svg';

const Home = () => (
  <Body className={css.body}>
    <LineMessages />
    <h3>Welcome to the Eden Clinical Messenger</h3>
    <p>Please select a chat to continue.</p>
  </Body>
);

export default Home;

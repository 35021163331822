import React, { useState } from 'react';

import { DateTime } from 'luxon';

import VisitDetailsModal from '../VisitDetailsModal';
import CalendarPage from './CalendarPage';

import css from './AfterVisitSummaryCard.module.css';

import { Clipboard } from 'components/ui/svg';
import { AfterVisitSummaryMessage } from 'types/tables/messages';
import { EdenColors } from 'types/colors';

interface Props {
  message: AfterVisitSummaryMessage;
}

const browserTimeZoneDepartments = [2, 8];

const AfterVisitSummaryCard = ({ message }: Props) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const { visit } = message.data;
  const { departmentId, startTime, timeZone, location, patientAppointmentTypeName } = visit;

  const useDeviceTimeZone = browserTimeZoneDepartments.includes(departmentId);

  const instant = DateTime.fromISO(startTime, useDeviceTimeZone ? undefined : { zone: timeZone });

  return (
    <>
      <div
        className={css.avsCard}
        onClick={() => {
          setIsDetailsModalOpen(true);
        }}
      >
        <div className={css.infoBlock}>
          <CalendarPage date={startTime} />

          <div className={css.infoText}>
            <div>
              {instant.toFormat('h:mm a ZZZZ')}
              {', '}
              {instant.toFormat('EEEE')}
            </div>

            <div>{location ? location.name : patientAppointmentTypeName}</div>
          </div>
        </div>

        <div className={css.summary}>
          <Clipboard width={16} height={16} color={EdenColors.Slate} />

          <span className={css.summaryText}>summary</span>
        </div>
      </div>

      <VisitDetailsModal
        visit={visit}
        isOpen={isDetailsModalOpen}
        closeModal={() => {
          setIsDetailsModalOpen(false);
        }}
      />
    </>
  );
};

export default AfterVisitSummaryCard;

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const EyeOn = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.427 8a12.45 12.45 0 0 0 1.643 2.21c1.194 1.274 2.863 2.457 4.93 2.457 2.067 0 3.736-1.183 4.93-2.456A12.447 12.447 0 0 0 14.573 8a12.448 12.448 0 0 0-1.643-2.21C11.736 4.515 10.067 3.332 8 3.332c-2.067 0-3.736 1.183-4.93 2.456A12.45 12.45 0 0 0 1.427 8Zm13.906 0c.597-.298.597-.298.596-.299V7.7l-.003-.004-.008-.015a12.393 12.393 0 0 0-.515-.875 13.785 13.785 0 0 0-1.5-1.928C12.597 3.484 10.6 2 8 2 5.4 2 3.403 3.484 2.097 4.877A13.784 13.784 0 0 0 .209 7.444a8.253 8.253 0 0 0-.127.236l-.008.015-.002.004-.001.002L.667 8 .07 7.702a.667.667 0 0 0 0 .596L.667 8l-.597.298.002.003.002.004.008.015a5.066 5.066 0 0 0 .127.236 13.781 13.781 0 0 0 1.888 2.567C3.403 12.516 5.4 14 8 14c2.6 0 4.597-1.484 5.903-2.877a13.782 13.782 0 0 0 1.888-2.567 8.561 8.561 0 0 0 .127-.236l.008-.015.002-.004.001-.002L15.333 8Zm0 0 .597.298a.668.668 0 0 0 0-.597L15.332 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.667a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666ZM5.335 8a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOn;

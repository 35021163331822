import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { profilesAdapter } from 'selectors/profiles';
import {
  HeadshotPayload,
  PatientHasHadVideoVisitsPayload,
  UpdateTriageStatusPayload,
} from 'types/grdn';
import { PrimaryKey } from 'types/tables/base';
import {
  PatientTriageStatus,
  ProfilePayload,
  transformPayload as transformProfilePayload,
} from 'types/tables/profiles';
import { Insurance, InsuranceCard, InsuranceLoadState, InsuranceOld } from 'types/athena/insurance';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { updateCareteam } from 'reducers/patients';
import { Visit } from 'types/athena/visits';
import { scheduleMeeting } from 'reducers/visits';

const prefix = 'profile';

const patientIdToProfileId = (patientId: string) => `${patientId}_profile`;

interface FetchInsuranceEligibilityArgs {
  patientId: PrimaryKey;
  insuranceId: string;
}

interface UpdateTriageStatusArgs {
  patientId: PrimaryKey;
  triageStatus: PatientTriageStatus;
}

enum FetchPatientRejectionType {
  Generic = 'Unable to fetch patient.',
}

enum FetchInsuranceRejectionType {
  Generic = 'Unable to fetch insurance.',
}

enum FetchInsuranceCardRejectionType {
  Generic = 'Unable to fetch insurance card.',
}

enum FetchInsuranceEligibilityRejectionType {
  Generic = 'Unable to fetch insurance card.',
}

enum VideoVisitsRejectionType {
  Generic = 'Unable to check video visit.',
}

enum FetchHeadshotRejectionType {
  Generic = 'Unable to fetch headshot.',
}

enum UpdateTriageStatusRejectionType {
  Generic = 'Unable to fetch headshot.',
}

// thunks
export const fetchProfile = createAsyncThunk<
  ProfilePayload,
  PrimaryKey,
  {
    rejectValue: { rejectionType: FetchPatientRejectionType };
  }
>(`${prefix}/fetchProfile`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.profile(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchPatientRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchPatientRejectionType.Generic });
  }
});

export const fetchPrimaryInsurance = createAsyncThunk<
  Insurance,
  PrimaryKey,
  {
    rejectValue: { rejectionType: FetchInsuranceRejectionType };
  }
>(`${prefix}/fetchPrimaryInsurance`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.primaryInsurance(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchInsuranceRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchInsuranceRejectionType.Generic });
  }
});

export const fetchPrimaryInsuranceOld = createAsyncThunk<
  InsuranceOld,
  PrimaryKey,
  {
    rejectValue: { rejectionType: FetchInsuranceRejectionType };
  }
>(`${prefix}/fetchPrimaryInsuranceOld`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.primaryInsuranceOld(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchInsuranceRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchInsuranceRejectionType.Generic });
  }
});

export const fetchPrimaryInsuranceCard = createAsyncThunk<
  InsuranceCard,
  PrimaryKey,
  {
    rejectValue: { rejectionType: FetchInsuranceCardRejectionType };
  }
>(`${prefix}/fetchPrimaryInsuranceCard`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.primaryInsuranceCard(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchInsuranceCardRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchInsuranceCardRejectionType.Generic });
  }
});

export const checkInsuranceEligibility = createAsyncThunk<
  string,
  FetchInsuranceEligibilityArgs,
  {
    rejectValue: { rejectionType: FetchInsuranceEligibilityRejectionType };
  }
>(
  `${prefix}/checkInsuranceEligibility`,
  async ({ patientId, insuranceId }, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.checkInsuranceEligibility(patientId, insuranceId);
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: FetchInsuranceEligibilityRejectionType.Generic });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: FetchInsuranceEligibilityRejectionType.Generic });
    }
  },
);

export const fetchHasHadVideoVisits = createAsyncThunk<
  PatientHasHadVideoVisitsPayload,
  PrimaryKey,
  {
    rejectValue: { rejectionType: VideoVisitsRejectionType };
  }
>(`${prefix}/fetchHasHadVideoVisits`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.hasHadVideoVisits(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: VideoVisitsRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: VideoVisitsRejectionType.Generic });
  }
});

export const fetchHeadshot = createAsyncThunk<
  HeadshotPayload,
  PrimaryKey,
  {
    rejectValue: { rejectionType: FetchHeadshotRejectionType };
  }
>(`${prefix}/fetchHeadshot`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.headshot(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: FetchHeadshotRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: FetchHeadshotRejectionType.Generic });
  }
});

export const updateTriageStatus = createAsyncThunk<
  UpdateTriageStatusPayload,
  UpdateTriageStatusArgs,
  {
    rejectValue: { rejectionType: UpdateTriageStatusRejectionType };
  }
>(`${prefix}/updateTriageStatus`, async ({ patientId, triageStatus }, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.updateTriageStatus(patientId, triageStatus);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: UpdateTriageStatusRejectionType.Generic });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: UpdateTriageStatusRejectionType.Generic });
  }
});

export const profiles = createSlice({
  name: prefix,
  initialState: profilesAdapter.getInitialState(),
  reducers: {
    updateOne: profilesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHeadshot.fulfilled, (state, action) => {
      const data = action.payload;
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: { img: `data:${data.contentType}, ${data.payload}` },
      });
    });
    builder.addCase(fetchHeadshot.rejected, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: { img: '' },
      });
    });
    builder.addCase(fetchHasHadVideoVisits.fulfilled, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: { hasHadVideoVisits: action.payload.hasHadVideoVisits },
      });
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      profilesAdapter.upsertOne(state, transformProfilePayload(action.payload));
    });
    builder.addCase(fetchPrimaryInsurance.pending, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Loading,
        },
      });
    });
    builder.addCase(fetchPrimaryInsurance.rejected, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Failure,
        },
      });
    });
    builder.addCase(fetchPrimaryInsurance.fulfilled, (state, action) => {
      const data = action.payload;
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Success,
          primaryInsurance: {
            ...data,
          },
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceOld.pending, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Loading,
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceOld.rejected, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Failure,
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceOld.fulfilled, (state, action) => {
      const data = action.payload;
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceLoadingState: InsuranceLoadState.Success,
          primaryInsuranceOld: {
            ...data,
          },
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceCard.pending, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceCardLoadingState: InsuranceLoadState.Loading,
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceCard.fulfilled, (state, action) => {
      const data = action.payload;
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceCardLoadingState: InsuranceLoadState.Success,
          primaryInsuranceCard: data,
        },
      });
    });
    builder.addCase(fetchPrimaryInsuranceCard.rejected, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg),
        changes: {
          primaryInsuranceCardLoadingState: InsuranceLoadState.Failure,
          primaryInsuranceCard: {
            image: '',
          },
        },
      });
    });
    builder.addCase(checkInsuranceEligibility.pending, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg.patientId),
        changes: {
          primaryInsuranceEligibilityLoadingState: InsuranceLoadState.Loading,
        },
      });
    });
    builder.addCase(checkInsuranceEligibility.fulfilled, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg.patientId),
        changes: {
          primaryInsuranceEligibilityLoadingState: InsuranceLoadState.Success,
        },
      });
    });
    builder.addCase(checkInsuranceEligibility.rejected, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg.patientId),
        changes: {
          primaryInsuranceEligibilityLoadingState: InsuranceLoadState.Failure,
        },
      });
    });
    builder.addCase(updateTriageStatus.fulfilled, (state, action) => {
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(action.meta.arg.patientId),
        changes: {
          currentTriageStatus: {
            status: action.payload.triageStatus,
            returnToWorkDate: action.payload.returnToWorkDate,
          },
        },
      });
    });
    builder.addCase(updateCareteam.fulfilled, (state, { meta }) => {
      const { patientId, athenaProviderId } = meta.arg;
      profilesAdapter.updateOne(state, {
        id: patientIdToProfileId(patientId),
        changes: { primaryProviderId: athenaProviderId?.toString() },
      });
    });
    builder.addCase(scheduleMeeting.fulfilled, (state, { meta, payload }) => {
      const { patientId, appointmentId } = meta.arg;

      const profile = profilesAdapter
        .getSelectors()
        .selectById(state, patientIdToProfileId(patientId));

      if (profile && profile.visits) {
        profilesAdapter.updateOne(state, {
          id: patientIdToProfileId(patientId),
          changes: {
            visits: profile.visits.map<Visit>((visit) => {
              if (visit.appointmentId === appointmentId) {
                return {
                  ...visit,
                  // Grdn returns the raw response from Zoom. The zoom ID is a number,
                  // but for other endpoints, Grdn expects a string for this same ID.
                  zoomMeetingId: String(payload.id),
                };
              }

              return visit;
            }),
          },
        });
      }
    });
  },
});

export default profiles.reducer;

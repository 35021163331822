import React from 'react';

import type { Booked } from '../../../CarePlanTypes';
import DateDisplay from '../../date';

import { useParameterizedSelector } from 'lib/hooks';
import { getPatientByMrn } from 'reducers/patients';
import type { Patient } from 'types/tables/patients';
import { formatPreferredFullNameFor } from 'lib/util';

interface Props {
  disposition: Booked;
}

const BookedCardDetail = ({ disposition }: Props) => {
  const { appointment, nextStep } = disposition;
  const { mrn } = nextStep;

  const patient = useParameterizedSelector(getPatientByMrn, mrn) as Patient;

  const isTelehealth = // TODO: waiting on Ben to see if this is still viable
    appointment?.patientAppointmentTypeName.toLocaleLowerCase() === 'telehealth' || undefined;

  return (
    <>
      {!!appointment ? (
        <>
          <DateDisplay date={disposition.when} />
          {' • Completed: '}
          {isTelehealth ? 'Telehealth visit' : 'In-person visit'}
          {' • '}
          {formatPreferredFullNameFor(patient)}
        </>
      ) : (
        'Completed • See Athena for more details'
      )}
    </>
  );
};

export default BookedCardDetail;

import { InsertAction, RemoveAction, TableActionType, UpdateAction } from 'legacy/types/actions';
import { PrimaryKey } from 'types/tables/base';
import { TableRecordType } from 'legacy/types/tables';
import { PendingMessage } from 'types/tables/messages';

export const INSERT_MESSAGE_DRAFT = (channelId: PrimaryKey, draft: string): InsertAction => ({
  type: TableActionType.Insert,
  tableRecordType: TableRecordType.MessageDrafts,
  records: [{ id: channelId, draft }],
});

export const REMOVE_MESSAGE_DRAFT = (channelId: PrimaryKey): RemoveAction => ({
  type: TableActionType.Remove,
  tableRecordType: TableRecordType.MessageDrafts,
  recordIds: [channelId],
});

export const UPDATE_MESSAGE_DRAFT = (channelId: PrimaryKey, draft: string): UpdateAction => ({
  type: TableActionType.Update,
  tableRecordType: TableRecordType.MessageDrafts,
  recordId: channelId,
  record: { draft },
});

export const INSERT_PENDING_MESSAGES = (
  channelId: PrimaryKey,
  pendingMessages: PendingMessage[],
): InsertAction => ({
  type: TableActionType.Insert,
  tableRecordType: TableRecordType.PendingMessages,
  channelId,
  records: pendingMessages,
});

export const REMOVE_PENDING_MESSAGES = (
  channelId: PrimaryKey,
  pendingMessageIds: PrimaryKey[],
): RemoveAction => ({
  type: TableActionType.Remove,
  tableRecordType: TableRecordType.PendingMessages,
  channelId,
  recordIds: pendingMessageIds,
});

import MarkdownIt from 'markdown-it';

export const markdownFormatter = MarkdownIt({
  // Convert '\n' in paragraphs into <br>`
  breaks: true,
  // Automatically convert URL's to anchor tags inline.
  linkify: true,
  // Enable some language-neutral replacement + quotes beautification.
  typographer: true,
});

export default function formatMarkdown({
  text,
  formatter = markdownFormatter,
  providerName = '$provider',
}: {
  text: string;
  formatter?: MarkdownIt;
  providerName?: string;
}) {
  return formatter
    .render(text)
    .replace(/\$firstName/g, `<strong>John</strong>`)
    .replace(/\$lastName/g, `<strong>Smith</strong>`)
    .replace(/\$clinicianFirstName/g, `<strong>John</strong>`)
    .replace(/\$clinicianFullName/g, `<strong>${providerName}</strong>`)
    .replace(/\$clinician/g, `<strong>${providerName}</strong>`)
    .replace(/\$provider/g, `<strong>${providerName}</strong>`);
}

const regexEmoji =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

export const sanitizeAvsBlobToMarkdown = (text: string) => {
  return text
    .replace(/<p>|<div>/g, '\n')
    .replace(/<\/p>|<\/div>/g, '\n\n')
    .replace(/<br ?\/?>/g, '\n')
    .replace(/<strong>|<\/strong>|<b>|<\/b>/g, '**')
    .replace(/<i>|<\/i>/g, '*')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(regexEmoji, '$1&nbsp;') // inserts a non-breaking space after emojis
    .replace(/\{\{DATE\|([/\d]+)}}/g, '$1');
};

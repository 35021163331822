import React from 'react';

import { EdenColors } from 'types/colors';
import { Calendar } from 'components/ui/Calendar';

interface Props {
  date: string;
}

const calendarStyle = {
  width: 28,
  height: 32,
  borderRadius: 4,
};

const monthStyle = {
  background: EdenColors.SlateDarken20,
  fontSize: 7,
  paddingTop: 2,
  letterSpacing: 1.5,
};

const dateStyle = {
  fontSize: 14,
  color: EdenColors.White,
  backgroundColor: EdenColors.SlateDarken20,
  paddingTop: 0,
  lineHeight: '100%',
  letterSpacing: 1,
  border: 'none',
};

const CalendarPage = ({ date }: Props) => {
  return (
    <Calendar date={date} style={calendarStyle} monthStyle={monthStyle} dateStyle={dateStyle} />
  );
};

export default CalendarPage;

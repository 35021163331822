import React from 'react';

import DateDisplay from '../../date';
import type { Dropped } from '../../../CarePlanTypes';

interface Props {
  disposition: Dropped;
}

const DroppedCardDetail = ({ disposition }: Props) => {
  return (
    <>
      {disposition.when && (
        <>
          <DateDisplay date={disposition.when} />
          {' • '}
        </>
      )}
      Completed
    </>
  );
};

export default DroppedCardDetail;

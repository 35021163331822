import React from 'react';
import cx from 'classnames';
import { EdenColors } from 'types/colors';
import { RefreshIcon } from 'components/ui/svg';
import { BodySmall, KickerHeader } from 'components/design-system/Text';
import css from 'components/features/Composer/ChatCopilot.module.css';
import { LoadingState } from 'types/tables/patients';
import { UpArrow, DownArrow } from 'components/ui/svg';

interface ChatCopilotProps {
  onInsert: () => void;
  onRegenerate: () => void;
  onMinMax: () => void;
  suggestion?: string;
  loadingState?: LoadingState;
  maximized?: boolean;
  canRegenerate?: boolean;
  error?: string;
}

export const ChatCopilot = ({
  onInsert,
  onRegenerate,
  onMinMax,
  suggestion,
  loadingState,
  maximized,
  canRegenerate,
  error,
}: ChatCopilotProps) => {
  switch (loadingState) {
    case LoadingState.Success:
      return (
        <div className={css.container} data-testid="ChatCopilot">
          <div className={css.headerContainer}>
            <KickerHeader className={css.suggestionHeader}>Eve&apos;s Suggestion</KickerHeader>
            <div>
              {canRegenerate && (
                <button className={css.regenerateButton} onClick={onRegenerate}>
                  <RefreshIcon color={EdenColors.Slate55} />
                </button>
              )}
              <button className={css.minMaxButton} onClick={onMinMax} data-testid="CopilotMinMax">
                {maximized ? <DownArrow /> : <UpArrow />}
              </button>
            </div>
          </div>
          {maximized && (
            <div data-testid="CopilotSuggestionBody">
              <BodySmall className={css.suggestionBody}>{suggestion}</BodySmall>
              <div className={css.insertButtonContainer}>
                <button className={css.insertButton} onClick={onInsert}>
                  + Insert
                </button>
              </div>
            </div>
          )}
        </div>
      );
    case LoadingState.Loading:
      return (
        <div className={css.loadingContainer}>
          <KickerHeader className={css.suggestionHeader} data-testid="CopilotStatusLoading">
            Eve is Thinking...
          </KickerHeader>
        </div>
      );
    case LoadingState.Failure:
      return (
        <div className={css.loadingContainer}>
          <KickerHeader
            className={cx(css.suggestionHeader, css.suggestionHeaderError)}
            data-testid="CopilotStatusError"
          >
            {error}
          </KickerHeader>
        </div>
      );
    default:
      return null;
  }
};

export default ChatCopilot;

import React from 'react';

import css from './VisitSummary.module.css';

import { markdownFormatter, sanitizeAvsBlobToMarkdown } from 'lib/markdown';
import { Clipboard } from 'components/ui/svg';
import { BodySmall } from 'components/design-system/Text';
import { EdenColors } from 'types/colors';

interface Props {
  summary: string;
}

const VisitSummary = ({ summary }: Props) => {
  return (
    <div className={css.visitSummary}>
      <div className={css.icon}>
        <Clipboard width={18} height={18} color={EdenColors.Slate55} />
      </div>

      <div className={css.body}>
        <BodySmall className={css.title}>After Visit Summary</BodySmall>

        <span
          dangerouslySetInnerHTML={{
            __html: markdownFormatter.render(sanitizeAvsBlobToMarkdown(summary)),
          }}
        />
      </div>
    </div>
  );
};

export default VisitSummary;

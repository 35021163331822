import cx from 'classnames';
import React, { HTMLProps } from 'react';
import { Body2 } from './Typography';
import css from './Forms.module.css';
import { Input } from './Fields';
import { ButtonProps, PrimaryButton } from './Buttons';
import { SVGProps } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

export interface FormHeaderProps {
  formName: string;
  icon: React.FC<SVGProps>;
}

export const FormHeader: React.FC<FormHeaderProps> = (props: FormHeaderProps) => (
  <div className={css.formHeader}>
    <div className={css.icon}>
      {props.icon({ color: EdenColors.SlateDarken20, height: '24', width: '24' })}
    </div>
    <div className={css.formHeaderText}>{props.formName}</div>
  </div>
);

export interface LabelProps extends HTMLProps<HTMLLabelElement> {
  text: string;
}

export const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const { text, className, children } = props;
  return (
    <label {...props} className={css.label}>
      <Body2 className={cx(className, css.labelText)}>{text}</Body2>
      {children}
    </label>
  );
};

interface FieldProps extends HTMLProps<HTMLInputElement> {
  // TODO: Fix error
  // eslint-disable-next-line react/no-unused-prop-types
  error?: string;
}

export const Field: React.FC<FieldProps> = (props: FieldProps) => {
  return (
    <div className={css.fieldContainer}>
      <Input
        className={cx(css.field, props.className)}
        {...{ ...props, errorclassname: css.error }}
      />
    </div>
  );
};

export const SubmitButton: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props,
) => (
  <div className={css.submitButtonContainer}>
    <PrimaryButton className={css.submitButton} type="submit" {...props} />
  </div>
);

export const validateString = (value: string, label: string): string | undefined =>
  value ? undefined : `Please enter a valid ${label}`;

export const validateUuid = (value: string, label: string): string | undefined => {
  const basicUuidRegexp =
    /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/;
  return value.match(basicUuidRegexp) ? undefined : `Please enter a valid ${label}`;
};

export const validateEmail = (value: string): string | undefined => {
  const basicEmailRegexp = /^[^ ]+@[^ ]+\.[^ ]+$/;
  return value.match(basicEmailRegexp) ? undefined : 'Please enter a valid email address';
};

export const validateMobilePhone = (value: string): string | undefined => {
  const basicPhoneRegexp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return value.match(basicPhoneRegexp)
    ? undefined
    : 'Please enter a valid North American phone number';
};

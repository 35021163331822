import React, { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { OverlayProvider } from 'react-aria';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import Main from '../Main/Main';
import Public from '../Public/Public';
import css from './App.module.css';
import { getUser } from 'reducers/user';
import { useAuth } from 'lib/auth';
import { captureBreadcrumb, captureException } from 'lib/sentry';
import { useNetwork } from 'lib/hooks';
import { loadEnv } from 'lib/env';
import { BreadcrumbCategory } from 'types/sentry';
import * as Grdn from 'lib/grdn';
import { isSuccessResponse } from 'types/api';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

interface AppProps {
  isAuthenticated: boolean;
}

const App = ({ isAuthenticated }: AppProps) => {
  useNetwork();
  const user = useSelector(getUser);
  const ldClient = useLDClient();

  const { isAuthorized, isLoggedIn } = user;
  const { signOut } = useAuth();

  const persistentAuthHandle = useCallback(async () => {
    if (isLoggedIn && isAuthorized && !isAuthenticated) {
      signOut();
    }
  }, [isLoggedIn, isAuthenticated, isAuthorized, signOut]);

  useEffect(() => {
    persistentAuthHandle().catch(captureException);
  }, [persistentAuthHandle]);

  useEffect(() => {
    // configure the eden health bug reporter extension
    if (loadEnv('REACT_APP_CHROME_EXTENSION_ID') && user) {
      try {
        const port = chrome?.runtime?.connect(loadEnv('REACT_APP_CHROME_EXTENSION_ID'));
        port.postMessage({ user });
      } catch (e) {
        captureBreadcrumb({
          level: 'debug',
          message: 'Cannot connect to Eden Health Chrome Extension',
          category: BreadcrumbCategory.ChromeExtension,
          data: e,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (ldClient) {
      Grdn.getLaunchdarklyCreds()
        .then((res) => {
          if (isSuccessResponse(res)) {
            return ldClient.identify({ key: res.data?.ldUserKey }, res.data?.ldHash);
          } else {
            throw res;
          }
        })
        .catch(captureException);
    }
  }, [user, ldClient]);

  useEffect(() => {
    const flushSentry = async () => {
      await Sentry.flush();
    };

    window.addEventListener('beforeunload', flushSentry);
    return () => {
      window.removeEventListener('beforeunload', flushSentry);
    };
  }, []);

  return (
    <OverlayProvider className={css.app}>
      <ToastContainer />
      <Routes>
        {isAuthorized ? (
          <Route path="/app/*" element={<Main />} />
        ) : (
          <Route path="/*" element={<Public />} />
        )}
      </Routes>
    </OverlayProvider>
  );
};

const SentryApp = Sentry.withProfiler(App);

export default withLDProvider({
  clientSideID: loadEnv('REACT_APP_LAUNCHDARKLY_JS_CLIENTSIDE_ID'),
  user: { key: 'unauthedUser' },
  options: {
    hash: loadEnv('REACT_APP_LAUNCHDARKLY_UNAUTHED_USER_HASH'),
    useReport: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
  flags: {
    'athena-outage': false,
    // TODO: [APP-8424] Remove insurance card feature flag
    'automated-insurance-card-upload': false,
    //TODO(@bear-eden): [APP-7431] remove when obsolete
    'provider-show-care-plan-close-knit': false,
    //TODO(@bear-eden) [APP-7944] remove when obsolete
    'provider-followup-next-steps': false,
    //TODO(@lazartea): APP-8005: Remove zoom links feature flag
    'zoom-links': false,
    // TODO: [APP-8481] Remove PDF upload enabled feature flag
    'pdf-upload-enabled': false,
    // TODO: [APP-8671] Remove File Enhancements feature flag
    'file-enhancements-enabled': false,
    // TODO: [APP-9812] Remove Generative AVS feature flag
    'generative-avs': false,
    // TODO: [APP-9981] Remove Chat Copilot feature flag
    'chat-copilot': false,
  },
  // TODO: Fix type
  // eslint-disable-next-line
  // @ts-ignore
})(SentryApp);

import React from 'react';
import { Item } from 'react-stately';
import cx from 'classnames';
import css from './VisitInfoBar.module.css';
import EllipsisMenu from 'components/design-system/EllipsisMenu';
import type { EllipsisMenuProps } from 'components/design-system/EllipsisMenu';
import { KickerHeader } from 'components/design-system/Text';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCheck,
  HealthcareIcon,
  Minus,
  RotatingEllipse,
} from 'components/ui/svg';
import { EdenColors } from 'types/colors';

type BasicTag = 'AvsReady' | 'AvsSent' | 'DueToday' | 'NoAvs' | 'ScreenersAssigned';
type ControlledTag = { type: 'DxOrders'; content: string | number };
export type Tag = BasicTag | ControlledTag;

const isControlledTag = (tag: Tag): tag is ControlledTag =>
  (tag as ControlledTag).type !== undefined;

const renderTags = (tags: Tag[]) => {
  return tags.map((tag, idx) => {
    if (isControlledTag(tag)) {
      switch (tag.type) {
        case 'DxOrders':
          return (
            <div className={css.label} data-testid="DxOrdersTag" key={`DxOrders${idx}`}>
              <HealthcareIcon className={cx(css.icon, css.healthcare)} />
              <KickerHeader>{tag.content}</KickerHeader>
            </div>
          );
      }
    } else {
      switch (tag) {
        case 'AvsReady':
          return (
            <div
              className={cx(css.label, css.emphasis)}
              data-testid="AvsReadyTag"
              key={`AvsReady${idx}`}
            >
              <ClipboardCheck
                className={cx(css.icon, css.clipboard)}
                color={EdenColors.SlateDarken20}
              />
              <KickerHeader>AVS READY</KickerHeader>
            </div>
          );
        case 'AvsSent':
          return (
            <div className={css.label} data-testid="AvsSentTag" key={`AvsSent${idx}`}>
              <ClipboardCheck className={cx(css.icon, css.clipboard)} />
              <KickerHeader>AVS SENT</KickerHeader>
            </div>
          );
        case 'DueToday':
          return (
            <div
              className={cx(css.label, css.emphasis)}
              data-testid="DueTodayTag"
              key={`DueToday${idx}`}
            >
              <KickerHeader>DUE TODAY</KickerHeader>
            </div>
          );
        case 'NoAvs':
          return (
            <div className={css.label} data-testid="NoAvsTag" key={`NoAvs${idx}`}>
              <Minus className={cx(css.icon, css.minus)} />
              <KickerHeader>NO AVS</KickerHeader>
            </div>
          );
        case 'ScreenersAssigned':
          return (
            <div
              className={css.label}
              data-testid="ScreenersAssignedTag"
              key={`ScreenersAssigned${idx}`}
            >
              <ClipboardCheck className={cx(css.icon, css.clipboard)} />
              <KickerHeader>SCREENERS ASSIGNED</KickerHeader>
            </div>
          );
      }
    }
  });
};

interface MenuItem {
  content: string;
  testId?: string;
}

const renderMenuItems = (menuItems: MenuItem[]) => {
  return menuItems.map(({ content, testId }) => (
    <Item key={content} textValue={content}>
      <span data-testid={testId ? testId : null}>{content}</span>
    </Item>
  ));
};

export type DropdownState = 'open' | 'closed' | 'loading' | 'none';

const renderDropdownIcon = (dropdownState?: DropdownState) => {
  switch (dropdownState) {
    case 'open':
      return <ChevronUpIcon className={cx(css.icon, css.chevron)} />;
    case 'closed':
      return <ChevronDownIcon className={cx(css.icon, css.chevron)} />;
    case 'loading':
      return <RotatingEllipse className={cx(css.loading)} />;
    default:
      return null;
  }
};

interface VisitInfoBarProps extends Partial<Pick<EllipsisMenuProps, 'onMenuSelect'>> {
  tags?: Tag[];
  menuItems?: MenuItem[];
  dropdownState?: DropdownState;
}

const VisitInfoBar = ({ tags, dropdownState, menuItems, onMenuSelect }: VisitInfoBarProps) => {
  return (
    <div className={css.visitInfoBar}>
      {tags && renderTags(tags)}
      {onMenuSelect && menuItems && menuItems.length > 0 && (
        <div className={css.overflowMenu}>
          <EllipsisMenu onMenuSelect={onMenuSelect} testId="OverflowMenu">
            {renderMenuItems(menuItems)}
          </EllipsisMenu>
        </div>
      )}
      {renderDropdownIcon(dropdownState)}
    </div>
  );
};

export default VisitInfoBar;

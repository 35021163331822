import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Chat from 'components/features/Chat/Chat';
import {
  channels,
  getPatientEventChannel,
  getPatientMessages,
  getPatientPrimaryChannel,
  getPrimaryPendingMessages,
  hasPrimaryPendingMessages,
} from 'reducers/channels';
import {
  ActiveChatContext,
  partialParameterizedSelector,
  useActivePatient,
  useParameterizedSelector,
} from 'lib/hooks';
import { useChannel, useReadReceiptHandler } from 'lib/sendbird';
import { captureException } from 'lib/sentry';
import { messages } from 'reducers/messages';

export default function PatientChat() {
  const dispatch = useDispatch();
  const { id: activePatientId, sendbirdUserId: activePatientSendbirdId } = useActivePatient();
  const primaryChannel = useParameterizedSelector(getPatientPrimaryChannel, activePatientId);
  const eventChannel = useParameterizedSelector(getPatientEventChannel, activePatientId);

  const primaryChannelUrl = primaryChannel.sendbirdChannelUrl;
  const eventChannelUrl = eventChannel.sendbirdChannelUrl;
  const primarySBChannel = useChannel(primaryChannelUrl);
  const eventSBChannel = useChannel(eventChannelUrl);
  const { loadMoreMessages: loadMoreEventMessages, hasMore: hasMoreEventMessages } = eventSBChannel;
  const {
    loadMoreMessages: loadMorePrimaryMessages,
    getReadReceipts: getPrimaryReadReceipts,
    hasMore: hasMorePrimaryMessages,
  } = primarySBChannel;

  const checkReadReceipts = useCallback(async () => {
    const receipts = await getPrimaryReadReceipts();
    const receipt = receipts.find((receipt) => receipt.sendbirdUserId === activePatientSendbirdId);
    const lastRead = receipt?.lastRead ?? 0;
    dispatch(
      channels.actions.updateOne({
        id: primaryChannel.id,
        changes: {
          lastRead,
        },
      }),
    );
  }, [dispatch, primaryChannel.id, getPrimaryReadReceipts, activePatientSendbirdId]);

  const loadHistory = useCallback(async () => {
    try {
      const primaryMessages = await loadMorePrimaryMessages();
      const eventMessages = await loadMoreEventMessages();
      dispatch(
        messages.actions.upsertMany({ channelId: primaryChannel.id, entities: primaryMessages }),
      );
      dispatch(
        messages.actions.upsertMany({ channelId: eventChannel.id, entities: eventMessages }),
      );

      await checkReadReceipts();
    } catch (e) {
      captureException(e);
    }
  }, [
    dispatch,
    primaryChannel.id,
    eventChannel.id,
    loadMorePrimaryMessages,
    loadMoreEventMessages,
    checkReadReceipts,
  ]);

  useEffect(() => {
    dispatch(
      channels.actions.updateOne({
        id: primaryChannel.id,
        changes: {
          hasMoreMessages: hasMorePrimaryMessages,
        },
      }),
    );
  }, [dispatch, primaryChannel.id, hasMorePrimaryMessages]);

  useEffect(() => {
    dispatch(
      channels.actions.updateOne({
        id: eventChannel.id,
        changes: {
          hasMoreMessages: hasMoreEventMessages,
        },
      }),
    );
  }, [dispatch, eventChannel.id, hasMoreEventMessages]);

  useReadReceiptHandler(`${primaryChannelUrl}-read-receipts`, checkReadReceipts);

  const patientChat = useMemo(
    () => ({
      channel: primaryChannel,
      sbChannel: primarySBChannel,
      ready: primarySBChannel.loaded && eventSBChannel.loaded,
      canLoadEarlier: primarySBChannel.hasMore,
      getMessages: partialParameterizedSelector(getPatientMessages, activePatientId),
      getPendingMessages: partialParameterizedSelector(getPrimaryPendingMessages, activePatientId),
      getHasPendingMessages: partialParameterizedSelector(
        hasPrimaryPendingMessages,
        activePatientId,
      ),
    }),
    [primaryChannel, primarySBChannel, eventSBChannel, activePatientId],
  );

  return (
    <ActiveChatContext.Provider value={patientChat}>
      <Chat loadHistory={loadHistory} showLastRead lastRead={primaryChannel?.lastRead ?? 0} />
    </ActiveChatContext.Provider>
  );
}

import React, { ReactNode, useCallback, useState } from 'react';
import _, { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import css from './AssignDropdown.module.css';
import { channels, getPatientPrimaryChannel } from 'reducers/channels';
import ProviderRow, {
  Statuses,
  SystemProviderRow,
} from 'components/features/PatientHeader/ProviderRow';
import { getMyProvider, getMyProviderId } from 'reducers/user';
import { Search } from 'components/ui/Search';
import Dropdown from 'components/ui/Dropdown';
import { Caption, Eyebrow1 } from 'components/ui/Typography';
import {
  getCloseKnitProviders,
  getPatientAssignedProviders,
  getProvidersByLicenseLocation,
  getUnassignedSystemProviders,
  providersSelectors,
  systemProviderIds,
} from 'reducers/providers';
import { Provider, ProviderRole } from 'types/tables/providers';
import { escapeRegExp, isProviderLicensed, hasProviderRole, hasNavigatorRole } from 'lib/util';
import {
  getPatientAssignedProviderIds,
  getPatientCurrentState,
  getPatientRecord,
  patients,
} from 'reducers/patients';
import * as GrdnApi from 'lib/grdn';
import { CheckCircleIcon, DownArrow, MinusCircleIcon } from 'components/ui/svg';
import { StateKey, StateLabel, states } from 'types/geo';
import { EdenColors } from 'types/colors';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { getIsCloseKnitPatient } from 'reducers/providerGroups';
import { ChannelStatus } from 'types/tables/channels';

interface AssignDropdownProps {
  isOpen: boolean;
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  searchTerm: string;
  handleSearchChange: (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: { value: string },
  ) => void;
  showRoutingSection: boolean;
}

interface ProviderCategoryProps {
  title: string;
  providers: Provider[];
  status: Statuses;
  nameFormatter: (item: Provider) => string;
  isCloseKnitPatient: boolean;
  collapsible: boolean;
  searchTerm: string;
  onClick: (id: string, status: Statuses) => Promise<void>;
  testId: string;
}

const ProviderCategory = ({
  title,
  providers,
  status,
  nameFormatter,
  isCloseKnitPatient,
  collapsible,
  searchTerm,
  onClick,
  testId,
}: ProviderCategoryProps) => {
  const myId = useSelector(getMyProviderId);
  const { id: patientId } = useActivePatient();

  const patientLocation = useParameterizedSelector(getPatientCurrentState, patientId);
  const stateKey: StateKey | undefined | null = patientLocation && StateKey[patientLocation];
  const closeKnitProviders = useSelector(getCloseKnitProviders);

  const searchRegExp = new RegExp(escapeRegExp(searchTerm), 'i');
  const filteredProviderList = providers.filter((provider) =>
    searchRegExp.test(nameFormatter(provider) || ''),
  );

  // Sort me at top, then licensed providers in patient's state, then by name
  const orderedProviderList: FilteredProvider[] = _.sortBy(
    filteredProviderList.map((provider) => ({
      isMe: myId === provider.id,
      isClinician: 'Clinician' === provider.displayName,
      isStateLicensed: isProviderLicensed(provider, stateKey),
      isCloseKnitCredentialed: isCloseKnitPatient && closeKnitProviders.includes(provider),
      provider,
    })),
    [
      ({ isMe }) => !isMe,
      ({ isClinician }) => !isClinician,
      ({ isStateLicensed, isCloseKnitCredentialed }) =>
        !isStateLicensed && !isCloseKnitCredentialed,
      ({ isStateLicensed }) => !isStateLicensed,
      ({ isCloseKnitCredentialed }) => !isCloseKnitCredentialed,
      ({ provider: { displayName } }) => displayName,
    ],
  );

  return (
    <ProviderList
      title={title}
      collapsible={collapsible}
      shouldExpand={!!searchTerm}
      testId={testId}
    >
      {orderedProviderList.map(({ isMe, isStateLicensed, isCloseKnitCredentialed, provider }) => {
        return (
          <ProviderRow
            onClick={(e) => {
              onClick(provider.id, status);
              e.stopPropagation();
            }}
            key={provider.id}
            image={provider.headshotUrl || ''}
            status={status}
            name={nameFormatter(provider) + (isMe ? ' (Me)' : '')}
            isStateLicensed={isStateLicensed}
            isCloseKnitCredentialed={isCloseKnitCredentialed}
          />
        );
      })}
    </ProviderList>
  );
};

interface SystemProviderCategoryProps {
  providers: Provider[];
  searchTerm: string;
  onClick: (id: string, status: Statuses) => Promise<void>;
}

const SystemProviderCategory = ({
  providers,
  searchTerm,
  onClick,
}: SystemProviderCategoryProps) => {
  const searchRegExp = new RegExp(escapeRegExp(searchTerm), 'i');
  const filteredProviderList = providers.filter((provider) =>
    searchRegExp.test(provider.displayName || ''),
  );

  return (
    <ProviderList title="Routing" collapsible shouldExpand={!!searchTerm} testId="Routing">
      {filteredProviderList.map(({ id, displayName, headshotUrl }) => {
        return (
          <SystemProviderRow
            onClick={(e) => {
              onClick(id, Statuses.UNASSIGNED);
              e.stopPropagation();
            }}
            key={id}
            image={headshotUrl || ''}
            status={Statuses.UNASSIGNED}
            name={displayName}
          />
        );
      })}
    </ProviderList>
  );
};

interface FilteredProvider {
  isMe: boolean;
  isClinician: boolean;
  isStateLicensed: boolean;
  isCloseKnitCredentialed: boolean;
  provider: Provider;
}

interface ProviderListProps {
  title: string;
  collapsible: boolean;
  shouldExpand: boolean;
  testId: string;
  children: ReactNode[];
}

const ProviderList = ({
  title,
  collapsible,
  shouldExpand,
  children,
  testId,
}: ProviderListProps) => {
  const [isManuallyExpanded, setIsManuallyExpanded] = useState(true);

  const toggleIsExpanded = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (!shouldExpand) {
      // Allow manually toggling the state only if the section is not forcibly expanded.
      setIsManuallyExpanded((isManuallyExpanded) => !isManuallyExpanded);
    }
  };

  const isExpanded = shouldExpand || isManuallyExpanded;

  return (
    <div className={css.providerList}>
      {collapsible && (
        <div onClick={toggleIsExpanded} className={css.titleContainer}>
          <Eyebrow1 className={css.title}>{title}</Eyebrow1>
          <div className={cx(css.titleArrow, !isExpanded && css.titleArrowClosed)}>
            <DownArrow />
          </div>
        </div>
      )}
      {isExpanded && <div data-testid={`ProviderCategory-${testId}`}>{children}</div>}
    </div>
  );
};

const StateLicensingOverview = () => {
  const { id: patientId } = useActivePatient();

  const patientLocation = useParameterizedSelector(getPatientCurrentState, patientId);
  const patient = useParameterizedSelector(getPatientRecord, patientId);
  const stateKey: StateKey | undefined | null = patientLocation && StateKey[patientLocation];
  const stateFullName: StateLabel | undefined | null =
    patientLocation && states[patientLocation].label;
  const licensedProviders = useParameterizedSelector(getProvidersByLicenseLocation, stateKey);
  const licensedCount = licensedProviders.length;

  let background, textColor, text, icon;
  if (!patient?.mrn) {
    background = EdenColors.Transparent;
    textColor = EdenColors.Razz;
    text = 'Error loading licensing information';
  } else if (!patientLocation) {
    background = EdenColors.Transparent;
    textColor = EdenColors.Razz;
    text = 'No patient location, provider licensing statuses unknown';
  } else if (patientLocation === StateKey.XX) {
    background = EdenColors.Lemon40;
    textColor = EdenColors.SlateDarken20;
    text = 'Patient is abroad';
    icon = MinusCircleIcon;
  } else if (licensedCount === 0) {
    background = EdenColors.Slate15;
    textColor = EdenColors.SlateDarken20;
    text = `No providers licensed in ${stateFullName}`;
    icon = MinusCircleIcon;
  } else {
    background = EdenColors.Apple40;
    textColor = EdenColors.EdenDarken20;
    text = `${licensedCount} providers licensed in ${stateFullName}`;
    icon = CheckCircleIcon;
  }

  return (
    <div
      style={{ background }}
      className={css.licensingOverview}
      data-testid="StateLicensingOverview"
    >
      {icon && icon({ className: css.licensingOverviewIcon, color: textColor })}
      <Caption color={textColor}>{text}</Caption>
    </div>
  );
};

const CloseKnitLicensingOverview = ({ isCloseKnitPatient }) => {
  const closeKnitProviderCount = useSelector(getCloseKnitProviders).length;

  let background, textColor, text, icon;

  if (closeKnitProviderCount === 0) {
    background = EdenColors.Slate15;
    textColor = EdenColors.SlateDarken20;
    text = 'No CloseKnit credentialed providers';
    icon = MinusCircleIcon;
  } else {
    background = EdenColors.Grape10;
    textColor = EdenColors.GrapeDarken20;
    text = `${closeKnitProviderCount} CloseKnit credentialed providers`;
    icon = CheckCircleIcon;
  }

  return isCloseKnitPatient ? (
    <div
      style={{ background }}
      className={css.licensingOverview}
      data-testid="CloseKnitLicensingOverview"
    >
      {icon && icon({ className: css.licensingOverviewIcon, color: textColor })}
      <Caption color={textColor}>{text}</Caption>
    </div>
  ) : null;
};

export const AssignDropdown = (props: AssignDropdownProps) => {
  const dispatch = useDispatch();

  const { showRoutingSection } = props;
  const { id: patientId, sponsorId } = useActivePatient();
  const primaryChannel = useParameterizedSelector(getPatientPrimaryChannel, patientId);
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, sponsorId);
  const assignedProvidersWithDetails = useParameterizedSelector(
    getPatientAssignedProviders,
    patientId,
  );
  const allProviders = useSelector(providersSelectors.selectAll);
  const myProvider = useSelector(getMyProvider);
  const assignedProviderIds = useParameterizedSelector(getPatientAssignedProviderIds, patientId);

  const unassignedProvidersWithDetails = allProviders.filter(
    (provider) =>
      !assignedProvidersWithDetails.includes(provider) &&
      provider !== myProvider &&
      (!showRoutingSection || !systemProviderIds.includes(provider.id)),
  );
  const unassignedSystemProviders = useParameterizedSelector(
    getUnassignedSystemProviders,
    patientId,
  );
  const isMyProviderAssigned = myProvider && assignedProvidersWithDetails.includes(myProvider);

  const searchTerm = props.searchTerm ? props.searchTerm : '';

  // Filter providers by provider type.
  const providers = unassignedProvidersWithDetails.filter((provider) => hasProviderRole(provider));

  const associates = unassignedProvidersWithDetails.filter((provider) =>
    hasNavigatorRole(provider),
  );

  const other =
    process.env.NODE_ENV === 'development'
      ? unassignedProvidersWithDetails.filter(
          (provider) => provider.role === ProviderRole.TECH_ADMIN,
        )
      : [];

  const handleClickProvider = useCallback(
    async (id: string, status: Statuses) => {
      if (status === 'assigned') {
        try {
          await GrdnApi.unassignProviders(patientId, [id]);
          dispatch(
            patients.actions.updateOne({
              id: patientId,
              changes: {
                assigned: _.uniq(_.without(assignedProviderIds, id)),
              },
            }),
          );
        } catch (e) {}
      } else {
        try {
          await GrdnApi.assignProviders(patientId, [id]);
          dispatch(
            patients.actions.updateOne({
              id: patientId,
              changes: { assigned: _.uniq([...assignedProviderIds, id]) },
            }),
          );
          if (primaryChannel.status === ChannelStatus.Archived) {
            dispatch(
              channels.actions.updateOne({
                id: primaryChannel.id,
                changes: {
                  status: ChannelStatus.Pending,
                },
              }),
            );
          }
        } catch (e) {}
      }
    },
    [assignedProviderIds, dispatch, patientId, primaryChannel],
  );

  const defaultFormatter = (provider: Provider) => provider.displayName;
  const shortcutsView = ProviderCategory({
    title: '',
    providers: myProvider ? [myProvider] : [],
    status: Statuses.UNASSIGNED,
    nameFormatter: defaultFormatter,
    isCloseKnitPatient: isCloseKnitPatient,
    collapsible: false,
    searchTerm: searchTerm,
    onClick: handleClickProvider,
    testId: 'Shortcuts',
  });
  const assignedView = ProviderCategory({
    title: 'Assignees',
    providers: assignedProvidersWithDetails,
    status: Statuses.ASSIGNED,
    nameFormatter: defaultFormatter,
    isCloseKnitPatient: isCloseKnitPatient,
    collapsible: true,
    searchTerm: searchTerm,
    onClick: handleClickProvider,
    testId: 'Assignees',
  });
  const providersView = ProviderCategory({
    title: 'Providers',
    providers: providers,
    status: Statuses.UNASSIGNED,
    nameFormatter: defaultFormatter,
    isCloseKnitPatient: isCloseKnitPatient,
    collapsible: true,
    searchTerm: searchTerm,
    onClick: handleClickProvider,
    testId: 'Providers',
  });
  const associatesView = ProviderCategory({
    title: 'Navigators',
    providers: associates,
    status: Statuses.UNASSIGNED,
    nameFormatter: (li) => li.displayName.split(',')[0],
    isCloseKnitPatient: isCloseKnitPatient,
    collapsible: true,
    searchTerm: searchTerm,
    onClick: handleClickProvider,
    testId: 'Navigators',
  });

  return (
    <Dropdown className={css.dropdown} isOpen={props.isOpen} onClose={props.onClose}>
      <StateLicensingOverview />
      <CloseKnitLicensingOverview isCloseKnitPatient={isCloseKnitPatient} />
      <div className={css.searchContainer}>
        <Search
          className={css.search}
          data-testid="AssignDropdownSearch"
          placeholder="Search by name"
          onSearchChange={debounce(props.handleSearchChange, 500, { leading: true })}
          value={searchTerm || ''}
          open={false}
        />
      </div>
      {myProvider && !isMyProviderAssigned ? shortcutsView : null}
      {assignedProvidersWithDetails.length > 0 ? assignedView : null}
      {showRoutingSection && unassignedSystemProviders.length > 0 && (
        <SystemProviderCategory
          providers={unassignedSystemProviders}
          searchTerm={searchTerm}
          onClick={handleClickProvider}
        />
      )}
      {providersView}
      {associatesView}
      {process.env.NODE_ENV === 'development' &&
        ProviderCategory({
          title: 'Other (dev only)',
          providers: other,
          status: Statuses.UNASSIGNED,
          nameFormatter: defaultFormatter,
          isCloseKnitPatient: isCloseKnitPatient,
          collapsible: true,
          searchTerm: searchTerm,
          onClick: handleClickProvider,
          testId: 'Other',
        })}
    </Dropdown>
  );
};

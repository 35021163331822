import * as React from 'react';
import * as Semantic from 'semantic-ui-react';
import css from './Search.module.css';
import { RotatingEllipse, SearchIcon } from 'components/ui/svg';

export const Search = (props: Semantic.SearchProps) => {
  const searchProps = { input: { iconPosition: 'left', className: css.searchInput }, ...props };
  return (
    <div>
      <div className={css.search}>
        <div className={css.searchIcon}>
          <SearchIcon />
        </div>
        <Semantic.Search {...searchProps} />
        {props.loading && (
          <div className={css.loader}>
            <RotatingEllipse />
          </div>
        )}
      </div>
    </div>
  );
};

import { NextStepType, NextStep, NextStepUIDescriptor } from '../CarePlanTypes';

const nextStepUIDescriptor: Record<NextStepType, NextStepUIDescriptor> = {
  'annual-preventative-visit': {
    stepType: 'annual-preventative-visit',
    displayName: 'Annual Preventative Visit',
    directive: 'Book annual preventative',
  },

  'annual-assessment': {
    stepType: 'annual-assessment',
    displayName: 'Annual Assessment',
    directive: 'Complete Annual Patient Intake',
  },

  'onboarding-assessment': {
    stepType: 'onboarding-assessment',
    displayName: 'Onboarding Assessment',
    directive: 'Complete Patient Intake',
  },

  'flu-shot': {
    stepType: 'flu-shot',
    displayName: 'Flu Shot',
    directive: 'Book flu shot',
  },

  srha: {
    stepType: 'srha',
    displayName: 'Overall Health Check-In',
    directive: 'Complete overall health check-in',
  },

  custom: {
    stepType: 'custom',
    displayName: '$title',
    directive: '$title',
  },

  followup: {
    stepType: 'followup',
    displayName: '$title',
    directive: '$title',
  },
};

//
// Obtains the descriptor for the passed Next Step. The `displayName` and `directive` properties
// may be substitution tokens for properties within the Next Step. In this case, the value is the
// Next Step property key prefixed with "$". For example, "$title" is substituted with the `title`
// property of the Next Step.
//
// @param nextStep: Next Step, the next step whose descriptor is to be obtained
// @returns the descriptor matching the passed step, with any substitution values replaced
//
export function descriptorFor(step: NextStep): NextStepUIDescriptor {
  const dx: NextStepUIDescriptor = { ...nextStepUIDescriptor[step.type] };
  dx.displayName = dx.displayName.startsWith('$')
    ? step[dx.displayName.substring(1)]
    : dx.displayName;
  dx.directive = dx.directive.startsWith('$') ? step[dx.directive.substring(1)] : dx.directive;
  return dx;
}

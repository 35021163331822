import React from 'react';
import { Modal } from 'semantic-ui-react';
import css from './OpenLinkModal.module.css';
import { ButtonSizes, PrimaryButton, SecondaryButton } from 'components/ui/Buttons';
import { Description, Header } from 'components/ui/Typography';
import { XIcon } from 'components/ui/svg';

interface OpenLinkModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  linkTarget: string;
}

const OpenLinkModal = ({ isOpen, onClose, linkTarget }: OpenLinkModalProps) => {
  return (
    <div className={css.modal} data-testid="OpenLinkModal">
      <Modal open={isOpen} size="tiny" onClose={onClose}>
        <Modal.Content>
          <div className={css.modalInner}>
            <div className={css.header}>
              <Header className={css.headerText}>Open External Link</Header>
              <XIcon className={css.close} onClick={onClose} />
            </div>
            <Description className={css.description}>
              Are you sure you want to open this external link? This will open a new tab to{' '}
              <strong>{linkTarget}</strong>.
            </Description>
            <div className={css.buttonRow}>
              <SecondaryButton size={ButtonSizes.large} onClick={onClose}>
                {"Don't Open"}
              </SecondaryButton>
              <PrimaryButton
                size={ButtonSizes.large}
                onClick={() => {
                  window.open(linkTarget, '_blank');
                  onClose();
                }}
              >
                Open Link
              </PrimaryButton>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default OpenLinkModal;

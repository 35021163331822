import React, { useState } from 'react';
import cx from 'classnames';
import css from 'components/design-system/HoverableText.module.css';

interface HoverableTextProps {
  text: string;
  copy?: string;
  hoverableContainerClass?: string;
  hoverableClass?: string;
  copyButtonClass?: string;
}

export const HoverableText = (props: HoverableTextProps) => {
  const [copiedVisible, setCopiedVisible] = useState(false);
  const { text, copy, hoverableClass, hoverableContainerClass, copyButtonClass } = props;
  const textToCopy = copy || text;

  const click = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    /*
     * dummy div element solution from:
     * https://stackoverflow.com/questions/52923771/react-copy-component-state-value-to-clipboard-without-dummy-element
     */
    const dummyInput = document.createElement('input');
    dummyInput.setAttribute('value', textToCopy);
    document.body.appendChild(dummyInput);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
    e.stopPropagation();
    setCopiedVisible(true);
    setTimeout(() => setCopiedVisible(false), 1000);
  };

  const dismiss = () => setCopiedVisible(false);

  return (
    <div
      className={cx(
        css.hoverableContainer,
        copiedVisible && css.hoverableCopied,
        hoverableContainerClass,
      )}
    >
      <div
        className={cx(css.hoverable, copiedVisible && css.mobileHoverable, hoverableClass)}
        onClick={click}
        onMouseLeave={dismiss}
      >
        <div className={css.hoverableText}>{text}</div>
        <div
          className={cx(css.copyButton, css.copiedText, copyButtonClass)}
          hidden={!copiedVisible}
        >
          Copied ✓
        </div>
        <div
          className={cx(css.copyButton, css.hoverableCopy, copyButtonClass)}
          hidden={copiedVisible}
        >
          Copy
        </div>
      </div>
    </div>
  );
};

import Tippy, { TippyProps } from '@tippyjs/react';
import React, { MouseEvent, useState } from 'react';
import cx from 'classnames';
import 'tippy.js/dist/tippy.css';
import css from './Tooltip.module.css';

interface ToolTipProps extends TippyProps {
  inheritButtonSize?: boolean;
  allowHover?: boolean;
}

const Tooltip = (props: ToolTipProps): ReturnType<React.FC> => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const placement = props.placement || 'right';
  const setButtonHeightWidth = typeof props.inheritButtonSize === 'undefined';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { inheritButtonSize, allowHover, ...tippyProps } = props;

  const handleIconClick = (e: MouseEvent) => {
    e.preventDefault();
    isOpen ? close() : open();
  };

  return props.allowHover ? (
    <Tippy interactive placement={placement} {...tippyProps}>
      <button className={cx(css.tooltipButton, setButtonHeightWidth && css.icon)}>
        {props.children}
      </button>
    </Tippy>
  ) : (
    <Tippy
      interactive
      visible={isOpen}
      onClickOutside={close}
      placement={placement}
      {...tippyProps}
    >
      <button
        className={cx(css.tooltipButton, setButtonHeightWidth && css.icon)}
        onClick={handleIconClick}
      >
        {props.children}
      </button>
    </Tippy>
  );
};

export default Tooltip;

import React from 'react';

import type { Declined, DeclineReasonType } from '../../../CarePlanTypes';
import DateDisplay from '../../date';

import { formatPreferredFullNameFor } from 'lib/util';
import { useParameterizedSelector } from 'lib/hooks';
import { getPatientByMrn } from 'reducers/patients';
import type { Patient } from 'types/tables/patients';

const reasonText: Record<DeclineReasonType, string> = {
  allergy: 'Allergy',
  'already-taken': 'Already taken',
  elsewhere: 'Doing elsewhere',
  irrelevant: 'No longer relevant',
  'no-answer': 'Prefer not to answer',
  'no-insurance': "Insurance won't cover",
  nonsensical: "Doesn't make sense",
  'not-interested': 'Not interested',
  other: 'Other',
  'provider-talk': 'Prefer talk with provider',
};

interface Props {
  disposition: Declined;
}

const DeclinedCardDetail = ({ disposition }: Props) => {
  const { nextStep, declineReasonType } = disposition;

  const { mrn } = nextStep;

  const patient = useParameterizedSelector(getPatientByMrn, mrn) as Patient;

  return (
    <>
      <DateDisplay date={disposition.when} />
      {' • Declined: '}
      {reasonText[declineReasonType]}
      {' • '}
      {formatPreferredFullNameFor(patient)}
    </>
  );
};

export default DeclinedCardDetail;

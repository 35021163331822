import React from 'react';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { Ellipse } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import {
  getPatientTicklersAnyOverdue,
  getPatientTicklersAnyDueToday,
  getPatientTicklersAnyDueWithinWeek,
} from 'reducers/patients';
import css from 'components/features/InfoPane/TicklersPresenceDot.module.css';

export const TicklersPresenceDot = () => {
  const activePatient = useActivePatient();
  const areTicklersOverdue = useParameterizedSelector(
    getPatientTicklersAnyOverdue,
    activePatient.id,
  );
  const areTicklersDueToday = useParameterizedSelector(
    getPatientTicklersAnyDueToday,
    activePatient.id,
  );
  const areTicklersDueWithinWeek = useParameterizedSelector(
    getPatientTicklersAnyDueWithinWeek,
    activePatient.id,
  );

  const calculateColor = ({
    areTicklersOverdue,
    areTicklersDueToday,
    areTicklersDueWithinWeek,
  }) => {
    if (areTicklersOverdue) {
      return EdenColors.RazzDarken20;
    } else if (areTicklersDueToday) {
      return EdenColors.Lemon;
    } else if (areTicklersDueWithinWeek) {
      return EdenColors.Eden;
    } else {
      return EdenColors.Transparent;
    }
  };

  const color = calculateColor({
    areTicklersOverdue,
    areTicklersDueToday,
    areTicklersDueWithinWeek,
  });

  return <Ellipse className={css.ticklersDot} color={color} />;
};

export default TicklersPresenceDot;

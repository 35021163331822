import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Chat from 'components/features/Chat/Chat';
import {
  channels,
  getInternalMessages,
  getInternalPendingMessages,
  getPatientInternalChannel,
  hasInternalPendingMessages,
} from 'reducers/channels';
import {
  ActiveChatContext,
  partialParameterizedSelector,
  useActivePatient,
  useParameterizedSelector,
} from 'lib/hooks';
import { useChannel } from 'lib/sendbird';
import { captureException } from 'lib/sentry';
import { messages } from 'reducers/messages';

export default function InternalChat() {
  const dispatch = useDispatch();
  const { id: activePatientId } = useActivePatient();
  const internalChannel = useParameterizedSelector(getPatientInternalChannel, activePatientId);
  const internalSBChannel = useChannel(internalChannel.sendbirdChannelUrl);

  const loadHistory = useCallback(async () => {
    try {
      const internalMessages = await internalSBChannel.loadMoreMessages();
      dispatch(
        messages.actions.upsertMany({ channelId: internalChannel.id, entities: internalMessages }),
      );
    } catch (e) {
      captureException(e);
    }
  }, [dispatch, internalChannel.id, internalSBChannel]);

  useEffect(() => {
    dispatch(
      channels.actions.updateOne({
        id: internalChannel.id,
        changes: {
          hasMoreMessages: internalSBChannel.hasMore,
        },
      }),
    );
  }, [dispatch, internalChannel.id, internalSBChannel.hasMore]);

  const internalChat = useMemo(
    () => ({
      channel: internalChannel,
      sbChannel: internalSBChannel,
      ready: internalSBChannel.loaded,
      canLoadEarlier: internalSBChannel.hasMore,
      getMessages: partialParameterizedSelector(getInternalMessages, activePatientId),
      getPendingMessages: partialParameterizedSelector(getInternalPendingMessages, activePatientId),
      getHasPendingMessages: partialParameterizedSelector(
        hasInternalPendingMessages,
        activePatientId,
      ),
    }),
    [internalChannel, internalSBChannel, activePatientId],
  );

  return (
    <ActiveChatContext.Provider value={internalChat}>
      <Chat loadHistory={loadHistory} showLastRead={false} />
    </ActiveChatContext.Provider>
  );
}

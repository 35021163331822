import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const CheckMark = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.256 5.244a.833.833 0 0 1 0 1.179l-9.167 9.166a.833.833 0 0 1-1.178 0l-4.167-4.166a.833.833 0 1 1 1.179-1.179L7.5 13.822l8.577-8.578a.833.833 0 0 1 1.179 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckMark;

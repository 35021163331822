import React from 'react';

import css from './EmptyNextStepsCard.module.css';

interface Props {
  type: string;
}

const EmptyNextStepsCard = ({ type }: Props) => {
  return (
    <div className={css.card}>
      <div className={css.text}>This patient doesn&apos;t have any {type} next steps.</div>
    </div>
  );
};

export default EmptyNextStepsCard;

import React from 'react';

import { NextStep } from '../CarePlanTypes';
import NextStepCard from './card/NextStepCard';

import css from './NextStepsList.module.css';

interface Props {
  steps: NextStep[];
}

const NextStepsList = ({ steps }: Props) => {
  return (
    <div className={css.container}>
      {steps.map((step) => (
        <NextStepCard key={step.id} step={step} />
      ))}
    </div>
  );
};

export default NextStepsList;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Placeholder } from 'semantic-ui-react';
import css from './PharmacyOverview.module.css';
import MedicalSummaryItem from './MedicalSummaryItem';
import { ChartElement, EmptyChartElement } from './ChartElements';
import { formatPhone } from 'lib/util';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { fetchPharmacyByPatientId, getPatientPharmacy } from 'reducers/pharmacies';
import { Pharmacy } from 'types/tables/pharmacies';

const itemLabel = 'Preferred Pharmacy';

const PharmacyDetails = (pharmacy: Pharmacy) => {
  const name = pharmacy.clinicalProviderName;
  const phone = formatPhone(pharmacy.phoneNumber);
  const city = pharmacy.city;
  const state = pharmacy.state;
  const zip = pharmacy.zip.substring(0, 5);
  const address = pharmacy.address1;

  return (
    <ChartElement>
      <div className={css.details}>
        <div className={css.panel}>
          <div className={css.pharmacyName}>{name}</div>
        </div>
        <div className={css.panel}>
          <div className={css.detailsContainer}>
            <div>
              {address}
              <br />
              {city} {state}, {zip}
            </div>
          </div>
          <div className={css.detailsContainer}>
            <div>{phone}</div>
          </div>
        </div>
      </div>
    </ChartElement>
  );
};

const EmptyPharmacy = () => (
  <EmptyChartElement>This patient does not have a preferred pharmacy.</EmptyChartElement>
);

const PharmacyOverview = () => {
  const dispatch = useDispatch();
  const { id: activePatientId } = useActivePatient();
  const pharmacy = useParameterizedSelector(getPatientPharmacy, activePatientId);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const refreshPharmacy = async () => {
      setIsLoading(true);
      await dispatch(fetchPharmacyByPatientId(activePatientId));
      setIsLoading(false);
    };
    refreshPharmacy().catch();
  }, [dispatch, activePatientId]);

  if (isLoading) {
    return (
      <MedicalSummaryItem label={itemLabel}>
        <ChartElement>
          <Placeholder style={{ width: '100%' }}>
            <Placeholder.Line />
          </Placeholder>
        </ChartElement>
      </MedicalSummaryItem>
    );
  }

  return (
    <MedicalSummaryItem label={itemLabel}>
      {pharmacy ? PharmacyDetails(pharmacy) : EmptyPharmacy()}
    </MedicalSummaryItem>
  );
};

export default PharmacyOverview;

import { createSelector } from 'reselect';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { isSuccessResponse } from 'types/api';
import { PrimaryKey } from 'types/tables/base';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { AppointmentScreeners } from 'types/tables/appointmentScreeners';

const prefix = 'appointmentScreeners';
const fetchRejectionType = 'Failed to get appointment screeners';
const checkInRejectionType = 'Failed to check in';

export const getAppointmentScreeners = createAsyncThunk(
  `${prefix}/getAppointmentScreeners`,
  async ({ appointmentId }: { appointmentId: string }, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getAppointmentScreeners(appointmentId);
      return isSuccessResponse(res)
        ? res.data
        : rejectWithValue({ rejectionType: fetchRejectionType });
    } catch (e) {
      return rejectWithValue({ rejectionType: fetchRejectionType });
    }
  },
);

export const appointmentCheckIn = createAsyncThunk(
  `${prefix}/appointmentCheckIn`,
  async (
    {
      appointmentId,
      patientId,
      screenerNames,
    }: { appointmentId: number; patientId: PrimaryKey; screenerNames: string[] },
    { rejectWithValue },
  ) => {
    try {
      const res = await GrdnApi.appointmentCheckIn(`${appointmentId}`, patientId, screenerNames);
      return isSuccessResponse(res)
        ? res.data
        : rejectWithValue({ rejectionType: checkInRejectionType });
    } catch (e) {
      return rejectWithValue({ rejectionType: checkInRejectionType });
    }
  },
);

export const appointmentScreenersAdapter = createEntityAdapter<AppointmentScreeners>({
  selectId: (appointmentScreenerData) => appointmentScreenerData.appointmentId,
});

export const appointmentScreeners = createSlice({
  name: prefix,
  initialState: appointmentScreenersAdapter.getInitialState(),
  reducers: {
    assignAppointmentScreeners: (state, { payload: appointmentId }: PayloadAction<number>) => {
      appointmentScreenersAdapter.updateOne(state, {
        id: appointmentId,
        changes: { isAssigned: true },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAppointmentScreeners.fulfilled,
      (state, { payload }: PayloadAction<AppointmentScreeners>) => {
        appointmentScreenersAdapter.upsertOne(state, { ...payload });
      },
    );
  },
});

export const { assignAppointmentScreeners } = appointmentScreeners.actions;

export const appointmentScreenersSelectors = appointmentScreenersAdapter.getSelectors<Store>(
  (state) => state.appointmentScreeners,
);

export const selectAppointmentScreeners = createSelector(
  (_, appointmentId: string) => appointmentId,
  appointmentScreenersSelectors.selectAll,
  (appointmentId, assignedScreenersLists) => {
    return assignedScreenersLists.find(
      (assignedScreeners) => `${assignedScreeners.appointmentId}` === `${appointmentId}`,
    );
  },
);

export const selectScreenersAssigned = createSelector(
  appointmentScreenersSelectors.selectAll,
  (assignedScreenersLists) =>
    assignedScreenersLists
      .filter((listInfo) => listInfo.isAssigned)
      .map((listInfo) => listInfo.appointmentId),
);

export default appointmentScreeners.reducer;

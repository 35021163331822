import { BaseRecord, PrimaryKey } from 'types/tables/base';
import { MessageDraft, PendingMessage } from 'types/tables/messages';
import { TableRecordType, TableStore } from 'legacy/types/tables';

export enum TableActionType {
  Insert = 'insert',
  Update = 'update',
  BulkUpsert = 'BulkUpsert',
  Remove = 'remove',
  Noop = 'noop',
}

interface BaseTableAction {
  tableRecordType: TableRecordType;
  type: TableActionType;
}

interface BaseRemoveAction extends BaseTableAction {
  type: TableActionType.Remove;
  recordIds: PrimaryKey[];
}

interface BaseInsertAction extends BaseTableAction {
  type: TableActionType.Insert;
  tableRecordType: TableRecordType;
  records: BaseRecord[];
}

interface BaseUpdateAction<T extends TableRecordType> extends BaseTableAction {
  type: TableActionType.Update;
  tableRecordType: T;
  recordId: PrimaryKey;
  record: Partial<TableStore[T]['byId'][keyof TableStore[T]['byId']]>;
}

interface MessageDraftsInsertAction extends BaseInsertAction {
  tableRecordType: TableRecordType.MessageDrafts;
  records: MessageDraft[];
}

type MessageDraftsUpdateAction = BaseUpdateAction<TableRecordType.MessageDrafts>;

interface MessageDraftsRemoveAction extends BaseRemoveAction {
  tableRecordType: TableRecordType.MessageDrafts;
}

export interface PendingMessagesInsertAction extends BaseInsertAction {
  tableRecordType: TableRecordType.PendingMessages;
  channelId: PrimaryKey;
  records: PendingMessage[];
}

export interface PendingMessagesRemoveAction extends BaseRemoveAction {
  tableRecordType: TableRecordType.PendingMessages;
  channelId: PrimaryKey;
  recordIds: PrimaryKey[];
}

export type UpdateAction = MessageDraftsUpdateAction;

export type InsertAction = MessageDraftsInsertAction | PendingMessagesInsertAction;

export type RemoveAction = MessageDraftsRemoveAction | PendingMessagesRemoveAction;

export type TableAction = InsertAction | RemoveAction | UpdateAction;

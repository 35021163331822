import { getEnvironment } from 'lib/env';

const athenaPreviewBaseUrl = 'https://preview.athenahealth.com/15349/4';

const athenaNetBaseUrl = 'https://athenanet.athenahealth.com/15349/69';

const athenaBaseUrl = () => {
  const environment = getEnvironment();
  return environment === 'Production' ? athenaNetBaseUrl : athenaPreviewBaseUrl;
};

// passing a base Athena url to this will return a URL that includes the dashboard container
const withDashboard = (url: string) => {
  return `${athenaBaseUrl()}/globalframeset.esp?MAIN=${url}`;
};

export const getAppointmentURL = (appointmentId: string) => {
  return withDashboard(`${athenaBaseUrl()}/schedule/apptworkflow.esp?ID=${appointmentId}`);
};

export const getExamPrepUrl = (appointmentId: string) => {
  return withDashboard(`${athenaBaseUrl()}/ax/encounter/${appointmentId}/exam_prep`);
};

export const getProviderTicklersUrl = (providerId: string, startDate: string, endDate: string) => {
  const url = new URL(`${athenaBaseUrl()}/schedule/appointmentticklerview.esp`);
  url.searchParams.append('APPOINTMENTTYPEID', '');
  url.searchParams.append('CONTACTPREFERENCE', '');
  url.searchParams.append('DEPARTMENTID', '');
  url.searchParams.append('ENDDATE', endDate);
  url.searchParams.append('PROVIDERID', providerId);
  url.searchParams.append('STARTDATE', startDate);
  url.searchParams.append('STATUS', 'NOSTATUS');
  url.searchParams.append('STATUS', 'Contacted');
  url.searchParams.append('STATUS', 'Contact Attempted');
  url.searchParams.append('STATUS', 'Deleted');
  url.searchParams.append('STATUS', 'Scheduled');
  return url.href;
};

export const getHomeUrl = () => {
  return withDashboard(`${athenaBaseUrl()}/ax`);
};

import React, { ReactElement } from 'react';
import cx from 'classnames';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import css from './EllipsisMenu.module.css';
import { EllipsisFeather } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

interface MenuButtonProps {
  ellipsisColor: EdenColors;
  isOpen?: boolean;
  testId?: string;
}

const MenuButton = ({ ellipsisColor, isOpen, testId }: MenuButtonProps) => {
  return (
    <div
      className={cx(css.ellipsisMenuTrigger, isOpen && css.open)}
      style={{ stroke: ellipsisColor }}
      data-testid={testId}
    >
      <EllipsisFeather className={css.ellipsisIcon} />
    </div>
  );
};

export interface EllipsisMenuProps {
  children: ReactElement | ReactElement[];
  ellipsisColor?: EdenColors;
  onMenuSelect: (key: string) => void;
  testId?: string;
}

const EllipsisMenu = ({
  children,
  ellipsisColor = EdenColors.Slate55,
  onMenuSelect,
  testId,
}: EllipsisMenuProps) => (
  <div className={css.ellipsisMenu}>
    <DropdownMenu
      menuTrigger={<MenuButton ellipsisColor={ellipsisColor} testId={testId} />}
      onAction={onMenuSelect}
      placement="bottom right"
    >
      {children}
    </DropdownMenu>
  </div>
);

export default EllipsisMenu;

import { createSelector } from 'reselect';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadEnv } from 'lib/env';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';
import { Store } from 'types/redux';
import { Provider } from 'types/tables/providers';
import { StateKey } from 'types/geo';
import { isProviderLicensed } from 'lib/util';
import { getPatientAssignedProviderIds } from 'reducers/patients';

const prefix = 'provider';
const fetchRejectionType = 'Unable to fetch provider details.';
export const systemProviderIds = [
  loadEnv('REACT_APP_POSTGRES_ROUTING_CLINICIAN_PROVIDER_ID'),
  loadEnv('REACT_APP_POSTGRES_ROUTING_NAVIGATOR_PROVIDER_ID'),
  loadEnv('REACT_APP_POSTGRES_ROUTING_MEDICAL_ASSISTANT_PROVIDER_ID'),
  loadEnv('REACT_APP_POSTGRES_ROUTING_NURSING_PROVIDER_ID'),
];

// thunks
export const initializeClinicianDetails = createAsyncThunk(
  `${prefix}/initializeClinicianDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.clinicianDetails();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: fetchRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: fetchRejectionType });
    }
  },
);

export const providersAdapter = createEntityAdapter<Provider>({});

export const providers = createSlice({
  name: prefix,
  initialState: providersAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeClinicianDetails.fulfilled, (state, action) => {
      providersAdapter.upsertMany(state, action.payload);
    });
  },
});

// selectors
export const providersSelectors = providersAdapter.getSelectors<Store>((state) => state.providers);

export const getProviderByAthenaId = createSelector(
  (_, athenaId: string) => athenaId,
  providersSelectors.selectAll,
  (athenaId, providers) =>
    providers.find(
      ({ athenaProviderId, athenaProviderIds }) =>
        athenaProviderId === Number(athenaId) ||
        athenaProviderIds?.find((athenaProvider) => athenaProvider.providerId === Number(athenaId)),
    ),
);

export const getProviderByGoogleId = createSelector(
  (_, googleId: string) => googleId,
  providersSelectors.selectAll,
  (googleId, providers) => providers.find(({ googleId: gId }) => gId === googleId),
);

export const getProviderById = createSelector(
  (_, targetId: string | undefined) => targetId,
  providersSelectors.selectAll,
  (targetId, providers) => (targetId ? providers.find(({ id }) => id === targetId) : undefined),
);

export const getProviderBySendbirdUserId = createSelector(
  (_, sendbirdId: string) => sendbirdId,
  providersSelectors.selectAll,
  (sendbirdId, providers) => providers.find(({ sendbirdUserId }) => sendbirdUserId === sendbirdId),
);

// Uses new provider role to determine if a provider is licensed
export const getProvidersByLicenseLocation = createSelector(
  (_, stateKey: StateKey | null | undefined) => stateKey,
  providersSelectors.selectAll,
  (stateKey, providers) => {
    return providers.filter((provider) => isProviderLicensed(provider, stateKey));
  },
);

export const getPatientAssignedProviders = createSelector(
  getPatientAssignedProviderIds,
  providersSelectors.selectAll,
  (assignedIds = [], providers) =>
    providers.filter((provider) => assignedIds.includes(provider.id)),
);

export const getCloseKnitProviders = createSelector(providersSelectors.selectAll, (providers) =>
  providers.filter((p) => p.isCloseknitCredentialed),
);

export const getIsCloseKnitProvider = createSelector(
  (_, providerId: string) => providerId,
  providersSelectors.selectEntities,
  (providerId, providers) => providers[providerId]?.isCloseknitCredentialed || false,
);

export const getSystemProviders = createSelector(
  (state: Store) =>
    providersSelectors.selectById(
      state,
      loadEnv('REACT_APP_POSTGRES_ROUTING_CLINICIAN_PROVIDER_ID'),
    ),
  (state: Store) =>
    providersSelectors.selectById(
      state,
      loadEnv('REACT_APP_POSTGRES_ROUTING_NAVIGATOR_PROVIDER_ID'),
    ),
  (state: Store) =>
    providersSelectors.selectById(
      state,
      loadEnv('REACT_APP_POSTGRES_ROUTING_MEDICAL_ASSISTANT_PROVIDER_ID'),
    ),
  (state: Store) =>
    providersSelectors.selectById(state, loadEnv('REACT_APP_POSTGRES_ROUTING_NURSING_PROVIDER_ID')),
  (
    clinicianSystemProvider,
    navigatorSystemProvider,
    medicalAssistantSystemProvider,
    nursingSystemProvider,
  ) =>
    [
      clinicianSystemProvider,
      navigatorSystemProvider,
      medicalAssistantSystemProvider,
      nursingSystemProvider,
    ].filter((systemProvider): systemProvider is Provider => !!systemProvider),
);

export const getUnassignedSystemProviders = createSelector(
  getPatientAssignedProviderIds,
  getSystemProviders,
  (assignedProviderIds, systemProviders) =>
    systemProviders.filter((systemProvider) => !assignedProviderIds.includes(systemProvider.id)),
);

export default providers.reducer;

import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const BellFilled = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.586 15.414A2 2 0 0 0 22 16a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2 2 2 0 0 0 2-2V9a8 8 0 1 1 16 0v5a2 2 0 0 0 .586 1.414Z"
      fill="currentColor"
    />
    <path d="M15 20H9a3 3 0 1 0 6 0Z" fill="currentColor" />
  </svg>
);

export default BellFilled;

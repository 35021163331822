import React, { HTMLProps } from 'react';

import cx from 'classnames';

import css from './Tag.module.css';

interface Props extends HTMLProps<HTMLDivElement> {}

const Tag: React.FC<Props> = ({ children, className, ...props }: Props) => {
  return (
    <div className={cx(css.tag, className)} {...props}>
      {children}
    </div>
  );
};

export default Tag;

import * as React from 'react';
import cx from 'classnames';
import css from './Buttons.module.css';

export enum ButtonSizes {
  tiny = 'tiny',
  small = 'small',
  wide = 'wide',
  large = 'large',
  dropdown = 'dropdown',
}

export enum ButtonType {
  tertiary = 'tertiary',
  transparent = 'transparent',
  red = 'red',
  grey = 'grey',
  green = 'green',
  link = 'link',
  primary = 'primary',
  send = 'send',
}

export interface ButtonProps {
  className?: string;
  buttonType?: ButtonType;
  children?: React.ReactNode;
  content?: string | React.ReactElement;
  icon?: any;
  size?: ButtonSizes;
  active?: boolean;
  disabled?: boolean;
  reactive?: boolean;
}

/*
Use this button only to make other buttons! Use the buttons below.
className   : Classname to include with the button
size        : Check enum above for valid sizes
children    : Standard React children component nested in the button, icon and content
              will not be used if this is passed in
content     : Can be used instead of children, usually used with an icon
icon        : icon on right of button
active      : disables button if true
hidden      : If true, button will not display
reactive    : If true, button will be 100% width in screens under 768px
disabled    : If true, button will be disabled
*/
export const Button = ({
  className,
  buttonType,
  size,
  children,
  content,
  icon,
  active,
  disabled,
  reactive,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cx(
        className,
        css.button,
        buttonType && css[buttonType],
        size && css[size],
        active && css.buttonActive,
        disabled && css.disabled,
        reactive && css.reactive,
      )}
      {...props}
      disabled={disabled}
    >
      {children || (
        <div className={css.contentAndIcon}>
          <div className={css.contentContainer}>{content}</div>
          {icon && <span className={css.iconContainer}>{icon}</span>}
        </div>
      )}
    </button>
  );
};

export const PrimaryButton = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => <Button buttonType={ButtonType.primary} {...props} />;
export const SecondaryButton = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => <Button buttonType={ButtonType.grey} {...props} />;
export const TertiaryButton = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => <Button buttonType={ButtonType.tertiary} {...props} />;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import cx from 'classnames';
import css from './HealthQuestionnaires.module.css';
import MedicalSummaryItem from './MedicalSummaryItem';
import { Body, Size } from 'components/ui/Typography';
import {
  AlertCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Loader,
  RecurringIcon,
} from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { useActivePatient } from 'lib/hooks';
import { getEdenScreenerResponses, selectAllScreeners } from 'reducers/screeners';
import { ScreenerResponse, ScreenerType } from 'types/tables/screeners';
import { ScreenerMultipleChoiceQuestion } from 'types/grdn';
import { useAppDispatch } from 'lib/hooks';

type ScreenerLoadingProps = {
  type: string;
};

const ScreenerLoading = (props: ScreenerLoadingProps) => {
  const { type } = props;

  return (
    <div className={cx(css.screenerResultsCard, css.loading)}>
      <Loader height="20" width="20" color={EdenColors.Slate} />
      <Body
        size={Size.Small}
        className={css.loadingMessage}
        data-testid={`${type}-loading-message`}
      >
        Loading {type} results...
      </Body>
    </div>
  );
};

type ScreenerErrorProps = {
  type: string;
  reload?: () => Promise<void>;
};

const ScreenerError = (props: ScreenerErrorProps) => {
  const { type, reload } = props;
  return (
    <div className={css.errorCard}>
      <AlertCircleIcon height="20" width="20" color={EdenColors.Razz} />
      <Body size={Size.Small} className={css.errorMessage} data-testid={`${type}-error-message`}>
        Error loading {type} results from {reload ? 'our database' : 'Athena'}
      </Body>
      {reload && (
        <div
          className={css.errorRefresh}
          onClick={() => reload().catch()}
          data-testid={`${type}-error-reload`}
        >
          <RecurringIcon height="20" width="20" color={EdenColors.Slate} />
        </div>
      )}
    </div>
  );
};

type ScreenerResultsProps = {
  type: string;
  records: ScreenerResponseRecords;
  isLoading?: boolean;
  isError?: boolean;
  reload?: () => Promise<void>;
};

const ScreenerResults = (props: ScreenerResultsProps) => {
  const { type, records, isLoading, reload, isError } = props;
  const [expanded, setExpanded] = useState(false);

  if (isLoading) {
    return <ScreenerLoading type={type} />;
  }

  if (isError) {
    return <ScreenerError type={type} reload={reload} />;
  }

  const handleClickExpand = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <div className={css.screenerResultsCard}>
      <div className={css.screenerType}>
        <Body size={Size.Small}>{type}</Body>
      </div>
      <div
        className={records.length > 1 ? css.screenerDropdown : css.screenerDropdownHidden}
        onClick={handleClickExpand}
        data-testid={`${type}-dropdown`}
      >
        {expanded ? (
          <ChevronUpIcon height="20" width="20" color={EdenColors.SlateDarken20} />
        ) : (
          <ChevronDownIcon height="20" width="20" color={EdenColors.SlateDarken20} />
        )}
      </div>
      <div className={css.screenerResultsContent}>
        <div className={css.screenerResultsRow}>
          <Body
            size={Size.Small}
            className={css.screenerInfoCurrent}
            data-testid={`${type}-current-score-section`}
          >
            {records.length > 0 ? records[0].score : 'None recorded'}
          </Body>
          <Body
            size={Size.Small}
            className={css.screenerInfoCurrent}
            data-testid={`${type}-current-date-section`}
          >
            {records.length > 0 ? records[0].date : 'Updated never'}
          </Body>
        </div>
        {expanded && (
          <div className={css.screenerResultsExpanded}>
            {records.map((record, index) => {
              if (index === 0) return;
              return (
                <div className={css.screenerResultsRow} key={record.id}>
                  <Body
                    size={Size.Small}
                    className={css.screenerInfoPast}
                    data-testid={`${type}-past-score-section`}
                  >
                    {record.score}
                  </Body>
                  <Body
                    size={Size.Small}
                    className={css.screenerInfoPast}
                    data-testid={`${type}-past-date-section`}
                  >
                    {record.date}
                  </Body>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

type ScreenerResponseRecords = {
  id: string;
  date: string;
  score: string;
}[];

const getSrhaRecords = (responses: ScreenerResponse[]): ScreenerResponseRecords => {
  const srhaRecords = responses.filter(
    (response) => response.screenerWithResponses.name === ScreenerType.srha,
  );
  const sortedSrhaRecords = srhaRecords.sort(
    (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis(),
  );
  const mappedRecords = sortedSrhaRecords.map((response) => {
    const questionInfo = response.screenerWithResponses.sections[0]
      .questionList[0] as ScreenerMultipleChoiceQuestion;
    return {
      id: `${response.screenerId}:${response.createdAt}`,
      date: DateTime.fromISO(response.createdAt, { setZone: true }).toLocaleString({
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }),
      score: questionInfo.possibleAnswers[questionInfo.answer],
    };
  });
  return mappedRecords;
};

const HealthQuestionnaires = () => {
  const { id: patientId } = useActivePatient();
  const dispatch = useAppDispatch();
  const screeners = useSelector(selectAllScreeners);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const loadEdenScreeners = useCallback(async () => {
    setIsLoading(true);
    const resp = await dispatch(getEdenScreenerResponses(patientId));
    setIsLoading(false);
    if (getEdenScreenerResponses.rejected.match(resp)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [dispatch, patientId]);

  useEffect(() => {
    loadEdenScreeners().catch();
  }, [loadEdenScreeners]);

  return (
    <MedicalSummaryItem label="Health Questionnaires">
      <div className={css.healthQuestionnaires}>
        <ScreenerResults
          type="SRHA"
          records={getSrhaRecords(screeners)}
          isLoading={isLoading}
          isError={isError}
          reload={loadEdenScreeners}
        />
      </div>
    </MedicalSummaryItem>
  );
};

export default HealthQuestionnaires;

import React, { useRef } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import css from './Dialog.module.css';

export interface DialogProps {
  isOpen?: boolean;
  onClose: () => void;
  children: ReactNode | ReactNode[];
  isDismissable?: boolean;
  modalProps?: HTMLAttributes<Element>;
}

export const Dialog = ({
  isOpen = false,
  onClose,
  isDismissable = true,
  modalProps = {},
  children,
}: DialogProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay({ isOpen, isDismissable, onClose }, dialogRef);

  usePreventScroll();

  const { dialogProps } = useDialog({}, dialogRef);

  return (
    <OverlayContainer>
      <div {...underlayProps} className={css.underlay}>
        <FocusScope contain restoreFocus autoFocus>
          <div
            {...mergeProps(overlayProps, dialogProps, modalProps)}
            ref={dialogRef}
            className={css.overlay}
          >
            {children}
            <DismissButton onDismiss={onClose} />
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
};

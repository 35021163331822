import React, { useState } from 'react';
import css from './Filter.module.css';
import Dropdown from 'components/ui/Dropdown';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import { FilledUpArrow, FilledDownArrow } from 'components/ui/svg';
import { ListItem1 } from 'components/ui/Lists';
import { Bucket, BucketFilterOption } from 'types/sendbird/chat';

interface FilterViewProps {
  onPickFilter: (filter: string) => void;
  filters: BucketFilterOption[];
  selectedFilter: string;
  bucket: Bucket;
}

const FilterView = (props: FilterViewProps) => {
  const { onPickFilter, selectedFilter, filters, bucket } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePickFilter = (filter: string) => {
    onPickFilter(filter);
    toggleDropdown();
  };

  const renderRow = (chatFilter: BucketFilterOption, key: number) => {
    return (
      <ListItem1
        key={key}
        className={css.filterItem}
        onClick={() => handlePickFilter(chatFilter.filter)}
        data-testid={`FilterRow-${bucket}`}
      >
        <div>{chatFilter.filter}</div>
        <div className={css.count}>{chatFilter.patients.length}</div>
      </ListItem1>
    );
  };

  return (
    <div>
      <Button
        buttonType={ButtonType.transparent}
        size={ButtonSizes.small}
        active={isOpen}
        content={`${selectedFilter}`}
        icon={isOpen ? <FilledUpArrow /> : <FilledDownArrow />}
        onClick={toggleDropdown}
        data-testid={`FilterDropdown-${bucket}`}
        className={css.filterButton}
      />
      <Dropdown className={css.dropdown} isOpen={isOpen} onClose={toggleDropdown}>
        {filters.map((value, index) => renderRow(value, index))}
      </Dropdown>
    </div>
  );
};

export default FilterView;

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import css from './styles.module.css';
import { FormProps, handleInternalError, RDashToolErrors } from './index';
import {
  Field,
  Label,
  FormHeader,
  SubmitButton,
  validateUuid,
  validateString,
} from 'components/ui/Forms';
import { Link } from 'components/ui/svg';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';

interface LinkAccountToRosterState {
  employeeId: string;
  patientId: string;
  patientIdError?: string;
  isFormSubmittable: boolean;
  employeeIdError?: string;
}

const initialState = Object.freeze({
  employeeId: '',
  patientId: '',
  isFormSubmittable: false,
});

const validateForm = ({ patientId, employeeId }: LinkAccountToRosterState): boolean =>
  !!employeeId && !validateUuid(patientId, 'Patient ID');

const LinkAccountToRoster: React.FC<FormProps> = (props: FormProps) => {
  const [{ isFormSubmittable, employeeId, patientId, patientIdError, employeeIdError }, setState] =
    useState<LinkAccountToRosterState>(initialState);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field) => {
    const val = e.target.value;
    setState((state) => ({
      ...state,
      [field]: val,
      isFormSubmittable: validateForm({ ...state, [field]: val }),
    }));
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    const clearAndClose = () => {
      setState(initialState);
      props.closeModal();
    };
    e.preventDefault();
    try {
      const resp = await GrdnApi.linkAccountToRoster(props.sponsor.id, employeeId, patientId);
      if (isSuccessResponse(resp)) {
        toast.success('Account successfully linked to roster.');
        clearAndClose();
      }
    } catch (e) {
      switch (e.data?.error?.type) {
        case RDashToolErrors.patientNotFound:
          setState((state) => ({ ...state, patientIdError: 'Patient with given ID not found.' }));
          break;
        case RDashToolErrors.memberNotFound:
          setState((state) => ({
            ...state,
            employeeIdError: 'Employee with given ID not found.',
          }));
          break;
        case RDashToolErrors.patientAlreadyLinked:
          toast.error('Patient is already linked with another member.');
          clearAndClose();
          break;
        case RDashToolErrors.sponsorNotFound:
          toast.error('Sponsor not found.');
          clearAndClose();
          break;
        default:
          const errorMessage = handleInternalError(e);
          toast.error(`Unable to link account to roster.\nError details: ${errorMessage}`);
          clearAndClose();
      }
    }
  };

  return (
    <div data-testid={`link-account-to-roster-form-${props.sponsor.id}`}>
      <FormHeader formName={'Link account to roster'} icon={Link} />
      <form className={css.form} onSubmit={submitForm}>
        <Label text="Employee ID">
          <Field
            data-testid="employeeid-input"
            placeholder="eg. 123456"
            value={employeeId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'employeeId')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                employeeIdError: validateString(employeeId, 'employee ID'),
              }))
            }
            error={employeeIdError}
          />
        </Label>
        <Label text="Patient ID">
          <Field
            data-testid="patientid-input"
            placeholder="eg. 0857f2e4-d57d-4f00-a713-a1a78bcaa53e"
            value={patientId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'patientId')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                patientIdError: validateUuid(patientId, 'Patient ID'),
              }))
            }
            error={patientIdError}
          />
        </Label>

        <SubmitButton data-testid="link-button" disabled={!isFormSubmittable}>
          Link
        </SubmitButton>
      </form>
    </div>
  );
};

export default LinkAccountToRoster;

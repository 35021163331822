import * as React from 'react';
import cx from 'classnames';
import css from './ProviderRow.module.css';
import { ProfilePicture } from 'components/ui/Pills';
import { ListHeaderSmall } from 'components/ui/Typography';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import { AddIcon, CheckCircleIcon, RemoveIcon } from 'components/ui/svg';
import { EdenColors } from 'types/colors';

export enum Statuses {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
}

interface ProviderRowProps {
  image: string;
  name: string;
  status: Statuses;
  isStateLicensed: boolean;
  onClick: (...args: any[]) => any;
  isCloseKnitCredentialed: boolean;
}

const StateLicensingIndicator = ({ isStateLicensed }) => {
  return isStateLicensed ? (
    <div className={css.circleContainer}>
      <div className={cx(css.checkCircle, css.stateCheckCircle)}>
        <CheckCircleIcon
          className={css.icon}
          width={'9.33'}
          height={'9.33'}
          testId="IsLicensedInState"
        />
      </div>
    </div>
  ) : null;
};

const CloseKnitCredentialIndicator = ({ isCloseKnitCredentialed, leftOffset }) => {
  return isCloseKnitCredentialed ? (
    <div className={css.circleContainer}>
      <div className={cx(css.checkCircle, css.closeKnitCheckCircle)} style={{ left: leftOffset }}>
        <CheckCircleIcon
          className={css.icon}
          width={'9.33'}
          height={'9.33'}
          color={EdenColors.GrapeDarken20}
          testId="IsCloseknitCredentialed"
        />
      </div>
    </div>
  ) : null;
};

const ProviderRow = ({
  image,
  name,
  onClick,
  status,
  isStateLicensed,
  isCloseKnitCredentialed,
}: ProviderRowProps) => {
  return (
    <span className={css.providerRow} onClick={onClick}>
      <div className={css.provider}>
        <ProfilePicture image={image} size="large" alt={name} />
        <StateLicensingIndicator isStateLicensed={isStateLicensed} />
        <CloseKnitCredentialIndicator
          isCloseKnitCredentialed={isCloseKnitCredentialed}
          leftOffset={isStateLicensed ? 0 : '-9px'}
        />
        <ListHeaderSmall className={css.name}>{name}</ListHeaderSmall>
      </div>
      <Button
        size={ButtonSizes.small}
        buttonType={ButtonType.transparent}
        className={css.assignButton}
        icon={
          status === Statuses.ASSIGNED ? (
            <RemoveIcon testId="UnassignIcon" />
          ) : (
            <AddIcon testId="AssignIcon" />
          )
        }
        content={status === Statuses.ASSIGNED ? 'Unassign' : 'Assign'}
      />
    </span>
  );
};

interface SystemProviderRowProps {
  image: string;
  name: string;
  status: Statuses;
  onClick: (e: React.MouseEvent) => void;
}

export const SystemProviderRow = ({ image, name, onClick, status }: SystemProviderRowProps) => {
  return (
    <span className={css.providerRow} onClick={onClick}>
      <div className={css.provider}>
        <ProfilePicture image={image} size="large" alt={name} />
        <ListHeaderSmall className={css.name}>{name}</ListHeaderSmall>
      </div>
      <Button
        size={ButtonSizes.small}
        buttonType={ButtonType.transparent}
        className={css.assignButton}
        icon={
          status === Statuses.ASSIGNED ? (
            <RemoveIcon testId="UnassignIcon" />
          ) : (
            <AddIcon testId="AssignIcon" />
          )
        }
        content={status === Statuses.ASSIGNED ? 'Unassign' : 'Assign'}
      />
    </span>
  );
};

export default ProviderRow;

import React, { useState } from 'react';
import cx from 'classnames';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import css from 'components/features/PatientPills/PatientPills.module.css';
import { ChevronDownIcon, ChevronUpIcon, Ellipse, Spinner } from 'components/ui/svg';
import { getSponsorById } from 'reducers/sponsors';
import { PatientTriageStatus, TriageStatus } from 'types/tables/profiles';
import { EdenColors } from 'types/colors';
import { BackToWorkDropdown } from 'components/features/PatientPills/BackToWork/BackToWorkModal';
import { VaxStatusPill } from 'components/features/PatientPills/VaxStatus/VaxStatusLabel';
import { getTriageStatusLabel } from 'components/features/PatientPills/BackToWork/BackToWorkLabel';
import { DeactivationPill } from 'components/features/PatientPills/DeactivationPill/DeactivationPill';
import { isPatientDeactivated } from 'reducers/patients';

export const BackToWorkLabel = ({ profile }) => {
  const patient = useActivePatient();
  const sponsor = useParameterizedSelector(getSponsorById, profile.sponsorId);
  const patientDeactivationState = useParameterizedSelector(isPatientDeactivated, patient.id);
  const triageStatus: PatientTriageStatus = profile.currentTriageStatus.status
    ? profile.currentTriageStatus
    : { status: TriageStatus.NeverTaken };
  const [{ isOpen, isLoading }, changeState] = useState<{ isOpen: boolean; isLoading: boolean }>({
    isLoading: false,
    isOpen: false,
  });
  return (
    <div className={css.container}>
      {patientDeactivationState && <DeactivationPill />}
      {triageStatus && sponsor && sponsor.screeners.length > 0 && (
        <div className={css.labelContainer} data-testid="BackToWorkStatusLabel">
          <div
            className={cx(css[triageStatus.status], isLoading && css.loading)}
            onClick={(e) => {
              if (!isLoading) changeState(() => ({ isOpen: true, isLoading: false }));
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Ellipse className={css.ellipse} />
            {!isLoading ? (
              <>
                <div className={css.message} data-testid="BackToWorkStatusText">
                  {' '}
                  {getTriageStatusLabel(triageStatus)}
                </div>
                {!isOpen ? (
                  <ChevronDownIcon
                    className={css.chevronDown}
                    color={EdenColors.SlateDarken20}
                    height={'14'}
                    width={'14'}
                  />
                ) : (
                  <ChevronUpIcon
                    className={css.chevronDown}
                    color={EdenColors.SlateDarken20}
                    height={'14'}
                    width={'14'}
                  />
                )}
              </>
            ) : (
              <Spinner className={css.spinner} />
            )}
          </div>
          <BackToWorkDropdown
            triageStatus={triageStatus}
            patientId={patient.id}
            isOpen={isOpen}
            onSubmit={() => changeState(() => ({ isOpen: false, isLoading: true }))}
            onClose={() => changeState(() => ({ isOpen: false, isLoading: false }))}
          />
        </div>
      )}
      <VaxStatusPill status={profile.vaccinationStatus} vaccinations={profile.vaccinations} />
    </div>
  );
};

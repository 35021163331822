import React from 'react';
import RemoveEmployee from './RemoveEmployee';
import LinkAccountToRoster from './LinkAccountToRoster';
import CreateNewLogin from './CreateNewLogin';
import css from './styles.module.css';
import UploadRoster from './UploadRoster';
import RosterUploadStatus from './RosterUploadStatus';
import { NonDismountingModal, ModalProps } from 'components/ui/Modal';
import { Sponsor } from 'types/tables/sponsors';
import { ErrorResponse, isGrdnError } from 'types/api';

const rDashTools: RDashTool[] = [
  { form: RemoveEmployee, displayName: 'Remove employee' },
  { form: LinkAccountToRoster, displayName: 'Link account to roster' },
  { form: CreateNewLogin, displayName: 'Create new login' },
  { form: UploadRoster, displayName: 'Upload roster' },
  { form: RosterUploadStatus, displayName: 'Roster upload status' },
];

export const handleInternalError = ({ data }: ErrorResponse): string => {
  let errorMessage = '';
  const errorObj = data.error;
  if (isGrdnError(errorObj)) {
    errorMessage = errorObj.message || errorObj.type || errorObj.cause || '';
  } else if (typeof errorObj === 'object' && errorObj.data?.error) {
    errorMessage =
      errorObj.data.error?.length > 140
        ? errorObj.data.error.slice(0, 140) + '...'
        : errorObj.data.error;
  } else {
    errorMessage = JSON.stringify(errorObj);
  }
  return errorMessage;
};

export const ToolModal = ({
  closeModal,
  isOpen,
  children,
  sponsorName,
  override,
}: ModalProps & { sponsorName: string }) => (
  <NonDismountingModal closeModal={closeModal} isOpen={isOpen} override={override}>
    <div className={css.sponsorName} data-testid={'tool-modal-header'}>
      {sponsorName}
    </div>
    {children}
  </NonDismountingModal>
);

export interface FormProps {
  sponsor: Sponsor;
  closeModal: () => void;
}

type ToolName =
  | 'Remove employee'
  | 'Link account to roster'
  | 'Create new login'
  | 'Upload roster'
  | 'Roster upload status';

export interface RDashTool {
  form: React.FC<FormProps>;
  displayName: ToolName;
}

export enum RDashToolErrors {
  memberNotFound = 'member-not-found',
  sponsorNotFound = 'sponsor-not-found',
  patientNotFound = 'patient-not-found',
  patientAlreadyLinked = 'patient-already-linked',
}

export default rDashTools;

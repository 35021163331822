import React, { CSSProperties } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import css from './Calendar.module.css';

interface CalendarProps {
  date: string;
  style?: CSSProperties;
  monthStyle?: CSSProperties;
  dateStyle?: CSSProperties;
  inactive?: boolean;
  past?: boolean;
}

export const Calendar = ({ date, style, monthStyle, dateStyle, inactive, past }: CalendarProps) => {
  const mDate = DateTime.fromISO(date, { setZone: true });
  const active = !inactive && !past;
  return (
    <div className={css.container} style={style}>
      <div className={cx(css.month, inactive && css.inactive, past && css.past)} style={monthStyle}>
        {mDate.monthShort}
      </div>
      <div
        className={cx(
          css.dayOfMonth,
          inactive && css.inactive,
          past && css.past,
          active && css.active,
        )}
        style={dateStyle}
      >
        {mDate.day}
      </div>
    </div>
  );
};

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import css from './styles.module.css';
import { FormProps, handleInternalError } from './index';
import { FileText } from 'components/ui/svg';
import * as GrdnApi from 'lib/grdn';
import {
  Field,
  Label,
  FormHeader,
  SubmitButton,
  validateEmail,
  validateString,
} from 'components/ui/Forms';
import { isSuccessResponse } from 'types/api';
import { ClientErrorCode } from 'types/http';

interface CreateNewLoginState {
  firstName: string;
  firstNameError?: string;
  lastName: string;
  lastNameError?: string;
  email: string;
  emailError?: string;
  isFormSubmittable: boolean;
  isFormSubmitted: boolean;
}

const initialState = Object.freeze({
  firstName: '',
  lastName: '',
  email: '',
  isFormSubmittable: false,
  isFormSubmitted: false,
});

const validateForm = ({ firstName, lastName, email }: CreateNewLoginState): boolean =>
  !!firstName && !!lastName && !validateEmail(email);

const CreateNewLogin: React.FC<FormProps> = (props: FormProps) => {
  const [
    {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      email,
      emailError,
      isFormSubmittable,
      isFormSubmitted,
    },
    setState,
  ] = useState<CreateNewLoginState>(initialState);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field) => {
    const val = e.target.value;
    setState((state) => ({
      ...state,
      [field]: val,
      isFormSubmittable: validateForm({ ...state, [field]: val }),
    }));
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setState((state) => ({
      ...state,
      isFormSubmitted: true,
    }));

    try {
      const resp = await GrdnApi.createRdashLogin({
        firstName,
        lastName,
        email,
        sponsorId: props.sponsor.id,
      });
      if (isSuccessResponse(resp)) {
        toast.success('New login successfully created.');
      }
    } catch (e) {
      if (e.status === ClientErrorCode.Conflict) {
        toast.error('Unable to create new login - email already used.');
      } else {
        const errorMsg = handleInternalError(e);
        toast.error(`Unable to create new login.\nError details: ${errorMsg}`);
      }
    }

    props.closeModal();
  };

  return (
    <div data-testid={`create-new-login-form-${props.sponsor.id}`}>
      <FormHeader formName={'Create new login'} icon={FileText} />
      <form className={css.form} onSubmit={submitForm}>
        <Label text="First name">
          <Field
            data-testid="firstname-input"
            placeholder="Jane"
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'firstName')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                firstNameError: validateString(firstName, 'first name'),
              }))
            }
            error={firstNameError}
          />
        </Label>
        <Label text="Last name">
          <Field
            data-testid="lastname-input"
            placeholder="Doe"
            value={lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'lastName')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                lastNameError: validateString(lastName, 'last name'),
              }))
            }
            error={lastNameError}
          />
        </Label>
        <Label text="Email">
          <Field
            data-testid="email-input"
            placeholder="example@email.com"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'email')}
            onBlur={() =>
              setState((state) => ({
                ...state,
                emailError: validateEmail(email),
              }))
            }
            error={emailError}
          />
        </Label>
        <SubmitButton data-testid="create-button" disabled={isFormSubmitted || !isFormSubmittable}>
          Create
        </SubmitButton>
      </form>
    </div>
  );
};

export default CreateNewLogin;

import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import 'semantic-ui-css/semantic.min.css';

import './styles.css';
import { BrowserRouter } from 'react-router-dom';
import App from './scenes/App/App';

import store from './store';
import css from './scenes/App/App.module.css';
import { useGoogleAuthEffect } from 'lib/google';

const RootNode = () => {
  const { isLibraryLoaded, isAuthenticated } = useGoogleAuthEffect();

  const app = !isLibraryLoaded ? (
    <div className={css.app}>Loading...</div>
  ) : (
    // TODO: Fix type
    // eslint-disable-next-line
    // @ts-ignore
    <App isAuthenticated={isAuthenticated} />
  );
  return (
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>{app}</BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default RootNode;

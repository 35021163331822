import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  OverlayProvider,
  useButton,
  useDialog,
  useFocusRing,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import cx from 'classnames';
import css from './ScrollModal.module.css';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import type { IconName } from 'components/design-system/Icon/Icon';
import { Button } from 'components/design-system/Button/Button';
import { Icon } from 'components/design-system/Icon/Icon';

const CloseIconButton = ({ onClose }: { onClose: () => void }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton({ onPress: onClose, 'aria-label': 'Cancel' }, buttonRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      className={cx(css.closeButton, isFocusVisible && 'focus-ring')}
      ref={buttonRef}
    >
      <Icon name="cross-mark" className={css.closeIcon} />
    </button>
  );
};

export interface ScrollModalProps {
  /**
   * Optional class name to customize the modal's overlay
   */
  className?: string;
  /**
   * Whether the cancel button should be hidden
   */
  hideCancelButton?: boolean;
  /**
   * Whether the cross mark to close the modal should be hidden.
   */
  hideCloseCrossMark?: boolean;
  /**
   * Name of an optional icon to display before the title.
   */
  iconName?: IconName;
  /**
   * Whether the modal is loading
   */
  isLoading?: boolean;
  /**
   * Whether the modal is open
   */
  isOpen: boolean;
  /**
   * Whether the submit button is disabled
   */
  isSubmitDisabled?: boolean;
  /**
   * Action to perform when the modal is closed
   */
  onClose: () => void;
  /**
   * Action to perform when the modal is submitted
   */
  onSubmit: () => void;
  /**
   * Optional label for the submit button.
   */
  submitLabel?: string;
  /**
   * Optional text below the modal's title
   */
  subtitle?: string;
  /**
   * Title displayed inside the modal.
   */
  title: string;
  children: ReactNode | ReactNode[];
}

/** */
const ScrollModalDialog = ({
  className,
  hideCancelButton = false,
  hideCloseCrossMark = false,
  iconName,
  isLoading = false,
  isOpen,
  isSubmitDisabled = false,
  onClose,
  onSubmit,
  submitLabel,
  subtitle,
  title,
  children,
}: ScrollModalProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      isDismissable: true,
    },
    dialogRef,
  );

  usePreventScroll();
  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, dialogRef);

  // Check if scroll view is bounded for rendering shadows
  const topBoundRef = useRef<HTMLDivElement>(null);
  const bottomBoundRef = useRef<HTMLDivElement>(null);
  const [isTopBounded, setIsTopBounded] = useState(true);
  const [isBottomBounded, setIsBottomBounded] = useState(true);

  useEffect(() => {
    if (!topBoundRef.current || !bottomBoundRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        switch (entry.target.id) {
          case 'topBound':
            setIsTopBounded(entry.isIntersecting);
            break;
          case 'bottomBound':
            setIsBottomBounded(entry.isIntersecting);
            break;
        }
      });
    });
    observer.observe(topBoundRef.current);
    observer.observe(bottomBoundRef.current);
    return () => observer.disconnect();
  });

  return (
    <OverlayContainer>
      <div {...underlayProps} className={css.underlay}>
        <FocusScope contain restoreFocus autoFocus>
          <div
            {...mergeProps(overlayProps, dialogProps, modalProps)}
            ref={dialogRef}
            className={cx(css.overlay, className)}
          >
            <div className={cx(css.headerContainer, isTopBounded && css.isScrollBoundary)}>
              <HeaderMedium className={css.title}>
                {iconName && <Icon name={iconName} className={css.icon} />}
                {title}
                {!hideCloseCrossMark && <CloseIconButton onClose={onClose} />}
              </HeaderMedium>
              {subtitle && <BodySmall className={css.subtitle}>{subtitle}</BodySmall>}
            </div>
            <div className={css.scrollContainer}>
              <div id="topBound" className={css.bound} ref={topBoundRef} />
              <div className={css.scrollContent}>{children}</div>
              <div id="bottomBound" className={css.bound} ref={bottomBoundRef} />
            </div>
            <DismissButton onDismiss={onClose} />
            <div className={cx(css.footerContainer, isBottomBounded && css.isScrollBoundary)}>
              {!hideCancelButton && (
                <Button onPress={onClose} type="secondary" size="small">
                  Cancel
                </Button>
              )}
              <Button
                size="small"
                isDisabled={isSubmitDisabled}
                onPress={onSubmit}
                isLoading={isLoading}
              >
                {submitLabel ? submitLabel : 'Submit'}
              </Button>
            </div>
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
};

/**
 * An accessible modal that supports scrolling for longer content and buttons for submitting or
 * cancelling. Handles keyboard interactions, e.g. closing the dialog with `Esc`. The
 * modal disables scrolling on the rest of the page but allows it for its content.
 * It keeps focus until it's closed, so cycling through elements with `Tab` loops
 * until an action is performed.
 */
export const ScrollModal = (props: ScrollModalProps) => (
  <>
    {props.isOpen && (
      <OverlayProvider>
        <ScrollModalDialog {...props} />
      </OverlayProvider>
    )}
  </>
);

import * as React from 'react';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import css from './Pills.module.css';
import { Caption } from './Typography';
import { SVGProps, EdenCircleLogo } from 'components/ui/svg';
import { EdenColors } from 'types/colors';
import { Provider } from 'types/tables/providers';

export enum PillColors {
  red = 'red',
  grey = 'grey',
  blue = 'blue',
  green = 'green',
}

interface PillProps {
  darker?: boolean;
  children: React.ReactNode;
  color?: PillColors;
}

export const Pill = ({ darker, children, color }: PillProps) => (
  <div
    className={`${darker ? css.darker : ''} ${color ? css[color.concat('Pill')] : css.greyPill}`}
  >
    {children}
  </div>
);

interface ProfilePictureProps {
  image?: string | null;
  size?: string;
  alt?: string;
  index?: number;
}

export const ProfilePicture = ({
  image = '',
  size = 'normal',
  alt = 'profile',
  index,
}: ProfilePictureProps) => (
  <div
    className={`${css.profilePicture} ${css[size]}`}
    style={
      typeof index !== 'undefined'
        ? { right: (index * widthMapping[size]) / 2 }
        : { position: 'static' }
    }
  >
    {image ? (
      <img src={image} className={css.profilePictureImage} alt={alt} />
    ) : (
      <EdenCircleLogo className={css.profilePictureImage} color={EdenColors.Slate} />
    )}
  </div>
);

interface EmptyProfilePictureProps {
  moreNumber: number;
  size?: string;
}

export const EmptyProfilePicture = ({ moreNumber, size = 'normal' }: EmptyProfilePictureProps) => (
  <div className={`${css.emptyProfilePicture} ${css[size]}`}>
    <span className={css.emptyProfilePictureImage}>+{moreNumber}</span>
  </div>
);

interface ProfilePictureGroupProps {
  providers: Provider[];
  size?: string;
  maxPills?: number;
  headsToShowWhenCompressed?: number;
}

const heightMapping: { [height: string]: number } = {
  small: 20, // this includes a 1px border
  normal: 24,
};

const widthMapping: { [width: string]: number } = {
  small: 24, // this includes a 1px border
  normal: 28,
};

export const ProfilePictureGroup = ({
  providers = [],
  size = 'normal',
  maxPills = 5,
  headsToShowWhenCompressed = 1,
}: ProfilePictureGroupProps) => {
  let width = 0;
  const showMore = providers.length > maxPills;
  if (showMore) {
    width = (headsToShowWhenCompressed * 0.5 + 1) * widthMapping[size];
  } else if (providers.length !== 0) {
    width = widthMapping[size] + (widthMapping[size] * (providers.length - 1)) / 2;
  }
  let indexSoFar = 0;
  return (
    <div
      className={css.profilePictureGroup}
      style={{
        height: heightMapping[size],
        width,
      }}
    >
      <>
        {providers
          .slice(0, showMore ? headsToShowWhenCompressed : providers.length)
          .reverse()
          .map((item, index) => {
            indexSoFar += 1;
            return (
              <ProfilePicture
                key={item.id}
                image={item.headshotUrl}
                size={size}
                index={showMore ? headsToShowWhenCompressed - index : providers.length - index - 1}
              />
            );
          })}
        {showMore && <EmptyProfilePicture moreNumber={providers.length - indexSoFar} size={size} />}
      </>
    </div>
  );
};

interface ExpandablePillProps {
  background: EdenColors;
  color: EdenColors;
  text?: string;
  height?: string;
  width?: string;
  icon: React.FC<SVGProps>;
  testID?: string; // Set this to uniquely idenify the pill for tests
}

export const ExpandablePill = ({
  icon,
  background,
  color,
  text,
  height = '12',
  width = '12',
  testID = '',
}: ExpandablePillProps) => (
  <div
    tabIndex={1}
    style={{ backgroundColor: background }}
    className={css.expandablePillOuter}
    data-testid={testID}
  >
    {icon({ className: css.expandablePillIcon, color, height, width })}
    {text && (
      <Caption color={color} className={css.expandablePillText}>
        {text}
      </Caption>
    )}
  </div>
);

export const PillLoader = () => (
  <div className={css.expandablePillOuter}>
    <Loader active inline size="small" />
  </div>
);

import React, {
  createContext,
  ReactNode,
  useCallback,
  useDebugValue,
  useEffect,
  useState,
} from 'react';
import Sendbird, {
  AdminMessage,
  GroupChannel,
  Member,
  PreviousMessageListQuery,
  SendBirdError,
  User,
  UserMessage,
} from 'sendbird';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureBreadcrumb, captureException } from './sentry';
import { useAppDispatch } from './hooks';
import { patients } from 'reducers/patients';
import { channels, getChannelBySendbirdUrl, getPatientIdByChannelId } from 'reducers/channels';
import { SerializedMember } from 'types/tables/channels';
import { messages } from 'reducers/messages';
import { loadEnv } from 'lib/env';
import * as GrdnApi from 'lib/grdn';
import { APIResponse, isSuccessResponse } from 'types/api';
import {
  AdminMessageType,
  EventMessage,
  Message,
  MessageType,
  PrimaryMessage,
  PrimaryMessageType,
  TextAdminMessage,
  TextPrimaryMessage,
} from 'types/tables/messages';
import { AuthPayload } from 'types/grdn';
import { BreadcrumbCategory, BreadcrumbType } from 'types/sentry';
import {
  AppId,
  ChannelInterface,
  ChannelUrl,
  ConnectionState,
  ConnectionStateEnum,
  SendbirdChannel,
  SendbirdMessage,
  SessionToken,
  UserId,
} from 'types/sendbird';

function promisify<T>(_this, fn, ...args): Promise<T> {
  /* This function is Sendbird specific. Sendbird has methods that take a callback
   take it as their final argument. This function takes such methods, and
   rejects or resolves a promise based on whether or not an error was returned.
   This function returns that promise with the rejection/resolution.
 */
  return new Promise((resolve, reject) => {
    fn.bind(_this)(...args, (data: T, error: SendBirdError) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

const appId: AppId = loadEnv('REACT_APP_SENDBIRD_APP_ID');
const client = new Sendbird({ appId });
const { ChannelHandler, ConnectionHandler } = client;

const connectionListener = new ConnectionHandler();
connectionListener.onReconnectFailed = () => {
  captureBreadcrumb({
    type: BreadcrumbType.Debug,
    level: 'debug',
    category: BreadcrumbCategory.SendbirdReconnectFailed,
    message: 'Reconnect Failed',
  });
};
connectionListener.onReconnectStarted = () => {
  captureBreadcrumb({
    type: BreadcrumbType.Debug,
    level: 'debug',
    category: BreadcrumbCategory.SendbirdReconnectStarted,
    message: 'Reconnect Started',
  });
};
connectionListener.onReconnectSucceeded = () => {
  captureBreadcrumb({
    type: BreadcrumbType.Debug,
    level: 'debug',
    category: BreadcrumbCategory.SendbirdReconnectSucceeded,
    message: 'Reconnect Failed',
  });
};

client.addConnectionHandler('CONNECTION_DEBUG_BREADCRUMBS', connectionListener);

enum SDKMessageType {
  user = 'user',
  admin = 'admin',
}

const messageIsUserType = (message: SendbirdMessage): message is UserMessage => {
  const messageType = SDKMessageType[message.messageType] || SDKMessageType.admin;
  return messageType === SDKMessageType.user;
};

const messageIsEdenAdminType = (message: SendbirdMessage): message is AdminMessage => {
  return (
    !messageIsUserType(message) &&
    (AdminMessageType.Appointment === message.customType || // legacy
      AdminMessageType.AfterHours === message.customType || // legacy
      AdminMessageType.CovidVaxEligible === message.customType || //legacy
      AdminMessageType.Text === message.customType ||
      AdminMessageType.Welcome === message.customType)
  );
};

export const parseMessage = (message: SendbirdMessage): Message => {
  const base = {
    timestamp: message.createdAt,
    id: message.messageId.toString(),
  };
  let parsedData;
  if (message.data !== null && message.data !== '') {
    parsedData = JSON.parse(message.data);
  } else {
    parsedData = {};
  }
  const customType = message.customType as MessageType;
  if (messageIsUserType(message)) {
    return {
      ...base,
      data: { ...parsedData, text: message.message },
      type: customType,
      senderId: message.sender?.userId ?? '',
    } as PrimaryMessage;
  } else if (messageIsEdenAdminType(message)) {
    return {
      ...base,
      data: { text: message.message },
      type: message.customType,
    } as TextAdminMessage;
  } else {
    return {
      ...base,
      data: parsedData,
      type: customType,
    } as EventMessage;
  }
};

export const paramifyTextMessage = ({
  text,
  ...data
}: TextPrimaryMessage['data']): Sendbird.UserMessageParams => {
  const params = new client.UserMessageParams();
  params.message = text;
  params.customType = PrimaryMessageType.Text;
  params.data = JSON.stringify(data);

  return params;
};

const connectToSendbird = (userId: UserId, sessionToken: SessionToken) =>
  promisify<User>(client, client.connect, userId, sessionToken);

export const disconnectFromSendbird = (): Promise<void> =>
  promisify<void>(client, client.disconnect);

const getSendbirdConnectionState = () => client.getConnectionState() as ConnectionStateEnum;

const useSendbirdConnection = (
  authorize: () => Promise<APIResponse<AuthPayload>>,
): ConnectionState => {
  const [connectionState, setConnectionState] = useState(getSendbirdConnectionState());
  const [sendbirdUserId, setSendbirdUserId] = useState('');
  useDebugValue(connectionState);
  const connect = useCallback(async () => {
    const authResponse = await authorize();
    if (isSuccessResponse(authResponse)) {
      captureBreadcrumb({
        level: 'debug',
        type: BreadcrumbType.Debug,
        category: BreadcrumbCategory.SendbirdConnect,
        message: 'connecting to sendbird',
      });
      const user = await connectToSendbird(
        authResponse.data.sendbirdUserId,
        authResponse.data.sendbirdSessionToken,
      );
      setSendbirdUserId(user.userId);
      setConnectionState(getSendbirdConnectionState());
    }
  }, [authorize]);

  const disconnect = useCallback(async () => {
    captureBreadcrumb({
      level: 'debug',
      type: BreadcrumbType.Debug,
      category: BreadcrumbCategory.SendbirdDisconnect,
      message: 'disconnecting from sendbird',
    });
    await disconnectFromSendbird();
  }, []);

  useEffect(() => {
    const handleConnectivityChanges = async () => {
      if (connectionState === ConnectionStateEnum.CLOSED) {
        await connect();
      }
    };
    handleConnectivityChanges().catch(captureException);
    return () => {
      if (connectionState === ConnectionStateEnum.OPEN) {
        disconnect().catch(captureException);
      }
    };
  }, [connect, connectionState, disconnect]);

  return {
    connectionState,
    sendbirdUserId,
  };
};

const useMessageHandler = (
  handlerName: string,
  handler: (channel: SendbirdChannel, message: Message) => void,
): void => {
  useDebugValue(handlerName);
  useEffect(() => {
    const channelHandler = new ChannelHandler();
    channelHandler.onMessageReceived = (channel: SendbirdChannel, message: SendbirdMessage) => {
      client.markAsDelivered(channel.url);
      handler(channel, parseMessage(message));
    };
    channelHandler.onMessageUpdated = (channel: SendbirdChannel, message: SendbirdMessage) => {
      handler(channel, parseMessage(message));
    };
    client.addChannelHandler(handlerName, channelHandler);
    return () => {
      client.removeChannelHandler(handlerName);
    };
  }, [handler, handlerName]);
};

const useTypingIndicatorHandler = (
  handlerName: string,
  handler: (channel: SendbirdChannel, members) => void,
): void => {
  useDebugValue(handlerName);
  useEffect(() => {
    const channelHandler = new ChannelHandler();
    channelHandler.onTypingStatusUpdated = (sbChannel) => {
      const members = sbChannel.getTypingUsers();
      handler(sbChannel, members);
    };
    client.addChannelHandler(handlerName, channelHandler);
    return () => {
      client.removeChannelHandler(handlerName);
    };
  }, [handler, handlerName]);
};

export const useReadReceiptHandler = (
  handlerName: string,
  handler: (channel: SendbirdChannel) => void,
) => {
  useDebugValue(handlerName);
  useEffect(() => {
    const channelHandler = new ChannelHandler();
    channelHandler.onReadReceiptUpdated = (channel: SendbirdChannel) => {
      handler(channel);
    };
    client.addChannelHandler(handlerName, channelHandler);
    return () => {
      client.removeChannelHandler(handlerName);
    };
  }, [handler, handlerName]);
};

const getChannel = async (channelUrl) => {
  const connectionState = getSendbirdConnectionState();
  if (connectionState !== ConnectionStateEnum.OPEN) {
    throw new Error('attempted to use a channel before sendbird socket is connected');
  }
  return await promisify<GroupChannel>(
    client.GroupChannel,
    client.GroupChannel.getChannel,
    channelUrl,
  );
};

const leaveChannel = async (channelUrl) => {
  const channel = await getChannel(channelUrl);
  return await promisify<void>(channel, channel.leave);
};

const captureMessagesSentToWrongChannel = (messages, channelUrl) => {
  // Leave in for now as we may have more to debug here in the near future
  const wrong = messages.filter((message) => message.channelUrl !== channelUrl);
  if (wrong.length) {
    wrong.forEach((message) => {
      const error = new Error('Message received on wrong channel');
      const extra = {
        expectedChannelUrl: channelUrl,
        messageChannelUrl: message.channelUrl,
      };
      captureException(error, extra);
    });
  }
};

export const useChannel = (channelUrl: ChannelUrl): ChannelInterface => {
  useDebugValue(channelUrl);
  const [hasMore, setHasMore] = useState(false);
  const [messageQuery, setMessageQuery] = useState<PreviousMessageListQuery>();

  const loadMoreMessages = useCallback(async () => {
    if (!messageQuery) {
      throw new Error('Message query not configured, cannot loadMoreMessages');
    }

    const messages = await promisify<SendbirdMessage[]>(messageQuery, messageQuery.load);

    setHasMore(() => messageQuery.hasMore ?? false);
    captureMessagesSentToWrongChannel(messages, channelUrl);

    return messages.filter((message) => message.channelUrl === channelUrl).map(parseMessage);
  }, [channelUrl, messageQuery]);

  const startTyping = useCallback(async () => {
    const channel = await getChannel(channelUrl);
    channel.startTyping();
  }, [channelUrl]);

  const stopTyping = useCallback(async () => {
    const channel = await getChannel(channelUrl);
    channel.endTyping();
  }, [channelUrl]);

  const markAsRead = useCallback(async () => {
    const channel = await getChannel(channelUrl);
    await channel.markAsRead((error) => {
      if (error) {
        captureException(error, {});
      }
    });
  }, [channelUrl]);

  const sendTextMessage = useCallback(
    async (data: TextPrimaryMessage['data']) => {
      const channel = await getChannel(channelUrl);
      const msg = await promisify<Sendbird.UserMessage>(
        channel,
        channel.sendUserMessage,
        paramifyTextMessage(data),
      );
      // TODO: Sendbird - implement typing indicator
      // await promisify(channel.current, channel.current.endTyping);
      return parseMessage(msg);
    },
    [channelUrl],
  );

  const getReadReceipts = useCallback(async () => {
    const channel = await getChannel(channelUrl);
    const sbReceipts = channel.getReadStatus();
    const sbUserIds = Object.keys(sbReceipts);
    return sbUserIds.map((sbUserId) => {
      return {
        sendbirdUserId: sbUserId,
        lastRead: sbReceipts[sbUserId].last_seen_at,
      };
    });
  }, [channelUrl]);

  useEffect(() => {
    setMessageQuery(undefined);
  }, [channelUrl]);

  useEffect(() => {
    const initialize = async () => {
      const channel = await getChannel(channelUrl);
      const query = channel.createPreviousMessageListQuery();
      query.limit = 50;
      setMessageQuery(query);
    };
    initialize().catch(captureException);
    return () => {
      leaveChannel(channelUrl).catch(captureException);
    };
  }, [channelUrl]);

  return {
    hasMore,
    loaded: !!messageQuery,
    loadMoreMessages: loadMoreMessages,
    startTyping: startTyping,
    stopTyping: stopTyping,
    markAsRead: markAsRead,
    sendTextMessage: sendTextMessage,
    getReadReceipts,
  };
};

export const SendbirdConnectionContext = createContext<ConnectionState>({
  connectionState: getSendbirdConnectionState(),
  sendbirdUserId: '',
});
SendbirdConnectionContext.displayName = 'SendBirdConnection';

interface SendbirdComponentProps {
  children: ReactNode;
}

export const typingIndicator = createAsyncThunk<
  void,
  { sbChannel: SendbirdChannel; members: Member[] }
>('sendbird/notify/typing', ({ sbChannel, members }, { getState, dispatch }) => {
  const channel = getChannelBySendbirdUrl(getState(), sbChannel.url);
  if (channel) {
    // note: sendbird's type for serialize is Object, hence the cast here
    const serialized = members.map((member) => member.serialize() as SerializedMember);
    dispatch(
      channels.actions.updateOne({
        id: channel.id,
        changes: {
          typingMembers: serialized,
        },
      }),
    );
  }
});

export const newMessage = createAsyncThunk<void, { sbChannel: SendbirdChannel; message: Message }>(
  'sendbird/new/message',
  ({ sbChannel, message }, { dispatch, getState }) => {
    const state = getState();
    const channel = getChannelBySendbirdUrl(state, sbChannel.url);
    if (channel) {
      const patientId = getPatientIdByChannelId(state, channel.id);
      dispatch(
        messages.actions.upsertMany({
          entities: [message],
          channelId: channel.id,
        }),
      );
      // When receiving a new message, enable chat copilot to regenerate
      dispatch(
        patients.actions.updateOne({
          id: patientId,
          changes: {
            copilotCanRegenerate: true,
          },
        }),
      );
    }
  },
);

const useNewMessage = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (sbChannel: SendbirdChannel, message: Message) => {
      dispatch(newMessage({ sbChannel, message }));
    },
    [dispatch],
  );
};

const useTypingIndicator = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (sbChannel: SendbirdChannel, members) => {
      dispatch(typingIndicator({ sbChannel, members }));
    },
    [dispatch],
  );
};

export const SendbirdConnectionProvider = ({ children }: SendbirdComponentProps) => {
  const connection = useSendbirdConnection(GrdnApi.authorize);
  const newMessage = useNewMessage();
  const typingIndicator = useTypingIndicator();

  useMessageHandler('GlobalMessageHandler', newMessage);

  useTypingIndicatorHandler('GlobalTypingHandler', typingIndicator);

  return (
    <SendbirdConnectionContext.Provider value={connection}>
      {children}
    </SendbirdConnectionContext.Provider>
  );
};

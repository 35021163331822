import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Message, Placeholder } from 'semantic-ui-react';
import { noop } from 'lodash';
import { DateTime } from 'luxon';
import css from './ChartOverview.module.css';
import MedicalSummaryItem from './MedicalSummaryItem';
import { fetchChart, getPatientChart } from 'reducers/chart';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import {
  OptionalSection,
  Section,
  sectionIsAllergies,
  sectionIsError,
  sectionIsMedications,
  sectionIsProblems,
  SectionItem,
  SectionType,
} from 'types/tables/charts';

const itemLabel = 'Past Medical History';

const headerTime = (lastUpdated: string | null): { text: string; asDateTime?: DateTime } => {
  if (lastUpdated === null) return { text: 'never' };

  const needle = DateTime.fromFormat(lastUpdated, 'MM/DD/YYYY'); // TODO backend should return ISO standard strings
  const updatedDaysAgo = DateTime.local().diff(needle, 'days').days;

  const headerTimeInfo = { asDateTime: needle };
  if (updatedDaysAgo === 0) {
    return { ...headerTimeInfo, text: 'today' };
  } else if (updatedDaysAgo === 1) {
    return { ...headerTimeInfo, text: 'yesterday' };
  } else if (isNaN(updatedDaysAgo)) {
    return { ...headerTimeInfo, text: 'never' };
  } else {
    return { ...headerTimeInfo, text: needle.toRelative() ?? 'sometime' };
  }
};

const activeItems = (items: SectionItem[]) => {
  return [...items]
    .sort((a, b) => {
      return +new Date(b.startDate) - +new Date(a.startDate);
    })
    .reduce((active: string[], i) => {
      if (i.active) {
        active.push(i.name);
      }
      return active;
    }, []);
};

const inactiveItems = (items: SectionItem[]) => {
  return [...items]
    .sort((a, b) => {
      return +new Date(b.endDate as string) - +new Date(a.endDate as string);
    })
    .reduce((inactive: string[], i) => {
      if (!i.active) {
        inactive.push(i.name);
      }
      return inactive;
    }, []);
};

interface ChartHeaderProps {
  name: string;
  lastUpdated: string | null;
  count: number;
  isToggled: boolean;
  setIsToggled: (val: boolean) => void;
}

const ChartHeader = (props: ChartHeaderProps) => {
  const { name, lastUpdated, count, isToggled, setIsToggled } = props;
  const headerTimeInfo = headerTime(lastUpdated);
  const toggleText = isToggled ? 'hide inactive' : 'show inactive';

  return (
    <div className={css.header}>
      <div className={css.headerName}>{name}</div>
      <div className={css.headerModified}>
        updated {headerTimeInfo.text}
        {headerTimeInfo.asDateTime ? (
          <span className={css.tooltip}>
            {headerTimeInfo.asDateTime.toFormat('ccc, MMMM Do YYYY')}
          </span>
        ) : null}
      </div>
      <div
        className={css.headerToggle}
        onClick={() => setIsToggled(!isToggled)}
        onKeyPress={noop}
        role="button"
        tabIndex={0}
      >
        {toggleText} ({count})
      </div>
    </div>
  );
};

const ErrorView = (title: string) => (
  <div data-testid={`error-section-${title}`}>
    <div className={css.header}>
      <div className={css.headerName}>{title}</div>
    </div>
    <div className={css.listContainer}>
      <ul className={css.itemList}>
        <li>
          <Message warning>Error loading {title} from Athena</Message>
        </li>
      </ul>
    </div>
  </div>
);

interface GenericChartSectionProps {
  chart: Section;
  items: SectionItem[];
  section: SectionType;
  report: (chart: Section, active: string[]) => void;
}

const GenericChartSection = (props: GenericChartSectionProps) => {
  const { chart, items, section, report } = props;
  const [isToggled, setIsToggled] = useState(false);

  const active = activeItems(items);
  const inactive = inactiveItems(items);

  const header = ChartHeader({
    name: section,
    lastUpdated: chart.lastUpdated,
    count: inactive.length,
    isToggled,
    setIsToggled,
  });

  return (
    <div data-testid={`chart-section-${section}`}>
      {header}
      <div className={css.listContainer}>
        <ul className={css.itemList}>
          {report(chart, active)}
          {active.map((i) => (
            <li className={css.activeItem} key={i}>
              {i}
            </li>
          ))}
        </ul>
        <ul className={css.itemList}>
          {inactive.map((i) => (
            <li className={isToggled ? css.inactiveItem : css.hiddenItem} key={i}>
              {i}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

interface ChartSectionProps {
  section: OptionalSection;
}

const AllergiesView = ({ section }: ChartSectionProps) => {
  if (sectionIsError(section) || !sectionIsAllergies(section)) {
    return ErrorView(SectionType.allergy);
  }

  const report = (allergies: typeof section, active) => {
    if (allergies.nkda) {
      return <li className={css.nkda}>NKDA</li>;
    } else if (active.length < 1) {
      return <li className={css.activeItem}>None recorded</li>;
    } else {
      return null;
    }
  };

  return GenericChartSection({
    report,
    chart: section,
    items: section.allergies,
    section: SectionType.allergy,
  });
};

const MedicationsView = ({ section }: ChartSectionProps) => {
  if (sectionIsError(section) || !sectionIsMedications(section)) {
    return ErrorView(SectionType.medication);
  }

  const report = (medications: typeof section, active) => {
    if (medications.noMedicationsReported && active.length < 1) {
      return <li className={css.activeItem}>No medications reported</li>;
    } else if (!medications.noMedicationsReported && active.length < 1) {
      return <li className={css.activeItem}>None recorded</li>;
    } else {
      return null;
    }
  };

  return GenericChartSection({
    report,
    chart: section,
    items: section.medications,
    section: SectionType.medication,
  });
};

const ProblemsView = ({ section }: ChartSectionProps) => {
  if (sectionIsError(section) || !sectionIsProblems(section)) {
    return ErrorView(SectionType.problem);
  }

  const report = (problems: typeof section, active) => {
    if (problems.noKnownProblems && active.length < 1) {
      return <li className={css.activeItem}>No known problems</li>;
    } else if (!problems.noKnownProblems && active.length < 1) {
      return <li className={css.activeItem}>None recorded</li>;
    } else {
      return null;
    }
  };

  return GenericChartSection({
    report,
    chart: section,
    items: section.problems,
    section: SectionType.problem,
  });
};

export const ChartOverview = () => {
  const { id: activePatientId } = useActivePatient();
  const chart = useParameterizedSelector(getPatientChart, activePatientId);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  /*
   * TODO this effect can call setIsLoading when the component is unmounted because
   *  fetchChart is slow (depends on athena) and the user can toggle between Medical Summary (this)
   *  and Visit & Cases before it completes loading
   * */
  useEffect(() => {
    const loadChart = async () => {
      setIsLoading(true);
      await dispatch(fetchChart(activePatientId));
      setIsLoading(false);
    };
    loadChart().catch();
  }, [dispatch, activePatientId]);

  if (isLoading || !chart) {
    return (
      <MedicalSummaryItem label={itemLabel}>
        <div className={css.container}>
          <Placeholder data-testid="PlaceholderGraphic">
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      </MedicalSummaryItem>
    );
  }

  return (
    <MedicalSummaryItem label={itemLabel}>
      <div className={css.container}>
        <AllergiesView section={chart[SectionType.allergy]} />
        <MedicationsView section={chart[SectionType.medication]} />
        <ProblemsView section={chart[SectionType.problem]} />
      </div>
    </MedicalSummaryItem>
  );
};

export default ChartOverview;

import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import css from './Modal.module.css';

import { XIcon } from 'components/ui/svg';

export interface ModalProps extends ReactModal.Props {
  closeModal: VoidFunction;
  isOpen: boolean;
  children: any;
  override?: string;
}

export const NonDismountingModal: React.FC<ModalProps> = (props: ModalProps) => {
  // this modal will not dismount and remount when it is closed and reopened.
  // this is useful in cases where we want state to persist despite opens and closes.
  const { closeModal, children, override } = props;
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
      onClose={closeModal}
      className={classnames(css.modalContent, override)}
      overlayClassName={css.modalOverlay}
      appElement={document.getElementById('root')}
      {...props}
    >
      <XIcon className={css.xIcon} onClick={closeModal} />
      {children}
    </ReactModal>
  );
};

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  // when this modal closes it dismounts all of its children.
  const { closeModal, children, override } = props;

  return props.isOpen ? (
    <ReactModal
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
      onClose={closeModal}
      className={classnames(css.modalContent, override)}
      overlayClassName={css.modalOverlay}
      appElement={document.getElementById('root')}
      {...props}
    >
      <XIcon className={css.xIcon} onClick={closeModal} />
      {children}
    </ReactModal>
  ) : (
    <div></div>
  );
};

import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { toast } from 'react-toastify';
import css from './Visit.module.css';
import { BodySmall, HeaderMedium } from 'components/design-system/Text';
import { Checkbox, PrimaryButton } from 'components/design-system/Form';
import { ListBody, ListHeaderMedium } from 'components/ui/Typography';
import { ClipboardCheck } from 'components/ui/svg';
import { Appointment } from 'types/athena/visits';
import {
  useActivePatient,
  useAppDispatch,
  useIsMounted,
  useParameterizedSelector,
} from 'lib/hooks';
import { fetchAppointmentNote } from 'reducers/visits';
import { EdenColors } from 'types/colors';
import { Modal } from 'components/ui/Modal';
import { getAppointmentURL, getExamPrepUrl } from 'lib/athenaUrls';
import {
  appointmentCheckIn,
  assignAppointmentScreeners,
  selectAppointmentScreeners,
} from 'reducers/appointmentScreeners';
import { AppointmentScreeners, DisplayNames } from 'types/tables/appointmentScreeners';
import { PrimaryKey } from 'types/tables/base';
import type { DropdownState } from 'components/features/VisitOverview/VisitInfoBar';
import VisitInfoBar from 'components/features/VisitOverview/VisitInfoBar';
import MeetingActions from 'components/features/VisitOverview/MeetingActions';
import { getIsCloseKnitPatient, getVideoVisitDepartmentBySponsorId } from 'reducers/providerGroups';

interface AssignScreenersModalProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  userInfo: { firstName: string | null; lastName: string | null; patientId: string };
  appointmentScreeners: AppointmentScreeners;
}

const AssignScreenersModal = (props: AssignScreenersModalProps) => {
  const {
    isOpen,
    closeModal,
    userInfo,
    appointmentScreeners: { appointmentId, screenerNames },
  } = props;

  const getInitialSelection = (): { [key: string]: boolean } =>
    screenerNames.reduce(
      (screenerSelection, screenerName) => ({ ...screenerSelection, [screenerName]: true }),
      {},
    );

  const [screenerSelection, setScreenerSelection] = useState(getInitialSelection);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  const handleAppointmentCheckIn = async (
    appointmentId: number,
    patientId: PrimaryKey,
    screenerNames: string[],
  ) => {
    if (isMounted.current) {
      setSubmitting(true);
    }

    const response = await dispatch(
      appointmentCheckIn({
        appointmentId,
        patientId,
        screenerNames,
      }),
    );

    if (appointmentCheckIn.fulfilled.match(response)) {
      dispatch(assignAppointmentScreeners(appointmentId));
      toast.success('Screeners successfully assigned.');
    } else {
      toast.error('Unable to assign screeners. Try again.');
    }
    if (isMounted.current) {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => {
        closeModal();
        setScreenerSelection(getInitialSelection());
      }}
    >
      <div className={css.screenersModal} data-testid="AssignScreenersModal">
        <HeaderMedium className={cx(css.screenersModalSection, css.screenersModalHeaderContainer)}>
          <ClipboardCheck color={EdenColors.Slate} />
          <span>Assign Screeners & Check-In</span>
        </HeaderMedium>
        <BodySmall className={css.screenersModalSection}>
          <div className={css.screenersModalExplainer}>
            Completing this action will check-in the patient for their upcoming appointment and
            assign the selected screeners to complete before the visit. If nothing is selected, then
            the patient will be checked-in and no screeners will be assigned in the patient app.
          </div>
        </BodySmall>
        <BodySmall>
          <div className={css.screenersModalListHeader}>
            Screeners to {userInfo.firstName} {userInfo.lastName}
          </div>
        </BodySmall>
        <div className={css.screenersModalList}>
          {screenerNames.map((screenerName) => (
            <div className={css.screenersModalListItem} key={screenerName}>
              <Checkbox
                checked={screenerSelection[screenerName]}
                onClick={() => {
                  setScreenerSelection((prevSelection) => ({
                    ...prevSelection,
                    [screenerName]: !prevSelection[screenerName],
                  }));
                }}
                height={19}
                width={19}
                color={EdenColors.Eden}
                data-testid={`${screenerName}-checkbox`}
              />
              <BodySmall>
                <div className={css.screenersModalListItemText}>
                  {DisplayNames[screenerName] || screenerName}
                </div>
              </BodySmall>
            </div>
          ))}
        </div>
        <PrimaryButton
          className={css.screenersModalButton}
          value="Check-In Patient"
          onClick={async () => {
            await handleAppointmentCheckIn(
              appointmentId,
              userInfo.patientId,
              screenerNames.filter((screenerName) => screenerSelection[screenerName]),
            );
            closeModal();
          }}
          loading={submitting}
          data-testid="AssignScreenersSubmitButton"
        />
      </div>
    </Modal>
  );
};

interface UpcomingVisitProps {
  visit: Appointment;
}

enum LoadingState {
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
}

type MenuItemOption = 'Assign screeners' | 'Exam prep' | 'Start check-in';

const UpcomingVisit = (props: UpcomingVisitProps) => {
  const { visit } = props;
  const {
    appointmentId,
    startTime,
    departmentName,
    patientAppointmentTypeName,
    appointmentNote,
    date,
    providerId,
    providerName,
  } = visit;
  const dispatch = useAppDispatch();
  const { id: patientId, firstName, lastName } = useActivePatient();
  const isMounted = useIsMounted();

  const [loadingState, setLoadingState] = useState(LoadingState.Success);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const appointmentScreeners = useParameterizedSelector(selectAppointmentScreeners, appointmentId);
  const hasScreeners = appointmentScreeners && appointmentScreeners.screenerNames.length > 0;

  const toggle = useCallback(async () => {
    if (!isOpen) {
      if (!appointmentNote) {
        setLoadingState(LoadingState.Loading);
        try {
          await dispatch(fetchAppointmentNote(patientId, `${appointmentId}`));
          setLoadingState(LoadingState.Success);
        } catch (e) {
          setLoadingState(LoadingState.Failure);
        }
      }
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isOpen, appointmentNote, dispatch, patientId, appointmentId]);

  const renderDetails = () => {
    if (loadingState === LoadingState.Loading) {
      return null;
    }

    let appointmentNoteText;
    if (loadingState === LoadingState.Failure) {
      appointmentNoteText = 'Failed to load appointment note';
    } else if (appointmentNote?.notetext) {
      appointmentNoteText = appointmentNote.notetext;
    } else {
      appointmentNoteText = 'None recorded';
    }

    return (
      <div className={css.ap}>
        <div className={css.appointmentDetail}>
          <ListHeaderMedium className={css.detailHeader}>Reason</ListHeaderMedium>
          <ListBody>{patientAppointmentTypeName}</ListBody>
        </div>
        <div className={css.appointmentDetail}>
          <ListHeaderMedium className={css.detailHeader}>Note</ListHeaderMedium>
          <ListBody>{appointmentNoteText}</ListBody>
        </div>
      </div>
    );
  };

  const renderDropdownState = (): DropdownState => {
    if (isOpen && loadingState !== LoadingState.Loading) {
      return 'open';
    } else if (loadingState === LoadingState.Loading) {
      return 'loading';
    } else {
      return 'closed';
    }
  };

  const getMenuItems = () => {
    const menuItems = [
      ...(isToday
        ? [
            {
              content: hasScreeners ? 'Assign screeners' : 'Start check-in',
              testId: hasScreeners ? 'AssignScreeners' : 'StartCheckIn',
            },
          ]
        : []),
      { content: 'Exam prep', testId: 'ExamPrep' },
    ];
    return menuItems;
  };

  const onMenuSelect = (key: MenuItemOption) => {
    switch (key) {
      case 'Assign screeners':
        setIsModalOpen(true);
        break;
      case 'Exam prep':
        window.open(getExamPrepUrl(`${appointmentId}`), '_blank', 'noopener, noreferrer');
        break;
      case 'Start check-in':
        window.open(getAppointmentURL(`${appointmentId}`), '_blank', 'noopener, noreferrer');
        break;
    }
  };

  const apptdate = date ? DateTime.fromFormat(date, 'MM/dd/yyyy') : DateTime.fromMillis(0);
  const today = DateTime.local();
  const time = DateTime.fromISO(startTime).toFormat('h:mma');
  const isThisYear = apptdate.hasSame(today, 'year');
  const isToday = apptdate.hasSame(today, 'day');
  const formattedDate = isThisYear
    ? apptdate.toFormat('ccc. MMM d')
    : apptdate.toFormat('ccc. MMM d, yyyy');
  const isProviderAssigned = providerId !== '3';
  const { sponsorId } = useActivePatient();
  const isCloseKnitPatient = useParameterizedSelector(getIsCloseKnitPatient, sponsorId);
  const videoVisitDepartmentId = useParameterizedSelector(
    getVideoVisitDepartmentBySponsorId,
    sponsorId,
  );
  const isVideoVisit = Number(visit.departmentId) === videoVisitDepartmentId;

  return (
    <>
      <div className={css.clickable} onClick={toggle} data-testid="UpcomingVisitCard">
        <div className={css.basicDetails}>
          <div className={css.visitHeader}>
            <ListHeaderMedium className={css.header}>
              {isVideoVisit
                ? `Video Visit`
                : departmentName
                ? `Office Visit at ${departmentName}`
                : 'Office Visit'}
            </ListHeaderMedium>
            <VisitInfoBar
              tags={isToday ? ['DueToday'] : []}
              dropdownState={renderDropdownState()}
              menuItems={getMenuItems()}
              onMenuSelect={onMenuSelect}
            />
          </div>
          <div className={css.visitContents}>
            <div className={css.subDetails}>
              <ListBody>{`${formattedDate} at ${time}`}</ListBody>
              {isProviderAssigned && (
                <div className={css.subDetails}>
                  <ListBody className={css.separator}>•</ListBody>
                  <ListBody>{providerName}</ListBody>
                </div>
              )}
            </div>
          </div>
          {isVideoVisit && !isCloseKnitPatient && (
            <MeetingActions
              appointmentId={visit.appointmentId}
              zoomMeetingId={visit.zoomMeetingId}
              isStarted={visit.isStarted}
            />
          )}
        </div>
        {isOpen && renderDetails()}
      </div>
      {appointmentScreeners && (
        <AssignScreenersModal
          isOpen={isModalOpen}
          closeModal={() => {
            if (isMounted.current) {
              setIsModalOpen(false);
            }
          }}
          userInfo={{ firstName, lastName, patientId }}
          appointmentScreeners={appointmentScreeners}
        />
      )}
    </>
  );
};

export default UpcomingVisit;

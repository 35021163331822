import React, { useState } from 'react';
import cx from 'classnames';
import css from './AttachButton.module.css';
import RequestToScheduleVisitModal from './RequestToScheduleVisitModal';
import { TertiaryButton } from 'components/ui/Buttons';
import { CalendarIcon } from 'components/ui/svg';

const RequestToScheduleVisitButton = ({ disabled }: { disabled: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <TertiaryButton
        disabled={disabled}
        data-testid="OpenRequestToScheduleVisitModal"
        className={cx(css.button, isModalOpen && css.active, disabled && css.disabled)}
        onMouseDown={() => !disabled && setIsModalOpen(true)}
      >
        <CalendarIcon />
      </TertiaryButton>
      <RequestToScheduleVisitModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default RequestToScheduleVisitButton;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'components/design-system/Alert/Alert';
import { BradState } from 'types/grdn';
import { fetchBradStatus, getBradStatus, useBradPoll } from 'reducers/brad';

export const BradBanner = () => {
  const dispatch = useDispatch();
  const bradStatus = useSelector(getBradStatus);

  useEffect(() => {
    /**
     * The `poll` utility does not execute the callback immediately after it
     * starts, so we have to manually retrieve the state once _and_ start
     * polling.
     */
    dispatch(fetchBradStatus());
  }, [dispatch]);

  useBradPoll();

  return bradStatus == BradState.Active ? (
    <Alert severity="info" center inline={false}>
      <p>
        BRAD is currently <strong>Active</strong>. Turn off when a Clinician is staffed to CFE.
      </p>
    </Alert>
  ) : null;
};

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, keyBy, partition, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import css from './VisitOverview.module.css';
import Encounter from 'components/features/VisitOverview/Encounter';
import Case from 'components/features/VisitOverview/Case';
import CurrentVisits from 'components/features/VisitOverview/CurrentVisits';
import { Body } from 'components/ui/Typography';
import { EdenColors } from 'types/colors';
import { selectScreenersAssigned } from 'reducers/appointmentScreeners';
import { getPatientProfile, getPatientProfileId } from 'selectors/profiles';
import { Visit } from 'types/athena/visits';
import { useActivePatient, useAppDispatch, useParameterizedSelector } from 'lib/hooks';
import { fetchVisits, getPatientVisits } from 'reducers/visits';
import { AlertCircleIcon, Loader, RecurringIcon } from 'components/ui/svg';

export enum VisitKind {
  encounter = 'encounter',
  case = 'case',
}

const greenDottedLineSection = () => (
  <>
    <svg className={css.line} viewBox="0 0 12 100">
      <line x1="6" x2="6" y1="-99999" y2="99999" strokeWidth="2px"></line>
    </svg>
    <svg className={css.lineBottom} viewBox="0 0 12 100">
      <line x1="6" x2="6" y1="-99999" y2="99999" strokeWidth="2px"></line>
    </svg>
    <svg className={css.pastVisitGreenCircleIcon} viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="6" />
    </svg>
  </>
);

export const renderLoading = (loadingMessage: string) => (
  <div className={css.container}>
    <div className={css.loading}>
      <Loader height="20" width="20" color={EdenColors.SlateDarken20} />
      <Body className={css.loadingStatusMessage}>{loadingMessage}</Body>
    </div>
  </div>
);

export const renderLoadFailure = (errorMessage: string, reload: () => Promise<void>) => (
  <div className={css.container} data-testid="error">
    <div className={css.error}>
      <AlertCircleIcon height="16" width="16" color={EdenColors.Razz} />
      <Body className={css.loadingStatusMessage}>{errorMessage}</Body>
      <div className={css.errorReload} onClick={() => reload().catch()} data-testid="error-reload">
        <RecurringIcon height="20" width="20" color={EdenColors.Slate} />
      </div>
    </div>
  </div>
);

const PastVisits = (visits) => {
  const list = sortBy(visits, (visit) => {
    return -DateTime.fromISO(visit.sortDate).toMillis();
  }).map((visit, index) => {
    const id = visit.encounterId ? visit.encounterId : visit.patientCaseId;
    const type = visit.encounterId ? VisitKind.encounter : VisitKind.case;
    return (
      <div key={`visit-${index}`} className={css.visitContainerWrapper}>
        {greenDottedLineSection()}
        <div className={css.pastVisitContainer} key={visit.encounter}>
          {type === VisitKind.encounter ? (
            <Encounter encounter={visit} encounterId={id} />
          ) : (
            <Case case={visit} caseId={id} />
          )}
        </div>
      </div>
    );
  });
  return (
    <div>
      <div className={css.label}>Past Visits</div>
      <div className={css.container}>{list}</div>
    </div>
  );
};

const appointmentEndTime = (visit) => {
  // default to 1 hour for encounters, which have no duration
  const duration = visit.duration && !visit.encounterId ? visit.duration : 60;
  return DateTime.fromISO(visit.sortDate).plus({ minutes: duration });
};

const visitCompleted = (visit) => {
  const diff = appointmentEndTime(visit).diffNow().milliseconds;
  return diff < 0 || visit.patientStatus === 'Checked Out';
};

const transformVisits = (visits: Visit[]): Record<string, Visit> =>
  keyBy(
    visits,
    (visit) => visit.encounterId || visit.patientCaseId || visit.appointmentId || 'noId',
  ) || {};

const getVisitsStages = (visits: Record<string, Visit>, screenersAssigned: number[]) => {
  const partitionedVisitsByCompletion = partition(visits, visitCompleted);
  const pastVisits = partitionedVisitsByCompletion[0];

  const partitionedVisitsByCheckIn = partition(
    partitionedVisitsByCompletion[1],
    (v) => !v.encounterId,
  );

  const checkedInVisits = partitionedVisitsByCheckIn[1].filter(
    // remove duplicates from checkedInVisits if they are already in pastVisits
    (v) => !pastVisits.some((pv) => `${pv.appointmentId}` === `${v.appointmentId}`),
  );

  const upcomingVisits = partitionedVisitsByCheckIn[0].filter(
    // remove duplicates from upcomingVisits if they are already in checkedInVisits or pastVisits
    (v) =>
      !checkedInVisits.some((cv) => `${cv.appointmentId}` === `${v.appointmentId}`) &&
      !pastVisits.some((pv) => `${pv.appointmentId}` === `${v.appointmentId}`),
  );

  // upcomingVisits that have had screeners assigned should be in checked-in section
  const [checkedInUpcomingVisits, remainingUpcomingVisits] = partition(upcomingVisits, (visit) =>
    screenersAssigned.includes(Number(visit.appointmentId)),
  );

  return {
    upcomingVisits: remainingUpcomingVisits,
    checkedInVisits: [...checkedInUpcomingVisits, ...checkedInVisits],
    pastVisits,
  };
};

export const VisitOverview = () => {
  const { id: activePatientId } = useActivePatient();

  const patientProfileId = useParameterizedSelector(getPatientProfileId, activePatientId);
  const patientProfile = useParameterizedSelector(getPatientProfile, activePatientId);
  const visits = useParameterizedSelector(getPatientVisits, activePatientId);
  const screenersAssigned = useSelector(selectScreenersAssigned);
  const dispatch = useAppDispatch();

  const [isLoadingVisits, setIsLoadingVisits] = useState(true);

  const { upcomingVisits, checkedInVisits, pastVisits } = getVisitsStages(
    transformVisits(visits || []),
    screenersAssigned || [],
  );

  const refreshVisits = useCallback(async () => {
    if (patientProfileId) {
      setIsLoadingVisits(true);
      await dispatch(fetchVisits(activePatientId));
      setIsLoadingVisits(false);
    }
  }, [dispatch, activePatientId, patientProfileId]);

  useEffect(() => {
    refreshVisits().catch();
  }, [refreshVisits]);

  if (patientProfile.isError) {
    return (
      <div className={css.loadingStatusContainer}>
        {renderLoadFailure('Error loading visits and cases. Try again', refreshVisits)}
      </div>
    );
  } else if (isLoadingVisits) {
    return (
      <div className={css.loadingStatusContainer}>
        {renderLoading('Loading visits and cases...')}
      </div>
    );
  } else if (isEmpty(visits)) {
    return <div className={css.empty}>This patient has no visits on record at this time.</div>;
  } else {
    return (
      <div>
        {(upcomingVisits.length > 0 || checkedInVisits.length > 0) && (
          <CurrentVisits upcomingVisits={upcomingVisits} checkedInVisits={checkedInVisits} />
        )}
        {pastVisits.length > 0 && PastVisits(pastVisits)}
      </div>
    );
  }
};

export default VisitOverview;

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { AthenaProvider } from 'types/athena/athenaProvider';
import { getProviderGroupBySponsorId } from 'reducers/providerGroups';
import { isSuccessResponse } from 'types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';

const prefix = 'athenaProvider';
const fetchRejectionType = 'Unable to fetch providers.';

const athenaProviderAdapter = createEntityAdapter<AthenaProvider>({
  selectId: (athenaProvider) => athenaProvider.athenaProviderId,
});

export const fetchAthenaProviders = createAsyncThunk(
  `${prefix}/getAthenaProviders`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await GrdnApi.getAthenaProviders();
      if (isSuccessResponse(res)) {
        return res.data;
      } else {
        return rejectWithValue({ rejectionType: fetchRejectionType });
      }
    } catch (e) {
      return rejectWithValue({ rejectionType: fetchRejectionType });
    }
  },
);

export const athenaProviders = createSlice({
  name: prefix,
  initialState: athenaProviderAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAthenaProviders.fulfilled, (state, action) => {
      athenaProviderAdapter.upsertMany(state, action.payload);
    });
  },
});

export const athenaProvidersSelectors = athenaProviderAdapter.getSelectors<Store>(
  (state) => state.athenaProviders,
);

const getAthenaProviders = athenaProvidersSelectors.selectAll;

export const getAthenaProvidersForSponsorId = createSelector(
  getProviderGroupBySponsorId,
  getAthenaProviders,
  (providerGroup, athenaProviders) => {
    const providerGroupAthenaId = providerGroup ? providerGroup.athenaId : null;

    if (providerGroupAthenaId) {
      return athenaProviders.filter((provider) =>
        provider.providerGroupList?.includes(providerGroupAthenaId),
      );
    } else {
      return [];
    }
  },
);

export default athenaProviders.reducer;

import React, { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import css from 'components/features/TicklerOverview/EditTicklerModal.module.css';
import formCss from 'components/design-system/Form.module.css';
import { Label, SelectAccessible, TextArea } from 'components/design-system/Form';
import { getAthenaProvidersForSponsorId } from 'reducers/athenaProviders';
import { getMyProvider } from 'reducers/user';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';

interface NoteInputProps {
  label: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  value?: string;
}

export const NoteInput = ({ label, onChange, required = false, value }: NoteInputProps) => {
  const [noteCharCount, setNoteCharCount] = useState<number>(0);

  return (
    <>
      <Label text={label} required={required} htmlFor="tickler-note-input" />
      <TextArea
        id="tickler-note-input"
        data-testid="tickler-note-input"
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          onChange(e);
          setNoteCharCount(e.target.value.length);
        }}
        value={value}
        type="text"
        name="note"
        className={cx(formCss.field, css.note, css.wide)}
        maxLength={250}
      />
      <div className={css.charCounter}>{noteCharCount}/250 characters</div>
    </>
  );
};

interface ProviderSelectorProps {
  label: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  value?: string;
}

export const ProviderSelector = ({
  label,
  onChange,
  required = false,
  value,
}: ProviderSelectorProps) => {
  const myProvider = useSelector(getMyProvider);
  const myAthenaProviderId = myProvider?.athenaProviderId;

  const patient = useActivePatient();
  const { sponsorId: patientSponsorId } = patient;

  const athenaProviders = useParameterizedSelector(
    getAthenaProvidersForSponsorId,
    patientSponsorId,
  );

  const sortedAthenaProviders = athenaProviders?.sort((p1, p2) => {
    if (p1.athenaProviderId === myAthenaProviderId) {
      // Put logged in user on top if they have Athena provider ID
      return -1;
    } else if (p2.athenaProviderId === myAthenaProviderId) {
      return 1;
    } else {
      // Order by display name
      const a = p1.displayName || '';
      const b = p2.displayName || '';
      return a.localeCompare(b);
    }
  });

  return (
    <Label text={label} required={required}>
      <SelectAccessible value={value} onChange={onChange} data-testid="tickler-athena-provider">
        {sortedAthenaProviders &&
          sortedAthenaProviders.map((ap) => (
            <option
              key={`athenaProvider_${ap.athenaProviderId}`}
              value={ap.athenaProviderId.toString()}
              data-testid={`athena-provider-${ap.athenaProviderId}`}
            >
              {ap.displayName}
            </option>
          ))}
      </SelectAccessible>
    </Label>
  );
};

import { createSelector } from 'reselect';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { EdenScreenerResponsePayload } from '../types/grdn';
import { isSuccessResponse } from './../types/api';
import * as GrdnApi from 'lib/grdn';
import { Store } from 'types/redux';
import { ScreenerResponse } from 'types/tables/screeners';

const prefix = 'screener';
const fetchRejectionType = 'Failed to get screener responses.';

export const getEdenScreenerResponses = createAsyncThunk<
  EdenScreenerResponsePayload,
  string,
  { rejectValue: { rejectionType: string } }
>(`${prefix}/getScreeners`, async (patientId, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.getEdenScreenerResponse(patientId);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: fetchRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: fetchRejectionType });
  }
});

export const screenerResponsesAdapter = createEntityAdapter<ScreenerResponse>({});

export const edenScreenerResponses = createSlice({
  name: prefix,
  initialState: screenerResponsesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEdenScreenerResponses.fulfilled, (state, action) => {
      screenerResponsesAdapter.setAll(state, action.payload.screenerResponses);
    });
  },
});

export const screenerSelectors = screenerResponsesAdapter.getSelectors<Store>(
  (state) => state.edenScreenerResponses,
);

export const selectAllScreeners = createSelector(
  screenerSelectors.selectAll,
  (screeners) => screeners,
);

export default edenScreenerResponses.reducer;

import React from 'react';

import css from './Toggler.module.css';

import { EdenColors } from 'types/colors';

interface Props {
  isOpen: boolean;
  isShown: boolean;
  onToggle: () => void;
}

const Toggler = ({ isShown, isOpen, onToggle }: Props) => {
  if (!isShown) return null;

  return (
    <div className={css.toggler} onClick={onToggle}>
      {isOpen ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.41107 6.07757C9.73651 5.75214 10.2641 5.75214 10.5896 6.07757L15.5896 11.0776C15.915 11.403 15.915 11.9306 15.5896 12.2561C15.2641 12.5815 14.7365 12.5815 14.4111 12.2561L10.0003 7.84534L5.58958 12.2561C5.26414 12.5815 4.73651 12.5815 4.41107 12.2561C4.08563 11.9306 4.08563 11.403 4.41107 11.0776L9.41107 6.07757Z"
            fill={EdenColors.SlateDarken20}
          />
        </svg>
      ) : (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.41107 7.74408C4.73651 7.41864 5.26414 7.41864 5.58958 7.74408L10.0003 12.1548L14.4111 7.74408C14.7365 7.41864 15.2641 7.41864 15.5896 7.74408C15.915 8.06951 15.915 8.59715 15.5896 8.92259L10.5896 13.9226C10.2641 14.248 9.73651 14.248 9.41107 13.9226L4.41107 8.92259C4.08563 8.59715 4.08563 8.06951 4.41107 7.74408Z"
            fill={EdenColors.SlateDarken20}
          />
        </svg>
      )}
    </div>
  );
};

export default Toggler;

import {
  AdminMessage,
  FileMessage,
  GroupChannel,
  OpenChannel,
  Sender,
  UserMessage,
} from 'sendbird';
import { Message, TextPrimaryMessage } from 'types/tables/messages';

export interface LastMessage extends UserMessage {
  user: Sender;
}

export type AppId = string;
export type UserId = string;
export type SessionToken = string;
export type ChannelUrl = string;
export type SendbirdMessage = UserMessage | FileMessage | AdminMessage;
export type SendbirdChannel = OpenChannel | GroupChannel;

export interface ReadReceipt {
  sendbirdUserId: string;
  lastRead: number;
}

export interface TypingIndicator {
  sendbirdUserId: string;
  lastRead: number;
}

/* Sendbird does not export this type for some reason */
export enum ConnectionStateEnum {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface ConnectionState {
  connectionState: ConnectionStateEnum;
  sendbirdUserId: string;
}

export interface ChannelInterface {
  loadMoreMessages: () => Promise<Message[]>;
  sendTextMessage: (message: TextPrimaryMessage['data']) => Promise<Message>;
  markAsRead: () => void;
  startTyping: () => void;
  stopTyping: () => void;
  getReadReceipts: () => Promise<ReadReceipt[]>;
  hasMore: boolean;
  loaded: boolean;
}

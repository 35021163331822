import React, { ChangeEvent } from 'react';
import css from './Radio.module.css';
import { BodySmall } from 'components/design-system/Text';

interface RadioProps {
  checked: boolean;
  id: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: any;
  testID?: string;
}

export const Radio = ({ checked, id, label, onChange, value, testID }: RadioProps) => {
  return (
    <label htmlFor={id} className={css.radioLabel} data-testid={testID}>
      <input
        type="radio"
        className={css.radioInput}
        checked={checked}
        value={value}
        onChange={onChange}
        id={id}
      />
      <BodySmall className={css.radioLabelText}>{label}</BodySmall>
    </label>
  );
};

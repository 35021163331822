import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Store } from '../types/redux';
import { Macro } from 'types/tables/macros';
import * as GrdnApi from 'lib/grdn';
import { isSuccessResponse } from 'types/api';

const prefix = 'macro';
export const createRejectionType = 'Unable to create macro, please try again.';
export const editRejectionType = 'Unable to edit macro, please try again.';
export const deleteRejectionType = 'Unable to delete macro, please try again.';

export const getMacros = createAsyncThunk(`${prefix}/getMacros`, GrdnApi.macros);

export const createMacro = createAsyncThunk<
  Macro,
  { title: string; text: string },
  { rejectValue: { rejectionType: string } }
>(`${prefix}/createMacro`, async ({ title, text }, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.createMacro(title, text);
    if (isSuccessResponse(res)) {
      return res.data;
    } else {
      return rejectWithValue({ rejectionType: createRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: createRejectionType });
  }
});

export const editMacro = createAsyncThunk<
  { title: string; text: string },
  { id: string; title: string; text: string },
  { rejectValue: { rejectionType: string } }
>(`${prefix}/editMacro`, async ({ id, title, text }, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.editMacro(id, title, text);
    if (isSuccessResponse(res)) {
      return { title, text };
    } else {
      return rejectWithValue({ rejectionType: editRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: editRejectionType });
  }
});

export const deleteMacro = createAsyncThunk<
  any,
  { id: string },
  { rejectValue: { rejectionType: string } }
>(`${prefix}/deleteMacro`, async ({ id }, { rejectWithValue }) => {
  try {
    const res = await GrdnApi.deleteMacro(id);
    if (isSuccessResponse(res)) {
      return { res };
    } else {
      return rejectWithValue({ rejectionType: deleteRejectionType });
    }
  } catch (e) {
    return rejectWithValue({ rejectionType: deleteRejectionType });
  }
});

export const macrosAdapter = createEntityAdapter<Macro>({});

export const macros = createSlice({
  name: prefix,
  initialState: macrosAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMacros.fulfilled, (state, action) => {
      if (isSuccessResponse(action.payload)) {
        macrosAdapter.upsertMany(state, action.payload.data);
      }
    });
    builder.addCase(createMacro.fulfilled, (state, action) => {
      macrosAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(deleteMacro.fulfilled, (state, action) => {
      macrosAdapter.removeOne(state, action.meta.arg.id);
    });
  },
});

export const macroSelectors = macrosAdapter.getSelectors<Store>((state) => state.macros);

export default macros.reducer;

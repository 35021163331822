import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import css from './styles.module.css';
import { FormProps } from './index';
import { TimeZone } from 'types/tables/popups';
import { isSuccessResponse } from 'types/api';
import { RosterIngestionStatus, RosterStatus } from 'types/grdn';
import * as GrdnApi from 'lib/grdn';
import { FormHeader } from 'components/ui/Forms';
import { Folder, Spinner } from 'components/ui/svg';

const RosterUploadStatus: React.FC<FormProps> = ({ sponsor }: FormProps) => {
  const [statuses, setStatuses] = useState<RosterStatus[]>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ingestionStatusText: Record<RosterIngestionStatus, string> = {
    [RosterIngestionStatus.Uploaded]: 'Processing',
    [RosterIngestionStatus.IngestionSuccess]: 'Success',
    [RosterIngestionStatus.IngestionError]: 'Error',
  };

  const timeStampText = (isoTimeStamp) => {
    return DateTime.fromISO(isoTimeStamp, { zone: TimeZone.Eastern }).toLocaleString(
      DateTime.DATETIME_SHORT,
    );
  };

  useEffect(() => {
    setIsLoading(true);
    GrdnApi.getRosterStatuses(sponsor.id)
      .then((response) => {
        if (isSuccessResponse(response)) return setStatuses(response.data);
        return setErrorText(`getRosterStatuses error - status ${response.status}`);
      })
      .catch((e) =>
        setErrorText(`getRosterStatuses error: Grdn returned ${e.status} ${e.statusText}`),
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [sponsor.id]);

  return (
    <div data-testid={`roster-upload-status-${sponsor.id}`} className={css.wrapper}>
      <FormHeader formName={'Roster Upload Status'} icon={Folder} />
      {isLoading ? (
        <Spinner size={24} className={css.spinnerBox} />
      ) : errorText ? (
        <div className={css.error}>{errorText}</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Uploaded by</th>
              <th>Uploaded at</th>
              <th>Processed at (ET)</th>
              <th>Status</th>
              <th className={css.notesColumn}>Notes</th>
            </tr>
          </thead>
          <tbody>
            {statuses.map((rosterStatus, idx) => (
              <tr key={`roster-status-${idx}`}>
                <td data-testid={`roster-provider-display-name-${idx}`}>
                  {rosterStatus.providerDisplayName}
                </td>
                <td className={css.noLineBreak} data-testid={`roster-uploaded-at-${idx}`}>
                  {timeStampText(rosterStatus.uploadedAt)}
                </td>
                <td className={css.noLineBreak} data-testid={`roster-processed-at-${idx}`}>
                  {rosterStatus.processedAt ? timeStampText(rosterStatus.processedAt) : '---'}
                </td>
                <td data-testid={`roster-ingestion-status-${idx}`}>
                  {ingestionStatusText[rosterStatus.status]}
                </td>
                <td className={css.lineBreaks} data-testid={`roster-ingestion-errors-${idx}`}>
                  {rosterStatus.errors?.join('\n')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RosterUploadStatus;

import * as React from 'react';
import cx from 'classnames';
import css from './Typography.module.css';
import { ColorProps } from 'types/colors';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

export enum Size {
  Small = 'small',
  Default = '',
}

interface SizeProps {
  size?: Size;
}

const Base = (props: any) => {
  return <span {...props} className={cx(css.base, props.className)} />;
};

export const Label = (props: any) => {
  return <label {...props} className={cx(css.label, props.className)} />;
};

export const Display = (props: any) => {
  return <Base {...props} className={cx(css.display, props.className)} />;
};

export const Subhead = (props: any) => {
  return <Base {...props} className={cx(css.subhead, props.className)} />;
};

export const Header = (props: any) => (
  <div {...props} className={cx(css.header, props.className)} />
);

/* Deprecated name */
export const Header1 = (props: any) => {
  return <Base {...props} className={cx(css.header1, props.className)} />;
};

/* Deprecated name */
export const Header2 = (props: any) => {
  return <Base {...props} className={cx(css.header2, props.className)} />;
};

/* Deprecated name */
export const Header3 = (props: any) => {
  return <Base {...props} className={cx(css.header3, props.className)} />;
};

/* Deprecated name */
export const Body1 = (props: any) => {
  return <Base {...props} className={cx(css.body1, props.className)} />;
};

/* Deprecated name */
export const Body2 = (props: any) => {
  return <Base {...props} className={cx(css.body2, props.className)} />;
};

export const Disclaimer = (props: any) => {
  return <Base {...props} className={cx(css.disclaimer, props.className)} />;
};

export const ErrorMessage = (props: any) => {
  return <Base {...props} className={cx(css.errorMessage, props.className)} />;
};

export const ListHeaderSmall = (props: any) => {
  return <Base {...props} className={cx(css.listHeaderSmall, props.className)} />;
};

export const ListHeaderMedium = (props: any) => {
  return <Base {...props} className={cx(css.listHeaderMedium, props.className)} />;
};

export const ListSubhead = (props: any) => {
  return <Base {...props} className={cx(css.listSubhead, props.className)} />;
};

export const ListBody = (props: any) => {
  return <Base {...props} className={cx(css.listBody, props.className)} />;
};

export const ListBodySmall = (props: any) => {
  return <Base {...props} className={cx(css.listBodySmall, props.className)} />;
};

export const Eyebrow1 = (props: any) => {
  return <Base {...props} className={cx(css.eyebrow1, props.className)} />;
};
export const Description = (props: DivProps) => (
  <div {...props} className={cx(css.description, props.className)} />
);

export const Caption = ({ className, color, ...props }: DivProps & ColorProps) => (
  <div {...props} style={{ color }} className={cx(css.caption, className)} />
);

export const Body = (props: DivProps & SizeProps) => (
  <div {...props} className={cx(css.body, css[props.size || Size.Default], props.className)} />
);

export const BodySemiBold = (props: DivProps & SizeProps) => (
  <div {...props} className={cx(css.body, css.bodySemiBold)} />
);

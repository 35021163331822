import React from 'react';
import css from './ReactiveMenu.module.css';
import { Toggle, Burger } from 'components/ui/svg';

interface ReactiveMenuProps {
  isOpen: boolean;
  handleToggleChats: any;
  handleToggleSidebar: any;
  testId: string;
}

export const ReactiveMenu = ({
  isOpen,
  handleToggleChats,
  handleToggleSidebar,
  testId,
}: ReactiveMenuProps) => (
  <div data-testid={testId}>
    <button
      className={isOpen ? css.hamburgerContainerOpen : css.hamburgerContainer}
      onClick={handleToggleSidebar}
    >
      <Burger />
    </button>
    <button className={css.chatToggle} onClick={handleToggleChats}>
      <Toggle />
    </button>
  </div>
);

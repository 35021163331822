export interface AppointmentScreeners {
  appointmentId: number;
  screenerNames: string[];
  isAssigned?: boolean;
}

// Endpoint returns the canonical, version-agnostic names of screeners
// This maps them to a more readable string
export enum DisplayNames {
  'Eden Single Response Health Assessment' = 'SRHA (overall health check-in)',
  'PHQ-2/PHQ-9' = 'PHQ-2/PHQ-9 (depression questionnaire)',
  'GAD-7' = 'GAD-7 (anxiety questionnaire)',
}

import React from 'react';
import { EdenColors } from 'types/colors';

const AnnualPreventativeVisitIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="20" fill={EdenColors.Apple10} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8715 17.063H20.2441C20.0265 17.7822 19.6341 18.4439 19.0931 18.985C18.2297 19.8483 17.0588 20.3333 15.8379 20.3333C14.6169 20.3333 13.446 19.8483 12.5826 18.985C12.0416 18.4439 11.6492 17.7822 11.4316 17.063H10.8043C11.0376 17.944 11.5003 18.7562 12.1558 19.4118C13.1323 20.3883 14.4568 20.9369 15.8379 20.9369C17.2189 20.9369 18.5434 20.3883 19.5199 19.4118C20.1754 18.7562 20.6381 17.944 20.8715 17.063ZM22.3445 16.3952C22.3819 16.029 22.0799 15.7297 21.7117 15.7297H19.7748C19.4066 15.7297 19.115 16.0312 19.0404 16.3918C18.9121 17.0123 18.6051 17.5873 18.1503 18.0421C17.537 18.6554 16.7052 19 15.8379 19C14.9705 19 14.1387 18.6554 13.5255 18.0422C13.0706 17.5873 12.7636 17.0123 12.6353 16.3918C12.5607 16.0312 12.2691 15.7297 11.9009 15.7297H9.96401C9.59582 15.7297 9.29381 16.029 9.33127 16.3952C9.48365 17.8854 10.1443 19.2858 11.213 20.3546C12.4396 21.5812 14.1032 22.2702 15.8379 22.2702C17.5725 22.2702 19.2361 21.5812 20.4627 20.3546C21.5314 19.2858 22.1921 17.8854 22.3445 16.3952Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3875 22.8648V21.081H14.7208V22.8648C14.7208 23.4817 15.2209 23.9819 15.8379 23.9819C16.4549 23.9819 16.955 23.4817 16.955 22.8648V21.3358H18.2883V22.8648C18.2883 24.2181 17.1912 25.3152 15.8379 25.3152C14.4845 25.3152 13.3875 24.2181 13.3875 22.8648Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9317 20.6667C24.7895 20.6667 23.8635 21.5926 23.8635 22.7349V27.7349C23.8635 30.0904 21.954 32 19.5984 32C17.2428 32 15.3333 30.0904 15.3333 27.7349V24.5333H16.6666V27.7349C16.6666 29.3541 17.9792 30.6667 19.5984 30.6667C21.2176 30.6667 22.5302 29.3541 22.5302 27.7349V22.7349C22.5302 20.8563 24.0531 19.3333 25.9317 19.3333C27.8103 19.3333 29.3333 20.8563 29.3333 22.7349V25.1H27.9999V22.7349C27.9999 21.5926 27.0739 20.6667 25.9317 20.6667Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9188 26.2883C29.1676 26.2883 29.3693 26.0866 29.3693 25.8378C29.3693 25.5891 29.1676 25.3874 28.9188 25.3874C28.67 25.3874 28.4683 25.5891 28.4683 25.8378C28.4683 26.0866 28.67 26.2883 28.9188 26.2883ZM28.9188 27.6216C29.9039 27.6216 30.7026 26.823 30.7026 25.8378C30.7026 24.8527 29.9039 24.0541 28.9188 24.0541C27.9337 24.0541 27.135 24.8527 27.135 25.8378C27.135 26.823 27.9337 27.6216 28.9188 27.6216Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4146 9.85582C12.0464 9.85582 11.7479 10.1543 11.7479 10.5225V15.7297H10.4146V10.5225C10.4146 9.41792 11.31 8.52249 12.4146 8.52249H13.4596V9.85582H12.4146Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4595 9.04505C13.3799 9.04505 13.3153 9.10958 13.3153 9.18919C13.3153 9.2688 13.3799 9.33333 13.4595 9.33333C13.5391 9.33333 13.6036 9.2688 13.6036 9.18919C13.6036 9.10958 13.5391 9.04505 13.4595 9.04505ZM13.4595 10.3784C14.1162 10.3784 14.6487 9.84596 14.6487 9.18919C14.6487 8.53242 14.1162 8 13.4595 8C12.8027 8 12.2703 8.53242 12.2703 9.18919C12.2703 9.84596 12.8027 10.3784 13.4595 10.3784Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2611 9.85582C19.6293 9.85582 19.9277 10.1543 19.9277 10.5225V15.7297H21.2611V10.5225C21.2611 9.41792 20.3657 8.52249 19.2611 8.52249H18.2161V9.85582H19.2611Z"
        fill={EdenColors.Eden}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.216 9.04505C18.2957 9.04505 18.3602 9.10958 18.3602 9.18919C18.3602 9.2688 18.2957 9.33333 18.216 9.33333C18.1364 9.33333 18.0719 9.2688 18.0719 9.18919C18.0719 9.10958 18.1364 9.04505 18.216 9.04505ZM18.216 10.3784C17.5593 10.3784 17.0269 9.84596 17.0269 9.18919C17.0269 8.53242 17.5593 8 18.216 8C18.8728 8 19.4052 8.53242 19.4052 9.18919C19.4052 9.84596 18.8728 10.3784 18.216 10.3784Z"
        fill={EdenColors.Eden}
      />
    </svg>
  );
};

export default AnnualPreventativeVisitIcon;

import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Description, Header } from '../../ui/Typography';
import css from './RequestToScheduleVisitModal.module.css';
import { useActivePatient, useAppDispatch } from 'lib/hooks';
import { CalendarIcon, VideoIcon, XIcon } from 'components/ui/svg';
import { ButtonSizes, PrimaryButton } from 'components/ui/Buttons';
import { requestVideoVisit } from 'reducers/messages';
import { loadEnv } from 'lib/env';
interface RequestToScheduleVisitModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

interface SchedulingOptionProps {
  children: React.ReactNode;
  onClick: () => void;
  selected: boolean;
}

const SchedulingOption = ({ children, onClick, selected }: SchedulingOptionProps) => {
  return (
    <div
      onClick={onClick}
      className={selected ? css.schedulingOptionSelected : css.schedulingOption}
    >
      {children}
    </div>
  );
};

const RequestToScheduleVisitModal = ({ isOpen, onClose }: RequestToScheduleVisitModalProps) => {
  const dispatch = useAppDispatch();
  const videoVisitTypes = {
    New: {
      text: 'Schedule a New Patient Establishing Care Visit',
      key: 'New',
      reasonId: Number(loadEnv('REACT_APP_VIDEO_VISIT_REASON_ID_NEW')),
    },
    Established: {
      text: 'Schedule a Brief Patient Video Visit',
      key: 'Established',
      reasonId: Number(loadEnv('REACT_APP_VIDEO_VISIT_REASON_ID_ESTABLISHED')),
    },
  } as const;

  const [selectedAppType, setSelectedAppType] = useState<'Established' | 'New'>('Established');
  const selectedReasonId = videoVisitTypes[selectedAppType].reasonId;
  const { id } = useActivePatient();
  const sendAndClose = async () => {
    const res = await dispatch(
      requestVideoVisit({ patientId: id, appointmentReasonId: selectedReasonId }),
    );
    if (requestVideoVisit.rejected.match(res)) {
      toast.error('Encountered an error while requesting a video visit.');
    }
    onClose();
  };
  return (
    <div className={css.modal}>
      <Modal open={isOpen} size="tiny" onClose={onClose}>
        <Modal.Content>
          <div className={css.modalInner}>
            <div className={css.header}>
              <CalendarIcon className={css.icon} />
              <Header className={css.headerText}>Send Scheduling Link</Header>
              <XIcon className={css.close} onClick={onClose} />
            </div>
            <Description className={css.description}>
              Scheduling links allow patients one-time access to specific appointment booking flows.
              Links expire in 48 hours or when an appointment is booked.
            </Description>
            <div className={css.secondaryHeader}>Available Scheduling Links</div>
            {Object.values(videoVisitTypes).map((data) => {
              return (
                <SchedulingOption
                  selected={data.key === selectedAppType}
                  key={data.key}
                  onClick={() => setSelectedAppType(data.key)}
                >
                  <VideoIcon /> {data.text}
                </SchedulingOption>
              );
            })}
            <div className={css.buttonRow}>
              <PrimaryButton
                data-testid="RequestToScheduleVisitButton"
                size={ButtonSizes.large}
                onClick={sendAndClose}
              >
                Send Link
              </PrimaryButton>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default RequestToScheduleVisitModal;

import { BaseRecord, PrimaryKey } from './base';

export enum PopupState {
  draft = 'draft',
  active = 'active',
}

export enum PopupTypes {
  active = 'Active',
  draft = 'Draft',
  past = 'Past',
}

export enum TimeZone {
  Eastern = 'America/New_York',
  Central = 'America/Chicago',
  Mountain = 'America/Denver',
  Pacific = 'America/Los_Angeles',
  Phoenix = 'America/Phoenix',
}

export enum EligibleMembers {
  EmployeesOnly = 'employees_only',
  EmployeesAndDependents = 'employees_and_dependents',
}

export interface PopupDate {
  startTime: string;
  endTime: string;
}

export interface Popup extends BaseRecord {
  id: PrimaryKey;
  sponsorId: string;
  date: string;
  endDate?: string;
  athenaReasonId: number;
  athenaSchedulingProviderId: number;
  popupState: PopupState;
  instructions?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  startTime?: string;
  endTime?: string;
  name: string;
  timeZoneId: TimeZone;
  eligibleMembers: EligibleMembers;
  popupDates?: PopupDate[];
  divisionIds?: (string | null)[];
}

export type Popups = {
  popups: Popup[];
};

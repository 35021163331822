import { BaseRecord } from 'types/tables/base';

export interface ExternalAddon extends BaseRecord {
  categoryId: string;
  benefitOverview: string;
  tags: string[];
  bulletList: string[];
  state: ExternalAddonState;
  companyName: string;
  enrollmentInstructions: string;
  enrollmentUrl: string;
  notes?: string;
}

export enum ExternalAddonState {
  disabled = 'disabled',
  enabled = 'enabled',
  archived = 'archived',
}

export interface AddonCategory extends BaseRecord {
  name: string;
  imageUrl: string;
}

export interface ExternalAddonWithCategory extends ExternalAddon {
  category: AddonCategory;
}

import * as React from 'react';
import cx from 'classnames';
import css from './Lists.module.css';
import { ListSubhead } from './Typography';
import { RightArrow } from 'components/ui/svg';

export const List1 = (props: any) => {
  return <ul {...props} className={cx(css.list1, props.className)} />;
};

export const List2 = (props: any) => {
  return <ul {...props} className={cx(css.list2, props.className)} />;
};

export const ListItem1 = (props: any) => {
  const { ...liProps } = props;

  return (
    <li {...liProps} className={cx(css.listItem1, props.active && css.active, props.className)}>
      <span>{props.children}</span>
    </li>
  );
};

export const ListItem2 = (props: any) => {
  return (
    <li {...props} className={cx(css.listItem2, props.active && css.active, props.className)}>
      {props.children}
      {props.details && <ListSubhead className={css.details}>{props.details}</ListSubhead>}
    </li>
  );
};

export const ListItem3 = (props: any) => {
  const { disabled, ...liProps } = props;
  const passableProps = { ...liProps, active: undefined };

  return (
    <li
      {...passableProps}
      className={cx(
        css.listItem3,
        props.active && css.active,
        disabled && css.disabled,
        props.className,
      )}
    >
      <span>{props.children}</span>
      <RightArrow className={css.arrowContainer} />
    </li>
  );
};

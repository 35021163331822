import React, { ChangeEvent } from 'react';

import NumberFormat from 'react-number-format';
import { DateTime } from 'luxon';

import css from './DatePickerWithDay.module.css';

import { computeDaysFrom } from 'lib/util';
import { DayPicker, Field, formatDateForDayPicker } from 'components/design-system/Form';

interface Props {
  value: string;
  onChange: (string) => void;
  testId: string;
  error?: boolean;
  futureOnly?: boolean;
}

const DatePickerWithDay = ({
  value,
  onChange,
  testId,
  error = false,
  futureOnly = false,
}: Props) => {
  const daysFromValue = computeDaysFrom(value);

  return (
    <>
      <DayPicker
        data-testid={`${testId}`}
        className={css.datePicker}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const date = e.target.value;
          onChange(date);
        }}
        {...(futureOnly ? { min: formatDateForDayPicker(DateTime.now().toISO()) } : {})}
        error={error}
      />

      <span>in</span>

      <NumberFormat
        customInput={Field}
        className={css.daysInput}
        data-testid={`${testId}-days`}
        value={daysFromValue !== 0 ? daysFromValue : ''}
        placeholder={`${daysFromValue}`}
        onValueChange={({ floatValue }) => {
          const date = DateTime.now().plus({ days: floatValue }).toFormat('yyyy-MM-dd');
          onChange(date);
        }}
        type="text"
        min={0}
        error={error}
      />

      <span>days</span>
    </>
  );
};

export default DatePickerWithDay;

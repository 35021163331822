import React from 'react';
import type { IconStylingProps } from 'components/design-system/Icon/Icon';

const AlertCircle = ({ className }: IconStylingProps) => (
  <svg
    className={className}
    aria-hidden
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15.333A7.312 7.312 0 0 1 .665 8 7.312 7.312 0 0 1 7.999.667 7.312 7.312 0 0 1 15.333 8a7.312 7.312 0 0 1-7.334 7.333ZM8 2C4.665 2 2 4.667 2 8s2.666 6 6 6c3.333 0 6-2.667 6-6s-2.667-6-6-6Z"
      fill="currentColor"
    />
    <path
      d="M8 8.667c-.4 0-.667-.267-.667-.667V5.333c0-.4.266-.666.666-.666s.667.266.667.666V8c0 .4-.267.667-.667.667ZM8 11.333a.605.605 0 0 1-.467-.2.605.605 0 0 1-.2-.466c0-.067 0-.2.066-.267.067-.067.067-.133.134-.2.2-.2.466-.267.733-.133.067 0 .067 0 .133.066 0 0 .067.067.134.067.066.067.133.133.133.2v.267c0 .066 0 .2-.067.266-.066.067-.066.134-.133.2a.605.605 0 0 1-.467.2Z"
      fill="currentColor"
    />
  </svg>
);

export default AlertCircle;

import { DateTime } from 'luxon';
import { CustomTimeFrameFormData, TimeFrameOption } from 'components/features/VisitOverview/types';

export const getTimeFrame = (
  selectedTimeFrame: TimeFrameOption,
  formData?: CustomTimeFrameFormData,
) => {
  if (selectedTimeFrame === TimeFrameOption.CustomTimeFrame && formData) {
    return {
      convoStart: DateTime.fromISO(`${formData.startDate}T${formData.startTime}`).toISO(),
      convoEnd: DateTime.fromISO(`${formData.endDate}T${formData.endTime}`).toISO(),
    };
  } else if (selectedTimeFrame === TimeFrameOption.LastHour) {
    const now = DateTime.now();
    return {
      convoStart: now.minus({ hours: 1 }).toISO(),
      convoEnd: now.toISO(),
    };
  } else if (selectedTimeFrame === TimeFrameOption.Last24Hours) {
    const now = DateTime.now();
    return {
      convoStart: now.minus({ hours: 24 }).toISO(),
      convoEnd: now.toISO(),
    };
  } else {
    const now = DateTime.now();
    return {
      convoStart: now.minus({ hours: 3 }).toISO(),
      convoEnd: now.toISO(),
    };
  }
};

export const isInFuture = (date: string) => {
  return DateTime.fromISO(date).startOf('day') > DateTime.now().startOf('day');
};

export type SentryEnv = 'Production' | 'Staging' | 'Development';

// The type of breadcrumb. By default, all breadcrumbs are recorded as default,
// which makes them appear as a Debug entry. Note `navigation` and `http` require
// specific keys in the breadcrumb data key.
// Types from https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
export enum BreadcrumbType {
  Debug = 'debug',
  Error = 'error',
  Navigation = 'navigation',
  Http = 'http',
  Info = 'info',
  Query = 'query',
  Transaction = 'transaction',
  UI = 'ui',
  User = 'user',
}

// A dotted string indicating what the crumb is or from where it comes.
export enum BreadcrumbCategory {
  GrdnApi = 'GrdnAPI',
  SendbirdReconnectFailed = 'useSendbirdConnection.onReconnectFailed',
  SendbirdReconnectStarted = 'useSendbirdConnection.onReconnectStarted',
  SendbirdReconnectSucceeded = 'useSendbirdConnection.onReconnectSucceeded',
  SendbirdConnect = 'useSendbirdConnection.connect',
  SendbirdDisconnect = 'useSendbirdConnection.disconnect',
  Navigation = 'Navigation',
  GoogleAuth = 'GoogleAuth',
  ChromeExtension = 'ChromeExtension',
  ReduxAction = 'ReduxAction',
}

/* istanbul ignore file */

import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Event } from '@sentry/types';
import _ from 'lodash';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { Profile } from 'types/tables/user';
import { getRelease } from 'lib/env';
import { BreadcrumbCategory, BreadcrumbType, SentryEnv } from 'types/sentry';

export type EventProcessor = (event: Event) => Event;

let sentryInitialized = false;

export const getSentryEnvironment = (): SentryEnv => {
  return (process.env['REACT_APP_SENTRY_ENVIRONMENT'] as SentryEnv) ?? 'Development';
};

export const scopeUser = (profile: Profile) => {
  const { email, name, username } = profile;
  Sentry.getCurrentScope().setUser({ email, name, username });
};

export const setupSentry = ({
  sentryEnv,
  extraEventProcessors = [],
}: {
  sentryEnv: SentryEnv;
  extraEventProcessors?: EventProcessor[];
}) => {
  Sentry.init({
    dsn: process.env['REACT_APP_SENTRY_DSN'],
    environment: sentryEnv,
    release: getRelease(),
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
      Sentry.browserProfilingIntegration(),
    ],

    // Tracing
    tracesSampleRate: sentryEnv === 'Production' ? 0.5 : 1.0,
    tracePropagationTargets: ['localhost', /^.+edenhealth\.com/],

    // Session replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,

    // Profiling
    profilesSampleRate: sentryEnv === 'Production' ? 0.3 : 1.0,
  });

  Sentry.getCurrentScope().addEventProcessor(downgradeNetworkFailures);
  extraEventProcessors.forEach((processor) => {
    Sentry.getCurrentScope().addEventProcessor(processor);
  });

  sentryInitialized = true;
  return Sentry;
};

const downgradeNetworkFailures = (event: Event): Event => {
  const exceptions = event?.exception?.values;
  if (_.isEmpty(exceptions)) {
    return event;
  }

  // Downgrade "Network request failed" errors to warn.
  const isNetworkError = _.some(exceptions, (e) => {
    const errorMessage = e.value;
    return errorMessage && errorMessage.match(/^TypeError: Network request failed/);
  });
  if (isNetworkError) {
    event.level = 'warning';
  }

  return event;
};

export const captureException = (error: Error, extra?: Record<string, any>) => {
  // wrapper for Sentry.CaptureException to add extra context to the error
  // logs to console if sentry is not initialized, and not in tests
  if (!sentryInitialized && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error(error, extra);
    return;
  }

  if (extra) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      Sentry.captureException(error);
    });
  } else {
    Sentry.captureException(error);
  }
};

export const sentryMiddleware = () => (next) => (action) => {
  // custom Redux Middleware to log set breadcrumbs for actions and capture errors in an action
  const breadcrumb: {
    message: string;
    type: BreadcrumbType;
    category: BreadcrumbCategory;
    data?: Record<string, any>;
  } = {
    message: action.type,
    category: BreadcrumbCategory.ReduxAction,
    type: BreadcrumbType.Debug,
  };
  const { payload } = action;
  if (payload) {
    breadcrumb.data = { payload };
  }
  captureBreadcrumb(breadcrumb);
  try {
    return next(action);
  } catch (e) {
    captureException(e, { extra: action });
  }
};

export const captureBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  if (!sentryInitialized && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.log(
      `[SENTRY|BREADCRUMB] [${_.toUpper(breadcrumb.level) || 'ERROR'}] ${breadcrumb.message}`,
      { breadcrumb },
    );
  }
  Sentry.addBreadcrumb(breadcrumb);
};

export const captureGrdnErrorBreadcrumb = (data: Record<string, any>, message: string) =>
  captureBreadcrumb({
    level: 'error',
    message,
    category: BreadcrumbCategory.GrdnApi,
    type: BreadcrumbType.Http,
    data,
  });

import React, { useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import css from 'components/features/VisitOverview/GenerateAVS.module.css';
import Dropdown from 'components/ui/Dropdown';
import { ListItem1 } from 'components/ui/Lists';
import { Button, ButtonSizes, ButtonType, PrimaryButton } from 'components/ui/Buttons';
import { ChevronDownIcon, ChevronUpIcon, LoaderAnimated } from 'components/ui/svg';
import { useActivePatient, useAppDispatch } from 'lib/hooks';
import { generateAndSaveAVS, updateVisitWithAfterVisitSummary } from 'reducers/visits';
import { CustomTimeFrame } from 'components/features/VisitOverview/CustomTimeFrame';
import { CustomTimeFrameFormData, TimeFrameOption } from 'components/features/VisitOverview/types';
import { getTimeFrame } from 'components/features/VisitOverview/utils';

interface Props {
  encounterId: string;
}

const GenerateAVS = ({ encounterId }: Props) => {
  const dispatch = useAppDispatch();
  const activePatient = useActivePatient();
  const { id: patientId } = activePatient;

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrameOption>(
    TimeFrameOption.Last3Hours,
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onSubmit = async (formData?: CustomTimeFrameFormData) => {
    if (isGenerating) return;
    setIsGenerating(true);

    const timeFrame = getTimeFrame(selectedTimeFrame, formData);
    const response = await dispatch(
      generateAndSaveAVS({
        patientId,
        encounterId,
        startTime: timeFrame.convoStart,
        endTime: timeFrame.convoEnd,
      }),
    );
    if (generateAndSaveAVS.fulfilled.match(response)) {
      dispatch(
        updateVisitWithAfterVisitSummary(patientId, encounterId, {
          afterVisitSummary: response.payload,
        }),
      );
      toast.success(`AVS successfully generated and saved.`);
    } else {
      toast.error(
        `Unable to generate AVS. Please try again, and if the problem persists please report the issue.`,
      );
    }
    setIsDropdownOpen(false);
    setIsGenerating(false);
  };

  const handleSelectedOption = (option: TimeFrameOption) => {
    setSelectedTimeFrame(option);
    toggleDropdown();
  };

  const TimeFrameRow = ({ option, testId }: { option: TimeFrameOption; testId: string }) => {
    return (
      <ListItem1
        onClick={() => handleSelectedOption(option)}
        className={css.timeFrameOption}
        data-testid={testId}
      >
        <span>{option}</span>
      </ListItem1>
    );
  };

  return (
    <div className={css.generateAVSContainer}>
      <div className={css.header}>Generate an AVS</div>
      <div className={css.timeFrameHeader}>Chat time frame:</div>
      <div className={css.timeFrameContainer}>
        <div>
          <Button
            buttonType={ButtonType.transparent}
            size={ButtonSizes.small}
            active={isDropdownOpen}
            disabled={isGenerating}
            // TODO: Fix types
            // eslint-disable-next-line
            // @ts-ignore
            content={<span>{selectedTimeFrame}</span>}
            icon={isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={toggleDropdown}
            className={css.timeFrameButton}
            data-testid="TimeFrameButton"
          />
          <Dropdown className={css.dropdown} isOpen={isDropdownOpen} onClose={toggleDropdown}>
            {Object.values(TimeFrameOption).map((option) => {
              const key = `TimeFrameOption-${_.camelCase(option)}`;
              return <TimeFrameRow key={key} option={option} testId={key} />;
            })}
          </Dropdown>
        </div>
        {selectedTimeFrame !== TimeFrameOption.CustomTimeFrame && (
          <PrimaryButton
            size={ButtonSizes.large}
            onClick={() => onSubmit()}
            content={isGenerating ? 'Generating' : 'Generate'}
            icon={
              isGenerating &&
              LoaderAnimated({ className: css.svgCircleSpinner, width: 20, height: 20 })
            }
            className={`${css.generateButton} ${isGenerating && css.buttonDisabled}`}
          />
        )}
      </div>
      {selectedTimeFrame === TimeFrameOption.CustomTimeFrame && (
        <CustomTimeFrame isGenerating={isGenerating} onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default GenerateAVS;

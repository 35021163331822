import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import css from './Patient.module.css';
import PatientHeader from 'components/features/PatientHeader/PatientHeader';
import InfoPane from 'components/features/InfoPane/InfoPane';
import { RoomType } from 'types/sendbird/chat';
import { PatientLoadState } from 'types/tables/patients';
import InternalChat from 'scenes/Main/Patient/InternalChat';
import PatientChat from 'scenes/Main/Patient/PatientChat';
import { SendbirdConnectionContext } from 'lib/sendbird';
import { ConnectionStateEnum } from 'types/sendbird';
import { AppContext } from 'lib/hooks';
import { fetchHeadshot, fetchProfile } from 'reducers/profiles';
import { fetchVisits } from 'reducers/visits';
import { captureException } from 'lib/sentry';

interface MessagesProps {
  loadState: PatientLoadState;
}

export default function Patient(props: MessagesProps) {
  const dispatch = useDispatch();
  const { mobileChatRoom, activePatientId } = useContext(AppContext);
  const { connectionState } = useContext(SendbirdConnectionContext);

  useEffect(() => {
    const loadPatientProfile = async () => {
      if (activePatientId) {
        await dispatch(fetchProfile(activePatientId));
        await Promise.all([
          dispatch(fetchVisits(activePatientId)),
          dispatch(fetchHeadshot(activePatientId)),
        ]);
      }
    };
    loadPatientProfile().catch(captureException);
  }, [dispatch, activePatientId]);

  if (connectionState !== ConnectionStateEnum.OPEN) {
    return (
      <Dimmer.Dimmable className={css.container}>
        <Dimmer active>
          <Loader>Connecting to Chat...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }

  const loading = [PatientLoadState.Switching, PatientLoadState.Loading].includes(props.loadState);
  return (
    <Dimmer.Dimmable className={css.container}>
      <Dimmer active={loading}>
        <Loader>Loading patient...</Loader>
      </Dimmer>
      <div className={css.chats}>
        <div className={mobileChatRoom === RoomType.Patient ? css.column : css.columnClosedMobile}>
          <div className={css.mainChatContainer}>
            <div className={css.header}>
              <PatientHeader />
            </div>
            <div className={css.chatHolder}>
              <PatientChat />
            </div>
          </div>
        </div>
        <div className={mobileChatRoom === RoomType.Provider ? css.column : css.columnClosedMobile}>
          <div className={css.container}>
            <Segment className={css.segContainer} vertical>
              <InfoPane />
            </Segment>
            <Segment className={css.segContainer} vertical>
              <InternalChat />
            </Segment>
          </div>
        </div>
      </div>
    </Dimmer.Dimmable>
  );
}

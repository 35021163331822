import React from 'react';

import cx from 'classnames';
import _ from 'lodash';

import type { TaggedStatus } from '../CarePlanTypes';

import css from './StatusTag.module.css';

interface Props {
  status: TaggedStatus;
}

const StatusTag = ({ status }: Props) => {
  return !!status && status !== 'active' ? (
    <div className={cx(css.statusTag, css[_.camelCase(status)])}>{status}</div>
  ) : null;
};

export default StatusTag;

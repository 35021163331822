import React, { useEffect, useState } from 'react';
import { Comment } from 'semantic-ui-react';
import axios from 'axios';
import css from './ChatImage.module.css';
import * as GrdnApi from 'lib/grdn';
import { useActivePatient } from 'lib/hooks';
import { isSuccessResponse } from 'types/api';

interface ChatImageProps {
  imageId: string;
}

const ChatImage = (props: ChatImageProps) => {
  const { id: activePatientId } = useActivePatient();
  const [image, setImage] = useState('');
  const imageRef = React.createRef<HTMLImageElement>();

  const { imageId } = props;

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    activePatientId &&
      GrdnApi.image(activePatientId, imageId, source.token)
        .then((res) => {
          if (isSuccessResponse(res)) {
            setImage('data:image/jpeg;base64, ' + res.data.image);
          }
          return;
        })
        .catch();

    return () => {
      source.cancel();
    };
  }, [activePatientId, imageId]);

  const handleClick = () => {
    setTimeout(() => {
      imageRef.current?.classList.toggle(css.expand);
      imageRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'end',
      });
    }, 0);
  };

  return (
    <Comment.Text className={css.container}>
      <button onClick={handleClick} className={css.btn}>
        <img ref={imageRef} src={image} alt="" className={css.img} />
      </button>
    </Comment.Text>
  );
};

export default ChatImage;

import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { AssignDropdown } from './AssignDropdown';
import css from './PatientHeader.module.css';
import { ChartAlert } from 'components/features/PatientPills/ChartAlert/ChartAlert';
import { AppLanguageLabel } from 'components/features/PatientPills/AppLanguage/AppLanguageLabel';
import { Button, ButtonSizes, ButtonType } from 'components/ui/Buttons';
import { ProfilePictureGroup } from 'components/ui/Pills';
import { dobToAge, formatPhone, formatPreferredFullNameFor, sexLabel } from 'lib/util';
import { DownArrow, EmailIcon, HomeIcon, PhoneIcon, UpArrow } from 'components/ui/svg';
import { useActivePatient, useParameterizedSelector } from 'lib/hooks';
import { getPatientProfile, getPatientTimeZone } from 'selectors/profiles';
import { getPatientAssignedProviders } from 'reducers/providers';
import { profileIsError } from 'types/tables/profiles';
import { HoverableText } from 'components/design-system/HoverableText';
import { BackToWorkLabel } from 'components/features/PatientPills/PatientPills';
import { TimeZoneLabel } from 'components/features/PatientPills/TimeZone/TimeZoneLabel';
import { getPatientChart } from 'reducers/chart';

const InfoPane = () => {
  const [showExtraDetails, setShowExtraDetails] = useState(false);
  const [showAssign, setShowAssign] = useState(false);

  const patient = useActivePatient();
  const { id: activePatientId, mrn, secondaryMrns } = patient;

  const assignedProvidersWithDetails = useParameterizedSelector(
    getPatientAssignedProviders,
    activePatientId,
  );
  const profile = useParameterizedSelector(getPatientProfile, activePatientId);
  const patientTimeZone = useParameterizedSelector(getPatientTimeZone, activePatientId);
  const chart = useParameterizedSelector(getPatientChart, activePatientId);
  const chartAlert = chart?.Alert;

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: { value: string },
  ) => {
    setSearchTerm(value);
  };

  const toggleExpandButton = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setShowExtraDetails((showExtraDetails) => !showExtraDetails);
  };

  const toggleAssignDropDown = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setShowAssign((showAssign) => !showAssign);
    setSearchTerm('');
  };

  const renderExpandButton = () => (
    <div className={css.expandArrow} onClick={toggleExpandButton}>
      {showExtraDetails ? <UpArrow /> : <DownArrow />}
    </div>
  );

  const renderAssign = () => (
    <div className={css.assign}>
      <Button
        buttonType={ButtonType.transparent}
        size={ButtonSizes.small}
        // TODO: Fix type
        // eslint-disable-next-line
        // @ts-ignore
        content={
          assignedProvidersWithDetails.length > 0 ? (
            <ProfilePictureGroup
              size="normal"
              providers={assignedProvidersWithDetails}
              headsToShowWhenCompressed={3}
            />
          ) : (
            'Assign'
          )
        }
        onClick={toggleAssignDropDown}
        icon={showAssign ? <UpArrow /> : <DownArrow />}
        className={css.assignButton}
        active={showAssign}
      />
      <AssignDropdown
        isOpen={showAssign}
        onClose={toggleAssignDropDown}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        showRoutingSection
      />
    </div>
  );

  const renderExtraDetails = () => {
    let email = '',
      mobilePhone = '',
      addressLine = '',
      zip = '',
      addrState = '',
      city = '';
    if (!profileIsError(profile)) {
      email = profile.email ?? '';
      mobilePhone = formatPhone(profile.mobilePhone);
      addressLine = `${profile.address1 ?? ''} ${profile.address2 ?? ''}`;
      zip = profile.zip ?? '';
      const rawCity = profile.city ?? '';
      addrState = profile.state ?? '';
      city = rawCity && rawCity.substr(0, 1).concat(rawCity.substr(1).toLowerCase());
    }
    return (
      <div className={css.profileWrapper}>
        <div className={css.infoContainer}>
          <div className={css.patientDetails}>
            <div className={css.column}>
              <div className={css.row}>
                <PhoneIcon /> <HoverableText text={mobilePhone} />
              </div>
              <div className={css.row}>
                <EmailIcon /> <HoverableText text={email} />
              </div>
            </div>
            {city && (
              <div className={css.column}>
                <div className={css.row}>
                  <HomeIcon />
                  <HoverableText text={`${addressLine}, ${city}, ${addrState}, ${zip}`} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  let name = '',
    pronouns = '',
    age = '',
    employerName = '',
    sex = '',
    dob = '',
    img = '';
  if (!profileIsError(profile)) {
    name = formatPreferredFullNameFor(profile);
    pronouns = profile.pronouns ?? '';
    dob = profile.dob ?? '';
    age = dobToAge(DateTime.fromFormat(dob, 'MM/dd/yyyy'));
    const sexStr = sexLabel(profile?.sex);
    employerName = profile.employerName ?? '';
    sex = sexStr === 'Female' ? 'F' : sexStr === 'Male' ? 'M' : 'I';
    img = profile?.img ?? '';
  }

  return (
    <div className={css.container} onClick={toggleExpandButton} data-testid="PatientHeader">
      <div className={css.infoHeader}>
        <div className={css.greyspaceGroup}>
          {renderExpandButton()}
          <div className={css.imgWrapper}>
            {img ? <img className={css.imgStyle} src={img} alt="patient headshot" /> : undefined}
          </div>
          <div className={css.flexContainer}>
            <div className={css.assignButtonContainer}>
              <div className={css.patientSummary}>
                <div className={css.patientName}>{name}</div>
                <div className={css.headerPills}>
                  <TimeZoneLabel patientTimeZone={patientTimeZone} />
                  <ChartAlert chartAlert={chartAlert} />
                </div>
                <div className={css.summary}>
                  {pronouns && <span>{pronouns}</span>}
                  <span className={pronouns && css.ageSexContainer}>
                    {age} {sex}
                  </span>
                  <span>
                    <HoverableText text={dob?.replace('///g', '-')} />
                  </span>
                  <span>
                    <HoverableText text={employerName ?? ''} />
                  </span>
                  <HoverableText text={'#' + mrn} copy={`${mrn}`} />
                  {secondaryMrns &&
                    secondaryMrns.map((secondaryMrn) => (
                      <HoverableText
                        text={'#' + secondaryMrn}
                        copy={`${secondaryMrn}`}
                        key={`${secondaryMrn}`}
                      />
                    ))}
                </div>
              </div>
              {renderAssign()}
            </div>
          </div>
        </div>
      </div>

      {!profileIsError(profile) && (
        <div className={css.additionalInfoContainer}>
          <BackToWorkLabel profile={profile} />
          <AppLanguageLabel profile={profile} />
        </div>
      )}
      {showExtraDetails && renderExtraDetails()}
    </div>
  );
};

export default InfoPane;

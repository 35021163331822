import React, { useState } from 'react';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import css from './Visit.module.css';
import { Button, ButtonSizes } from 'components/ui/Buttons';
import { Caption, ListBody, ListHeaderMedium } from 'components/ui/Typography';
import { EdenColors } from 'types/colors';
import * as GrdnApi from 'lib/grdn';
import { Visit } from 'types/athena/visits';
import { useActivePatient } from 'lib/hooks';
import VisitInfoBar from 'components/features/VisitOverview/VisitInfoBar';
import type { DropdownState } from 'components/features/VisitOverview/VisitInfoBar';

interface CaseProps {
  case: Visit;
  caseId: string;
}

export enum CaseStatus {
  closed = 'CLOSED',
  open = 'OPEN',
  review = 'REVIEW',
}

export default function Case(props: CaseProps) {
  const { id: patientId } = useActivePatient();

  const status = props.case.status;
  const subject = props.case.subject;
  const note = props.case.internalNote;
  const subclass = get(props, 'case.documentSubclass', '').replace('PATIENTCASE_', '');
  const caseType = subclass.charAt(0) + subclass.slice(1).toLowerCase();
  const name = props.case.providerName;
  const date = props.case.lastModifiedDate
    ? DateTime.fromFormat(props.case.lastModifiedDate, 'MM/dd/yyyy')
    : DateTime.local();
  const isThisYear = date.hasSame(DateTime.local(), 'years');
  const formattedDate = isThisYear ? date.toFormat('MMM d') : date.toFormat('MMM d, yyyy');

  const [isOpen, setIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const toggle = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const requestFeedback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // stops the case visit panel from toggling
    e.stopPropagation();
    setIsRequesting(true);
    GrdnApi.requestCaseFeedback(patientId, props.caseId)
      .then((resp) => {
        setIsRequesting(false);
        if (resp.status === 200) {
          toast.success('Successfully requested case feedback!');
        } else {
          toast.warn('Already requested feedback for this case.');
        }
        return;
      })
      .catch(() => {
        toast.error('Failed to request case feedback.');
        setIsRequesting(false);
      });
  };

  const renderDetails = () => {
    return (
      <div className={css.ap}>
        <div className={css.diagnosisDescription}>{subject}</div>
        <div className={css.note}>{note}</div>
        <Button
          disabled={isRequesting}
          hidden={status !== CaseStatus.closed}
          size={ButtonSizes.small}
          onClick={requestFeedback}
          data-testid="RequestFeedbackButton"
        >
          <Caption color={EdenColors.White}>Request Feedback</Caption>
        </Button>
      </div>
    );
  };

  const renderDropdownState = (): DropdownState => {
    if (isOpen) {
      return 'open';
    } else {
      return 'closed';
    }
  };

  return (
    <div className={css.clickable} onClick={toggle} data-testid="CaseCard">
      <div className={css.basicDetails}>
        <div className={css.headerContainer}>
          <ListHeaderMedium className={css.header}>{caseType}</ListHeaderMedium>
          <div className={css.subDetails}>
            <ListBody>{formattedDate}</ListBody>
            <ListBody className={css.separator}>•</ListBody>
            <ListBody>{name}</ListBody>
          </div>
        </div>
        <div className={css.leftWrapper}>
          <VisitInfoBar dropdownState={renderDropdownState()} />
        </div>
      </div>
      {isOpen && renderDetails()}
    </div>
  );
}

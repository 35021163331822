import { BaseRecord } from 'types/tables/base';
import { Patient } from 'types/tables/patients';

export enum SectionType {
  alert = 'Alert',
  medication = 'Medications',
  allergy = 'Allergies',
  problem = 'Problems',
}

export interface SectionItem {
  name: string;
  active: boolean;
  startDate: string; // date
  endDate: string | null; // date or null
}

interface Alert {
  noteText: string;
}

interface Allergies {
  lastUpdated: string | null;
  allergies: SectionItem[];
  nkda: boolean;
}

interface Medications {
  lastUpdated: string | null;
  medications: SectionItem[];
  noMedicationsReported: boolean;
}

interface Problems {
  lastUpdated: string | null;
  problems: SectionItem[];
  noKnownProblems: boolean;
}

export interface SectionError {
  error: string;
}

export type OptionalSection = Medications | Allergies | Problems | SectionError;
export type Section = Extract<OptionalSection, { lastUpdated: string | null }>;

export const sectionIsError = (chart: OptionalSection): chart is SectionError => {
  return 'error' in chart;
};

export const sectionIsAllergies = (
  chart: OptionalSection,
): chart is Extract<OptionalSection, { error: boolean } | { allergies: SectionItem[] }> => {
  return sectionIsError(chart) || 'allergies' in chart;
};

export const sectionIsMedications = (
  chart: OptionalSection,
): chart is Extract<OptionalSection, { error: boolean } | { medications: SectionItem[] }> => {
  return sectionIsError(chart) || 'medications' in chart;
};

export const sectionIsProblems = (
  chart: OptionalSection,
): chart is Extract<OptionalSection, { error: boolean } | { problems: SectionItem[] }> => {
  return sectionIsError(chart) || 'problems' in chart;
};

export interface Chart extends BaseRecord {
  id: Patient['id'];
  [SectionType.alert]: Alert | SectionError;
  [SectionType.allergy]: Allergies | SectionError;
  [SectionType.medication]: Medications | SectionError;
  [SectionType.problem]: Problems | SectionError;
}

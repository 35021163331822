import React from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import css from 'components/features/PatientPills/TimeZone/TimeZoneLabel.module.css';
import { Clock } from 'components/ui/svg';
import Tooltip from 'components/design-system/Tooltip';

export const TimeZoneLabel = ({ patientTimeZone }) => {
  const providerTimeZone = DateTime.now().zone.name;
  const isSameTimezone = patientTimeZone === providerTimeZone;
  const isUnknownTimezone = patientTimeZone == null;

  const innerText = isUnknownTimezone
    ? 'Unknown'
    : DateTime.now().setZone(patientTimeZone).toFormat('ZZZZ · hh:mm a');

  const hoverText = isUnknownTimezone
    ? "This patient's timezone is unknown to Eden"
    : isSameTimezone
    ? 'This patient is in the same timezone as you'
    : 'This patient is in a different timezone than you';

  return (
    <span
      className={cx(css.labelContainer, css.inlineContainer)}
      data-testid={'patient-timezone-container'}
    >
      <Tooltip
        content={hoverText}
        placement={'bottom'}
        delay={[500, 0]}
        inheritButtonSize
        allowHover
      >
        <div
          className={cx(
            css.label,
            isUnknownTimezone
              ? css.unknown
              : isSameTimezone
              ? css.sameTimeZone
              : css.differentTimeZone,
          )}
          data-testid={'patient-timezone-div'}
        >
          <Clock />
          <span className={css.timeInfo}>{innerText}</span>
        </div>
      </Tooltip>
    </span>
  );
};

export default TimeZoneLabel;

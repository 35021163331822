import { createSlice } from '@reduxjs/toolkit';

export enum NetworkStatus {
  Online = 'online',
  Offline = 'offline',
}

export interface App {
  networkStatus: NetworkStatus;
}

export const initialState: App = {
  networkStatus: NetworkStatus.Online,
};

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    networkOnline: (state) => ({ ...state, networkStatus: NetworkStatus.Online }),
    networkOffline: (state) => ({ ...state, networkStatus: NetworkStatus.Offline }),
  },
});

export default app;

import React from 'react';
import _ from 'lodash';
import styles from './GoogleLoginButton.module.css';
import { GoogleLogo } from 'components/ui/svg';

type GoogleLoginButtonProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  onClick,
}: GoogleLoginButtonProps) => {
  return (
    <div onClick={onClick} className={styles.googleLoginButton}>
      <div className={styles.googleLoginButtonBorder}>
        <div className={styles.googleLoginIconWrapper}>
          <div className={styles.googleLoginIcon}>
            <GoogleLogo />
          </div>
        </div>
        <span className={styles.googleLoginText}>Sign in with Google</span>
      </div>
    </div>
  );
};

GoogleLoginButton.defaultProps = {
  onClick: _.noop,
};

export default GoogleLoginButton;
